export const ACTIVE_CHAINS = [
  "0x1",
  "0x38",
  "0x89",
  "0x2105",
  // '0x334'
];

export const ACTIVE_CHAINS_DECIMAL = [
  1, 56, 137, 8453,
  // 820
];

export const CHAINS = {
  // Mainnet: '0x1',
  // BSCTESTNET: '0x61',
  // BSCMAINNET: '0x38',
  // POLYGON: '0x89',
  // Base : '0x2105',
  // Arthera : '0x2803',
  "0x1": "Ethereum",
  "0x38": "BSC",
  // '0x61': 'BSC_TESTNET',
  "0x89": "Polygon",
  // '0x334': 'Callisto',
  "0x2105": "Base",
  // '0x2803': 'Arthera'
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const explorerLink = {
  1: "https://etherscan.io",
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
  137: "https://polygonscan.com",
  // 820: "https://explorer.callisto.network",
  8453: "https://basescan.org",
  10243: "https://explorer-test.arthera.net",
};

export const rpcLink = {
  1: "https://eth.meowrpc.com",
  56: "https://bsc-dataseed.binance.org",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  137: "https://polygon-rpc.com",
  // 820: "https://rpc.callisto.network",
  8453: "https://mainnet.base.org",
  10243: "https://rpc-test.arthera.net",
};

export const nativeTokenList = {
  56: {
    chainId: 56,
    decimals: 18,
    symbol: "BNB",
    name: "Binance Chain Coin",
    token: "0x0000000000000000000000000000000000000000",
    price: 0,
    logoURI: `../images/chains_logo/56.png`,
    balances: { 0: 0, 1: 0, available: 0, total: 0 },
    dsUnlockDate: "-",
    dsUnlockDateUTC: "-",
    networkName: "BSC",
  },
  1: {
    chainId: 1,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    token: "0x0000000000000000000000000000000000000000",
    price: 0,
    logoURI: `../images/chains_logo/1.png`,
    balances: { 0: 0, 1: 0, available: 0, total: 0 },
    dsUnlockDate: "-",
    dsUnlockDateUTC: "-",
    isNativeToken: true,
    networkName: "Ethereum",
  },
  137: {
    chainId: 137,
    decimals: 18,
    symbol: "MATIC",
    name: "Matic Token",
    token: "0x0000000000000000000000000000000000000000",
    price: 0,
    logoURI: `../images/chains_logo/137.png`,
    balances: { 0: 0, 1: 0, available: 0, total: 0 },
    dsUnlockDate: "-",
    dsUnlockDateUTC: "-",
    isNativeToken: true,
    networkName: "Polygon",
  },
  // 820: {
  //   chainId: 820,
  //   decimals: 18,
  //   symbol: "CLO",
  //   name: "Callisto",
  //   token: "0x0000000000000000000000000000000000000000",
  //   price: 0,
  //   logoURI: `../images/chains_logo/820.png`,
  //   balances: { 0: 0, 1: 0, available: 0, total: 0 },
  //   dsUnlockDate: "-",
  //   dsUnlockDateUTC: "-",
  //   isNativeToken: true,
  //   networkName: "Callisto",
  // },
  8453: {
    chainId: 8453,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    token: "0x0000000000000000000000000000000000000000",
    price: 0,
    logoURI: `../images/chains_logo/8453.png`,
    balances: { 0: 0, 1: 0, available: 0, total: 0 },
    dsUnlockDate: "-",
    dsUnlockDateUTC: "-",
    isNativeToken: true,
    networkName: "Base",
  },
  10243: {
    chainId: 10243,
    decimals: 18,
    symbol: "AA",
    name: "Arthera",
    token: "0x0000000000000000000000000000000000000000",
    price: 0,
    logoURI: `../images/chains_logo/10243.png`,
    balances: { 0: 0, 1: 0, available: 0, total: 0 },
    dsUnlockDate: "-",
    dsUnlockDateUTC: "-",
    isNativeToken: true,
    networkName: "Arthera",
  },
};

export const supportedStableCoins = {
  56: {
    "0x55d398326f99059ff775485246999027b3197955": {
      chainId: 56,
      decimals: 18,
      symbol: "USDT",
      name: "Tether USD",
      token: "0x55d398326f99059ff775485246999027b3197955",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "BSC",
    },
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": {
      chainId: 56,
      decimals: 18,
      symbol: "USDC",
      name: "USD Coin",
      token: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "BSC",
    },
    "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3": {
      chainId: 56,
      decimals: 18,
      symbol: "DAI",
      name: "Dai Token",
      token: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "BSC",
    },
  },
  137: {
    "0xc2132d05d31c914a87c6611c10748aeb04b58e8f": {
      chainId: 137,
      decimals: 18,
      symbol: "USDT",
      name: "Tether USD",
      token: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "Polygon",
    },
    "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359": {
      chainId: 137,
      decimals: 18,
      symbol: "USDC",
      name: "USD Coin",
      token: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "Polygon",
    },
    "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063": {
      chainId: 137,
      decimals: 18,
      symbol: "DAI",
      name: "Dai Token",
      token: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "Polygon",
    },
  },
  // 820: {
  //   "0xbf6c50889d3a620eb42c0f188b65ade90de958c4": {
  //     chainId: 820,
  //     decimals: 18,
  //     symbol: "BUSDT",
  //     name: "Bulls USD",
  //     token: "0xbf6c50889d3a620eb42C0F188b65aDe90De958c4",
  //     price: 0,
  //     logoURI: "",
  //     balances: { 0: 0, 1: 0, available: 0, total: 0 },
  //     dsUnlockDate: "-",
  //     dsUnlockDateUTC: "-",
  //     networkName: "Callisto",
  //   },
  // },
  8453: {
    "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913": {
      chainId: 8453,
      decimals: 18,
      symbol: "USDC",
      name: "USD Coin",
      token: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "Base",
    },
    "0x50c5725949a6f0c72e6c4a641f24049a917db0cb": {
      chainId: 8453,
      decimals: 18,
      symbol: "DAI",
      name: "Dai Token",
      token: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      price: 0,
      logoURI: "",
      balances: { 0: 0, 1: 0, available: 0, total: 0 },
      dsUnlockDate: "-",
      dsUnlockDateUTC: "-",
      networkName: "Base",
    },
  },
  1: {},
};

// Testnet config

// export const CHAIN_ID = CHAINS.BSCTESTNET;

// // export const BASE_API_HOST = "http://localhost:8443";
// export const BASE_API_HOST = 'https://api.dumpershield.exchange:8443/api/v1/test';

// export const currencyAddresses = {
//     "BNB": "0x0000000000000000000000000000000000000000",
//     "ETH": "0x0000000000000000000000000000000000000001",
//     "JNTR/e": "0x40a99d086d517f06f9d1ed564f51ef75b8f7f042",
//     "JNTR/b": "0x001667842cc59cadb0a335bf7c7f77b3c75f41c2",
//     "JNTR": "0x1350044d6a653E87Ed3384DC1D2f6b1A7F138e0A",
//     "DEGEN": "0xd337efbb55ddcbfdc1d7f4a2c32eb58a8851dc93"
// };

// export const contractAddresses = {
//     "DUMPERSHIELD_FACTORY_ADDRESS": "0xe36234dbc2380c96b82D76c80D55309F40e48CbF",
//     "DYNAMIC_PAIR_ADDRESS": "0x50a8db1461ac3F1306e76E1A87Ebc3D51bA39bAC"
// }

// Mainnet config

export const CHAIN_ID = "0x38";

export const BASE_API_HOST = "https://api.dumpershield.exchange:8443/api/v1";

export const currencyAddresses = {
  BNB: "0x0000000000000000000000000000000000000000",
  ETH: "0x0000000000000000000000000000000000000001",
  DEGEN: "0x1eea643fc6c0b4d253621839986fc566fce40704",
};

export const contractAddresses = {
  DUMPERSHIELD_FACTORY_ADDRESS: "0x2aC982F7E5BCE4Fd28D80E6633D45dA1c9398e72",
  DYNAMIC_PAIR_ADDRESS: "0x50a8db1461ac3F1306e76E1A87Ebc3D51bA39bAC",
};

export const contractAddressesChainWise = {
  "0x38": {
    DUMPERSHIELD_FACTORY_ADDRESS: "0x2aC982F7E5BCE4Fd28D80E6633D45dA1c9398e72",
  },
  "0x89": {
    DUMPERSHIELD_FACTORY_ADDRESS: "0x2aC982F7E5BCE4Fd28D80E6633D45dA1c9398e72",
  },
  "0x334": {
    DUMPERSHIELD_FACTORY_ADDRESS: "0xAae3B6850fA346f1BB6514C1c416a682E346C8e7",
  },
  "0x2105": {
    DUMPERSHIELD_FACTORY_ADDRESS: "0x2aC982F7E5BCE4Fd28D80E6633D45dA1c9398e72",
  },
  "0x1": {
    DUMPERSHIELD_FACTORY_ADDRESS: "0x2aC982F7E5BCE4Fd28D80E6633D45dA1c9398e72",
    MIGRATION_VAULT_ADDRESS: "0xe7ca156057908cc28cf76020cc2c643eb53b16fd",
  },
  "0x61": {
    MIGRATION_VAULT_ADDRESS: "0x74863De6eb9738cD6996Baf596C8eEBaAc542fbc",
  },
};

export const addChainConfigs = {
  "0x38": {
    chainId: "0x38",
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  "0x89": {
    chainId: "0x89",
    rpcUrls: ["https://polygon-rpc.com/"],
    chainName: "Matic Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  // "0x334": {
  //   chainId: "0x334",
  //   rpcUrls: ["https://rpc.callisto.network/"],
  //   chainName: "Callisto Mainnet",
  //   nativeCurrency: {
  //     name: "CLO",
  //     symbol: "CLO",
  //     decimals: 18,
  //   },
  //   blockExplorerUrls: ["https://explorer.callisto.network/"],
  // },
  "0x2105": {
    chainId: "0x2105",
    rpcUrls: ["https://mainnet.base.org/"],
    chainName: "Base",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://basescan.org"],
  },
  "0x1": {
    chainId: "0x1",
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io"],
  },
};

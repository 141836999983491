import Decimal from "decimal.js-light";

export function formatPrice(price) {
  // Convert the price to a string for manipulation
  let priceStr = Number(price).toFixed(20).replace(/0+$/, "");

  if (priceStr.includes(".")) {
    let [integerPart, decimalPart] = priceStr.split(".");

    // Find the first non-zero digit in the decimal part
    let leadingZeros =
      decimalPart.length - decimalPart.replace(/^0+/, "").length;

    // Extract the repeating zero part and the remaining part
    if (leadingZeros > 3) {
      let remainingPart = decimalPart.slice(leadingZeros);
      let subscriptZeros = leadingZeros
        .toString()
        .split("")
        .map((digit) => String.fromCharCode(0x2080 + parseInt(digit)))
        .join("");
      return `${integerPart}.0${subscriptZeros}${remainingPart.slice(0, 4)}`;
    } else {
      if (decimalPart.length === 0) {
        return `${integerPart}`;
      } else {
        return `${integerPart}.${decimalPart.slice(0, 4)}`;
      }
    }
  } else {
    return `${priceStr}`; // No decimal part
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Media from "../Theme/media-breackpoint";
import { Scrollbars } from "react-custom-scrollbars-2";
import OrderRaw from "./OrderRaw";
import { GlobalContext } from "../Context/globalContext";
import { BASE_API_HOST, explorerLink } from "../constants";
import {
  getClaimDetails,
  getSignatureDetails,
  getTokenDetails,
  vaultTransfer,
} from "../Service/otc_function";
import notificationConfig from "../config/notificationConfig";
import Web3 from "web3";
import { changeNetwork } from "../Service/metamask_function";

function OTCMyOffers(props) {
  const [account, setAccount] = useState(null);
  const [index, setIndex] = useState(4);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [offersByMe, setOffersByMe] = useState(props.offersByMe);
  const [offersToMe, setOffersToMe] = useState(props.offersToMe);
  const [tokenList, setTokenList] = useState(null);
  const [claimDetailsUser, setClaimDetailsUser] = useState([]);
  const [isClaiming, setIsClaiming] = useState(false);
  const [tokenListLoading, setTokenListLoading] = useState(true);
  const globalContext = useContext(GlobalContext);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleToggle2 = () => {
    setIsActive2(!isActive2);
  };

  const handleToggle3 = () => {
    setIsActive3(!isActive3);
  };

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      await setTokenList(globalContext.tokenList);
      setTokenListLoading(false);
    }
  }, [tokenList, globalContext.tokenList]);

  useEffect(async () => {
    if (globalContext.isWalletConnected) {
      await setAccount(globalContext.walletAddress);
      let claimDetails = await getClaimDetails({
        user: globalContext.walletAddress,
      });
      console.log(claimDetails.result);
      setClaimDetailsUser(claimDetails.result);
    }
  }, [globalContext.walletAddress]);

  const handleClaim = async (index) => {
    if (claimDetailsUser.length > 0) {
      if (claimDetailsUser[index].remainingClaimAmount <= 0) {
        notificationConfig.warning("No claim amount to claim!");
        return;
      }
      setIsClaiming(true);
      let currentClaimDetails = claimDetailsUser[index];
      let signature = await getSignatureDetails({
        user: currentClaimDetails.user,
        claimToken: currentClaimDetails.claimToken,
        chainId: currentClaimDetails.chainId,
      });
      if (
        signature.response_code === 200 &&
        signature.result.message === "Signature generated"
      ) {
        console.log(signature.result.signature.signature);
        currentClaimDetails["signature"] = signature.result.signature.signature;
        await vaultTransfer(
          currentClaimDetails,
          async (hash) => {
            // notificationConfig.success('Transaction Submitted! Please Wait For Confirmation!');
          },
          async (receipt) => {
            console.log("receipt");
            console.log(receipt);
            let createdInterval = setInterval(async () => {
              let claimDetails = await getClaimDetails({
                user: account,
              });
              console.log(claimDetails.result);
              if (
                Number(claimDetails.result[index].remainingClaimAmount) !==
                Number(claimDetailsUser[index].remainingClaimAmount)
              ) {
                await setClaimDetailsUser(claimDetails.result);
                await setIsClaiming(false);
                await notificationConfig.success("Claimed Successfully!");
                clearInterval(createdInterval);
              }
            }, 3000);
            // setAskLoading(false)
          },
          async (error) => {
            // setAskLoading(false)
            if (error.code === 4001) {
              notificationConfig.error("Transaction rejected!");
            }
          }
        );
      } else if (
        signature.response_code === 200 &&
        signature.result.code === 1
      ) {
        setIsClaiming(false);
        notificationConfig.warning(signature.result.message);
      }
    }
  };

  const onToggle = (indexNew) => setIndex(index === indexNew ? null : indexNew);

  return (
    <>
      <TDOuertext className="mt-40">
        <p>
          Claim Tokens
          <span
            data-html="true"
            data-tip="Approve other users' tax requests"
            className="helpIco"
          >
            <i className="fas fa-question-circle"></i>
          </span>
        </p>
        <div
          className={isActive ? "active caret-down" : "caret-down"}
          onClick={() => handleToggle()}
        ></div>
      </TDOuertext>
      <Scrollbars
        className={isActive ? "deactive custom-scroll" : "custom-scroll"}
        autoHeight
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        //autoHeightMin={386}
        autoHeightMax={386}
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className="thumb-horizontal" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        renderView={(props) => <div {...props} className="view" />}
      >
        <OpfyTableBX className="table1">
          <table width="100%" border="0" cellSpacing="0" cellPadding="20">
            <thead>
              <tr>
                <th width="240" align="left" valign="middle" scope="col">
                  Token name{" "}
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th width="240" align="left" valign="middle" scope="col">
                  Token Amount
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th width="240" align="left" valign="middle" scope="col">
                  Position
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th scope="col">
                  Action
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {claimDetailsUser.length > 0 ? (
                claimDetailsUser.map((ele, key) => {
                  return (
                    <tr align="left" valign="middle">
                      <td>{ele.claimTokenSymbol}</td>
                      <td className="bor02">
                        {Web3.utils.fromWei(ele.remainingClaimAmount)}
                      </td>
                      <td>
                        {ele.position === 0 ? 1 : ele.position === 1 ? 2 : 3}
                      </td>
                      <td align="center">
                        <div className="OpfyBTNbar">
                          {globalContext.chainId === "0x1" ? (
                            isClaiming ? (
                              <button className="btn03">Claiming...</button>
                            ) : (
                              <button
                                className="btn03"
                                onClick={() => {
                                  handleClaim(key);
                                }}
                              >
                                Claim
                              </button>
                            )
                          ) : (
                            <button
                              className="btn03"
                              onClick={() => {
                                changeNetwork("0x1");
                              }}
                            >
                              CHANGE NETWORK
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr align="left" valign="middle">
                  <td className="noResult" colSpan="8" align="middle">
                    No any claim available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </OpfyTableBX>
      </Scrollbars>

      <Pagination>
        <PaginationInner>
          <p>Page </p>
          <div className="p-box">
            <PNumberText>1</PNumberText>
            <div className="caret-down"></div>
          </div>
          <p>result 1-10 of 300</p>
        </PaginationInner>
      </Pagination>

      <TDOuertext className="mt-40">
        <p>
          Offers by me
          <span
            data-html="true"
            data-tip="Placed orders awaiting a buyer"
            className="helpIco"
          >
            <i className="fas fa-question-circle"></i>
          </span>
        </p>
        <div
          className={isActive2 ? "active caret-down" : "caret-down"}
          onClick={() => handleToggle2()}
        ></div>
      </TDOuertext>
      <Scrollbars
        className={isActive2 ? "deactive custom-scroll" : "custom-scroll"}
        autoHeight
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        //autoHeightMin={386}
        autoHeightMax={386}
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className="thumb-horizontal" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        renderView={(props) => <div {...props} className="view" />}
      >
        <OpfyTableBX className="table2">
          <table
            width="100%"
            border="0"
            cellSpacing="0"
            cellPadding="20" /*onClick={() => this.onToggle(1)}*/
          >
            <thead>
              <tr>
                <th align="left" valign="middle" scope="col">
                  Token name{" "}
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Vesting due date{" "}
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Token Amount
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" className="bor01" valign="middle" scope="col">
                  Face Value
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Asking Token
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Asking Amount
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Asking Price
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Competitive Value
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th scope="col">Cancel Offers</th>
              </tr>
            </thead>
            <tbody>
              {offersByMe.length > 0 ? (
                offersByMe.map((ele, key) => {
                  return (
                    <OrderRaw order={ele} tokenList={tokenList}></OrderRaw>
                  );
                })
              ) : (
                <tr align="left" valign="middle">
                  <td className="noResult" colSpan="9" align="middle">
                    No any offers
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </OpfyTableBX>
      </Scrollbars>
      {/* <OpfyText>
                                        <p><a href="javascript:void(0);" onClick={() => this.onToggle(2)}> Explore more opportunities on the marketplace</a></p>
                                    </OpfyText> */}
      <Pagination>
        <PaginationInner>
          <p>Page </p>
          <div className="p-box">
            <PNumberText>1</PNumberText>
            <div className="caret-down"></div>
          </div>
          <p>result 1-10 of 300</p>
        </PaginationInner>
      </Pagination>
      <TDOuertext>
        <p>
          Offers to me
          <span
            data-html="true"
            data-tip="Received orders pending approval"
            className="helpIco"
          >
            <i className="fas fa-question-circle"></i>
          </span>
        </p>
        <div
          className={isActive3 ? "active caret-down" : "caret-down"}
          onClick={() => handleToggle3()}
        ></div>
      </TDOuertext>
      <Scrollbars
        className={isActive3 ? "deactive custom-scroll" : "custom-scroll"}
        autoHeight
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        //autoHeightMin={386}
        autoHeightMax={386}
        renderTrackHorizontal={(props) => (
          <div {...props} className="track-horizontal" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className="thumb-horizontal" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        renderView={(props) => <div {...props} className="view" />}
      >
        <OpfyTableBX className="table3">
          <table
            width="100%"
            border="0"
            cellSpacing="0"
            cellPadding="20" /*onClick={() => this.onToggle(1)}*/
          >
            <thead>
              <tr>
                <th align="left" valign="middle" scope="col">
                  Token name{" "}
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Vesting due date{" "}
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Token Amount
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                {/* <th align="left" valign="middle" scope="col">Token Price<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th> */}
                <th className="bor01" align="left" valign="middle" scope="col">
                  Face Value
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Asking Token
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th width="11%" align="left" valign="middle" scope="col">
                  Asking Amount
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th align="left" valign="middle" scope="col">
                  Asking Price
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th width="12%" align="left" valign="middle" scope="col">
                  Competitive Value
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>
                  <a href="#" className="sortLink">
                    <i className="fas fa-sort"></i>
                  </a>
                </th>
                <th scope="col">Buy Opportunity</th>
              </tr>
            </thead>
            <tbody>
              {offersToMe.length > 0 ? (
                offersToMe.map((ele, key) => {
                  return (
                    <tr align="left" valign="middle">
                      <td>
                        <a
                          target="_blank"
                          href={
                            explorerLink[ele.chainId] +
                            "/tx/" +
                            ele.transactionHash
                          }
                        >
                          {tokenList[ele.tokenSell].symbol}
                        </a>
                      </td>
                      <td>{ele.vestingDate}</td>
                      <td>{Number(ele.sellAmount) / 10 ** 18}</td>
                      {/*     <td>${((currencyPrices[currencyNames[ele.tokenSell]])).toFixed(4)}</td> */}
                      <td className="bor01">
                        $
                        {(
                          (Number(ele.sellAmount) / 10 ** 18) *
                          tokenList[ele.tokenSell].price
                        ).toFixed(2)}
                      </td>
                      <td>{tokenList[ele.wantToken].symbol}</td>
                      <td>{Number(ele.value) / 10 ** 18}</td>
                      <td>
                        $
                        {(
                          (Number(ele.value) / 10 ** 18) *
                          tokenList[ele.wantToken].price
                        ).toFixed(2)}
                      </td>
                      <td>
                        {/* Use color-green class if value is + and color-red if - */}
                        <span className="color-red">
                          $
                          {(
                            (Number(ele.value) / 10 ** 18) *
                              tokenList[ele.wantToken].price -
                            (Number(ele.sellAmount) / 10 ** 18) *
                              tokenList[ele.tokenSell].price
                          ).toFixed(2)}{" "}
                          |{" "}
                          {(
                            ((Number(ele.sellAmount) / 10 ** 18) *
                              tokenList[ele.tokenSell].price *
                              100) /
                              ((Number(ele.value) / 10 ** 18) *
                                tokenList[ele.wantToken].price) -
                            100
                          ).toFixed(2)}
                          %
                        </span>
                      </td>
                      {/* <td>
                                                                    <div className="OpfyBTNbar v2">
                                                                        <button className="btn01" onClick={() => { this.setState({ popupMyofferClaim: true }) }}>CLAIM</button>

                                                                    </div>
                                                                </td> */}
                      <td>
                        <div className="OpfyBTNbar">
                          <button
                            className="btn03"
                            onClick={() => {
                              props.setPopupMyofferClaim(true);
                              props.setCurrentClaimOrder(ele);
                            }}
                          >
                            BUY
                          </button>
                          {/* <button className="btn02"><i className="fas fa-trash-alt"></i></button> */}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr align="left" valign="middle">
                  <td className="noResult" colSpan="10" align="middle">
                    No any offers
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </OpfyTableBX>
      </Scrollbars>

      <THirdBoxBottom>
        <OpfyText className="m-0">
          {/* <a href="javascript:void(0);" onClick={()=>handleClaim()}>Claim</a> */}
          <p>
            <a href="javascript:void(0);" onClick={() => onToggle(2)}>
              {" "}
              Explore more opportunities on the marketplace
            </a>
          </p>
        </OpfyText>
        <Pagination className="m-0">
          <PaginationInner>
            <p>Page </p>
            <div className="p-box">
              <PNumberText>1</PNumberText>
              <div className="caret-down"></div>
            </div>
            <p>result 1-10 of 300</p>
          </PaginationInner>
        </Pagination>
      </THirdBoxBottom>
    </>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const TDOuertext = styled(FlexDiv)`
  justify-content: space-between;
  &.mt-40 {
    margin-top: 40px;
  }
  p {
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    .helpIco {
      right: -5px;
      top: -10px;
      font-size: 10px !important;
    }
  }
  .caret-down {
    width: 0;
    height: 0;
    border-radius: 3px;
    border: 6px solid transparent;
    border-top: 0px;
    border-bottom: 6px solid #858686;
    transition: 0.5s ease all;
    cursor: pointer;
    ${Media.md} {
      display: block;
    }
    &.active {
      transform: rotate(-180deg);
    }
  }
`;

const OpfyTableBX = styled(FlexDiv)`
  margin: 0px;
  font-size: 14px;
  color: #fff;
  justify-content: flex-start;
  p {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
  }
  .searchBAR {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    display: flex;
    flex-direction: row;
    padding: 4px;
    align-items: center;
    justify-content: space-between;
    display: none;
    input {
      width: calc(100% - 30px);
      height: 34px;
      padding: 5px;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #545861;
      border-radius: 5px;
    }
  }
  .collapse.active .searchBAR {
    display: flex;
  }

  a {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
  .sortLink {
    position: absolute;
    right: 12px;
    margin-top: 0;
    width: auto;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
    ${Media.xl} {
      position: initial;
      margin: 0px 5px;
    }
  }
  .searchLink {
    position: absolute;
    right: 31px;
    margin-top: 0;
    width: auto;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
    ${Media.xl} {
      position: initial;
      margin: 0px 5px 0px 10px;
    }
  }
  .OpfyBTNbar {
    display: flex;
    align-items: center;
    justify-content: center; /* width:210px; */
    &.v2 {
      width: 180px;
      justify-content: center;
    }

    .btn01 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid #00f02b;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #000;
        background-color: #00f02b;
      }
    }
    .btn03 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid transparent;
      background: none;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #00f02b;
        border: 2px solid #00f02b;
        box-shadow: 0 0 10px #00f02bba;
      }
    }
    .btn02 {
      color: #858686;
      padding: 10px;
      min-width: 42px;
      &:hover {
        color: #c32b2d;
      }
      .btn03 + & {
        margin-left: 6px;
      }
      ${Media.md} {
        padding: 10px 0px;
      }
    }
  }
  table {
    min-width: max-content;
    width: 100%;
    border-collapse: collapse;
    background: #000000;
  }
  table tr td {
    border: 1px solid #1e2127;
    padding: 7px 18px;
    &:nth-last-child(01) {
      width: 212px;
    }
    &:nth-child(01) {
      color: #00f02b;
      a {
        color: #00f02b;
        :hover {
          text-decoration: underline;
        }
      }
    }
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
    &.bor02 {
      color: #4b4bff;
    }
    &.noResult {
      font-size: 14px;
      color: #9a9a9a;
      height: 120px;
    }
    .color-red {
      color: #c32b2d;
    }
    .color-green {
      color: #00f02b;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
  }

  table tr:hover td {
    background-color: #0b0c0f;
  }
  table tr th {
    border: 1px solid #1e2127;
    border-top: 2px solid #1e2127;
    border-bottom: 2px solid #1e2127;
    color: #9a9a9a;
    position: relative;
    padding: 12px;
    height: 60px; /* position: sticky; top:0; left:0; */
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
  }
`;

const Pagination = styled.div`
  margin: 10px 0px 75px;
  display: flex;
  &.m-0 {
    margin: 0;
  }
`;

const PaginationInner = styled(FlexDiv)`
  justify-content: flex-start;
  margin-left: auto;
  width: auto;
  p {
    margin: 0;
    font-size: 12px;
    color: #9a9a9a;
  }
  .caret-down {
    width: 0;
    height: 0;
    border-radius: 3px;
    border: 6px solid transparent;
    border-top: 0px;
    border-bottom: 6px solid #858686;
    transition: 0.5s ease all;
    cursor: pointer;
    transform: rotate(-180deg);
  }
  .p-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    background-color: #010202;
    border-radius: 4px;
    padding: 8px 10px;
    margin: 0px 15px;
  }
`;

const OpfyText = styled.div`
  margin: 10px 0px 50px 0px;
  &.m-0 {
    margin: 0;
  }
  p {
    color: #00f02b;
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin: 0px;
    ${Media.md} {
      margin-top: 15px;
    }
  }
  a {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
`;

const PNumberText = styled.div`
  font-size: 12px;
  color: #9a9a9a;
`;

const THirdBoxBottom = styled(FlexDiv)`
  justify-content: space-between;
  margin: 10px 0px 40px;
`;

export default OTCMyOffers;

import React, { Component } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Media from "../Theme/media-breackpoint";

import MenuIconRefresh from "./../Assets/images/lb-Icon01.png";
import MenuIconGateway from "./../Assets/images/lb-Icon02.png";
import MenuIconRouter from "./../Assets/images/lb-Icon03.png";
import MenuIconGovernance from "./../Assets/images/lb-Icon04.png";
import MenuIconUnfreeze from "./../Assets/images/lb-Icon05.png";
import AddTokens from "./../Assets/images/lb-Icon06.png";
import MenuIconOTC from "./../Assets/images/lb-Icon07.png";

class LeftMBX extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <LeftBX className="wow fadeInLeft" data-wow-delay="0.1s">
          <LBBTNBar>
            {/* <NavLink exact to="otc-old" activeClassName="active">
              <IconButton activeClassName="active">
                <img src={MenuIconRefresh} alt="" />{" "}
              </IconButton>
            </NavLink> */}
            <NavLink to="otc" activeClassName="active">
              <IconButton activeClassName="active">
                <img src={MenuIconRefresh} alt="" />{" "}
              </IconButton>
            </NavLink>
            {/* <NavLink className={"disable"} to='/Gateway' > <img src={MenuIconGateway} alt='' /> </NavLink> */}
            <NavLink className={"disable"} to="/Router">
              <IconButton activeClassName="active">
                <img src={MenuIconRouter} alt="" />{" "}
              </IconButton>
            </NavLink>
            <NavLink className={"disable"} to="/Unfreeze">
              <IconButton activeClassName="active">
                <img src={MenuIconUnfreeze} alt="" />{" "}
              </IconButton>
            </NavLink>
            <NavLink className={"disable"} to="/Governance">
              <IconButton activeClassName="active">
                <img src={MenuIconGovernance} alt="" />{" "}
              </IconButton>
            </NavLink>
            <NavLink to="/addprojecttokens">
              <IconButton activeClassName="active">
                <img src={AddTokens} alt="" />
              </IconButton>
            </NavLink>
          </LBBTNBar>
        </LeftBX>
      </>
    );
  }
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const LeftBX = styled(FlexDiv)`
  width: 82px;
  margin-top: 1px;
  background-color: #010602;
  border-right: 1px solid #1e2127;
  min-height: 90vh;
  align-items: flex-start;

  ${Media.sm} {
    width: 100%;
    margin-top: 0;
    min-height: auto;
  }
`;

const IconButton = styled.button`
  background-color: #000;
  padding: 15px 0 !important;
  &.active {
    background-color: #000;
  }
`;

const LBBTNBar = styled(FlexDiv)`
  flex-direction: column;
  width: 100%;

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    width: 100%;
    background-color: #000;
    &.active {
      background-image: linear-gradient(to right, #00f02b, #8146e4);
    }
    ${Media.sm} {
      width: 16%;
      flex-grow: 1;
      &:last-child {
        border-right: 0;
      }
    }
    ${Media.xs} {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .disable {
    position: relative;
    :hover {
      :after {
        content: "Coming Soon";
        background-color: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  ${Media.sm} {
    flex-direction: initial;
  }
`;

export default LeftMBX;

/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Gs from "../Theme/globalStyles";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import LeftMBX from "../Component/leftBar";
import BuyReqPopup from "../Component/popup/buyreqpopup";
import ClaimToken from "../Component/popup/claimToken";
import Collapse from "@kunukn/react-collapse";
import OTCTransferTokens from "../Component/OTCTransferToken";
import {
  BASE_API_HOST,
  CHAINS,
  CHAIN_ID,
  explorerLink,
  ZERO_ADDRESS,
  nativeTokenList,
} from "../constants";
import {
  addressApprove,
  getDegenPrice,
  getTokenPriceUsingPairAddress,
  getVestedTokens,
  otcCancelOrder,
} from "../Service/otc_function";
import Media from "../Theme/media-breackpoint";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  getAccountaddress,
  metamask_connection,
} from "../Service/metamask_function";
import notificationConfig from "../config/notificationConfig";
import ConnectWallet from "./connectWallet";
import ReactLoaderSpinner from "react-loader-spinner";
import { getEtherValue } from "../Service/otc_function";
import { GlobalContext } from "../Context/globalContext";
import OtcExchange from "../Component/OtcExchange";
import LiquidityGateway from "../Component/LiquidityGateway";
import _ from "lodash";
import Web3 from "web3";
import OTCMyOffers from "../Component/OTCMyOffers";
import Decimal from "decimal.js-light";

function OTC(props) {
  const [index, setIndex] = useState(4);
  const [dropDown, setDropDown] = useState(false);
  const [popupOTCClaim, setPopupOTCClaim] = useState(false);
  const [popupMyofferClaim, setPopupMyofferClaim] = useState(false);
  const [searchBX, setSearchBX] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [pairs, setPairs] = useState(null);
  const [otcOrders, setOtcOrders] = useState([]);
  const [taxProtectionRequests, setTaxProtectionRequests] = useState([]);
  const [offersByMe, setOffersByMe] = useState([]);
  const [offersToMe, setOffersToMe] = useState([]);
  const [historyPurchases, setHistoryPurchases] = useState([]);
  const [historySelling, setHistorySelling] = useState([]);
  const [currentClaimOrder, setCurrentClaimOrder] = useState(null);
  const [account, setAccount] = useState(null);
  const [currencyPrices, setCurrencyPrices] = useState({});
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [tokenList, setTokenList] = useState(null);
  const [dsUsers, setDSUsers] = useState(null);
  const [otcTradersData, setOtcTradersData] = useState([]);
  const globalContext = useContext(GlobalContext);

  useEffect(async () => {
    if (globalContext.linkToTransfer === true) {
      setIndex(3);
      globalContext.setLinkToTransfer(false);
    }
  }, [globalContext.linkToTransfer]);

  useEffect(async () => {
    if (globalContext.currencyPrices !== null) {
      await setOrdersLoading(true);
      setCurrencyPrices(globalContext.currencyPrices);
    }
  }, [globalContext.currencyPrices]);

  useEffect(async () => {
    if (globalContext.isWalletConnected) {
      await setAccount(globalContext.walletAddress);
    }
  }, [globalContext.walletAddress]);

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      setTokenList(globalContext.tokenList);
    }
  }, [globalContext.tokenList]);

  useEffect(async () => {
    if (globalContext.dsUsers !== null) {
      setDSUsers(globalContext.dsUsers);
    }
  }, [globalContext.dsUsers]);

  useEffect(async () => {
    // console.log(tokenList)
    await fetchOrders();
    await startOrderInterval();
    window.addEventListener("focus", async () => {
      await startOrderInterval();
    });
  }, [tokenList]);

  const startOrderInterval = async () => {
    let createdInterval = setInterval(async () => {
      await fetchOrders();
    }, 5000);
    window.addEventListener("blur", async () => {
      await clearInterval(createdInterval);
    });
  };

  const findMedian = async (arr) => {
    arr.sort((a, b) => a - b);
    const middleIndex = Math.floor(arr.length / 2);

    if (arr.length % 2 === 0) {
      return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
    } else {
      return arr[middleIndex];
    }
  };

  const fetchOrders = async () => {
    if (tokenList !== null && account !== null) {
      // console.log("fetching orders",tokenList)
      await fetch(BASE_API_HOST + "/otcRoute/getAllOTCMarketPlaceOrders")
        .then((res) => res.json())
        .then(
          async (result) => {
            let otcOrders = result.result;
            let allOrders = [];
            let otcTradersData = {};
            let historyPurchases = {};
            let historySelling = {};
            let pairs = {};
            let chainWiseOrders = {};
            let start = Date.now();

            for (var i = 0; i < otcOrders.length; i++) {
              let currentOrder = otcOrders[i];
              let tokenSellPrice = 0;
              let tokenWantPrice = 0;
              // let tokenSellPrice = tokenList[currentOrder.tokenSell].price;
              // let tokenWantPrice = tokenList[currentOrder.wantToken].price;
              // console.log(currentOrder.wantToken)
              // console.log(currentOrder)
              if (currentOrder.orderType === "ask") {
                // console.log(currentOrder.wantToken)
                // console.log(currentOrder.chainId)
                // console.log(tokenList[currentOrder.chainId][currentOrder.wantToken])
                tokenWantPrice =
                  tokenList[currentOrder.chainId][currentOrder.wantToken].price;
                tokenSellPrice = await getTokenPriceUsingPairAddress({
                  ...tokenList[currentOrder.chainId][currentOrder.tokenSell],
                  pairAddress: currentOrder.pairAddress,
                });
              } else {
                tokenWantPrice = await getTokenPriceUsingPairAddress({
                  ...tokenList[currentOrder.chainId][currentOrder.wantToken],
                  pairAddress: currentOrder.pairAddress,
                });
                tokenSellPrice =
                  tokenList[currentOrder.chainId][currentOrder.tokenSell].price;
              }
              // console.log(tokenSellPrice)
              let sellAmount = Web3.utils.fromWei(currentOrder.sellAmount);
              let sellAmountPrice = Number(sellAmount) * tokenSellPrice;
              let value = Web3.utils.fromWei(currentOrder.value);
              let valuePrice = Number(value) * tokenWantPrice;
              currentOrder["txLink"] =
                explorerLink[currentOrder.chainId] +
                "/tx/" +
                currentOrder.transactionHash;
              currentOrder["vestingDate"] = tokenList[currentOrder.chainId][
                currentOrder.tokenSell
              ]
                ? tokenList[currentOrder.chainId][currentOrder.tokenSell]
                    .dsUnlockDateUTC
                : 0;
              currentOrder["liquidityPosition"] =
                currentOrder.position === 0
                  ? "1st"
                  : currentOrder.position === 1
                  ? "2nd"
                  : "3rd";

              currentOrder["supplyAvailable"] = sellAmount;
              currentOrder["askingAmount"] = value;
              currentOrder["askingAmountUSD"] = valuePrice;

              if (currentOrder.orderStatus < 4) {
                currentOrder["marketPrice"] =
                  (Number(sellAmount) * tokenSellPrice) /
                  Number(tokenWantPrice);
                currentOrder["marketPriceUSD"] = sellAmountPrice;

                currentOrder["competitiveValueUSD"] =
                  valuePrice - sellAmountPrice;

                if (Number(sellAmountPrice) === 0) {
                  currentOrder["competitiveValuePercentage"] = 0;
                } else {
                  currentOrder["competitiveValuePercentage"] = new Decimal(
                    valuePrice
                  )
                    .minus(sellAmountPrice)
                    .dividedBy(sellAmountPrice)
                    .times(100);
                }
                if (
                  Number(valuePrice) === 0 ||
                  Number(sellAmount) === 0 ||
                  Number(tokenWantPrice) === 0
                ) {
                  currentOrder["otcPrice"] = 0;
                } else {
                  currentOrder["otcPrice"] = new Decimal(valuePrice)
                    .dividedBy(sellAmount)
                    .dividedBy(tokenWantPrice);
                }

                if (Number(valuePrice) === 0 || Number(sellAmount) === 0) {
                  currentOrder["otcPriceUSD"] = 0;
                } else {
                  currentOrder["otcPriceUSD"] = new Decimal(
                    valuePrice
                  ).dividedBy(sellAmount);
                }
              } else {
                currentOrder["marketPrice"] =
                  valuePrice / Number(tokenSellPrice);
                currentOrder["marketPriceUSD"] = valuePrice;

                currentOrder["competitiveValueUSD"] =
                  sellAmountPrice - valuePrice;

                if (Number(valuePrice) === 0) {
                  currentOrder["competitiveValuePercentage"] = 0;
                } else {
                  currentOrder["competitiveValuePercentage"] = new Decimal(
                    sellAmountPrice
                  )
                    .minus(valuePrice)
                    .dividedBy(valuePrice)
                    .times(100);
                }
                if (
                  Number(valuePrice) === 0 ||
                  Number(sellAmount) === 0 ||
                  Number(tokenWantPrice) === 0
                ) {
                  currentOrder["otcPrice"] = 0;
                } else {
                  currentOrder["otcPrice"] = new Decimal(sellAmountPrice)
                    .dividedBy(value)
                    .dividedBy(tokenSellPrice);
                }

                if (Number(sellAmountPrice) === 0 || Number(value) === 0) {
                  currentOrder["otcPriceUSD"] = 0;
                } else {
                  currentOrder["otcPriceUSD"] = new Decimal(
                    sellAmountPrice
                  ).dividedBy(value);
                }
              }
              if (currentOrder.boughtOrders.length > 0) {
                if (account) {
                  if (
                    currentOrder.seller.toLocaleLowerCase() ===
                    account.toLocaleLowerCase()
                  ) {
                    if (historySelling[currentOrder.chainId]) {
                      historySelling[currentOrder.chainId].push(currentOrder);
                    } else {
                      historySelling[currentOrder.chainId] = [];
                      historySelling[currentOrder.chainId].push(currentOrder);
                    }
                  }
                }
                let orderAdded = false;
                await currentOrder.boughtOrders.filter((ele) => {
                  if (account) {
                    if (
                      ele.buyer.toLocaleLowerCase() ===
                      account.toLocaleLowerCase()
                    ) {
                      if (
                        historyPurchases[currentOrder.chainId] &&
                        !orderAdded
                      ) {
                        historyPurchases[currentOrder.chainId].push(
                          currentOrder
                        );
                        orderAdded = true;
                      } else {
                        historyPurchases[currentOrder.chainId] = [];
                        historyPurchases[currentOrder.chainId].push(
                          currentOrder
                        );
                        orderAdded = true;
                      }
                    }
                  }
                });
              }

              let currentPair =
                (tokenList[currentOrder.chainId][currentOrder.tokenSell]
                  ? tokenList[currentOrder.chainId][currentOrder.tokenSell]
                      .symbol
                  : "") +
                "/" +
                (tokenList[currentOrder.chainId][currentOrder.wantToken]
                  ? tokenList[currentOrder.chainId][currentOrder.wantToken]
                      .symbol
                  : "");
              // console.log(currentPair)
              currentOrder["pair"] = currentPair;
              allOrders.push(currentOrder);

              if (pairs[currentOrder.chainId] === undefined) {
                pairs[currentOrder.chainId] = {};
              }

              if (pairs[currentOrder.chainId][currentPair] === undefined) {
                pairs[currentOrder.chainId][currentPair] = [];
                pairs[currentOrder.chainId][currentPair].push(currentOrder);
                pairs[currentOrder.chainId][currentPair]["min"] = currentOrder;
                pairs[currentOrder.chainId][currentPair]["max"] = currentOrder;
                pairs[currentOrder.chainId][currentPair][
                  "totalTransaction"
                ] = 0;
                pairs[currentOrder.chainId][currentPair]["pricesChart"] = [];
                pairs[currentOrder.chainId][currentPair]["marketPricesChart"] =
                  [];
                pairs[currentOrder.chainId][currentPair]["supplyChart"] = [];
                pairs[currentOrder.chainId][currentPair]["date"] = [];
                if (
                  currentOrder.orderStatus === "1" ||
                  currentOrder.orderStatus === "4"
                ) {
                  pairs[currentOrder.chainId][currentPair]["pricesChart"].push(
                    currentOrder.otcPriceUSD
                  );
                  pairs[currentOrder.chainId][currentPair][
                    "marketPricesChart"
                  ].push(currentOrder.marketPriceUSD);
                  pairs[currentOrder.chainId][currentPair]["supplyChart"].push(
                    currentOrder.supplyAvailable
                  );
                  pairs[currentOrder.chainId][currentPair]["date"].push(
                    new Date(currentOrder.createdAt).getTime()
                  );
                }
                if (currentOrder.boughtOrders.length > 0) {
                  pairs[currentOrder.chainId][currentPair][
                    "totalTransaction"
                  ] += 1;
                  pairs[currentOrder.chainId][currentPair]["totalAllocation"] =
                    currentOrder.boughtOrders.reduce((sum, order) => {
                      return sum + Number(getEtherValue(order.boughtAmount));
                    }, 0);
                } else {
                  pairs[currentOrder.chainId][currentPair][
                    "totalAllocation"
                  ] = 0;
                }
              } else {
                pairs[currentOrder.chainId][currentPair].push(currentOrder);
                if (
                  currentOrder.orderStatus === "1" ||
                  currentOrder.orderStatus === "4"
                ) {
                  if (
                    pairs[currentOrder.chainId][currentPair]["pricesChart"]
                      .length > 0
                  ) {
                    pairs[currentOrder.chainId][currentPair][
                      "pricesChart"
                    ].push(currentOrder.otcPriceUSD);
                    pairs[currentOrder.chainId][currentPair][
                      "marketPricesChart"
                    ].push(currentOrder.marketPriceUSD);
                    pairs[currentOrder.chainId][currentPair][
                      "supplyChart"
                    ].push(currentOrder.supplyAvailable);
                    pairs[currentOrder.chainId][currentPair]["date"].push(
                      new Date(currentOrder.createdAt).getTime()
                    );
                  } else {
                    pairs[currentOrder.chainId][currentPair]["pricesChart"] =
                      [];
                    pairs[currentOrder.chainId][currentPair][
                      "marketPricesChart"
                    ] = [];
                    pairs[currentOrder.chainId][currentPair]["supplyChart"] =
                      [];
                    pairs[currentOrder.chainId][currentPair]["date"] = [];
                    pairs[currentOrder.chainId][currentPair][
                      "pricesChart"
                    ].push(currentOrder.otcPriceUSD);
                    pairs[currentOrder.chainId][currentPair][
                      "marketPricesChart"
                    ].push(currentOrder.marketPriceUSD);
                    pairs[currentOrder.chainId][currentPair][
                      "supplyChart"
                    ].push(currentOrder.supplyAvailable);
                    pairs[currentOrder.chainId][currentPair]["date"].push(
                      new Date(currentOrder.createdAt).getTime()
                    );
                  }
                }
                if (currentOrder.boughtOrders.length > 0) {
                  pairs[currentOrder.chainId][currentPair][
                    "totalTransaction"
                  ] += 1;
                  pairs[currentOrder.chainId][currentPair]["totalAllocation"] =
                    pairs[currentOrder.chainId][currentPair][
                      "totalAllocation"
                    ] +
                    currentOrder.boughtOrders.reduce((sum, order) => {
                      return sum + Number(getEtherValue(order.boughtAmount));
                    }, 0);
                }
                if (
                  pairs[currentOrder.chainId][currentPair]["min"].otcPrice >
                  currentOrder.otcPrice
                ) {
                  pairs[currentOrder.chainId][currentPair]["min"] =
                    currentOrder;
                }
                if (
                  pairs[currentOrder.chainId][currentPair]["max"].otcPrice <
                  currentOrder.otcPrice
                ) {
                  pairs[currentOrder.chainId][currentPair]["max"] =
                    currentOrder;
                }
              }
              // console.log(pairs)
            }

            const today = new Date().getTime();
            const initialDay = today - 31536000000;

            Object.entries(pairs).map((eleOut, keyOut) => {
              otcTradersData[eleOut[0]] = [];
              Object.entries(eleOut[1]).map(async (ele, key) => {
                const pairNames = ele[0];

                const totalAmount = ele[1].reduce((total, obj) => {
                  const date = new Date(obj.updatedAt);
                  if (date.getTime() >= initialDay && date.getTime() <= today) {
                    return (
                      total +
                      obj.boughtOrders.reduce((sum, order) => {
                        return sum + Number(getEtherValue(order.boughtAmount));
                      }, 0)
                    );
                  }

                  return total + 0;
                }, 0);

                let latestDate = 0;
                let avgPriceAll = 0;
                let avgPriceAllUSD = 0;
                let countAll = 0;
                let avgPriceTillYesterday = 0;
                let count = 0;
                let change = 0;
                let lowestAsk = 0;
                let totalSeller = new Set();
                let allAsking = [];

                ele[1].forEach((order) => {
                  const date = new Date(order.updatedAt).getTime();
                  const dateCreated = new Date(order.createdAt).getTime();
                  if (
                    date >= initialDay &&
                    date <= today &&
                    date >= latestDate
                  ) {
                    latestDate = date;
                  }
                  if (order.orderStatus === "1" || order.orderStatus === "4") {
                    if (order.orderType === "ask") {
                      if (
                        order.initialValue === 0 ||
                        order.initialSellAmount === 0
                      ) {
                        allAsking.push(0);
                      } else {
                        allAsking.push(
                          new Decimal(Web3.utils.fromWei(order.initialValue))
                            .dividedBy(
                              Web3.utils.fromWei(order.initialSellAmount)
                            )
                            .toNumber()
                        );
                      }
                      avgPriceAll =
                        avgPriceAll +
                        order.initialValue / order.initialSellAmount;
                      countAll++;
                      totalSeller.add(order.seller);
                      if (lowestAsk === 0) {
                        lowestAsk =
                          order.initialValue / order.initialSellAmount;
                      } else if (
                        lowestAsk >
                        order.initialValue / order.initialSellAmount
                      ) {
                        lowestAsk =
                          order.initialValue / order.initialSellAmount;
                      }
                    } else {
                      if (
                        order.initialValue === 0 ||
                        order.initialSellAmount === 0
                      ) {
                        allAsking.push(0);
                      } else {
                        allAsking.push(
                          new Decimal(
                            Web3.utils.fromWei(order.initialSellAmount)
                          )
                            .dividedBy(Web3.utils.fromWei(order.initialValue))
                            .toNumber()
                        );
                      }
                      avgPriceAll =
                        avgPriceAll +
                        order.initialSellAmount / order.initialValue;
                      totalSeller.add(order.seller);
                      if (lowestAsk === 0) {
                        lowestAsk =
                          order.initialSellAmount / order.initialValue;
                      } else if (
                        lowestAsk >
                        order.initialSellAmount / order.initialValue
                      ) {
                        lowestAsk =
                          order.initialSellAmount / order.initialValue;
                      }
                      countAll++;
                    }
                  }
                  if (
                    dateCreated <
                    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
                  ) {
                    if (
                      order.orderStatus === "1" ||
                      order.orderStatus === "4"
                    ) {
                      if (order.orderType === "ask") {
                        avgPriceTillYesterday =
                          avgPriceTillYesterday +
                          order.initialValue / order.initialSellAmount;
                        count++;
                      } else {
                        avgPriceTillYesterday =
                          avgPriceTillYesterday +
                          order.initialSellAmount / order.initialValue;
                        count++;
                      }
                    }
                  }
                });

                if (countAll > 0) {
                  avgPriceAll = avgPriceAll / countAll;
                  avgPriceAllUSD =
                    avgPriceAll *
                    currencyPrices[nativeTokenList[eleOut[0]].symbol];
                }
                if (count > 0) {
                  avgPriceTillYesterday = avgPriceTillYesterday / count;
                }
                if (avgPriceTillYesterday > 0)
                  change =
                    ((avgPriceAll - avgPriceTillYesterday) /
                      avgPriceTillYesterday) *
                    100;

                latestDate = new Date(latestDate);
                let totalSellerSize = totalSeller.size;
                let tokenLogo =
                  tokenList[ele[1][0].chainId][ele[1][0].tokenSell].logoURI;
                let users = 0;
                dsUsers.map((eleUser) => {
                  if (totalSellerSize > 0) {
                    if (
                      Number(eleUser._id.chainId) === Number(ele[1][0].chainId)
                    )
                      users = eleUser._id.numberOfColors;
                  }
                });
                let uniqueSellers =
                  ((totalSellerSize / users) * 100).toFixed(2) +
                  " % (" +
                  totalSellerSize +
                  "/" +
                  users +
                  ")";
                let median = 0;
                if (allAsking.length > 0) {
                  median = await findMedian(allAsking);
                }
                let medianPriceAllUSD =
                  median * currencyPrices[nativeTokenList[eleOut[0]].symbol];

                otcTradersData[eleOut[0]].push({
                  pairNames,
                  totalAmount,
                  latestDate,
                  avgPriceAll,
                  avgPriceAllUSD,
                  avgPriceTillYesterday,
                  count,
                  change,
                  totalSellerSize,
                  lowestAsk,
                  tokenLogo,
                  uniqueSellers,
                  median,
                  medianPriceAllUSD,
                });
              });
            });

            let taxProtectionRequests = {};
            let offersByMe = {};
            let offersToMe = {};

            await otcOrders.filter((ele) => {
              if (ele.buyer === ZERO_ADDRESS || ele.seller === ZERO_ADDRESS) {
                if (ele.orderStatus === "1" || ele.orderStatus === "4") {
                  if (chainWiseOrders[ele.chainId]) {
                    chainWiseOrders[ele.chainId].push(ele);
                  } else {
                    chainWiseOrders[ele.chainId] = [];
                    chainWiseOrders[ele.chainId].push(ele);
                  }
                  if (
                    ele.seller === account ||
                    (ele.buyer === account && ele.tokenSell === ZERO_ADDRESS)
                  ) {
                    if (offersByMe[ele.chainId]) {
                      offersByMe[ele.chainId].push(ele);
                    } else {
                      offersByMe[ele.chainId] = [];
                      offersByMe[ele.chainId].push(ele);
                    }
                  }
                  if (
                    (ele.buyer === account && ele.wantToken === ZERO_ADDRESS) ||
                    (ele.seller === account && ele.tokenSell === ZERO_ADDRESS)
                  ) {
                    if (offersToMe[ele.chainId]) {
                      offersToMe[ele.chainId].push(ele);
                    } else {
                      offersToMe[ele.chainId] = [];
                      offersToMe[ele.chainId].push(ele);
                    }
                  }
                  if (ele.confirmatory === account) {
                    if (taxProtectionRequests[ele.chainId]) {
                      taxProtectionRequests[ele.chainId].push(ele);
                    } else {
                      taxProtectionRequests[ele.chainId] = [];
                      taxProtectionRequests[ele.chainId].push(ele);
                    }
                  }
                } else return;
              } else {
                if (ele.seller === account) {
                  if (offersByMe[ele.chainId]) {
                    offersByMe[ele.chainId].push(ele);
                  } else {
                    offersByMe[ele.chainId] = [];
                    offersByMe[ele.chainId].push(ele);
                  }
                }
                if (ele.buyer === account) {
                  if (offersToMe[ele.chainId]) {
                    offersToMe[ele.chainId].push(ele);
                  } else {
                    offersToMe[ele.chainId] = [];
                    offersToMe[ele.chainId].push(ele);
                  }
                }
              }
            });
            // console.log(historyPurchases)
            // console.log(historySelling)
            // console.log(pairs)
            // console.log(allOrders)
            // console.log(
            //   chainWiseOrders[56].filter((ele) => ele.pair === "DEGEN/BNB"),
            // );
            setPairs(pairs);
            setAllOrders(allOrders);
            setOtcOrders(chainWiseOrders);
            setTaxProtectionRequests(taxProtectionRequests); //taxProtectionRequests
            setOtcTradersData(otcTradersData);
            setOffersByMe(offersByMe); // offersByMe
            setOffersToMe(offersToMe); //offersToMe
            setHistoryPurchases(historyPurchases);
            setHistorySelling(historySelling);
            setOrdersLoading(false);
            let timeTakenOverAll = Date.now() - start;
            console.log(
              "Total time taken overall OTC : " +
                timeTakenOverAll +
                " milliseconds"
            );
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
            // this.setState({
            //     error
            // });
          }
        );
    }
  };

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const connectWallet = async () => {
    await metamask_connection(localStorage.getItem("account"), "ahrefClick");
    let chainId = localStorage.getItem("chainId");
    if (chainId === CHAIN_ID) {
      this.setState({ redirect: true });
    } else {
      // if (chainId === CHAINS.BSCTESTNET)
      //     notificationConfig.error("Please Select BSC TestNet")
      // else if (chainId === CHAINS.BSCMAINNET)
      //     notificationConfig.error("Please Select BSC MainNet")
    }
  };

  const onToggle = (indexNew) => setIndex(index === indexNew ? null : indexNew);

  const onToggle03 = (searchBXNew) =>
    setSearchBX(searchBX === searchBXNew ? null : searchBXNew);

  return (
    <>
      <MainContainer>
        <LeftMBX />
        <RightContainer>
          <DSTitle01 className="wow fadeInUp" data-wow-delay="0.1s">
            OTC
            {/* {index != 5 && (
                            <div className="DSrPart">Tax protection <span data-html='true' data-tip="Tokens received for free (which means you didn't buy them with other assets you own), may be considered by many jurisdictions as a gift that is subject to income tax. Therefore, the tax protection automatically places those gift tokens under your sub account to avoid an immediate tax event. <br/><br/>
Tax protected token require another person to help access your tokens and an accountant should be consulted.*<br/><br/>* It is your responsibility to check with a local tax expert and the tax code that applies on you by your jurisdiction.
" className="helpIco"><i className="fas fa-question-circle"></i></span>
                                <div className="apollo-element apollo-element-active apollo-field-switcher">
                                    <div className="apollo-fieldset">
                                        <label> <input type="checkbox" defaultChecked name="shortcode[active]" value="1" data-depend-id="active" data-atts="active" />
                                            <em data-on="on" data-off="off"></em><span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            )} */}
            {index === 5 && (
              <div className="linkBX01">
                <Link>How it works?</Link> <span>|</span>
                <Link to="/buy-tokens">Buy tokens from the Gateway</Link>
              </div>
            )}
          </DSTitle01>

          <MobileTab>
            <h3>Liquidity Gateway</h3>
            <div className="dropDown">
              <a
                href="javascript:void(0)"
                className={"tab-Link " + (index === 4 ? "active" : "")}
                onClick={() => onToggle(4)}
              >
                OTC exchange
              </a>
              <a
                href="javascript:void(0)"
                className={"tab-Link " + (index === 3 ? "active" : "")}
                onClick={() => onToggle(3)}
              >
                Transfer tokens
              </a>
              {/* <a href="javascript:void(0)" className={"tab-Link " + (index === 5 ? "active" : "")} onClick={() => onToggle(5)}>Liquidity gateway</a> */}
              <a
                href="javascript:void(0)"
                className={"tab-Link " + (index === 1 ? "active" : "")}
                onClick={() => onToggle(1)}
              >
                My offers
              </a>
            </div>
          </MobileTab>
          <TabNav className="wow fadeInUp" data-wow-delay="0.1s">
            <a
              href="javascript:void(0)"
              className={"tab-Link " + (index === 4 ? "active" : "")}
              onClick={() => onToggle(4)}
            >
              <span>OTC exchange</span>
            </a>

            <a
              href="javascript:void(0)"
              className={"tab-Link " + (index === 3 ? "active" : "")}
              onClick={() => onToggle(3)}
            >
              Transfer tokens
            </a>
            <a
              href="javascript:void(0)"
              className={"tab-Link " + (index === 1 ? "active" : "")}
              onClick={() => onToggle(1)}
            >
              <span>
                My offers
                {/* <i className="fas fa-question-circle"></i> */}
                {/* <GreenBadge>6</GreenBadge> */}
              </span>
            </a>
          </TabNav>
          <Collapse
            className={"collapse wow fadeInUp " + (index === 4 ? "active" : "")}
            isOpen={index === 4}
            onChange={({ state }) => {}}
            onInit={({ state }) => {}}
          >
            <OtcExchange
              account={account}
              connectWallet={connectWallet}
              otcOrders={otcOrders}
              offersByMe={offersByMe}
              ordersLoading={ordersLoading}
              currencyPrices={currencyPrices}
              setPopupMyofferClaim={setPopupMyofferClaim}
              setCurrentClaimOrder={setCurrentClaimOrder}
              pairs={pairs}
              historyPurchases={historyPurchases}
              historySelling={historySelling}
              setPopupOTCClaim={setPopupOTCClaim}
              otcTradersData={otcTradersData}
              chainId={globalContext.chainId}
              dsUsers={dsUsers}
            ></OtcExchange>
          </Collapse>
          <Collapse
            className={"collapse wow fadeInUp " + (index === 5 ? "active" : "")}
            isOpen={index === 5}
            onChange={({ state }) => {}}
            onInit={({ state }) => {}}
          >
            <LiquidityGateway></LiquidityGateway>
          </Collapse>
          <Collapse
            className={"collapse wow fadeInUp " + (index === 1 ? "active" : "")}
            isOpen={index === 1}
            data-wow-delay="0.1s"
            onChange={({ state }) => {}}
            onInit={({ state }) => {}}
          >
            {account !== null ? (
              <OTCMyOffers
                offersByMe={offersByMe}
                offersToMe={offersToMe}
                setPopupMyofferClaim={setPopupMyofferClaim}
                setCurrentClaimOrder={setCurrentClaimOrder}
              ></OTCMyOffers>
            ) : (
              <ConnectWallet></ConnectWallet>
            )}
          </Collapse>

          <Collapse
            className={"collapse " + (index === 2 ? "active" : "")}
            isOpen={index === 2}
            onChange={({ state }) => {}}
            onInit={({ state }) => {}}
          >
            <Scrollbars
              className="custom-scroll"
              autoHeight
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeightMin={386}
              autoHeightMax={386}
              renderTrackHorizontal={(props) => (
                <div {...props} className="track-horizontal" />
              )}
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              renderThumbHorizontal={(props) => (
                <div {...props} className="thumb-horizontal" />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
              renderView={(props) => <div {...props} className="view" />}
              universal={true}
            >
              <OpfyTableBX className="table4">
                <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                  <thead>
                    <tr>
                      <th align="left" valign="middle" scope="col">
                        Token Symbol
                        <a
                          href="#"
                          className={
                            "searchLink " + (searchBX === 1 ? "active" : "")
                          }
                          onClick={() => onToggle03(1)}
                        >
                          <i className="fas fa-search"></i>
                        </a>
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                        <Collapse
                          className={
                            "collapse " + (searchBX === 1 ? "active" : "")
                          }
                          isOpen={searchBX === 1}
                          data-wow-delay="0.1s"
                          onChange={({ state }) => {}}
                          onInit={({ state }) => {}}
                        >
                          <div className="searchBAR">
                            <input type="text" placeholder="Search" />
                            <a
                              href="#"
                              onClick={() => onToggle03(1)}
                              className={
                                "sortLink " + (searchBX === 1 ? "active" : "")
                              }
                            >
                              <i className="fas fa-times"></i>
                            </a>
                          </div>
                        </Collapse>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Vesting due date <br />
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Amount of <br /> Tokens
                        {/* <a href="#" className="searchLink"  ><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Token Price
                        {/* <a href="#" className="searchLink"><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Face Value
                        {/* <a href="#" className="searchLink"><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Asking Token
                        {/* <a href="#" className="searchLink"><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Asking amount
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                        {/* <a href="#" className="searchLink"><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Asking Price
                        {/* <a href="#" className="searchLink"><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Competitive
                        <br />
                        Value
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                        {/* <a href="#" className="searchLink"><i className="fas fa-search"></i></a> */}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th scope="col">Buy Opportunity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {
                                                ordersLoading ?
                                                    <tr align="left" valign="middle">
                                                        <td colSpan="9" align='middle'>
                                                            <ReactLoaderSpinner type="Bars" color="#fff" height={50} width={150} />
                                                        </td>
                                                    </tr>
                                                    :
                                                    otcOrders.length > 0 ?
                                                        otcOrders.map((ele, key) => {
                                                            return (
                                                                <tr align="left" valign="middle">
                                                                    <td><a target="_blank" href={explorerLink[ele.chainId] + "/tx/" + ele.transactionHash}>{currencyNames[ele.tokenSell]}</a></td>
                                                                    <td>{ele.vestingDate}</td>
                                                                    <td>{Number(ele.sellAmount) / 10 ** 18}</td>
                                                                    <td>${((currencyPrices[currencyNames[ele.tokenSell]])).toFixed(4)}</td>
                                                                    <td>${((Number(ele.sellAmount) / 10 ** 18) * (currencyPrices[currencyNames[ele.tokenSell]])).toFixed(2)}</td>
                                                                    <td>{currencyNames[ele.wantToken]}</td>
                                                                    <td>{Number(ele.value) / 10 ** 18}</td>
                                                                    <td>${((Number(ele.value) / 10 ** 18) * (currencyPrices[currencyNames[ele.wantToken]])).toFixed(2)}</td>
                                                                    <td>${(((Number(ele.value) / 10 ** 18) * (currencyPrices[currencyNames[ele.wantToken]])) - ((Number(ele.sellAmount) / 10 ** 18) * (currencyPrices[currencyNames[ele.tokenSell]]))).toFixed(2)} | {((((((Number(ele.value) / 10 ** 18) * (currencyPrices[currencyNames[ele.wantToken]])) - ((Number(ele.sellAmount) / 10 ** 18) * (currencyPrices[currencyNames[ele.tokenSell]]))) / ((Number(ele.sellAmount) / 10 ** 18) * (currencyPrices[currencyNames[ele.tokenSell]]))) * 100)).toFixed(2)}%</td>
                                                                    <td>
                                                                        <div className="OpfyBTNbar v2">
                                                                            {account !== null ?
                                                                                account !== ele.seller ?
                                                                                    <button className="btn01" onClick={() => { setPopupMyofferClaim(true); setCurrentClaimOrder(ele); }} >BUY</button>
                                                                                    :
                                                                                    <button className="btn01">Your request</button>
                                                                                :
                                                                                <button className="btn01" onClick={() => connectWallet()} >Connect Wallet</button>}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : null
                                            } */}
                  </tbody>
                </table>
              </OpfyTableBX>
            </Scrollbars>
            <THirdBoxBottom>
              <OpfyText className="m-0">
                <p>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      setPopupOTCClaim(true);
                    }}
                  >
                    List OTC request{" "}
                  </a>
                </p>
              </OpfyText>
              <Pagination className="m-0">
                <PaginationInner>
                  <p>Page </p>
                  <div className="p-box">
                    <PNumberText>1</PNumberText>
                    <div className="caret-down"></div>
                  </div>
                  <p>result 1-10 of 300</p>
                </PaginationInner>
              </Pagination>
            </THirdBoxBottom>
          </Collapse>
          <Collapse
            className={"collapse " + (index === 3 ? "active" : "")}
            isOpen={index === 3}
            onChange={({ state }) => {}}
            onInit={({ state }) => {}}
          >
            {account !== null ? (
              <>
                <OTCTransferTokens></OTCTransferTokens>
              </>
            ) : (
              <ConnectWallet></ConnectWallet>
            )}
          </Collapse>
        </RightContainer>
      </MainContainer>
      <BuyReqPopup
        isOpen={popupOTCClaim}
        dismiss={() => {
          setPopupOTCClaim(false);
        }}
        newOfferType={1}
        currencyPrices={currencyPrices}
      />
      <ClaimToken
        isOpen={popupMyofferClaim}
        dismiss={() => {
          setPopupMyofferClaim(false);
        }}
        orderDetails={currentClaimOrder}
        orderTradersData={otcTradersData}
        historyPurchases={historyPurchases}
      />

      <ReactTooltip effect="solid" className="myTip" />
    </>
  );
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const MainContainer = styled(FlexDiv)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

const RightContainer = styled(FlexDiv)`
  width: calc(100% - 82px);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 18px 56px;
  align-content: baseline;
  ${Media.md} {
    padding: 25px 20px;
  }
  ${Media.sm} {
    width: 100%;
  }
  .collapse {
    &.active {
      overflow: visible !important;
    }
    .custom-scroll {
      margin-top: 50px;
      ${Media.md} {
        margin-top: 30px;
      }
      &.deactive {
        display: none;
      }
    }
  }
`;

const DSTitle01 = styled(FlexDiv)`
  justify-content: space-between;
  font: 700 36px/40px "Kanit", arial;
  color: #00f02b;
  .DSrPart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: normal;
    color: #fff;
    font-weight: 700;
    ${Media.xs} {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
  .linkBX01 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #5a5e67;
    font-weight: 300;
    text-transform: initial;
    a {
      color: #00f02b;
      margin: 0 8px;
      font-family: "IBM Plex Mono", Arial, sans-serif;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
      :first-child {
        ${Media.xs} {
          margin: 0px 8px 0px 0px;
        }
      }
    }
    ${Media.xs} {
      align-items: flex-start;
      margin-top: 15px;
      flex-flow: column;
      justify-content: flex-start;
      a {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
  ${Media.xs} {
    display: block;
  }
`;

const DSTitle02 = styled(FlexDiv)`
  justify-content: flex-start;
  font: 700 21px/40px "IBM Plex Mono", arial;
  color: #ffffff;
  margin: 22px 0;
`;

const OpfyText = styled.div`
  margin: 10px 0px 50px 0px;
  &.m-0 {
    margin: 0;
  }
  p {
    color: #00f02b;
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin: 0px;
    ${Media.md} {
      margin-top: 15px;
    }
  }
  a {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
`;

const TabNav = styled(FlexDiv)`
  padding-top: 90px;
  flex-direction: row;
  ${Media.md} {
    align-items: stretch;
  }
  .tab-Link {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    flex-grow: 1;
    text-align: center;
    border-bottom: 3px solid #393d46;
    padding-bottom: 30px;
    position: relative;
    i {
      position: absolute;
      top: -3px;
      margin-left: 3px;
      i {
        font-size: 14px;
        color: #000;
        width: 23px;
        height: 23px;
        background-color: #00f02b;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
    span {
      position: relative;
    }
    ${Media.md} {
      font-size: 18px;
    }
    ${Media.sm} {
      font-size: 14px;
      padding: 15px 0px;
    }
  }

  .tab-Link.active {
    color: #00f02b;
    border-bottom-color: #00f02b;
    text-shadow: 0 0 10px #00f02b;
  }

  .hoverClass :hover span {
    display: none;
  }
  .hoverClass :hover : before {
    content: "Coming Soon";
  }
  ${Media.md} {
    display: none;
  }
  ${Media.sm} {
    padding-top: 40px;
    .tab-Link {
      width: 100%;
      text-align: left;
      &.active:after {
        content: "";
        border-width: 6px;
        border-style: solid;
        border-bottom: 0px;
        -webkit-transition: 0.5s ease all;
        transition: 0.5s ease all;
        border-color: #00f02b transparent transparent transparent;
        position: absolute;
        right: 0;
        top: 20px;
      }
    }
  }
`;
const MobileTab = styled(FlexDiv)`
  padding-top: 40px;
  display: none;
  h3 {
    color: #00f02b;
    font-size: 18px;
    padding: 15px 0px;
    border-bottom: 3px solid #00f02b;
    width: 100%;
    position: relative;
    margin: 0;
    &:after {
      content: "";
      border-width: 6px;
      border-style: solid;
      border-bottom: 0px;
      -webkit-transition: 0.5s ease all;
      transition: 0.5s ease all;
      border-color: #00f02b transparent transparent transparent;
      position: absolute;
      right: 0;
      top: 23px;
    }
  }
  .dropDown {
    width: 100%;
    display: flex;
    flex-flow: column;
    a {
      padding: 15px 0px;
      font-size: 14px;
      border-bottom: 1px solid #393d46;
      color: #fff;
    }
  }
  ${Media.md} {
    display: block;
  }
`;
const OpfyTableBX = styled(FlexDiv)`
  margin: 0px;
  font-size: 14px;
  color: #fff;
  justify-content: flex-start;
  p {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
  }
  .searchBAR {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    display: flex;
    flex-direction: row;
    padding: 4px;
    align-items: center;
    justify-content: space-between;
    display: none;
    input {
      width: calc(100% - 30px);
      height: 34px;
      padding: 5px;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #545861;
      border-radius: 5px;
    }
  }
  .collapse.active .searchBAR {
    display: flex;
  }

  a {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
  .sortLink {
    position: absolute;
    right: 12px;
    margin-top: 0;
    width: auto;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
    ${Media.xl} {
      position: initial;
      margin: 0px 5px;
    }
  }
  .searchLink {
    position: absolute;
    right: 31px;
    margin-top: 0;
    width: auto;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
    ${Media.xl} {
      position: initial;
      margin: 0px 5px 0px 10px;
    }
  }
  .OpfyBTNbar {
    display: flex;
    align-items: center;
    justify-content: center; /* width:210px; */
    &.v2 {
      width: 180px;
      justify-content: center;
    }

    .btn01 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid #00f02b;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #000;
        background-color: #00f02b;
      }
    }
    .btn03 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid transparent;
      background: none;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #00f02b;
        border: 2px solid #00f02b;
        box-shadow: 0 0 10px #00f02bba;
      }
    }
    .btn02 {
      color: #858686;
      padding: 10px;
      min-width: 42px;
      &:hover {
        color: #c32b2d;
      }
      .btn03 + & {
        margin-left: 6px;
      }
      ${Media.md} {
        padding: 10px 0px;
      }
    }
  }
  table {
    min-width: max-content;
    width: 100%;
    border-collapse: collapse;
    background: #000000;
  }
  table tr td {
    border: 1px solid #1e2127;
    padding: 7px 18px;
    &:nth-last-child(01) {
      width: 212px;
    }
    &:nth-child(01) {
      color: #00f02b;
      a {
        color: #00f02b;
        :hover {
          text-decoration: underline;
        }
      }
    }
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
    &.bor02 {
      color: #4b4bff;
    }
    &.noResult {
      font-size: 14px;
      color: #9a9a9a;
      height: 120px;
    }
    .color-red {
      color: #c32b2d;
    }
    .color-green {
      color: #00f02b;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
  }

  table tr:hover td {
    background-color: #0b0c0f;
  }
  table tr th {
    border: 1px solid #1e2127;
    border-top: 2px solid #1e2127;
    border-bottom: 2px solid #1e2127;
    color: #9a9a9a;
    position: relative;
    padding: 12px;
    height: 60px; /* position: sticky; top:0; left:0; */
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
  }
`;

const GreenBadge = styled(FlexDiv)`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #00f02b;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  right: -27px;
  top: -7px;
  ${Media.md} {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
`;

const FormBTNBX = styled(FlexDiv)`
  max-width: 664px;
  margin: 0 auto 50px auto;
  ${Media.md} {
    margin: 0 15px 50px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    ${Media.xs} {
      font-size: 14px;
    }
  }
  button {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    height: 70px;
    background-color: #383b42;
    color: #00f02b;
    border-radius: 10px;
    :hover {
      background-color: #282b32;
    }
    ${Media.md} {
      font-size: 24px;
    }
  }

  .hoverClass :hover span {
    display: none;
  }
  .hoverClass :hover : before {
    content: "Coming Soon";
  }
`;

const Pagination = styled.div`
  margin: 10px 0px 75px;
  display: flex;
  &.m-0 {
    margin: 0;
  }
`;

const PaginationInner = styled(FlexDiv)`
  justify-content: flex-start;
  margin-left: auto;
  width: auto;
  p {
    margin: 0;
    font-size: 12px;
    color: #9a9a9a;
  }
  .caret-down {
    width: 0;
    height: 0;
    border-radius: 3px;
    border: 6px solid transparent;
    border-top: 0px;
    border-bottom: 6px solid #858686;
    transition: 0.5s ease all;
    cursor: pointer;
    transform: rotate(-180deg);
  }
  .p-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    background-color: #010202;
    border-radius: 4px;
    padding: 8px 10px;
    margin: 0px 15px;
  }
`;

const PNumberText = styled.div`
  font-size: 12px;
  color: #9a9a9a;
`;

const THirdBoxBottom = styled(FlexDiv)`
  justify-content: space-between;
  margin: 10px 0px 40px;
`;

export default OTC;

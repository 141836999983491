import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from '../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import LeftMBX from '../Component/leftBar';
import DumperShieldStatus from '../Component/popup/dumperShieldStatus';
import Media from '../Theme/media-breackpoint';

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
      dropDown: false,
      popup01: false,
    };
  }

  render() {
    return (
      <>
        <MainContainer>
          <LeftMBX />
          <RightContainer>
            <FormMBX01 className='wow fadeInRight mt-50' data-wow-delay='0.1s'>
              <FormSbxLabel className='L1'>
                Choose a liquidity channels{' '}
                <span data-tip='Help Text' className='helpIco'>
                  <i className='fas fa-question-circle'></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <div className='md-checkbox md-checkbox-inline'>
                  <input type='checkbox' id='checkbox01' name='' />
                  <label htmlFor='checkbox01'>SmartSwap</label>
                </div>
                <div className='md-checkbox md-checkbox-inline'>
                  <input type='checkbox' id='checkbox02' name='' />
                  <label htmlFor='checkbox02'>PDO</label>
                </div>
                <div className='md-checkbox md-checkbox-inline'>
                  <input type='checkbox' id='checkbox03' name='' />
                  <label htmlFor='checkbox03' className='mb-0'>
                    Other channels
                  </label>
                </div>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01 className='wow fadeInRight' data-wow-delay='0.2s'>
              <FormSbxLabel className='L1'>
                Choose a period of shield{' '}
                <span data-tip='Help Text' className='helpIco'>
                  <i className='fas fa-question-circle'></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className='formBox'>
                  <span className='badge01'>DAYS</span>
                  <input type='text' />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01 className='wow fadeInRight' data-wow-delay='0.2s'>
              <FormSbxLabel className='L2'>
                Can the users vote to change the shield period?
              </FormSbxLabel>
              <FormSbxContent className='ver2'>
                <ROuter>
                  <div className='md-radio md-radio-inline'>
                    <input type='radio' id='radio01' name='apradio01' />
                    <label htmlFor='radio01'>No</label>
                  </div>
                  <RInner>
                    <div className='md-radio md-radio-inline'>
                      <input type='radio' id='radio02' name='apradio01' />
                      <label htmlFor='radio02'>Yes</label>
                    </div>
                    <MajorityBlock>
                      <p>Majority require</p>
                      <DDMenu className='formBox'>
                        <input type='text' />
                      </DDMenu>
                    </MajorityBlock>
                  </RInner>
                </ROuter>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01 className='wow fadeInRight' data-wow-delay='0.2s'>
              <FormSbxLabel className='L2'>
                Can the users vote to add/remove liquidity channels?
              </FormSbxLabel>
              <FormSbxContent className='ver2'>
                <ROuter>
                  <div className='md-radio md-radio-inline'>
                    <input type='radio' id='radio03' name='apradio02' />
                    <label htmlFor='radio03'>No</label>
                  </div>
                  <RInner>
                    <div className='md-radio md-radio-inline'>
                      <input type='radio' id='radio04' name='apradio02' />
                      <label htmlFor='radio04'>Yes</label>
                    </div>
                    <MajorityBlock>
                      <p>Majority require</p>
                      <DDMenu className='formBox'>
                        <input type='text' />
                      </DDMenu>
                    </MajorityBlock>
                  </RInner>
                </ROuter>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01 className='wow fadeInRight' data-wow-delay='0.2s'>
              <FormSbxLabel className='L2'>
                Can user vote to change the majority?
              </FormSbxLabel>
              <FormSbxContent className='ver2'>
                <ROuter>
                  <div className='md-radio md-radio-inline'>
                    <input type='radio' id='radio05' name='apradio03' />
                    <label htmlFor='radio05'>No</label>
                  </div>
                  <RInner>
                    <div className='md-radio md-radio-inline'>
                      <input type='radio' id='radio06' name='apradio03' />
                      <label htmlFor='radio06'>Yes</label>
                    </div>
                    <MajorityBlock>
                      <DDMenu className='formBox ml-0 ver2'>
                        <input type='text' />
                      </DDMenu>
                    </MajorityBlock>
                  </RInner>
                </ROuter>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01 className='wow fadeInRight' data-wow-delay='0.3s'>
              <FormSbxLabel></FormSbxLabel>
              <FormSbxContent>
                <button className='PlaOrBTN01'>ADD YOUR PROJECT</button>
              </FormSbxContent>
            </FormMBX01>
            <CodeBlock>
              <p>
                <span>
                  //user should approve tokens transfer before calling this
                  function.
                </span>
              </p>
              <p>
                <span>//if no licensee set it to address(0)</span>
              </p>
              <p>function swap(</p>
              <p>
                address tokenA,{' '}
                <span>
                  // token that user send to swap ( address(1) for BNB,
                  address(2) for ETH)
                </span>
              </p>
              <p>
                address tokenB,{' '}
                <span>
                  // token that user want to receive ( address(1) for BNB,
                  address(2) for ETH)
                </span>
              </p>
              <p>
                address receiver,{' '}
                <span>
                  // address that will receive tokens on other chain (user's
                  wallet address)
                </span>
              </p>
              <p>
                uint256 amountA,{' '}
                <span>// amount of tokens user sends to swap</span>
              </p>
              <p>
                address licensee, <span>// for now, = address(0)</span>
              </p>
              <p>
                bool isInvestment, <span>// for now, = false</span>
              </p>
              <p>
                uint128 minimumAmountToClaim,{' '}
                <span>
                  // do not claim on user behalf less of this amount. Only
                  exception if order fulfilled. For now, = 0
                </span>
              </p>
              <p>
                uint128 limitPice <span></span>// Do not match user if token A
                price less this limit. For now, = 0
              </p>
              <p>)</p>
              <p>external</p>
              <p>payable</p>
              <p>returns (bool)</p>
            </CodeBlock>
          </RightContainer>
        </MainContainer>

        <DumperShieldStatus
          isOpen={this.state.popup01}
          dismiss={() => {
            this.setState({ popup01: false });
          }}
        />
        <ReactTooltip effect='solid' className='myTip' />
      </>
    );
  }
  onToggle = (index) =>
    this.setState((state) => ({ index: state.index === index ? null : index }));
  onToggle02 = (dropDown) =>
    this.setState((state) => ({
      dropDown: state.dropDown === dropDown ? null : dropDown,
    }));
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const AddNewChanel = styled.div`
  margin-bottom: 50px;
  a {
    font-size: 12px;
    color: #00f02b;
  }
`;

const MainContainer = styled(FlexDiv)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

const RightContainer = styled(FlexDiv)`
  width: calc(100% - 82px);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 18px 56px;
  align-content: baseline;
  ${Media.md} {
    padding: 25px 20px;
  }
  ${Media.sm} {
    width: 100%;
  }
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 30px;
  ${Media.md} {
    display: block;
  }
  &.mt-50 {
    margin-top: 50px;
    ${Media.md} {
      margin-top: 35px;
    }
  }
`;

const FormSbxLabel = styled(FlexDiv)`
  width: 30%;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  line-height: 24px;
  ${Media.md} {
    width: 100%;
  }
  &.L1 {
    ${Media.md} {
      margin-bottom: 30px;
    }
  }
  &.L2 {
    ${Media.md} {
      margin-bottom: 15px;
    }
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: 70%;
  justify-content: flex-start;
  ${Media.md} {
    width: 100%;
  }
  &.ver2 {
    justify-content: space-between;
  }
  .md-checkbox {
    width: 33.33%;
    ${Media.md} {
      width: 100%;
    }
  }
  .md-radio {
    width: 28.33%;
    ${Media.md} {
      width: 100%;
    }
  }
  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    padding: 0 5px 0 40px;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }
  .md-radio label:before,
  .md-radio input[type='radio']:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }
  .PlaOrBTN01 {
    min-width: 662px;
    font-size: 30px;
    font-weight: 700;
    height: 80px;
    background-color: #00f02b;
    color: #5d6168;
    border-radius: 10px;
    :hover {
      background-color: #0dd732;
    }
    ${Media.lg} {
      font-size: 24px;
      min-width: 500px;
      height: 60px;
    }
    ${Media.xs} {
      min-width: 100%;
    }
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 56px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: '';
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
  }
  &.formBox {
    position: relative;
    input {
      width: 100%;
      height: 52px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }
    .badge01 {
      background-color: #5a5e67;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 120px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${Media.md} {
      width: 100% !important;
      margin-bottom: 0px;
    }
    &.v2 {
      width: calc(50% - 33px);
      img {
        position: absolute;
        top: 18px;
        right: 20px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const MajorityBlock = styled(FlexDiv)`
  width: 70%;
  .formBox {
    width: calc(100% - 165px);
    margin-left: 30px;
    &.ml-0 {
      margin-left: 0px;
    }
    &.ver2 {
      width: 100%;
    }
    ${Media.md} {
      margin-left: 0px;
    }
  }
  ${Media.md} {
    width: 100%;
    justify-content: flex-start;
    margin: 20px 0px 0px;
  }
  p {
    margin: 0px;
    ${Media.md} {
      margin: 0px 0px 20px;
    }
  }
`;

const ROuter = styled(FlexDiv)`
  justify-content: space-between;
`;

const RInner = styled(FlexDiv)`
  width: calc(100% - 33.33%);
  justify-content: space-between;
  ${Media.md} {
    width: 100%;
  }
`;

const CodeBlock = styled.div`
  background-color: #242831;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin: 50px 0px 60px;
  p {
    font-size: 11px;
    :first-child {
      margin-top: 0px;
    }
    :last-child {
      margin-bottom: 0px;
    }
    span {
      color: #5a5e67;
    }
  }
  ${Media.md} {
    margin: 30px 0px 40px;
  }
`;

export default AddProject;

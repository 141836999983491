/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect } from "react";
import styled from "styled-components";
import Modal from "../modal";
import Collapse from "@kunukn/react-collapse";
import Media from "../../Theme/media-breackpoint";
import {
  ZERO_ADDRESS,
  contractAddressesChainWise,
  explorerLink,
  nativeTokenList,
} from "../../constants";
import notificationConfig from "../../config/notificationConfig";
import {
  approveTokenTransfer,
  getBalance,
  otcBuyOrder,
  vaultTransfer,
} from "../../Service/otc_function";
import Web3 from "web3";
import ReactLoaderSpinner from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import {
  changeNetwork,
  getAccountaddress,
} from "../../Service/metamask_function";
import BigNumber from "bignumber.js";
import Smartswap from "../../Assets/images/smartswap.png";
import smartpayLogo from "../../Assets/images/smartpayLogo.png";
import new_token from "../../Assets/images/new_token.png";
import { GlobalContext } from "../../Context/globalContext";
import { useState } from "react";
import { useContext } from "react";
import walletBuy from "../../Assets/images/wallet buy.png";
import walletSell from "../../Assets/images/wallet sell.png";
import vaultBuy from "../../Assets/images/vault buy.png";
import vaultSell from "../../Assets/images/vault sell.png";
import Switch from "react-switch";
import { formatPrice } from "../../utils/utils";
import Decimal from "decimal.js-light";
import { Range } from "react-range";
// icon images
// import CoinIco01 from '../../Assets/images/coinicon-uniswap.png'

function ClaimToken(props) {
  const [dropDown, setDropDown] = useState(false);
  const [value, setValue] = useState(0);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderTradersData, setOrderTradersData] = useState(null);
  const [claimLoading, setClaimLoading] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [isInsufficientFund, setIsInsufficientFund] = useState(false);
  const [tokenList, setTokenList] = useState(null);
  const [requireApproval, setRequireApproval] = useState(false);
  const [position, setPosition] = useState(null);
  const [approveTokenTransferLoading, setApproveTokenTransferLoading] =
    useState(false);
  const globalContext = useContext(GlobalContext);
  const [buy, setBuy] = useState(true);
  const [wallet, setWallet] = useState(true);
  const [myPurchases, setMyPurchases] = useState(false);
  const [historyPurchases, setHistoryPurchases] = useState(null);
  const [account, setAccount] = useState(null);
  const [offerCreatorLink, setOfferCreatorLink] = useState("");
  const [remainedSupply, setRemainedSupply] = useState(0);

  const networkNames = {
    1: "ETH",
    56: "BSC",
    137: "Polyon",
    // 820: "Callisto",
    8453: "Base",
  };

  useEffect(async () => {
    if (globalContext.isWalletConnected && globalContext.chainId) {
      await setAccount(globalContext.walletAddress);
      let balance = await getBalance(
        globalContext.walletAddress,
        Web3.utils.toDecimal(globalContext.chainId)
      );
      setUserBalance(balance);
    }
  }, [globalContext.walletAddress, globalContext.chainId]);

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      setTokenList(globalContext.tokenList);
    }
  }, [globalContext.tokenList]);

  useEffect(() => {
    setHistoryPurchases(props.historyPurchases);
  }, [props.historyPurchases]);

  useEffect(async () => {
    let newOrderDetails = props.orderDetails;
    let newOrderTradersData = props.orderTradersData;
    if (orderDetails !== null && newOrderDetails !== null) {
      if (orderDetails.orderId !== newOrderDetails.orderId) resetOrderDetails();
    }
    if (newOrderDetails !== null) {
      if (newOrderDetails.orderType === "ask") {
        setBuy(true);
      } else {
        setBuy(false);
      }
    }
    setOrderTradersData(newOrderTradersData);
    setOrderDetails(newOrderDetails);
    // if(newOrderDetails !== null && tokenList !== null){
    //   setValue(Number(Web3.utils.fromWei(newOrderDetails.sellAmount).toFixed(2)))
    //   setPayableAmount((new BigNumber(Web3.utils.fromWei(newOrderDetails.sellAmount)).multipliedBy(new BigNumber(newOrderDetails.value).div(new BigNumber(newOrderDetails.sellAmount)))).toString())
    //   setPosition(newOrderDetails.position)
    //   if(tokenList[newOrderDetails.chainId][newOrderDetails.wantToken].mainBalance < Web3.utils.fromWei(newOrderDetails.value)){
    //     setIsInsufficientFund(true)
    //   } else if(newOrderDetails.tokenSell === ZERO_ADDRESS && tokenList[newOrderDetails.chainId][newOrderDetails.wantToken].allowance < Web3.utils.fromWei(newOrderDetails.value)){
    //     setRequireApproval(true)
    //   } else if(newOrderDetails.tokenSell === ZERO_ADDRESS && tokenList[newOrderDetails.chainId][newOrderDetails.wantToken].allowance === Web3.utils.fromWei(newOrderDetails.value)){
    //     setRequireApproval(false)
    //   }
    // }
  }, [props.orderDetails, props.orderTradersData, tokenList]);

  useEffect(() => {
    if (orderDetails !== null) {
      setPosition(orderDetails.position);
      const link =
        orderDetails.buyer !== ZERO_ADDRESS
          ? orderDetails.buyer
          : orderDetails.seller;
      setOfferCreatorLink(
        explorerLink[orderDetails.chainId] + "/address/" + link
      );
      setRemainedSupply(Web3.utils.fromWei(orderDetails.sellAmount, "ether"));
      handleChange(Web3.utils.fromWei(orderDetails.sellAmount, "ether"));
    }
  }, [orderDetails]);

  const resetOrderDetails = () => {
    setValue(0);
    setPayableAmount(0);
    setIsInsufficientFund(false);
    setRequireApproval(false);
  };

  const handleApproveTokenTransfer = async () => {
    if (payableAmount === "" || payableAmount === "0" || payableAmount <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }
    let chainId = localStorage.getItem("chainId");

    setApproveTokenTransferLoading(true);

    let approveTokenTransferData = {};

    approveTokenTransferData["token"] = orderDetails.wantToken;
    approveTokenTransferData["spender"] =
      contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
    approveTokenTransferData["amount"] = (
      Number(payableAmount) *
      10 ** 18
    ).toFixed();

    // otcDepositTokensData["orderId"] = orderDetails.orderId;
    // value = value / 100;
    // value = value * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // otcDepositTokensData["paymentAmount"] = Web3.utils.toWei(value.toString());
    // otcDepositTokensData["payableAmount"] = Web3.utils.toWei(value.toString());

    await approveTokenTransfer(
      approveTokenTransferData,
      (hash) => {},
      async (receipt) => {
        await globalContext.fetchTokenList();
        await handleChange(value);
        setApproveTokenTransferLoading(false);
        notificationConfig.success("Token Transfer Approved Successfully!");
      },
      async (error) => {
        setApproveTokenTransferLoading(false);

        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handleBuyOrder = async () => {
    if (payableAmount === "" || payableAmount === "0" || payableAmount <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }

    setClaimLoading(true);

    let otcBuyOrderData = {};

    otcBuyOrderData["orderId"] = orderDetails.orderId;
    otcBuyOrderData["paymentAmount"] = (
      Number(payableAmount) *
      10 ** 18
    ).toFixed();
    let actualBalance = 0;
    if (orderDetails.tokenSell === ZERO_ADDRESS) {
      actualBalance = 0;
    } else {
      actualBalance = (Number(payableAmount) * 10 ** 18).toFixed();
    }

    if (orderDetails.wantToken === ZERO_ADDRESS) {
      otcBuyOrderData["payableAmount"] = actualBalance;
    } else {
      otcBuyOrderData["payableAmount"] = 0;
    }

    if (wallet) {
      await otcBuyOrder(
        otcBuyOrderData,
        (hash) => {},
        async (receipt) => {
          await globalContext.fetchTokenList();
          setClaimLoading(false);
          props.dismiss();
          notificationConfig.success("Tokens Claim Successfully!");
        },
        async (error) => {
          setClaimLoading(false);
          if (error.code === 4001) {
            notificationConfig.error("Transaction rejected!");
          }
        }
      );
    } else {
      await vaultTransfer(
        otcBuyOrderData,
        (hash) => {},
        async (receipt) => {
          await globalContext.fetchTokenList();
          setClaimLoading(false);
          props.dismiss();
          notificationConfig.success("Tokens Claim Successfully!");
        },
        async (error) => {
          setClaimLoading(false);
          if (error.code === 4001) {
            notificationConfig.error("Transaction rejected!");
          }
        }
      );
    }
  };

  // const handleChange = (value) => {
  //   // let payableAmount = value / 100;

  //   // console.log(new BigNumber(Web3.utils.toWei(value.toString())).toString())
  //   // console.log(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
  //   // console.log(new BigNumber(Web3.utils.toWei(value.toString())).multipliedBy(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount))).toString())
  //   let payableAmount =
  //     Number(
  //       new BigNumber(value)
  //         .multipliedBy(
  //           new BigNumber(orderDetails.otcPrice).div(
  //             new BigNumber(orderDetails.supplyAvailable),
  //           ),
  //         )
  //         .toString(),
  //     ) * 1.025;
  //   console.log(payableAmount);
  //   let actualBalance = 0;
  //   if (orderDetails.tokenSell === ZERO_ADDRESS) {
  //     actualBalance = Web3.utils.fromWei(
  //       tokenList[orderDetails.chainId][orderDetails.wantToken].balances[0],
  //     );
  //   } else {
  //     if (orderDetails.wantToken === ZERO_ADDRESS) {
  //       actualBalance = Web3.utils.fromWei(userBalance);
  //     } else {
  //       actualBalance =
  //         tokenList[orderDetails.chainId][orderDetails.wantToken].mainBalance;
  //     }
  //   }
  //   let isInsufficientFund;
  //   if (orderDetails.wantToken !== ZERO_ADDRESS) {
  //     if (
  //       Number(
  //         tokenList[orderDetails.chainId][orderDetails.wantToken].allowance,
  //       ) < Number(payableAmount)
  //     ) {
  //       setRequireApproval(true);
  //     } else {
  //       setRequireApproval(false);
  //     }
  //     isInsufficientFund =
  //       Number(
  //         tokenList[orderDetails.chainId][orderDetails.wantToken].mainBalance,
  //       ) < Number(payableAmount);
  //   } else {
  //     isInsufficientFund = Number(actualBalance) < Number(payableAmount);
  //   }
  //   setValue(value);
  //   setPayableAmount(payableAmount);
  //   setIsInsufficientFund(isInsufficientFund);
  // };

  const handleChange = (value) => {
    // let payableAmount = value / 100;

    // console.log(new BigNumber(Web3.utils.toWei(value.toString())).toString())
    // console.log(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // console.log(new BigNumber(Web3.utils.toWei(value.toString())).multipliedBy(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount))).toString())
    let payableAmount =
      Number(
        new BigNumber(value)
          .multipliedBy(
            new BigNumber(orderDetails.value).div(
              new BigNumber(orderDetails.sellAmount)
            )
          )
          .toString()
      ) * 1.025;
    let actualBalance = 0;
    if (orderDetails.tokenSell === ZERO_ADDRESS) {
      actualBalance = Web3.utils.fromWei(
        tokenList[orderDetails.chainId][orderDetails.wantToken].balances[0]
      );
    } else {
      if (orderDetails.wantToken === ZERO_ADDRESS) {
        actualBalance = Web3.utils.fromWei(userBalance);
      } else {
        actualBalance =
          tokenList[orderDetails.chainId][orderDetails.wantToken].mainBalance;
      }
    }
    // console.log(Number(tokenList[orderDetails.chainId][orderDetails.wantToken].allowance))
    let isInsufficientFund;
    if (orderDetails.wantToken !== ZERO_ADDRESS) {
      if (
        Number(
          tokenList[orderDetails.chainId][orderDetails.wantToken].allowance
        ) < Number(payableAmount)
      ) {
        setRequireApproval(true);
      } else {
        setRequireApproval(false);
      }
      isInsufficientFund =
        Number(
          tokenList[orderDetails.chainId][orderDetails.wantToken].mainBalance
        ) < Number(payableAmount);
    } else {
      isInsufficientFund = Number(actualBalance) < Number(payableAmount);
    }
    setValue(value);
    setPayableAmount(payableAmount);
    setIsInsufficientFund(isInsufficientFund);
  };

  const onToggle02 = (newDropDown) =>
    setDropDown(dropDown === newDropDown ? null : newDropDown);

  const floatOnly = (event) => {
    if (event.shiftKey === true) event.preventDefault();

    var code = event.keyCode;

    if (
      (code >= 48 && code <= 57) ||
      (code >= 96 && code <= 105) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      code === 39 ||
      code === 46 ||
      code === 190 ||
      code === 110
    ) {
      // allowed characters
    } else event.preventDefault();

    if (
      event.target.value.indexOf(".") !== -1 &&
      (code === 190 || code === 110)
    )
      event.preventDefault();
  };

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Modal isOpen={props.isOpen} dismiss={props.dismiss}>
      {orderDetails !== null ? (
        <BuyPopupContainer>
          <MainContainer>
            <BuyLPopupContainer>
              <div className="header">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt={"offer"}
                    src={orderDetails.tokenLogo}
                    style={{ height: "50px", marginRight: "5px" }}
                  ></img>
                  <span style={{ fontSize: "24px", fontWeight: "500" }}>
                    {orderDetails.pair}
                  </span>
                </div>
                <div>
                  <i
                    style={{ fontSize: "8px", marginRight: "15px" }}
                    className={
                      orderDetails.liquidityPosition === "1st"
                        ? "fas fa-circle color-green"
                        : orderDetails.liquidityPosition === "2nd"
                        ? "fas fa-circle color-yellow"
                        : "fas fa-circle color-red"
                    }
                  ></i>
                  <span className="color-dull">
                    {orderDetails.liquidityPosition}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  alignItems: "flex-start",
                  fontSize: "14px",
                }}
              >
                <span className={`${buy ? "color-green" : "color-red"}`}>
                  {orderDetails.buyer !== ZERO_ADDRESS
                    ? orderDetails.buyer.slice(0, 5) +
                      "..." +
                      orderDetails.buyer.slice(orderDetails.buyer.length - 3)
                    : orderDetails.seller !== ZERO_ADDRESS
                    ? orderDetails.seller.slice(0, 5) +
                      "..." +
                      orderDetails.seller.slice(orderDetails.seller.length - 3)
                    : ""}
                </span>
                <span className="color-dull"> is selling </span>
                <span>
                  {new Decimal(
                    Web3.utils.fromWei(orderDetails.sellAmount, "ether")
                  )
                    .toNumber()
                    .toFixed(2)
                    .toLocaleString("en-US", { currency: "USD" })}{" "}
                  {orderDetails.pair.split("/")[0]}
                </span>
                <span className="color-dull"> for </span>
                <span>
                  {formatPrice(
                    new Decimal(
                      Web3.utils.fromWei(orderDetails.sellAmount, "ether")
                    )
                      .times(orderDetails.otcPrice)
                      .toString()
                  )}{" "}
                  {orderDetails.pair.split("/")[1]}
                </span>
              </div>
              <div className="buying">
                <span
                  className={`${buy ? "color-green" : "color-red"}`}
                  style={{ fontSize: "14px" }}
                >
                  {buy ? "Buying" : "Selling"}{" "}
                  <i className="fas fa-question-circle icon"></i>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <span style={{fontSize:'28px', fontWeight:'600', margin:'20px 0' }}>{value.toLocaleString('en-us')}</span> */}
                  <span
                    style={{
                      fontSize: "28px",
                      fontWeight: "600",
                      margin: "20px 0",
                    }}
                  >
                    {Number(Number(value).toFixed(5)).toLocaleString("en-US", {
                      currency: "USD",
                    })}
                  </span>
                  <img
                    alt={"buying"}
                    src={orderDetails.tokenLogo}
                    style={{ height: "40px" }}
                  ></img>
                </div>

                <FormSbxContent
                  buy={buy}
                  className="flex"
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <DragMBX style={{ width: "100%" }} buy={buy}>
                    <div className="dragorInput">
                      <Range
                        step={1}
                        min={0}
                        max={remainedSupply > 0 ? remainedSupply : 1}
                        values={[value]}
                        onChange={(values) => {
                          handleChange(values[0]);
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "15px",
                              width: "100%",
                              borderRadius: "40px",
                              backgroundColor: buy ? "#00f02b" : "#c32b2d",
                            }}
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                              backgroundColor: buy ? "#00f02b" : "#c32b2d",
                              border: "1px solid #000",
                            }}
                          />
                        )}
                      />
                    </div>
                  </DragMBX>
                  <div
                    className="SlideValue"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      min={0}
                      max={remainedSupply}
                      style={{
                        background: "transparent",
                        border: "none",
                        color: "#fff",
                        width: "100%",
                      }}
                      type="text"
                      value={value}
                      name=""
                      onKeyDown={(e) => floatOnly(e)}
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="MaxBtn"
                    onClick={() => handleChange(remainedSupply)}
                  >
                    MAX
                  </button>
                </FormSbxContent>
              </div>
              <div className="for">
                <span className="color-dull" style={{ fontSize: "14px" }}>
                  FOR <i className="fas fa-question-circle icon"></i>
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "20px 0",
                  }}
                >
                  <span style={{ fontSize: "28px", fontWeight: "600" }}>
                    {formatPrice(payableAmount)}
                  </span>
                  <img
                    alt={"for"}
                    src={
                      orderDetails.pair.split("/")[1] ===
                      nativeTokenList[orderDetails.chainId].symbol
                        ? tokenList[orderDetails.chainId][ZERO_ADDRESS].logoURI
                        : new_token
                    }
                    style={{ height: "40px" }}
                  ></img>
                </div>
                <span
                  className="color-dull"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  [$
                  {formatPrice(payableAmount * orderDetails.otcPriceUSD)}]
                </span>
              </div>
              <div className="aptContainer">
                <FormBTNBX>
                  {/* <button className='btnGrey disable'><img src={Smartswap}></img>SmartPayment</button> */}
                  {Web3.utils.toHex(orderDetails.chainId) !==
                  globalContext.chainId ? (
                    <button
                      className={`${buy ? "btnGreen" : "btnRed"}`}
                      onClick={() => {
                        changeNetwork(Web3.utils.toHex(orderDetails.chainId));
                      }}
                    >
                      CHANGE NETWORK
                    </button>
                  ) : isInsufficientFund ? (
                    <button className={`${buy ? "btnGreen" : "btnRed"}`}>
                      Insufficient Fund
                    </button>
                  ) : requireApproval ? (
                    approveTokenTransferLoading ? (
                      <button className="white">
                        <ReactLoaderSpinner
                          type="ThreeDots"
                          color="#00f02b"
                          height={15}
                          width={80}
                        />
                      </button>
                    ) : (
                      <button
                        className="white"
                        onClick={() => handleApproveTokenTransfer()}
                      >
                        Approve
                      </button>
                    )
                  ) : (
                    <button
                      className={`${buy ? "btnGreen" : "btnRed"}`}
                      onClick={() => handleBuyOrder()}
                    >
                      {claimLoading ? (
                        <ReactLoaderSpinner
                          type="ThreeDots"
                          color="#fff"
                          height={15}
                          width={80}
                        />
                      ) : (
                        "ACCEPT THE " + orderDetails.orderType.toUpperCase()
                      )}
                    </button>
                  )}
                </FormBTNBX>
                <div className="walletImg">
                  <img
                    style={{ height: "100%", cursor: "pointer" }}
                    src={
                      buy
                        ? wallet
                          ? walletBuy
                          : vaultBuy
                        : wallet
                        ? walletSell
                        : vaultSell
                    }
                    alt={
                      buy
                        ? wallet
                          ? "Wallet Buy"
                          : "Vault Buy"
                        : wallet
                        ? "Wallet Sell"
                        : "Vault Sell"
                    }
                    onClick={() => setWallet(!wallet)}
                  ></img>
                </div>
              </div>
              <div className="desc">
                <p style={{ margin: "5px 0" }}>
                  <span className={`${buy ? "color-green" : "color-red"}`}>
                    {buy ? "Buying" : "Selling"}{" "}
                  </span>
                  <span>
                    {value} {orderDetails.pair.split("/")[0]}
                  </span>
                  <span className="color-dull"> for </span>
                  <span>
                    {formatPrice(payableAmount)}{" "}
                    {orderDetails.pair.split("/")[1]}
                  </span>
                </p>
                <p className="color-dull" style={{ margin: "5px 0" }}>
                  *{" "}
                  <span className="color-white">
                    {orderDetails.pair.split("/")[0]}
                  </span>{" "}
                  tokens will be deposited into your vault after settlement.
                </p>
                <p className="color-dull" style={{ margin: "5px 0" }}>
                  ** Total cost after fees includes 2.5% OTC fee and swap fees
                  if payment method different from asking payment.
                </p>
                <p className="color-dull" style={{ margin: "5px 0" }}>
                  *** 100% gas and fee reimbursement.
                </p>
              </div>
            </BuyLPopupContainer>
            <BuyRPopupContainer>
              <OfferDetailTitle>Offer details</OfferDetailTitle>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Offer</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span>
                    {Number(
                      Number(orderDetails.supplyAvailable).toFixed(2)
                    ).toLocaleString("en-US", { currency: "USD" })}{" "}
                    {
                      tokenList[orderDetails.chainId][orderDetails.tokenSell]
                        .symbol
                    }
                  </span>
                  <img
                    alt={"offer"}
                    src={orderDetails.tokenLogo}
                    style={{ width: "25px", marginLeft: "5px" }}
                  ></img>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>For</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span>
                    {formatPrice(
                      new Decimal(
                        Web3.utils.fromWei(orderDetails.sellAmount, "ether")
                      )
                        .times(orderDetails.otcPrice)
                        .toString()
                    ) +
                      " " +
                      orderDetails.pair.split("/")[1]}
                  </span>
                  <img
                    alt={"for"}
                    src={
                      orderDetails.pair.split("/")[1] ===
                      nativeTokenList[orderDetails.chainId].symbol
                        ? tokenList[orderDetails.chainId][ZERO_ADDRESS].logoURI
                        : new_token
                    }
                    style={{
                      height: "25px",
                      marginLeft: "5px",
                      marginRight: "3px",
                    }}
                  ></img>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Price / Token</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span>$ {formatPrice(orderDetails.otcPriceUSD)}</span>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Liquidity Postion</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <i
                    style={{ fontSize: "8px", marginRight: "15px" }}
                    className={
                      orderDetails.liquidityPosition === "1st"
                        ? "fas fa-circle color-green"
                        : orderDetails.liquidityPosition === "2nd"
                        ? "fas fa-circle color-yellow"
                        : "fas fa-circle color-red"
                    }
                  ></i>
                  <span className="color-dull">
                    {orderDetails.liquidityPosition}
                  </span>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>OTC avg price</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span className="color-dull">
                    ${" "}
                    {formatPrice(
                      orderTradersData
                        ? orderTradersData[orderDetails.chainId]
                          ? orderTradersData[orderDetails.chainId].find(
                              (el) => el.pairNames === orderDetails.pair
                            )
                            ? Number(
                                orderTradersData[orderDetails.chainId].find(
                                  (el) => el.pairNames === orderDetails.pair
                                ).medianPriceAllUSD
                              )
                            : 0
                          : 0
                        : 0
                    )}
                  </span>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Spread to avg</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span
                    className={
                      orderTradersData[orderDetails.chainId].find(
                        (el) => el.pairNames === orderDetails.pair
                      ).change > 0
                        ? "color-green"
                        : orderTradersData[orderDetails.chainId].find(
                            (el) => el.pairNames === orderDetails.pair
                          ).change === 0
                        ? ""
                        : "color-red"
                    }
                  >
                    (
                    {orderTradersData[orderDetails.chainId].find(
                      (el) => el.pairNames === orderDetails.pair
                    ).change > 0
                      ? "+"
                      : ""}
                    {Number(
                      orderTradersData[orderDetails.chainId].find(
                        (el) => el.pairNames === orderDetails.pair
                      ).change
                    ).toFixed(2)}
                    %)
                  </span>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Total supply</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span>
                    {Number(
                      Number(orderDetails.supplyAvailable).toFixed(2)
                    ).toLocaleString("en-US", {
                      currency: "USD",
                    }) + " "}
                    {
                      tokenList[orderDetails.chainId][orderDetails.tokenSell]
                        .symbol
                    }
                  </span>
                  <img
                    alt={"total supply"}
                    src={orderDetails.tokenLogo}
                    style={{ width: "25px", marginLeft: "5px" }}
                  ></img>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Remaining supply</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span>
                    {Number(orderDetails.supplyAvailable).toLocaleString(
                      "en-US",
                      {
                        currency: "USD",
                      }
                    )}{" "}
                    {
                      tokenList[orderDetails.chainId][orderDetails.tokenSell]
                        .symbol
                    }
                  </span>
                  <img
                    alt={"remaining supply"}
                    src={orderDetails.tokenLogo}
                    style={{ width: "25px", marginLeft: "5px" }}
                  ></img>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Offer Creator</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <a
                    href={offerCreatorLink}
                    target="_blank"
                    rel="noreferrer"
                    className="color-blue"
                  >
                    {orderDetails.buyer !== ZERO_ADDRESS
                      ? orderDetails.buyer.slice(0, 5) +
                        "..." +
                        orderDetails.buyer.slice(orderDetails.buyer.length - 3)
                      : orderDetails.seller !== ZERO_ADDRESS
                      ? orderDetails.seller.slice(0, 5) +
                        "..." +
                        orderDetails.seller.slice(
                          orderDetails.seller.length - 3
                        )
                      : ""}
                  </a>
                </div>
              </OfferDetailItem>
              <OfferDetailItem>
                <div className="optionTitle">
                  <span>Offer Tx</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span className="color-dull">
                    {networkNames[orderDetails.chainId]}
                  </span>
                  <a
                    href={orderDetails.txLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="fas fa-external-link-alt color-dull icon"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </a>
                </div>
              </OfferDetailItem>
              <OfferDetailItem style={{ border: "none" }}>
                <div className="optionTitle">
                  <span>Listed at</span>
                  <i className="fas fa-question-circle icon"></i>
                </div>
                <div className="optionDetail">
                  <span className="color-dull">{orderDetails.vestingDate}</span>
                </div>
              </OfferDetailItem>
            </BuyRPopupContainer>
          </MainContainer>
          <div>
            <PurchasesSwitch>
              <span
                style={{
                  marginRight: "20px",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                My Purchases
              </span>
              <Switch
                onChange={() => setMyPurchases(!myPurchases)}
                checked={myPurchases}
              />
            </PurchasesSwitch>
            <TableMain className="height1">
              <table
                className="border-cell"
                width="100%"
                border="0"
                cellSpacing="0"
                cellPadding="20"
              >
                <thead>
                  <tr>
                    <th width="25%" align="left" valign="middle" scope="col">
                      Transaction Date{" "}
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="25%" align="left" valign="middle" scope="col">
                      Allocation Purchased{" "}
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="25%" align="left" valign="middle" scope="col">
                      Purchase Price{" "}
                      <span
                        data-html="true"
                        data-tip="test"
                        className="helpIco"
                        currentitem="false"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>{" "}
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="25%" align="left" valign="middle" scope="col">
                      Proof
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {historyPurchases[orderDetails.chainId] ? (
                    historyPurchases[orderDetails.chainId].map((ele, key) => {
                      return ele.boughtOrders.length > 0
                        ? ele.boughtOrders.map((subEle, key) => {
                            if (
                              subEle.buyer.toLocaleLowerCase() ===
                              account.toLocaleLowerCase()
                            ) {
                              return (
                                <tr key={key}>
                                  <td className="color-dull">
                                    {ele.vestingDate}
                                  </td>
                                  <td className="color-dull">
                                    {formatPrice(
                                      Web3.utils.fromWei(subEle.boughtAmount)
                                    )}
                                  </td>
                                  <td className="color-dull">
                                    {formatPrice(ele.marketPrice)}
                                    <Block className="color-dull">
                                      [${formatPrice(ele.marketPrice)}]
                                    </Block>
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      href={
                                        explorerLink[ele.chainId] +
                                        "/tx/" +
                                        subEle.transactionHash
                                      }
                                      rel="noreferrer"
                                      className="color-blue"
                                    >
                                      {subEle.transactionHash.slice(0, 5) +
                                        "..." +
                                        subEle.transactionHash.slice(62, 66)}
                                    </a>
                                  </td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })
                        : null;
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" align="middle">
                        No transaction
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableMain>
          </div>
        </BuyPopupContainer>
      ) : null}
    </Modal>
  );
}

const FlexDiv = styled.div`
  display: flex;
  flex-direction: auto;
  max-width: 1220px;
  margin: 0 auto;
`;

const BuyPopupContainer = styled(FlexDiv)`
  flex-direction: column;
`;

const MainContainer = styled(FlexDiv)`
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  ${Media.md} {
    flex-direction: column;
    gap: 30px;
  }
`;

const BuyLPopupContainer = styled(FlexDiv)`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #1e2127;
  padding: 30px 20px;
  width: 100%;
  margin-right: 20px;
  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;
  }
  .buying {
    display: flex;
    flex-direction: column;
    border: 1px solid #1e2127;
    padding: 20px;
    width: 100%;
    margin-top: 30px;
    border-radius: 15px 15px 0 0;
  }
  .for {
    display: flex;
    flex-direction: column;
    border: 1px solid #1e2127;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0 0 15px 15px;
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-white {
    color: #fff;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  .desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
  }
  .walletImg {
    margin-left: 10px;
    height: 70px;
    padding: 5px;
    background: #191b23;
    border-radius: 5px;
    border: 1px solid #191b23;
    justify-content: center;
    align-items: center;
  }
  .aptContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  ${Media.md2} {
    width: -webkit-fill-available;
    max-width: 100%;
    margin: 0px 15px;
  }
`;

const BuyRPopupContainer = styled(FlexDiv)`
  display: flex;
  flex-direction: column;
  border: 1px solid #1e2127;
  padding: 30px 20px;
  width: 60%;
  ${Media.md2} {
    width: -webkit-fill-available;
    max-width: 100%;
    margin: 0px 15px;
  }
`;

const OfferDetailTitle = styled(FlexDiv)`
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  color: #fff;
  margin-bottom: 20px;
  ${Media.sm} {
    margin-top: 0px;
  }
`;

const OfferDetailItem = styled(FlexDiv)`
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #1e2127;
  justify-content: space-between;
  align-items: center;
  .optionTitle {
    color: #9a9a9a;
  }
  .optionDetail {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 12px !important;
    position: relative;
    right: -5px;
    top: -5px;
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  ${Media.md2} {
    width: -webkit-fill-available;
    max-width: 100%;
    margin: 0px 15px;
  }
`;

const BrPtitle01 = styled(FlexDiv)`
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 60px;
  &.MRFix01 {
    margin-top: 120px;
    ${Media.sm} {
      margin-top: 0px;
    }
  }
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 15px;
  ${Media.sm} {
    display: block;
  }
  &.mtb-50 {
    margin: 50px 0px 0;
  }
`;

const FormSbxLabel = styled(FlexDiv)`
  width: 350px;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 300;
  ${Media.sm} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: calc(100% - 350px);
  justify-content: flex-start;
  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }
  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }
  &.flex {
    flex-flow: row;
  }
  .SlideValue {
    width: 100px;
    height: 42px;
    border: 1px solid #5a5e67;
    border-radius: 5px;
    background: none;
    margin-left: 30px;
    margin-right: 10px;
    padding: 0 10px;
    color: #ffffff;
  }
  .MaxBtn {
    width: 100px;
    height: 42px;
    border: none;
    background: ${(props) => (props.buy ? "#00f02b" : "#c32b2d")};
    color: #fff;
    border-radius: 5px;
  }
  ${Media.sm} {
    width: 100%;
  }
  &.justify-between {
    justify-content: space-between;
    font-weight: 300;
    font-size: 18px;
  }

  ${Media.sm} {
    width: 100%;
  }
  .infotxt {
    position: relative;
    font-size: 12px;
    color: #9a9a9a;
    margin: 7px 0 0 0;
    justify-content: flex-end;
    width: 100%;
  }

  .md-radio {
    margin: 0;
    label {
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      padding-left: 44px;
      &:after {
        background-color: #00f02b;
      }
    }
    &.noWidth {
      width: auto;
    }
  }
  .spaceB {
    justify-content: space-between;
    padding: 0 0 0 15px;
  }
  .redCol {
    .md-radio label:after {
      background-color: #c32b2d;
    }
  }

  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }

  .disable {
    position: relative;
    :hover {
      :after {
        content: "Coming Soon";
        background-color: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .InputInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 0;
    .infotxt {
      position: static;
    }
  }
  .flexBox {
    display: flex;
    width: 100%;
    margin: 25px 0 0 0;
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 67px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 52px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    z-index: 1000;
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;
const FormBTNBX = styled(FlexDiv)`
  margin: 15px 0px;
  width: 100%;
  /* button{ width:100%;  font-size:24px; font-weight:700; height:70px; background-color:#00f02b; color:#000; border-radius:10px; */
  /* :hover{ background-color:#0dd732; color:#313740;} } */
  .btnGrey {
    font-size: 24px;
    font-weight: 700;
    background: #5a5e67;
    border-radius: 10px;
    height: 70px;
    padding: 0 45px;
    color: #fff;
    width: 320px;
    display: flex;
    align-items: center;
    img {
      margin-right: 18px;
    }
    &.full {
      width: 100%;
      justify-content: center;
    }
    &:hover {
      filter: brightness(1.2);
    }
  }
  .btnGreen {
    font-size: 24px;
    font-weight: 700;
    background: none;
    border-radius: 10px;
    height: 70px;
    padding: 0 45px;
    color: #fff;
    border: 2px solid #00f02b;
    margin-left: auto;
    box-shadow: 0 0 10px #00f02b;
    width: 100%;
    &:hover {
      background: #00f02b;
    }
    &.full {
      width: 100%;
      justify-content: center;
    }
  }
  .btnRed {
    font-size: 24px;
    font-weight: 700;
    background: none;
    border-radius: 10px;
    height: 70px;
    padding: 0 45px;
    color: #fff;
    border: 2px solid #c32b2d;
    margin-left: auto;
    box-shadow: 0 0 10px #c32b2d;
    width: 100%;
    &:hover {
      background: #c32b2d;
    }
    &.full {
      width: 100%;
      justify-content: center;
    }
  }
  .btnInfo {
    font-size: 12px;
    color: #9a9a9a;
    width: 100%;
    margin-top: 5px;
  }
  .disable {
    position: relative;
    :hover {
      :after {
        content: "Coming Soon";
        background-color: rgba(0, 0, 0, 1);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .white {
    font-size: 24px;
    font-weight: 700;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 10px;
    height: 70px;
    padding: 0 45px;
    margin-left: auto;
    box-shadow: 0 0 10px #ffffff;
    color: #00f02b;
    width: 100%;
    &:hover {
      background-color: #ffffff;
      color: #00f02b;
    }
    &.full {
      width: 100%;
      justify-content: center;
    }
  }

  ${Media.sm} {
    .btnGrey {
      font-size: 20px;
      font-weight: 500;
    }
    .btnGreen {
      font-size: 20px;
      font-weight: 500;
    }
    .btnRed {
      font-size: 20px;
      font-weight: 500;
    }
    .btnWhite {
      font-size: 20px;
      font-weight: 500;
    }
  }
`;
const Spacer = styled(FlexDiv)`
  height: 1px;
  margin: 12px 0 25px 0;
  background-color: #1e2127;
`;

const DragMBX = styled(FlexDiv)`
  position: relative;

  .dragorInput {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0px;
  }

  .dragorInput .input-range__slider {
    -webkit-appearance: none;
    appearance: none;
    background: ${(props) => (props.buy ? "#00f02b" : "#c32b2d")};
    border: 1px solid #000 !important;
    cursor: pointer;
    display: block;
    margin-left: -0.9rem;
    margin-top: -1.4rem;
    color: #fff !important;
    font-weight: 700;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 29px;
    height: 29px;
    font-weight: 400;
    border: 0px;
    border-radius: 50%;
    // transform: translateY(-22px);
  }

  .dragorInput .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .dragorInput .input-range__slider-container {
    transition: left 0.3s ease-out;
    z-index: 100;
  }
  .dragorInput .input-range__label {
    color: #8e9195;
    font-size: 12px;
    transform: translateZ(0);
    white-space: nowrap;
  }
  .dragorInput .input-range__label--min,
  .input-range__label--max {
    bottom: auto;
    position: absolute;
    display: none;
  }
  .dragorInput .input-range__label--min {
    left: 0px;
    top: -30px;
    z-index: 2;
    display: none;
    &:before {
      content: "\f056";
      color: #c32b2d;
      font-family: "Font Awesome 5 Free";
      font-weight: bold;
      font-size: 16px;
      display: inline-block;
      vertical-align: top;
      margin: -2px 11px 0 0;
    }
  }
  .dragorInput .input-range__label--max {
    right: 0px;
    top: -30px;
    &:after {
      content: "\f055";
      color: #00f02b;
      font-family: "Font Awesome 5 Free";
      font-weight: bold;
      font-size: 16px;
      display: inline-block;
      vertical-align: top;
      margin: -2px 0 0 11px;
    }
  }
  .dragorInput .input-range__label--value {
    position: absolute;
    display: none;
    top: 15px;
    z-index: 2;
    color: #fff;
    left: 0px;
    pointer-events: none;
    transform: translateX(-50%);
  }
  .dragorInput .input-range__label-container {
    left: 0%;
    position: relative;
    color: #8e9195 !important;
    font: 700 14px/16px "IBM Plex Mono", arial;
    pointer-events: none;
  }
  .dragorInput .input-range__label--max .input-range__label-container {
    left: 0%;
  }
  .dragorInput .input-range__track {
    background: #393e48;
    cursor: pointer;
    display: block;
    height: 15px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .dragorInput .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .dragorInput .input-range__track--active {
    background: ${(props) => (props.buy ? "#00f02b" : "#c32b2d")};
  }
  .dragorInput .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
  .dragorInput .input-range__label--value .input-range__label-container {
    color: #fff !important;
    font-weight: 700;
    font-size: 14px;
    position: relative;
  }

  .input-range__track {
    border-radius: 40px;
  }
  .dragorInput .input-range__label--value {
    &:after {
      content: "";
      border: none;
      width: fit-content;
      left: -12px;
      right: 0;
      bottom: -34px;
      color: #fff;
      font-size: 12px;
      font-family: "IBM Plex Mono", arial;
      text-align: center;
      font-weight: 400;
      position: absolute;
    }
  }
`;

const FormHint = styled.div`
  margin: 20px 0 0 0;
  font-size: 11px;
  color: #8e9195;
  line-height: 20px;
  padding-left: 0;
  text-align: left;
  width: 100%;
  span {
    font-size: 12px !important;
    top: 0px;
  }
  & + & {
    margin-top: 0;
  }
  img {
    margin-left: 10px;
  }
`;

const DDMenuSmall = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 16px;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  margin: 0 0 -13px 0;
  width: 160px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 5px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 64px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }

    &.green {
      input {
        color: #00f02b;
        font-size: 18px;
      }
    }
    &.red {
      input {
        color: #c32b2d;
        font-size: 18px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  &.ApBX01 {
    position: relative;

    .plsBtn {
      position: absolute;
      left: 4px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;
      :hover {
        background-color: #00f02b;
      }
      i {
        margin: 0;
      }
    }
    .minBtn {
      position: absolute;
      left: 54px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      :hover {
        background-color: #c32b2d;
      }
      i {
        margin: 0;
      }
    }

    input {
      padding-left: 120px;
      font-weight: 400;
    }
    span {
      background-color: #5a5e67;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 60px;
      border-radius: 0 6px 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const DropMain = styled.div`
  position: relative;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  display: flex;
  width: 100%;
  ${DDMenu} {
    border: 0px;
    &.xs {
      border-left: 2px solid #5a5e67;
      border-radius: 0px;
      width: 108px;
      justify-content: center;
    }
  }
  .infotxt.dropRed {
    ${DDMenuSmall} {
      color: #c32b2d;
    }
  }
`;
const Divider = styled.div`
  border-bottom: 1px solid #202125;
  display: block;
  width: 100%;
  margin: 55px 0 48px;
`;

const PurchasesSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableMain = styled.div`
  position: relative;
  overflow: auto;
  margin: 30px 0;
  max-width: 100%;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #1e2127;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #1e2127; */
    box-shadow: inset 0 0 0 2px #1e2127;
  }
  &.height1 {
    max-height: 281px;
    min-height: 281px;
  }
  &.height2 {
    max-height: 281px;
  }
  &.height3 {
    max-height: 622px;
    min-height: 622px;
  }
  table {
    width: 100%;
    background: #000;
    max-width: 100%;
    border: 1px solid #1e2127;
    border-collapse: separate;
    thead {
      position: sticky;
      top: 0;
      z-index: 5;
      th {
        position: sticky;
        top: 0;
        left: 0;
        border-top: 1px solid #1e2127;
        border-bottom: 1px solid #1e2127;
        border-right: 1px solid #1e2127;
        &:last-child {
          border-right: 0;
        }
        &.highlighted {
          background: #191c22;
        }
        &.ColSticky {
          right: 0px;
          position: sticky;
          border-right: 1px solid #1e2127 !important;
          padding-right: 10px;
          ${Media.lg} {
            box-shadow: -6px 5px 10px 0 rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    td {
      border-top: 1px solid #1e2127;
    }
    &.border-cell {
      td {
        border-bottom: 0 solid #1e2127;
        border-right: 1px solid #1e2127;
        &:first-child {
          a {
            color: #fff;
            white-space: nowrap;
          }
        }
        &:last-child {
          border-right: 0;
          a:not(.btn02) {
            color: #3131ff;
          }
        }
        &.ColSticky {
          right: 0;
          border-right: 1px solid #1e2127 !important;
          position: sticky;
          background: #000;
          overflow: hidden;
          /* &:after {content: ""; background: #000; z-index: 1; position: absolute; top: 0; left: 0; right: 0; bottom: 0;} */
          ${Media.lg} {
            box-shadow: -6px 5px 10px 0 rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  table tr td {
    padding: 10px 18px;
    color: #fff;
    height: 60px;
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
    &.bor02 {
      color: #4b4bff;
    }
    &.highlighted {
      background: #191c22;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
    .fa-circle {
      font-size: 8px;
      line-height: 16px;
      vertical-align: top;
      margin-right: 8px;
    }
  }

  table tr:hover td {
    background-color: #010202;
  }
  table tr th {
    background-color: #000;
    color: #9a9a9a;
    position: relative;
    padding: 12px;
    height: 60px;
    padding-right: 27px;
    a {
      color: #9a9a9a;
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-last-child(01) {
    }
    &:nth-child(01) {
    }
    &.bor01 {
      border-right: 5px solid #545861;
    }
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  .btn01 {
    min-width: 102px;
    white-space: nowrap;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #00f02b;
      box-shadow: 0 0 10px #00f02b;
    }
  }
  .btn02 {
    width: -webkit-fill-available;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #c32b2d;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #c32b2d;
      box-shadow: 0 0 10px #c32b2d;
      cursor: pointer;
    }
  }
`;

const Block = styled.div`
  display: block;
`;

export default ClaimToken;

import React, { Component, useContext, useEffect, useState } from 'react';
import Collapse from '@kunukn/react-collapse';
import { ZERO_ADDRESS } from "../constants";
import styled from 'styled-components';
import Media from '../Theme/media-breackpoint';
import ReactLoaderSpinner from "react-loader-spinner";



function OtcExchange(props) {
    const [ToggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const [isOpen, setOpen] = React.useState(true);

    const getActiveClass = (index, className) =>
        ToggleState === index ? className : '';

    const [dropDown, setDropDown] = useState(false);
    const [tokenToTransfer, setTokenToTransfer] = useState(ZERO_ADDRESS);
    const [confirmatory, setConfirmatory] = useState(ZERO_ADDRESS);
    const [tokensSymbolToTransfer, setTokensSymbolToTransfer] = useState("Select Token...");
    const [tokenListLoading, setTokenListLoading] = useState(true);
    const [tokenList, setTokenList] = useState(null);
    const onToggle02 = (dropDownNew) => 
        setDropDown(dropDown === dropDownNew ? null : dropDownNew);
    return (
        <>
            <LiqudityGatwy>
                {/* <TopTxt>The Liquidity Gateway system is designed to reduce the number of OTC orders by importing liquidity from the secondary market without negatively impacting the token's appreciation.<span data-html='true' data-tip="To prevent excessive dumping, the Liquidity Gateway persistently monitors the previous day's token appreciation to determine the maximum limit on the number of tokens that can be sold during a given time period based on a dynamic appreciation limit. The goal of the Liquidity Gateway is to decrease the sell pressure from behind the dumper shield before the vesting period ends, while ensuring that the liquidity flowing into the OTC market does not reduce the token's last appreciation by more than 50%. This way, each day will have a small sell pressure while the token continues to appreciate.<br/><br/>
                
                Example:<br/>
Let's say the token's current price is $10 and it appreciates by 10% to $11. <br/>
The Liquidity Gateway system determines that the maximum limit on the number of tokens that can be sold to the secondary market is 10,000 tokens, which would reduce the token value to $10.5.<br/>
The Liquidity Gateway system operates like a flash loan concept, buying the tokens from the users behind the dumper shield and selling them on the secondary market subject to the calculated limit amount." className="helpIco"><i className="fas fa-question-circle"></i></span></TopTxt> */}

                {/* <Tabs className='v2'>
                    <a className='active'><i class="fas fa-exclamation-circle"></i> How it works</a><span>|</span>
                    <a>Positions</a>
                </Tabs>
                <TabBox>
                    <a className='close'><i class="fas fa-times"></i></a>
                    <h5>How it works</h5>
                    <p>The Liquidity Gateway system is designed to reduce the number of OTC orders by importing liquidity from the secondary market without negatively impacting the token's appreciation.</p>
                    <p>To prevent excessive dumping, the Liquidity Gateway persistently monitors the previous day's token appreciation to determine the maximum limit on the number of tokens that can be sold during a given time period based on a dynamic appreciation limit. The goal of the Liquidity Gateway is to decrease the sell pressure from behind the dumper shield before the vesting period ends, while ensuring that the liquidity flowing into the OTC market does not reduce the token's last appreciation by more than 50%. This way, each day will have a small sell pressure while the token continues to appreciate.
        </p>
                    <p>
                        Example:<br/>
                        Let's say the token's current price is $10 and it appreciates by 10% to $11. <br/>
                        The Liquidity Gateway system determines that the maximum limit on the number of tokens that can be sold to the secondary market is 10,000 tokens, which would reduce the token value to $10.5.<br/>
                        The Liquidity Gateway system operates like a flash loan concept, buying the tokens from the users behind the dumper shield and selling them on the secondary market subject to the calculated limit amount.<br/>
                    </p>
                </TabBox>
                <Tabs className='v2'>
                    <a ><i class="fas fa-exclamation-circle"></i> How it works</a><span>|</span>
                    <a className='active'>Positions</a>
                </Tabs>
                <TabBox>
                    <a className='close'><i class="fas fa-times"></i></a>
                    <h5>Positions</h5>
                    <p>The Dumper Shield comprises three classes of positions: 1st, 2nd, and 3rd. The position determines the order in which liquidity from the Gateway is disbursed among the positions. Once there are no holders on the position above, the liquidity flows to the next position, all the way to the last.
While the first position may have a variable vesting period based on voting, the second and third positions have an infinite vesting period. Tokens always remain behind the Dumper Shield but are still available to engage with liquidity via the OTC and through the Gateway.<br/>
For example, Qwantum Finance Labs uses the first position to place investors who actually paid to purchase tokens, while the second position is used to place investors who teleport their positions from other Qwantum Finance Labs projects. The last position is used for placing users who receive tokens for free as rewards or airdrops. This order makes sense because users who receive free tokens may have different selling behavior than investors who bought the tokens.</p>
                    <p>That being said, each project can decide how to utilize these three positions according to their specific needs.</p>
                    <p>Dumper Shield Position Examples</p>
                    <p>1st position supply 10,000 tokens <br/>
2nd position supply 5,000 tokens<br/>
3rd position supply 2,000 tokens <br/>
Day 1 the gateway determines 6,000 worth of tokens are available<br/>
Day 2 the gateway determines additional 8,000 worth of tokens are available<br/>
Day 3 the gateway determines additional 1,000 worth of tokens are available<br/>
In day 1,  the 6,000 token liquidity available will be used to buyout orders in the 1st position. Ordres in 2nd and 3rd positions remain pending<br/>
In day 2,  the 9,000 token liquidity available will be used to buyout the remaining 2,000 orders in the 1st position, and the 5,000 tokens liquidity available leftover will be used to buyout the 5,000 orders in the 2st position. Ordres in 3rd positions remain pending.<br/>
In day 3,  the 1,000 token liquidity available will be used to buyout the partia of the  2,000 orders in the 3st position, and 1,000 orders on 3rd positions remain pending.
                    </p>
                </TabBox> */}

                <BlockContainer>
                    <MainLeft>
                        <Tabs>
                            <a className='active'>Pairs</a><span>|</span>
                            <a>My Pairs</a>
                        </Tabs>
                        <TabContainer className="no-padding dull-color">
                            <TableMain className='height10tr'>
                                <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                    <thead>
                                        <tr>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Pairs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>JNTR &#x3c;&#x3e; <span className='color-dull'>ETH</span></td></tr>
                                        <tr><td>SMART &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>PDO &#x3c;&#x3e; <span className='color-dull'>POLYGON</span></td></tr>
                                        <tr><td>DEGEN &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>JNTR &#x3c;&#x3e; <span className='color-dull'>ETH</span></td></tr>
                                        <tr><td>SMART &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>PDO &#x3c;&#x3e; <span className='color-dull'>POLYGON</span></td></tr>
                                        <tr><td>DEGEN &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>JNTR &#x3c;&#x3e; <span className='color-dull'>ETH</span></td></tr>
                                        <tr><td>PDO &#x3c;&#x3e; <span className='color-dull'>POLYGON</span></td></tr>
                                    </tbody>
                                </table>
                            </TableMain>
                        </TabContainer>
                    </MainLeft>
                    <CenterMain>
                        <Tabs> 
                            <a className='active color-red'>Order Book</a><span>|</span>
                            <a>My Orders</a>
                            <div>SMART &#x3e; BNB</div>
                        </Tabs>
                        <TabContainer className="no-padding">
                            <TableMain className='height10tr'>
                                <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                    <thead>
                                        <tr>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Offering Price<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Offering Allocation<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Asking Amount<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000</td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 </td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableMain>
                        </TabContainer>
                    </CenterMain>
                    <MainRight>
                        <Tabs> 
                            <a className='active'>Market trades</a><span>|</span>
                            <a>My trades</a>
                        </Tabs>
                        <TabContainer className="no-padding ">
                            <TableMain className='height10tr'>
                                <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                    <thead>
                                        <tr>
                                            <th width="20%" align="left" valign="middle" scope="col">Offering Price<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th>
                                            <th width="20%" align="left" valign="middle" scope="col">Total Allocation<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th>
                                            <th width="20%" align="left" valign="middle" scope="col">Total Amount<a href="#" className="sortLink"><i className="fas fa-sort"></i></a></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000</td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableMain>
                        </TabContainer>
                    </MainRight>
                </BlockContainer>
                <BlockContainer>
                    <MainLeft>
                        <Tabs>
                            <a className='active'>Pairs</a><span>|</span>
                            <a>My Pairs</a>
                        </Tabs>
                        <TabContainer className="no-padding dull-color">
                            <TableMain className='height10tr'>
                                <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                    <thead>
                                        <tr>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Pairs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>JNTR &#x3c;&#x3e; <span className='color-dull'>ETH</span></td></tr>
                                        <tr><td>SMART &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>PDO &#x3c;&#x3e; <span className='color-dull'>POLYGON</span></td></tr>
                                        <tr><td>DEGEN &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>JNTR &#x3c;&#x3e; <span className='color-dull'>ETH</span></td></tr>
                                        <tr><td>SMART &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>PDO &#x3c;&#x3e; <span className='color-dull'>POLYGON</span></td></tr>
                                        <tr><td>DEGEN &#x3c;&#x3e; <span className='color-dull'>BNB</span></td></tr>
                                        <tr><td>JNTR &#x3c;&#x3e; <span className='color-dull'>ETH</span></td></tr>
                                        <tr><td>PDO &#x3c;&#x3e; <span className='color-dull'>POLYGON</span></td></tr>
                                    </tbody>
                                </table>
                            </TableMain>
                        </TabContainer>
                    </MainLeft>
                    <CenterMain>
                        <Tabs> 
                            <a >Order Book</a><span>|</span>
                            <a className='active color-red'>My Orders</a>
                            <div>SMART &#x3e; BNB</div>
                        </Tabs>
                        <TabContainer className="no-padding">
                            <TableMain className='height10tr'>
                                <table className='border-cell hoverActive' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                    <thead>
                                        <tr>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Offering Price</th>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Total Allocation</th>
                                            <th width="14.28%" align="left" valign="middle" scope="col">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000 <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-red">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-red">100,000  <a className='Dlist'>Delist</a></td>
                                            <td className="color-red">60 BNB <Block className='color-dull'>[$1,0198]</Block></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableMain>
                        </TabContainer>
                    </CenterMain>
                    <MainRight>
                        <Tabs> 
                            <a>Market trades</a><span>|</span>
                            <a className='active'>My trades</a>
                        </Tabs>
                        <TabContainer className="no-padding ">
                            <TableMain className='height10tr'>
                                <table className='border-cell hoverActive' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                                    <thead>
                                        <tr>
                                            <th width="20%" align="left" valign="middle" scope="col">Purchase Price</th>
                                            <th width="20%" align="left" valign="middle" scope="col">Allocation Purchased</th>
                                            <th width="20%" align="left" valign="middle" scope="col">Amount Paid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                        <tr>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                            <td className="color-green">100,000 <a className="Dlist">Proof</a></td>
                                            <td className="color-green">0.0000 BNB <Block className='color-dull'>[$0.0198]</Block></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableMain>
                        </TabContainer>
                    </MainRight>
                </BlockContainer>
            </LiqudityGatwy>
            {/* <PlacOrdMBX>
                <DSTitle01>
                    <span>Sell Token Via Gateway 
                        <span data-tip='Tokens transferred through the Dumper Shield remain behind the Dumper Shield for the receiver, regardless of method utilized.' className="helpIco"><i className="fas fa-question-circle"></i></span> </span>
                </DSTitle01>
            </PlacOrdMBX>
                
                <FormMBX01>
                    <FormSbxLabel>Token symbol</FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu onClick={() => onToggle02(1)}>
                            {tokensSymbolToTransfer}
                            <div className="DDContainer">
                                <Collapse className={"collapse " + (dropDown === 1 ? "active" : "")}
                                    isOpen={dropDown === 1}
                                    onChange={({ state }) => {  }}
                                    onInit={({ state }) => {  }}>


                                    <div className="DDSubContainer">
                                        {
                                            !tokenListLoading ?
                                                Object.entries(tokenList).map((ele) => {
                                                    if(ele[1].canTransfer)
                                                        return <button onClick={() => { setTokenToTransfer(ele[1].token); setTokensSymbolToTransfer(ele[1].symbol);} }>{ele[1].symbol}</button>
                                                })
                                                : <ReactLoaderSpinner type="Bars" color="#fff" height={20} width={40} /> 
                                        }
                                        
                                        <button>BNB</button>
                                        <button>ETH</button>
                                        <button>FTM</button>
                                    </div>
                                </Collapse>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel>Choose your liquidity channel <span data-tip='Help Text here' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="noBorder ver2">
                            <div className="md-checkbox md-checkbox-inline">
                                <input type="checkbox" id="checkbox01" name="" />
                                <label htmlFor="checkbox01">PDO</label><span data-tip='Not all projects utilize the PDO to help with liquidity. If your token does not have an active PDO on PDO.Finance please reach out to the token admin and have them launch a free PDO' className="helpIco"><i className="fas fa-question-circle"></i></span>
                            </div>
                            <div className="md-checkbox md-checkbox-inline">
                                <input type="checkbox" id="checkbox02" name="" />
                                <label htmlFor="checkbox02">Swap Provider</label><span data-tip='Help Text here' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                <strong>Access liquidity via SP trigger incentive fee <span data-tip='Test' className="helpIco"><i className="fas fa-question-circle"></i></span></strong>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel>Token amount</FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="formBox">
                            <input type="text" name="transferTokensAmount"  value='' autoComplete="off" />
                        </DDMenu>
                        <p className="small">Available balance : 
                            {tokensSymbolToTransfer !== "Select Token..." && tokenToTransfer !== ZERO_ADDRESS ?
                            <span>
                                 {" "+(Number(tokenList[tokenToTransfer].balances[0]) / 10 ** 18).toFixed(4)} {tokensSymbolToTransfer}
                            </span>
                            : " N/A" }
                        </p>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel></FormSbxLabel>
                    <FormSbxContent >
                        <button className="PlaOrBTN01">ADD TO THE GATEWAY</button>
                    </FormSbxContent>
                </FormMBX01> */}
            
            {/* <Ledger>
                <h4>Ledger</h4>

                <TabNav>
                    <a className={`tab-Link ${getActiveClass(1, 'active')}`} onClick={() => toggleTab(1)}>
                    Sold</a>
                    <a className={`tab-Link ${getActiveClass(2, 'active')}`} onClick={() => toggleTab(2)}>Pending</a>
                </TabNav>
                {getActiveClass(1,
                <TabContainer>
                    <TableMain className='height1'>
                        <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                            <thead>
                                <tr>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Token Symbol</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Liquidity Channel</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Received Amount</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Face Value</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Incentive Fee <span data-tip='Help Text here' className="helpIco"><i className="fas fa-question-circle"></i></span></th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Proof</th>
                                    <th width="14.28%" align="center" valign="middle" scope="col">Claim Fund</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='color-green'>SMART</td>
                                    <td>PDO</td>
                                    <td>2 BNB</td>
                                    <td>$453.61</td>
                                    <td >0%</td>
                                    <td className='color-blue'>0xe39...075D</td>
                                    <td align="center"><a className='btn01'>CLAIM</a></td>
                                </tr>
                                <tr>
                                    <td className='color-green'>SMART</td>
                                    <td>PDO</td>
                                    <td>2 BNB</td>
                                    <td>$453.61</td>
                                    <td >0%</td>
                                    <td className='color-blue'>0xe39...075D</td>
                                    <td align="center"><a className='btn01'>CLAIM</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </TableMain>
                </TabContainer>
                )}
                {getActiveClass(2,
                <TabContainer>
                    <TableMain className='height1'>
                        <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                            <thead>
                                <tr>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Token Symbol</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Liquidity Channel</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Pending Amount</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Face Value</th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Incentive Fee <span data-tip='Help Text here' className="helpIco"><i className="fas fa-question-circle"></i></span></th>
                                    <th width="14.28%" align="left" valign="middle" scope="col">Proof</th>
                                    <th width="14.28%" align="center" valign="middle" scope="col">Cancel Pending</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='color-green'>SMART</td>
                                    <td>PDO</td>
                                    <td>100</td>
                                    <td>$453.61</td>
                                    <td >0%</td>
                                    <td className='color-blue'>0xe39...075D</td>
                                    <td align="center"><a className='btn-red'><i className="far fa-times-circle"></i></a></td>
                                </tr>
                                <tr>
                                    <td className='color-green'>SMART</td>
                                    <td>PDO</td>
                                    <td>100</td>
                                    <td>$453.61</td>
                                    <td >0%</td>
                                    <td className='color-blue'>0xe39...075D</td>
                                    <td align="center"><a className='btn-red'><i className="far fa-times-circle"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </TableMain>
                </TabContainer>
                )}
            </Ledger> */}
        </>
    );
}

const LiqudityGatwy = styled.div `
    
`
const TopTxt = styled.p `
    font-size: 18px; line-height: 30px; margin-top: 40px;
    ${Media.sm} {
        font-size: 16px; margin-bottom: 36px;
    }
`
const MainLeft = styled.div `
    width: 175px;
    ${Media.md3} {
        flex-grow: 1;
    }
    table.border-cell {
        tr:hover td {background-color: #14151a;}
    }
`
const BlockContainer = styled.div `
    display: flex; gap: 40px;
    .no-padding {padding: 0 0 50px; Position: ; 
        table tr th {max-height: 60px; padding: 0 18px; Position: sticky;}
    }
    .dull-color table tr th,
    .dull-color table tr td {background-color: #050909}
    ${Media.md3} {
        flex-flow: wrap;  gap: 0 40px;
    }
    ${Media.sm} {
        padding: 0 0 40px;
    }
`
const CenterMain = styled.div `
    flex-grow: 1;
    .Dlist {color: #c32b2d; cursor: pointer; position: absolute; left: 0; right: 0; opacity: 0; text-align: center;}
    .TableMain-fisnFY {width: 500px; min-width: 100%;}
    table { min-width: 600px !important;
        tr{
            td {position: relative;}
        }
        &.hoverActive tr:hover {
            td {color: transparent; border-right: 1px solid transparent;
                .color-dull{color: transparent;}
                .Dlist {opacity: 1;}
            }
        }
    }
    ${Media.md} {
        max-width: 100%;
    }
`
const MainRight = styled.div `
    flex-grow: 1; width: 550px;
    .TableMain-fisnFY {width: 300px; min-width: 100%;}
    .Dlist {color: #00f02b; cursor: pointer; position: absolute; left: 0; right: 0; opacity: 0; text-align: center;}
    table { min-width: 600px !important;
        &.hoverActive tr:hover {
            td {color: transparent; border-right: 1px solid transparent; position: relative;
                .color-dull{color: transparent;}
                .Dlist {opacity: 1;}
            }
        }
    }
    ${Media.md} {
        max-width: 100%;
    }
`
const Tabs = styled.div `
    display: flex; width: 100%; margin-bottom: 25px; align-items: center; margin-top: 48px;
    a { font-weight: bold;
        &.active {color: #00f02b}
        &.active.color-red {color: #c32b2d}
    }
    span {margin: 0 6px; color: #9a9a9a;}
    div {margin-left: auto; color: #9a9a9a;}
    ${Media.sm} {
        margin-top: 0; flex-flow: wrap;
        div {width: 100%; margin-top: 16px;}
    }
    &.v2 {
        margin-bottom: 48px;
        a { color: #5a5e67; font-weight: bold;
            &.active {color: #fff}
        }
    }
`

const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap; width:100%;
`;
const TableMain = styled.div `
    position: relative;   overflow: auto; max-width: 100%; max-height: 500px;
    &::-webkit-scrollbar {
        width: 10px; height: 10px; background: #545861;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #0a0c0e;
        /* outline: 3px solid #545861; */
        box-shadow: inset 0 0 0 2px #545861;
    }
    &.height1 {max-height: 427px;}
    &.height2 {max-height: 281px;}
    &.height3 {max-height: 750px;}
    &.height10tr {max-height: 662px;}
    table{
        min-width:100%; border: 1px solid #1e2127; border-collapse: separate; background-color: #010202;
        thead { 
            th {position: sticky; top: 0; border-top: 1px solid #1e2127; border-bottom: 1px solid #1e2127; border-right: 1px solid #1e2127; background: #000; z-index: 2;
                &:last-child {border-right: 0;}
            }
        }
        td {border-top: 1px solid#1e2127;}
        &.border-cell {
            td { border-bottom: 0 solid #545861; border-right: 1px solid #1e2127;
                &:last-child {border-right: 0;}
            }
        }
    }
    table tr td{ padding:10px 18px; color: #fff; height: 60px;
        &.bor01{border-right:5px solid #545861; }
        &.bor02{color: #4B4BFF; }
        ${Media.md} {
            padding:10px 10px;
        }
        .fa-circle {font-size: 8px; line-height: 16px; vertical-align: top;}
    }
    
    table tr:hover td{background-color: #0b0c0f;}
    table tr th{color:#9a9a9a;  position:relative; padding: 18px; height:60px; padding-right: 27px;
        a {color:#9a9a9a; font-size: 18px; display: inline-block; vertical-align: middle; position: absolute; right: 10px; top: 50%; transform: translateY(-50%);}
        &:nth-last-child(01){ }
        &:nth-child(01){ }
        &.bor01{border-right:5px solid #545861; }
    } 
    .color-dull {color: #9a9a9a;}
    .color-green {color: #00f02b;}
    .color-red {color: #c32b2d;}
    .color-yellow {color: #e4a029;}
    .color-blue {color: #3131ff;}
    .btn01{width:200px; font-weight:700; height:40px;  border-radius:5px; color:#00f02b; display: inline-block; text-align: center; line-height: 36px; border:2px solid transparent; margin: 0px auto; cursor: pointer;
        &:hover{  border:2px solid #00f02b; box-shadow: 0 0 10px #00f02b;} 
    }
    .btn02{width:102px; font-weight:700; height:40px;  border-radius:5px; color:#c32b2d; display: inline-block; text-align: center; line-height: 36px; border:2px solid transparent;
        &:hover{  border:2px solid #c32b2d; box-shadow: 0 0 10px #c32b2d;} 
    }
    .btn-red {color: #53575d; font-size: 25px; cursor: pointer; margin: 0px auto;}
    .btn-red:hover {color: #c32b2d;}
`
const Block = styled.div `
    display: block;
`
const TabNav = styled(FlexDiv)`
    justify-content: flex-start;
    a {font-size: 16px; color: #fff; margin-right: 54px; font-weight: bold; &.active {color: #00f02b}}
`;
const TabContainer = styled.div`
    padding: 50px 0 20px;
    .Note {text-align: center; width: 100%; font-size: 18px; color: #9a9a9a; margin-top: 56px;}
`;
const FormMBX01 = styled(FlexDiv)`
    justify-content:flex-start; flex-direction:row; margin-bottom:20px;
    ${Media.sm} {
        display:block;
    }
`;
const FormSbxLabel = styled(FlexDiv)`
    display: -webkit-inline-box; width:30%; justify-content:flex-start; font-size:16px; font-weight:700; 
    ${Media.sm} {
        width:100%; margin-bottom:10px;
    }
`;
const FormSbxContent = styled(FlexDiv)`
    width:70%; justify-content:flex-start;
    ${Media.sm} {
        width:100%;
    }
    p{font-size:11px; font-weight:normal; color:#8e9195; text-align:right; margin-top:10px; margin-bottom:0px; width:100%;
        span{color:#fff;}
    }
    .md-radio label{ font-size:16px; color:#fff; font-weight:400;}
    .md-radio label:after{ background-color:#00f02b;}

    .md-radio label:before, .md-radio input[type="radio"]:checked + label:before { border: 2px solid #5a5e67;  left: -2px; top: -2px; width: 24px; height: 24px;}


    .PlaOrBTN01{ min-width:344px; font-size:16px; font-weight:700; height:60px; background:none; color:#fff; border-radius:10px; border: 2px solid #0dd732; box-shadow: 0 0 10px #0dd732a1;
        :hover{ background-color:#0dd732;}
        ${Media.sm} {
            min-width:auto; width:100%;
        }
    } 
`;
const DDMenu = styled(FlexDiv)`
    justify-content:flex-start; position:relative; height:60px; border-radius:10px;  border:2px solid #5a5e67;  
    font-size: 16px; font-weight:400; padding:0 15px;
    i{max-width:80px; margin-right:10px;}
    :after{ content: ""; border-width:6px; border-style:solid; border-bottom:0px; transition:0.5s ease all; border-color:#fff transparent transparent transparent; position:absolute; right: 16px; top: 25px; transform: rotate(0deg); transform-origin: center;}
    &.active{
        :after{transform:rotate(180deg);}
    }
    &.formBox{
        input{ width:100%; height:52px; padding:6px 0; background-color:transparent; border:0px; color:#fff; font-size: 16px; font-weight:400;}
        :after{ display:none;}
    }
    &.noBorder{
        :after{ display:none;}
        border:0px; margin-bottom: 16px;
    }
    .md-checkbox-inline {width: 50%;
        strong {color: #c32b2d; display: table; margin: 14px 0 -34px 41px; font-size: 12px;}
    }
    .DDContainer{ width:calc(100% + 4px); z-index:10; position:absolute; left:-2px; top:90%;}
    .DDSubContainer{ width:100%; color:white; border-radius:10px; background-color:#0b0c0f; border:2px solid #5a5e67; padding:10px; border-top:0px; border-top-left-radius:0px; border-top-right-radius:0px;
        margin:0 0 8px 0;
    
        button{ width:100%; color:#fff; border-radius:8px; text-align:left;display:block; padding:10px 6px; border:none; outline:none; 
            :hover{  background-color:rgba(255,255,255,0.05); }
        }
    }
    &.ver2{
        ${Media.xs} {
            height:auto;
        }
    }
    .md-radio.md-radio-inline{
        ${Media.xs} {
            width:100%; display:block;
        }
    }
`;
const PlacOrdMBX = styled(FlexDiv)`
    padding:50px 0;  color:#fff; border-top:1px solid #1e2127; 
    ${Media.xs} {
        padding:30px 0;   
    }
`;
const DSTitle01 = styled(FlexDiv)`
    justify-content:space-between; font:700 36px/40px 'Kanit', arial; color:#00f02b;  
    .DSrPart{ display: flex; align-items:center; justify-content:center; font-size:16px; color:#fff; font-weight:700; }
    ${Media.xs} {
        font:700 28px/34px 'Kanit', arial;
    }
    .helpIco{
        top:-20px; right:5px;
    }
`;
const Ledger = styled.div `
    border-top: 1px solid #1d2025; padding: 75px 0 0; margin: 80px 0 0 0;
    h4 {font-size: 21px; width:100%; margin: 0 0 53px;}
`
const TabBox = styled.div `
    background: #000; border: 1px solid #1e2127; padding: 30px 28px; position: relative;
    h5 {font-size: 21px; font-weight: 700; margin: 0 0 25px;}
    p {font-size: 18px; line-height: 30px; margin: 0 0 36px;}
    .close {position: absolute; top: 0; right: 0; height: 30px; width: 30px; line-height: 30px; text-align: center; background: #1e2127; font-size: 16px; cursor: pointer;}
`
export default OtcExchange;
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import BodyBG from "../Assets/images/bodyBG.jpg";
import Media from "../Theme/media-breackpoint";

var Gs = {};

Gs.GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0; 
    
    background-color: ${(props) => props.theme.bodybg01}; 
    background-image:url(${BodyBG});
    background-position: center top;
    background-repeat: no-repeat; 
    background-size: 100%;
    color: ${(props) => props.theme.color01}; 
    ${Media.md} {
      background-size: auto 100vh;
    }
  }
  .orangeColor{color:#febb00 !important; }
  .myTip{ max-width:360px; font:500 12px/17px 'IBM Plex Mono', arial !important; color:#000 !important; background-color:#fff !important; opacity:1 !important;}
  .myTip.place-top:after{ border-top-color:#fff !important; }
  .helpIco{ position:relative; right:-5px; top:-5px; font-size:12px !important; font-weight:400; }

  .MainBox{ min-height:100vh;  background-position: 50% 120px; background-repeat: no-repeat;}

  .MainBox.noBG{background-image:none;}

  input{ outline:none;}
  img{ max-width:100%; height:auto;}
  button{
    background:transparent; outline:none; border:0;
  }
 
  .track-vertical{ width:19px !important; height:100%; background-color:#545861; position:absolute; right:0px;}
  .thumb-vertical{ width:9px !important; margin:5px; background-color:#000000; }
  .track-horizontal{width:100% !important; height:19px !important; background-color:#545861; position:absolute; bottom:0px;}
  .thumb-horizontal{ height:9px !important; margin:5px; background-color:#000000;}
  // .view{padding-right:17px; padding-bottom:17px;}
 
  .redColor{color:#c32b2d!important;} 

.collapse { width:0px; transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);}
.collapse.noAni{ transition:none}
.collapse.active{ width:100%;}

.sb-BorRight{ border-right:1px solid #393d46; } 

.collapse-css-transition { transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1); }

.md-checkbox{position:relative;margin:10px 0; width:100%;}

.md-checkbox label:before{width:20px;height:20px;background:transparent !important; border:2px solid #5a5e67 !important; border-radius:5px;cursor:pointer;transition:background .3s;}
.md-checkbox input[type="checkbox"]{outline:0;margin-right:10px;position:absolute;}
.md-checkbox input[type="checkbox"]:checked + label:before{background:#337ab7;border:none;}
.md-checkbox input[type="checkbox"]:checked + label:after{transform:rotate(-45deg);top:5px;left:4px;width:12px;height:6px;border:2px solid #fff;border-top-style:none;border-right-style:none;}
.md-checkbox:not(:last-child){padding-right:10px;}
.md-checkbox input{background:none!important;border:none!important;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.md-checkbox label{font-size:16px;font-weight:normal; padding-left:40px; margin-left:0px;
  ${Media.md} {
    margin-bottom:30px;
  }
  &.mb-0{
    ${Media.md} {
      margin-bottom:0px;
    } 
  }
}
.md-checkbox label:before,.md-checkbox input[type="checkbox"]:checked + label:before{border:2px solid rgba(255, 255, 255, 0.10);background:rgba(255, 255, 255, 0.05);left:0; right:auto;}
.md-checkbox input[type="checkbox"]:checked + label:after{transform:rotate(-45deg);top:0px; left:4px; width:18px;height:9px;border:4px solid #00f02b; border-top-style:none;border-right-style:none;right:-4px;}
.md-checkbox input[type="checkbox"]:checked + label{color:#fff;}
input{border-radius:4px;}
.md-checkbox:nth-child(01) { margin-top:20px;} 
.disaBled{ opacity:0.7; pointer-events:none; color:#4B4E56 !important; border-color:#4B4E56 !important;} 
.md-checkbox.md-checkbox-inline{ width:auto; margin:0px; }
.md-checkbox.leftS label{ margin-right:0px; margin-left:30px; }
.md-checkbox.leftS label:before,.md-checkbox.leftS input[type="checkbox"]:checked + label:before{ left:0px; right:auto;}
.md-checkbox.leftS input[type="checkbox"]:checked + label:after{right:auto; left:5px;}
 
.__react_component_tooltip{ z-index:10000 !important;}


  .aos-init {
    ${Media.md3} {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    }
  }

`;

Gs.MainBox = styled.div`
  margin: 79px 0 0 0;
`;

Gs.Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1362px;
`;

Gs.Container2 = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1456px;
  ${Media.lg} {
    max-width: 1276px;
  }
  ${Media.md3} {
    max-width: 1170px;
  }
  ${Media.md} {
    max-width: 961px;
  }
  ${Media.sm} {
    max-width: 737px;
  }
  ${Media.xs} {
    max-width: -webkit-fill-available;
  }
`;

Gs.Container3 = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1530px;
  ${Media.lg} {
    max-width: 1276px;
  }
  ${Media.md3} {
    max-width: 1170px;
  }
  ${Media.md} {
    max-width: 961px;
  }
  ${Media.sm} {
    max-width: 737px;
  }
  ${Media.xs} {
    max-width: -webkit-fill-available;
  }
`;
export default Gs;

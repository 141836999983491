import axios from "axios";
import Web3 from "web3";
import { contractAddressesChainWise, rpcLink } from "../constants";

const DumperShieldFactory = require("../Abi/DumperShieldFactory.json");

export const getEtherValue = (weiValue) =>
  Web3.utils.fromWei(weiValue, "ether");

export const getABIEncode = async (abiEncodeData) => {
  let { chainId, functionSignature, params } = abiEncodeData;
  // console.log(functionName)
  // console.log(abiEncodeData)
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;

  let web3, contractInstance;
  web3 = new Web3(rpcLink[Web3.utils.toDecimal(chainId)]);
  console.log(params);
  const paramsString = functionSignature.match(/\(([^)]+)\)/)[1];
  const paramsArray = paramsString.split(",");
  try {
    // let functionSignatureEncode = await web3.eth.abi.encodeFunctionSignature(functionSignature);
    let functionParameterEncode = await web3.eth.abi.encodeParameters(
      paramsArray,
      params
    );

    console.log(functionParameterEncode);
    return functionParameterEncode;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getABIDecode = async (abiDecodeData) => {
  let { chainId, paramsType, args } = abiDecodeData;
  console.log(paramsType);
  console.log(abiDecodeData);
  let web3;
  web3 = new Web3(rpcLink[Web3.utils.toDecimal(chainId)]);
  try {
    let functionParameterDecode = await web3.eth.abi.decodeParameters(
      [paramsType],
      args
    );
    console.log(functionParameterDecode);
    return functionParameterDecode;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getTotalRules = async (chainId) => {
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;

  let web3, contractInstance;
  web3 = new Web3(rpcLink[Web3.utils.toDecimal(chainId)]);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  try {
    let rulesIds = await contractInstance.methods.rulesIds().call();
    return rulesIds;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const checkMinimumRequiredTokens = async (chainId, token) => {
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;

  let web3, contractInstance;
  web3 = new Web3(rpcLink[Web3.utils.toDecimal(chainId)]);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  try {
    let totalSupply = await contractInstance.methods.totalSupply(token).call();
    let minimalPercentage = await contractInstance.methods
      .minimalLevel(token)
      .call();
    let calculateTokens = totalSupply * (minimalPercentage / 100);
    return calculateTokens;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getRules = async (chainId) => {
  //   let chainId = localStorage.getItem("chainId");
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;

  let web3, contractInstance;
  web3 = new Web3(rpcLink[Web3.utils.toDecimal(chainId)]);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  try {
    // let totalRules = await getTotalRules(chainId);
    let totalRules = 5;
    let allRules = [];
    for (var i = 0; i <= totalRules; i++) {
      let rule = await contractInstance.methods.getRule(i).call();
      allRules.push(rule);
    }
    return allRules;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const createBallot = async (
  createBallotData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("createBallotData: ", createBallotData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  // let web3,contractInstance,new_value,response,CurrentgasPrice;
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });
  // const ss = gasPrice != '0' ? gasPrice * Math.pow(10, 9) : CurrentgasPrice;

  let payloadData = contractInstance.methods
    .createBallot(
      createBallotData.token,
      createBallotData.ruleId,
      createBallotData.args
    )
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      // await result.wait().then(async (receipt) => {
      receiptCb(result);
      // });
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const govVote = async (govVoteData, txCb, receiptCb, errorCb) => {
  let chainId = localStorage.getItem("chainId");
  console.log("govVoteData: ", govVoteData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .vote(govVoteData._ballotId, govVoteData.yea)
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      receiptCb(result);
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

// export const fetchCurrencyPrices = async () => {
//   console.log(
//     "--------------------------------currency-price-fetch-------------------------------------",
//   );
//   let tableDataLocalcoingecko = [];
//   var currencyPrices = {};
//   // try {
//   await axios
//     .get(
//       `https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cbinancecoin%2Cmatic-network%2Ccallisto%2Ctether%2Cusd-coin%2Cdai&vs_currencies=USD&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true`,
//       {
//         headers: {
//           "Access-Control-Allow-Origin": "*",
//         },
//       },
//     )
//     .then(async (res) => {
//       tableDataLocalcoingecko = res.data;
//       currencyPrices["ETH"] = tableDataLocalcoingecko["ethereum"]["usd"];
//       currencyPrices[1] = currencyPrices["ETH"];

//       currencyPrices["BNB"] = tableDataLocalcoingecko["binancecoin"]["usd"];
//       currencyPrices[56] = currencyPrices["BNB"];

//       currencyPrices["MATIC"] = tableDataLocalcoingecko["matic-network"]["usd"];
//       currencyPrices[137] = currencyPrices["MATIC"];

//       currencyPrices["BASE"] = tableDataLocalcoingecko["ethereum"]["usd"];
//       currencyPrices[8453] = currencyPrices["BASE"];

//       currencyPrices["CLO"] = tableDataLocalcoingecko["callisto"]["usd"];
//       currencyPrices[820] = currencyPrices["CLO"];

//       currencyPrices["USDT"] = tableDataLocalcoingecko["tether"]["usd"];
//       currencyPrices["USDC"] = tableDataLocalcoingecko["usd-coin"]["usd"];
//       currencyPrices["DAI"] = tableDataLocalcoingecko["dai"]["usd"];
//       currencyPrices["BUSDT"] = tableDataLocalcoingecko["tether"]["usd"];

//       let degenPriceBNB = await getDegenPrice();
//       currencyPrices["DEGEN"] =
//         Number(currencyPrices.BNB) * Number(degenPriceBNB);
//       currencyPrices["SMART"] = 0;
//     })
//     .catch(async (err) => {
//       // await Moralis.EvmChain.token.getTokenPrice({address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", chain: "bsc"})
//       console.log("error", err);
//       if (!Moralis.Core.isStarted) {
//         await Moralis.start({
//           apiKey:
//             "CNkBmyT59hIHHLVPVKc2n4fbcvUwAR3iylv0YlDMKOjZLcN295ib9CpDjvhqNbw3",
//         });
//       }
//       let ethPrice = (
//         await Moralis.EvmApi.token.getTokenPrice({
//           address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
//           chain: EvmChain.ETHEREUM,
//         })
//       ).toJSON();
//       let bnbPrice = (
//         await Moralis.EvmApi.token.getTokenPrice({
//           address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
//           chain: EvmChain.BSC,
//         })
//       ).toJSON();
//       let maticPrice = (
//         await Moralis.EvmApi.token.getTokenPrice({
//           address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
//           chain: EvmChain.POLYGON,
//         })
//       ).toJSON();

//       console.log(ethPrice.usdPrice, bnbPrice.usdPrice);
//       currencyPrices["ETH"] = ethPrice.usdPrice;
//       currencyPrices[1] = currencyPrices["ETH"];

//       currencyPrices["BNB"] = bnbPrice.usdPrice;
//       currencyPrices[56] = currencyPrices["BNB"];

//       currencyPrices["MATIC"] = maticPrice.usdPrice;
//       currencyPrices[137] = currencyPrices["MATIC"];

//       currencyPrices["BASE"] = ethPrice.usdPrice;
//       currencyPrices[8453] = currencyPrices["BASE"];

//       currencyPrices["CLO"] = 0;
//       currencyPrices[820] = currencyPrices["CLO"];

//       let degenPriceBNB = await getDegenPrice();
//       currencyPrices["DEGEN"] =
//         Number(currencyPrices.BNB) * Number(degenPriceBNB);
//       currencyPrices["SMART"] = 0;
//     });
//   // } catch(ex) {
//   //     response = null;
//   //     // error
//   //     console.log(ex);
//   //     reject(ex);
//   // }

//   return currencyPrices;
// };

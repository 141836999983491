 

var Media = {} 
 
Media = {
  xxl: '@media(max-width:1700px)',
  xl: '@media(max-width:1600px)',
  lg: '@media(max-width:1366px)',
  md3: '@media(max-width:1200px)',
  md2: '@media(max-width:1024px)',
  md: '@media(max-width:991px)',
  sm: '@media(max-width:767px)',
  xs: '@media(max-width:500px)'
}


 
export default Media; 
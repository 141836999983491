/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FaCirclePlay } from "react-icons/fa6";
import Gs from "../Theme/globalStyles";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import LeftMBX from "../Component/leftBar";
import BuyReqPopup from "../Component/popup/buyreqpopup";
import ClaimToken from "../Component/popup/claimToken";
import Collapse from "@kunukn/react-collapse";
import OTCTransferTokens from "../Component/OTCTransferToken";
import {
  BASE_API_HOST,
  CHAINS,
  CHAIN_ID,
  explorerLink,
  ZERO_ADDRESS,
  nativeTokenList,
} from "../constants";
import {
  addressApprove,
  getDegenPrice,
  getTokenPriceUsingPairAddress,
  getVestedTokens,
  otcCancelOrder,
} from "../Service/otc_function";
import Media from "../Theme/media-breackpoint";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  getAccountaddress,
  metamask_connection,
} from "../Service/metamask_function";
import notificationConfig from "../config/notificationConfig";
import ConnectWallet from "./connectWallet";
import ReactLoaderSpinner from "react-loader-spinner";
import { getEtherValue } from "../Service/otc_function";
import { GlobalContext } from "../Context/globalContext";
import OtcExchange from "../Component/OtcExchange";
import LiquidityGateway from "../Component/LiquidityGateway";
import _ from "lodash";
import Web3 from "web3";
import OTCMyOffers from "../Component/OTCMyOffers";
import NewOtcExchange from "../Component/NewOtcExchange";
import Decimal from "decimal.js-light";

function OTCNEW(props) {
  const [index, setIndex] = useState(4);
  const [dropDown, setDropDown] = useState(false);
  const [popupOTCClaim, setPopupOTCClaim] = useState(false);
  const [popupMyofferClaim, setPopupMyofferClaim] = useState(false);
  const [searchBX, setSearchBX] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [pairs, setPairs] = useState(null);
  const [otcOrders, setOtcOrders] = useState([]);
  const [taxProtectionRequests, setTaxProtectionRequests] = useState([]);
  const [offersByMe, setOffersByMe] = useState([]);
  const [offersToMe, setOffersToMe] = useState([]);
  const [historyPurchases, setHistoryPurchases] = useState([]);
  const [historySelling, setHistorySelling] = useState([]);
  const [currentClaimOrder, setCurrentClaimOrder] = useState(null);
  const [account, setAccount] = useState(null);
  const [currencyPrices, setCurrencyPrices] = useState({});
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [tokenList, setTokenList] = useState(null);
  const [dsUsers, setDSUsers] = useState(null);
  const [otcTradersData, setOtcTradersData] = useState([]);
  const [newOfferType, setNewOfferType] = useState(1);
  const globalContext = useContext(GlobalContext);
  const [totalOtcAllocation, setTotalOtcAllocation] = useState(null);
  const [otcTab, setOtcTab] = useState("exchange");
  const [explanationTab, setExplanationTab] = useState("");

  useEffect(async () => {
    if (globalContext.linkToTransfer === true) {
      setOtcTab("transfer-token");
    }
  }, [globalContext.linkToTransfer]);

  useEffect(async () => {
    if (globalContext.currencyPrices !== null) {
      await setOrdersLoading(true);
      setCurrencyPrices(globalContext.currencyPrices);
    }
  }, [globalContext.currencyPrices]);

  useEffect(async () => {
    if (globalContext.isWalletConnected) {
      await setAccount(globalContext.walletAddress);
    }
  }, [globalContext.walletAddress]);

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      setTokenList(globalContext.tokenList);
    }
  }, [globalContext.tokenList]);

  useEffect(async () => {
    if (globalContext.dsUsers !== null) {
      setDSUsers(globalContext.dsUsers);
    }
  }, [globalContext.dsUsers]);

  useEffect(async () => {
    if (tokenList !== null) {
      await fetchOrders();
      // await startOrderInterval();
      // window.addEventListener("focus", async () => {
      //   await startOrderInterval();
      // });
    }
  }, [tokenList]);

  const startOrderInterval = async () => {
    let createdInterval = setInterval(async () => {
      await fetchOrders();
    }, 5000);
    window.addEventListener("blur", async () => {
      await clearInterval(createdInterval);
    });
  };

  const findMedian = (arr) => {
    arr.sort((a, b) => a - b);
    const middleIndex = Math.floor(arr.length / 2);

    if (arr.length % 2 === 0) {
      return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
    } else {
      return arr[middleIndex];
    }
  };

  const fetchOrders = async () => {
    await fetch(BASE_API_HOST + "/otcRoute/getAllOTCMarketPlaceOrders")
      .then((res) => res.json())
      .then(
        async (result) => {
          let otcOrders = result.result.filter(
            (token) => token.chainId !== 820
          );
          console.log(tokenList);
          let allOrders = [];
          let otcTradersData = {};
          let historyPurchases = {};
          let historySelling = {};
          let pairs = {};
          let chainWiseOrders = {};
          let start = Date.now();
          let otcAllocation = 0;

          const otcOrdersPromises = otcOrders.map((currentOrder) => {
            const tokenDetail =
              currentOrder.orderType === "ask"
                ? tokenList[currentOrder.chainId][currentOrder.tokenSell]
                : tokenList[currentOrder.chainId][currentOrder.wantToken];
            return tokenDetail.pairContract
              ? getTokenPriceUsingPairAddress(tokenDetail)
              : Promise.resolve(0);
          });

          const pairPrices = await Promise.all(otcOrdersPromises);
          console.log(pairPrices);

          otcOrders.map((order, index) => {
            const currentOrder = order;
            let tokenSellPrice = 0;
            let tokenWantPrice = 0;
            const tokenSell =
              tokenList[currentOrder.chainId][currentOrder.tokenSell];
            const tokenWant =
              tokenList[currentOrder.chainId][currentOrder.wantToken];
            if (currentOrder.orderType === "ask") {
              tokenWantPrice = tokenWant.price;
              tokenSellPrice = pairPrices[index];
            } else {
              tokenWantPrice = pairPrices[index];
              tokenSellPrice = tokenSell.price;
            }

            let sellAmount = Web3.utils.fromWei(currentOrder.sellAmount);
            let sellAmountPrice = Number(sellAmount) * tokenSellPrice;
            let value = Web3.utils.fromWei(currentOrder.value);
            let valuePrice = Number(value) * tokenWantPrice;
            currentOrder["txLink"] =
              explorerLink[currentOrder.chainId] +
              "/tx/" +
              currentOrder.transactionHash;
            currentOrder["vestingDate"] = tokenList[currentOrder.chainId][
              currentOrder.tokenSell
            ]
              ? tokenList[currentOrder.chainId][currentOrder.tokenSell]
                  .dsUnlockDateUTC
              : 0;
            currentOrder["liquidityPosition"] =
              currentOrder.position === 0
                ? "1st"
                : currentOrder.position === 1
                ? "2nd"
                : "3rd";
            currentOrder["supplyAvailable"] = sellAmount;
            currentOrder["askingAmount"] = value;
            currentOrder["askingAmountUSD"] = valuePrice;
            if (currentOrder.orderStatus < 4) {
              currentOrder["marketPrice"] =
                (Number(sellAmount) * tokenSellPrice) / Number(tokenWantPrice);
              currentOrder["marketPriceUSD"] = sellAmountPrice;
              currentOrder["competitiveValueUSD"] =
                valuePrice - sellAmountPrice;
              if (Number(sellAmountPrice) === 0) {
                currentOrder["competitiveValuePercentage"] = 0;
              } else {
                currentOrder["competitiveValuePercentage"] = new Decimal(
                  valuePrice
                )
                  .minus(sellAmountPrice)
                  .dividedBy(sellAmountPrice)
                  .times(100)
                  .toNumber();
              }
              if (
                Number(valuePrice) === 0 ||
                Number(sellAmount) === 0 ||
                Number(tokenWantPrice) === 0
              ) {
                currentOrder["otcPrice"] = 0;
              } else {
                currentOrder["otcPrice"] = new Decimal(valuePrice)
                  .dividedBy(sellAmount)
                  .dividedBy(tokenWantPrice)
                  .toNumber();
              }
              if (Number(valuePrice) === 0 || Number(sellAmount) === 0) {
                currentOrder["otcPriceUSD"] = 0;
              } else {
                currentOrder["otcPriceUSD"] = new Decimal(valuePrice)
                  .dividedBy(sellAmount)
                  .toNumber();
              }
            } else {
              currentOrder["marketPrice"] = valuePrice / Number(tokenSellPrice);
              currentOrder["marketPriceUSD"] = valuePrice;
              currentOrder["competitiveValueUSD"] =
                sellAmountPrice - valuePrice;
              if (Number(valuePrice) === 0) {
                currentOrder["competitiveValuePercentage"] = 0;
              } else {
                currentOrder["competitiveValuePercentage"] = new Decimal(
                  sellAmountPrice
                )
                  .minus(valuePrice)
                  .dividedBy(valuePrice)
                  .times(100)
                  .toNumber();
              }
              if (
                Number(valuePrice) === 0 ||
                Number(sellAmount) === 0 ||
                Number(tokenWantPrice) === 0
              ) {
                currentOrder["otcPrice"] = 0;
              } else {
                currentOrder["otcPrice"] = new Decimal(sellAmountPrice)
                  .dividedBy(value)
                  .dividedBy(tokenSellPrice)
                  .toNumber();
              }
              if (Number(sellAmountPrice) === 0 || Number(value) === 0) {
                currentOrder["otcPriceUSD"] = 0;
              } else {
                currentOrder["otcPriceUSD"] = new Decimal(sellAmountPrice)
                  .dividedBy(value)
                  .toNumber();
              }
            }
            if (currentOrder.boughtOrders.length > 0) {
              if (account) {
                if (
                  currentOrder.seller.toLocaleLowerCase() ===
                  account.toLocaleLowerCase()
                ) {
                  if (historySelling[currentOrder.chainId]) {
                    historySelling[currentOrder.chainId].push(currentOrder);
                  } else {
                    historySelling[currentOrder.chainId] = [];
                    historySelling[currentOrder.chainId].push(currentOrder);
                  }
                }
              }
              let orderAdded = false;
              currentOrder.boughtOrders.filter((ele) => {
                if (account) {
                  if (
                    ele.buyer.toLocaleLowerCase() ===
                    account.toLocaleLowerCase()
                  ) {
                    if (historyPurchases[currentOrder.chainId] && !orderAdded) {
                      historyPurchases[currentOrder.chainId].push(currentOrder);
                      orderAdded = true;
                    } else {
                      historyPurchases[currentOrder.chainId] = [];
                      historyPurchases[currentOrder.chainId].push(currentOrder);
                      orderAdded = true;
                    }
                  }
                }
              });
            }
            let currentPair =
              (tokenList[currentOrder.chainId][currentOrder.tokenSell]
                ? tokenList[currentOrder.chainId][currentOrder.tokenSell].symbol
                : "") +
              "/" +
              (tokenList[currentOrder.chainId][currentOrder.wantToken]
                ? tokenList[currentOrder.chainId][currentOrder.wantToken].symbol
                : "");
            currentOrder["pair"] = currentPair;
            allOrders.push(currentOrder);
            if (pairs[currentOrder.chainId] === undefined) {
              pairs[currentOrder.chainId] = {};
            }
            if (pairs[currentOrder.chainId][currentPair] === undefined) {
              pairs[currentOrder.chainId][currentPair] = [];
              pairs[currentOrder.chainId][currentPair].push(currentOrder);
              pairs[currentOrder.chainId][currentPair]["min"] = currentOrder;
              pairs[currentOrder.chainId][currentPair]["max"] = currentOrder;
              pairs[currentOrder.chainId][currentPair]["totalTransaction"] = 0;
              pairs[currentOrder.chainId][currentPair]["pricesChart"] = [];
              pairs[currentOrder.chainId][currentPair]["marketPricesChart"] =
                [];
              pairs[currentOrder.chainId][currentPair]["supplyChart"] = [];
              pairs[currentOrder.chainId][currentPair]["date"] = [];
              if (
                currentOrder.orderStatus === "1" ||
                currentOrder.orderStatus === "4"
              ) {
                pairs[currentOrder.chainId][currentPair]["pricesChart"].push(
                  currentOrder.otcPriceUSD
                );
                pairs[currentOrder.chainId][currentPair][
                  "marketPricesChart"
                ].push(currentOrder.marketPriceUSD);
                pairs[currentOrder.chainId][currentPair]["supplyChart"].push(
                  currentOrder.supplyAvailable
                );
                pairs[currentOrder.chainId][currentPair]["date"].push(
                  new Date(currentOrder.createdAt).getTime()
                );
              }
              if (currentOrder.boughtOrders.length > 0) {
                pairs[currentOrder.chainId][currentPair][
                  "totalTransaction"
                ] += 1;
                pairs[currentOrder.chainId][currentPair]["totalAllocation"] =
                  currentOrder.boughtOrders.reduce((sum, order) => {
                    return sum + Number(getEtherValue(order.boughtAmount));
                  }, 0);
                otcAllocation += Number(
                  pairs[currentOrder.chainId][currentPair]["totalAllocation"]
                );
              } else {
                pairs[currentOrder.chainId][currentPair]["totalAllocation"] = 0;
              }
            } else {
              pairs[currentOrder.chainId][currentPair].push(currentOrder);
              if (
                currentOrder.orderStatus === "1" ||
                currentOrder.orderStatus === "4"
              ) {
                if (
                  pairs[currentOrder.chainId][currentPair]["pricesChart"]
                    .length > 0
                ) {
                  pairs[currentOrder.chainId][currentPair]["pricesChart"].push(
                    currentOrder.otcPriceUSD
                  );
                  pairs[currentOrder.chainId][currentPair][
                    "marketPricesChart"
                  ].push(currentOrder.marketPriceUSD);
                  pairs[currentOrder.chainId][currentPair]["supplyChart"].push(
                    currentOrder.supplyAvailable
                  );
                  pairs[currentOrder.chainId][currentPair]["date"].push(
                    new Date(currentOrder.createdAt).getTime()
                  );
                } else {
                  pairs[currentOrder.chainId][currentPair]["pricesChart"] = [];
                  pairs[currentOrder.chainId][currentPair][
                    "marketPricesChart"
                  ] = [];
                  pairs[currentOrder.chainId][currentPair]["supplyChart"] = [];
                  pairs[currentOrder.chainId][currentPair]["date"] = [];
                  pairs[currentOrder.chainId][currentPair]["pricesChart"].push(
                    currentOrder.otcPriceUSD
                  );
                  pairs[currentOrder.chainId][currentPair][
                    "marketPricesChart"
                  ].push(currentOrder.marketPriceUSD);
                  pairs[currentOrder.chainId][currentPair]["supplyChart"].push(
                    currentOrder.supplyAvailable
                  );
                  pairs[currentOrder.chainId][currentPair]["date"].push(
                    new Date(currentOrder.createdAt).getTime()
                  );
                }
              }
              if (currentOrder.boughtOrders.length > 0) {
                pairs[currentOrder.chainId][currentPair][
                  "totalTransaction"
                ] += 1;
                pairs[currentOrder.chainId][currentPair]["totalAllocation"] =
                  pairs[currentOrder.chainId][currentPair]["totalAllocation"] +
                  currentOrder.boughtOrders.reduce((sum, order) => {
                    return sum + Number(getEtherValue(order.boughtAmount));
                  }, 0);
                otcAllocation += Number(
                  pairs[currentOrder.chainId][currentPair]["totalAllocation"]
                );
              }
              if (
                pairs[currentOrder.chainId][currentPair]["min"].otcPrice >
                currentOrder.otcPrice
              ) {
                pairs[currentOrder.chainId][currentPair]["min"] = currentOrder;
              }
              if (
                pairs[currentOrder.chainId][currentPair]["max"].otcPrice <
                currentOrder.otcPrice
              ) {
                pairs[currentOrder.chainId][currentPair]["max"] = currentOrder;
              }
            }
          });

          const today = new Date().getTime();
          const initialDay = today - 31536000000;

          Object.entries(pairs).map((eleOut, keyOut) => {
            otcTradersData[eleOut[0]] = [];
            Object.entries(eleOut[1]).map(async (ele, key) => {
              const pairNames = ele[0];

              const totalAmount = ele[1].reduce((total, obj) => {
                const date = new Date(obj.updatedAt);
                if (date.getTime() >= initialDay && date.getTime() <= today) {
                  return (
                    total +
                    obj.boughtOrders.reduce((sum, order) => {
                      return sum + Number(getEtherValue(order.boughtAmount));
                    }, 0)
                  );
                }

                return total + 0;
              }, 0);

              let latestDate = 0;
              let avgPriceAll = 0;
              let avgPriceAllUSD = 0;
              let countAll = 0;
              let avgPriceTillYesterday = 0;
              let count = 0;
              let change = 0;
              let lowestAsk = 0;
              let totalSeller = new Set();
              let allAsking = [];

              ele[1].forEach((order) => {
                const date = new Date(order.updatedAt).getTime();
                const dateCreated = new Date(order.createdAt).getTime();
                if (date >= initialDay && date <= today && date >= latestDate) {
                  latestDate = date;
                }
                if (order.orderStatus === "1" || order.orderStatus === "4") {
                  if (order.orderType === "ask") {
                    if (
                      order.initialValue === 0 ||
                      order.initialSellAmount === 0
                    ) {
                      allAsking.push(0);
                    } else {
                      allAsking.push(
                        new Decimal(Web3.utils.fromWei(order.initialValue))
                          .dividedBy(
                            Web3.utils.fromWei(order.initialSellAmount)
                          )
                          .toNumber()
                      );
                    }
                    avgPriceAll =
                      avgPriceAll +
                      order.initialValue / order.initialSellAmount;
                    countAll++;
                    totalSeller.add(order.seller);
                    if (lowestAsk === 0) {
                      lowestAsk = order.initialValue / order.initialSellAmount;
                    } else if (
                      lowestAsk >
                      order.initialValue / order.initialSellAmount
                    ) {
                      lowestAsk = order.initialValue / order.initialSellAmount;
                    }
                  } else {
                    if (
                      order.initialValue === 0 ||
                      order.initialSellAmount === 0
                    ) {
                      allAsking.push(0);
                    } else {
                      allAsking.push(
                        new Decimal(Web3.utils.fromWei(order.initialSellAmount))
                          .dividedBy(Web3.utils.fromWei(order.initialValue))
                          .toNumber()
                      );
                    }
                    avgPriceAll =
                      avgPriceAll +
                      order.initialSellAmount / order.initialValue;
                    totalSeller.add(order.seller);
                    if (lowestAsk === 0) {
                      lowestAsk = order.initialSellAmount / order.initialValue;
                    } else if (
                      lowestAsk >
                      order.initialSellAmount / order.initialValue
                    ) {
                      lowestAsk = order.initialSellAmount / order.initialValue;
                    }
                    countAll++;
                  }
                }
                if (
                  dateCreated <
                  new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
                ) {
                  if (order.orderStatus === "1" || order.orderStatus === "4") {
                    if (order.orderType === "ask") {
                      avgPriceTillYesterday =
                        avgPriceTillYesterday +
                        order.initialValue / order.initialSellAmount;
                      count++;
                    } else {
                      avgPriceTillYesterday =
                        avgPriceTillYesterday +
                        order.initialSellAmount / order.initialValue;
                      count++;
                    }
                  }
                }
              });

              if (countAll > 0) {
                avgPriceAll = avgPriceAll / countAll;
                avgPriceAllUSD =
                  avgPriceAll *
                  currencyPrices[nativeTokenList[eleOut[0]].symbol];
              }
              if (count > 0) {
                avgPriceTillYesterday = avgPriceTillYesterday / count;
              }
              if (avgPriceTillYesterday > 0)
                change =
                  ((avgPriceAll - avgPriceTillYesterday) /
                    avgPriceTillYesterday) *
                  100;

              latestDate = new Date(latestDate);
              let totalSellerSize = totalSeller.size;
              let tokenLogo =
                tokenList[ele[1][0].chainId][ele[1][0].tokenSell].logoURI;
              let users = 0;
              dsUsers.map((eleUser) => {
                if (totalSellerSize > 0) {
                  if (Number(eleUser._id.chainId) === Number(ele[1][0].chainId))
                    users = eleUser._id.numberOfColors;
                }
              });
              let uniqueSellers =
                ((totalSellerSize / users) * 100).toFixed(2) +
                " % (" +
                totalSellerSize +
                "/" +
                users +
                ")";
              let median = 0;
              if (allAsking.length > 0) {
                median = findMedian(allAsking);
              }
              let medianPriceAllUSD =
                median * currencyPrices[nativeTokenList[eleOut[0]].symbol];

              otcTradersData[eleOut[0]].push({
                pairNames,
                totalAmount,
                latestDate,
                avgPriceAll,
                avgPriceAllUSD,
                avgPriceTillYesterday,
                count,
                change,
                totalSellerSize,
                lowestAsk,
                tokenLogo,
                uniqueSellers,
                median,
                medianPriceAllUSD,
              });
            });
          });

          let taxProtectionRequests = {};
          let offersByMe = {};
          let offersToMe = {};

          otcOrders.filter((ele) => {
            if (ele.buyer === ZERO_ADDRESS || ele.seller === ZERO_ADDRESS) {
              if (ele.orderStatus === "1" || ele.orderStatus === "4") {
                if (chainWiseOrders[ele.chainId]) {
                  chainWiseOrders[ele.chainId].push(ele);
                } else {
                  chainWiseOrders[ele.chainId] = [];
                  chainWiseOrders[ele.chainId].push(ele);
                }
                if (account) {
                  if (
                    ele.seller === account ||
                    (ele.buyer === account && ele.tokenSell === ZERO_ADDRESS)
                  ) {
                    if (offersByMe[ele.chainId]) {
                      offersByMe[ele.chainId].push(ele);
                    } else {
                      offersByMe[ele.chainId] = [];
                      offersByMe[ele.chainId].push(ele);
                    }
                  }
                  if (
                    (ele.buyer === account && ele.wantToken === ZERO_ADDRESS) ||
                    (ele.seller === account && ele.tokenSell === ZERO_ADDRESS)
                  ) {
                    if (offersToMe[ele.chainId]) {
                      offersToMe[ele.chainId].push(ele);
                    } else {
                      offersToMe[ele.chainId] = [];
                      offersToMe[ele.chainId].push(ele);
                    }
                  }
                  if (ele.confirmatory === account) {
                    if (taxProtectionRequests[ele.chainId]) {
                      taxProtectionRequests[ele.chainId].push(ele);
                    } else {
                      taxProtectionRequests[ele.chainId] = [];
                      taxProtectionRequests[ele.chainId].push(ele);
                    }
                  }
                }
              } else return;
            } else {
              if (account) {
                if (ele.seller === account) {
                  if (offersByMe[ele.chainId]) {
                    offersByMe[ele.chainId].push(ele);
                  } else {
                    offersByMe[ele.chainId] = [];
                    offersByMe[ele.chainId].push(ele);
                  }
                }
                if (ele.buyer === account) {
                  if (offersToMe[ele.chainId]) {
                    offersToMe[ele.chainId].push(ele);
                  } else {
                    offersToMe[ele.chainId] = [];
                    offersToMe[ele.chainId].push(ele);
                  }
                }
              }
            }
          });
          setPairs(pairs);
          setAllOrders(allOrders);
          setOtcOrders(chainWiseOrders);
          setTaxProtectionRequests(taxProtectionRequests); //taxProtectionRequests
          setOtcTradersData(otcTradersData);
          setOffersByMe(offersByMe); // offersByMe
          setOffersToMe(offersToMe); //offersToMe
          setHistoryPurchases(historyPurchases);
          setHistorySelling(historySelling);
          setOrdersLoading(false);
          setTotalOtcAllocation(otcAllocation);
          let timeTakenOverAll = Date.now() - start;
          console.log(
            "Total time taken overall OTC : " +
              timeTakenOverAll +
              " milliseconds"
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          // this.setState({
          //     error
          // });
        }
      );
  };

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const connectWallet = async () => {
    await metamask_connection(localStorage.getItem("account"), "ahrefClick");
    let chainId = localStorage.getItem("chainId");
    if (chainId === CHAIN_ID) {
      this.setState({ redirect: true });
    } else {
      // if (chainId === CHAINS.BSCTESTNET)
      //     notificationConfig.error("Please Select BSC TestNet")
      // else if (chainId === CHAINS.BSCMAINNET)
      //     notificationConfig.error("Please Select BSC MainNet")
    }
  };

  const onToggle = (indexNew) => setIndex(index === indexNew ? null : indexNew);

  const onToggle03 = (searchBXNew) =>
    setSearchBX(searchBX === searchBXNew ? null : searchBXNew);

  return (
    <>
      <MainContainer>
        <LeftMBX />
        <RightContainer>
          <MyOfferButton
            onClick={() => {
              setOtcTab("myOffers");
            }}
          >
            My Offers (
            {Number(offersToMe.length ?? 0) + Number(offersByMe.length ?? 0)})
          </MyOfferButton>
          <DSTitle01 className="wow fadeInUp" data-wow-delay="0.1s">
            OTC Exchange
          </DSTitle01>
          <TabContainer>
            <Tabs>
              <div
                onClick={() => setExplanationTab("how-it-works")}
                style={{
                  cursor: "pointer",
                  color:
                    explanationTab === "how-it-works" ? "white" : "#9a9a9a",
                }}
              >
                <i className="fas fa-exclamation-circle"></i> How it works
              </div>
              <span>|</span>
              <div
                onClick={() => setExplanationTab("asks-vs-bids")}
                style={{
                  cursor: "pointer",
                  color:
                    explanationTab === "asks-vs-bids" ? "white" : "#9a9a9a",
                }}
              >
                Asks vs bids
              </div>
              <span>|</span>
              <div
                onClick={() => setExplanationTab("positions")}
                style={{
                  cursor: "pointer",
                  color: explanationTab === "positions" ? "white" : "#9a9a9a",
                }}
              >
                Positions
              </div>
              <span>|</span>
              <div
                onClick={() => setExplanationTab("ai-gateway")}
                style={{
                  cursor: "pointer",
                  color: explanationTab === "ai-gateway" ? "white" : "#9a9a9a",
                }}
              >
                AI Gateway
              </div>
            </Tabs>
            <GradientButton>
              <PlayButton>
                How IT Works
                <FaCirclePlay style={{ color: "#00f02b", marginLeft: "5px" }} />
              </PlayButton>
            </GradientButton>
          </TabContainer>

          {explanationTab === "how-it-works" && (
            <TabBox>
              <a className="close" onClick={() => setExplanationTab("")}>
                <i class="fas fa-times"></i>
              </a>
              <h5>How it works</h5>
              <p>
                The Liquidity Gateway system is designed to reduce the number of
                OTC orders by importing liquidity from the secondary market
                without negatively impacting the token's appreciation.
              </p>
              <p>
                To prevent excessive dumping, the Liquidity Gateway persistently
                monitors the previous day's token appreciation to determine the
                maximum limit on the number of tokens that can be sold during a
                given time period based on a dynamic appreciation limit. The
                goal of the Liquidity Gateway is to decrease the sell pressure
                from behind the dumper shield before the vesting period ends,
                while ensuring that the liquidity flowing into the OTC market
                does not reduce the token's last appreciation by more than 50%.
                This way, each day will have a small sell pressure while the
                token continues to appreciate.
              </p>
              <p>
                Example:
                <br />
                Let's say the token's current price is $10 and it appreciates by
                10% to $11. <br />
                The Liquidity Gateway system determines that the maximum limit
                on the number of tokens that can be sold to the secondary market
                is 10,000 tokens, which would reduce the token value to $10.5.
                <br />
                The Liquidity Gateway system operates like a flash loan concept,
                buying the tokens from the users behind the dumper shield and
                selling them on the secondary market subject to the calculated
                limit amount.
                <br />
              </p>
            </TabBox>
          )}
          {explanationTab === "asks-vs-bids" && (
            <TabBox>
              <a className="close" onClick={() => setExplanationTab("")}>
                <i class="fas fa-times"></i>
              </a>
              <h5>Asks vs Bids</h5>
              <p>
                The Dumper Shield is a feature that enables sellers to place ask
                orders and buyers to place bid orders, creating a marketplace
                for the buying and selling of tokens. Sellers can specify the
                amount of tokens they wish to sell and the price they are asking
                for, while buyers can indicate the amount they are willing to
                buy and the price they are willing to pay. This allows for a
                more efficient and transparent way of trading, as buyers and
                sellers can directly interact with each other without the need
                for intermediaries or any personal engagement over social media
                chat.
              </p>
            </TabBox>
          )}
          {explanationTab === "positions" && (
            <TabBox>
              <a className="close" onClick={() => setExplanationTab("")}>
                <i class="fas fa-times"></i>
              </a>
              <h5>Positions</h5>
              <p>
                The Dumper Shield comprises three classes of positions: 1st,
                2nd, and 3rd. The position determines the order in which
                liquidity from the Gateway is disbursed among the positions.
                Once there are no holders on the position above, the liquidity
                flows to the next position, all the way to the last. While the
                first position may have a variable vesting period based on
                voting, the second and third positions have an infinite vesting
                period. Tokens always remain behind the Dumper Shield but are
                still available to engage with liquidity via the OTC and through
                the Gateway.
                <br />
                For example, Qwantum Finance Labs uses the first position to
                place investors who actually paid to purchase tokens, while the
                second position is used to place investors who teleport their
                positions from other Qwantum Finance Labs projects. The last
                position is used for placing users who receive tokens for free
                as rewards or airdrops. This order makes sense because users who
                receive free tokens may have different selling behavior than
                investors who bought the tokens.
              </p>
              <p>
                That being said, each project can decide how to utilize these
                three positions according to their specific needs.
              </p>
              <p>Dumper Shield Position Examples</p>
              <p>
                1st position supply 10,000 tokens <br />
                2nd position supply 5,000 tokens
                <br />
                3rd position supply 2,000 tokens <br />
                Day 1 the gateway determines 6,000 worth of tokens are available
                <br />
                Day 2 the gateway determines additional 8,000 worth of tokens
                are available
                <br />
                Day 3 the gateway determines additional 1,000 worth of tokens
                are available
                <br />
                In day 1, the 6,000 token liquidity available will be used to
                buyout orders in the 1st position. Ordres in 2nd and 3rd
                positions remain pending
                <br />
                In day 2, the 9,000 token liquidity available will be used to
                buyout the remaining 2,000 orders in the 1st position, and the
                5,000 tokens liquidity available leftover will be used to buyout
                the 5,000 orders in the 2st position. Ordres in 3rd positions
                remain pending.
                <br />
                In day 3, the 1,000 token liquidity available will be used to
                buyout the partia of the 2,000 orders in the 3st position, and
                1,000 orders on 3rd positions remain pending.
              </p>
            </TabBox>
          )}
          {otcTab === "exchange" ? (
            <NewOtcExchange
              account={account}
              connectWallet={connectWallet}
              otcOrders={otcOrders}
              offersByMe={offersByMe}
              ordersLoading={ordersLoading}
              currencyPrices={currencyPrices}
              setPopupMyofferClaim={setPopupMyofferClaim}
              setCurrentClaimOrder={setCurrentClaimOrder}
              pairs={pairs}
              historyPurchases={historyPurchases}
              historySelling={historySelling}
              setPopupOTCClaim={setPopupOTCClaim}
              otcTradersData={otcTradersData}
              chainId={globalContext.chainId}
              dsUsers={dsUsers}
              setNewOfferType={setNewOfferType}
              totalOtcAllocation={totalOtcAllocation}
            ></NewOtcExchange>
          ) : otcTab === "myOffers" ? (
            <>
              {account !== null ? (
                <OTCMyOffers
                  offersByMe={offersByMe}
                  offersToMe={offersToMe}
                  setPopupMyofferClaim={setPopupMyofferClaim}
                  setCurrentClaimOrder={setCurrentClaimOrder}
                ></OTCMyOffers>
              ) : (
                <ConnectWallet></ConnectWallet>
              )}
            </>
          ) : otcTab === "transfer-token" ? (
            <>
              {account !== null ? (
                <>
                  <OTCTransferTokens></OTCTransferTokens>
                </>
              ) : (
                <ConnectWallet></ConnectWallet>
              )}
            </>
          ) : null}
        </RightContainer>
      </MainContainer>
      <BuyReqPopup
        isOpen={popupOTCClaim}
        dismiss={() => {
          setPopupOTCClaim(false);
        }}
        newOfferType={newOfferType}
        currencyPrices={currencyPrices}
      />
      <ClaimToken
        isOpen={popupMyofferClaim}
        dismiss={() => {
          setPopupMyofferClaim(false);
        }}
        orderDetails={currentClaimOrder}
        orderTradersData={otcTradersData}
        historyPurchases={historyPurchases}
      />

      <ReactTooltip effect="solid" className="myTip" />
    </>
  );
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const MainContainer = styled(FlexDiv)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

const RightContainer = styled(FlexDiv)`
  position: relative;
  width: calc(100% - 82px);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 18px 56px;
  align-content: baseline;
  ${Media.md} {
    padding: 25px 20px;
  }
  ${Media.sm} {
    width: 100%;
  }
  .collapse {
    &.active {
      overflow: visible !important;
    }
    .custom-scroll {
      margin-top: 50px;
      ${Media.md} {
        margin-top: 30px;
      }
      &.deactive {
        display: none;
      }
    }
  }
`;

const DSTitle01 = styled(FlexDiv)`
  justify-content: space-between;
  font: 700 36px/40px "Kanit", arial;
  color: #00f02b;
  .DSrPart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: normal;
    color: #fff;
    font-weight: 700;
    ${Media.xs} {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
  .linkBX01 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #5a5e67;
    font-weight: 300;
    text-transform: initial;
    a {
      color: #00f02b;
      margin: 0 8px;
      font-family: "IBM Plex Mono", Arial, sans-serif;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
      :first-child {
        ${Media.xs} {
          margin: 0px 8px 0px 0px;
        }
      }
    }
    ${Media.xs} {
      align-items: flex-start;
      margin-top: 15px;
      flex-flow: column;
      justify-content: flex-start;
      a {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
  ${Media.xs} {
    display: block;
  }
`;

const DSTitle02 = styled(FlexDiv)`
  justify-content: flex-start;
  display: flex;
  font: 700 24px/40px "Kanit", arial;
  color: #00f02b;
  margin: 22px 0;
`;

const OpfyText = styled.div`
  margin: 10px 0px 50px 0px;
  &.m-0 {
    margin: 0;
  }
  p {
    color: #00f02b;
    font-size: 12px;
    text-align: left;
    width: 100%;
    margin: 0px;
    ${Media.md} {
      margin-top: 15px;
    }
  }
  a {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
`;

const TabNav = styled(FlexDiv)`
  padding-top: 90px;
  flex-direction: row;
  ${Media.md} {
    align-items: stretch;
  }
  .tab-Link {
    font-size: 21px;
    font-weight: 700;
    color: #fff;
    flex-grow: 1;
    text-align: center;
    border-bottom: 3px solid #393d46;
    padding-bottom: 30px;
    position: relative;
    i {
      position: absolute;
      top: -3px;
      margin-left: 3px;
      i {
        font-size: 14px;
        color: #000;
        width: 23px;
        height: 23px;
        background-color: #00f02b;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
    span {
      position: relative;
    }
    ${Media.md} {
      font-size: 18px;
    }
    ${Media.sm} {
      font-size: 14px;
      padding: 15px 0px;
    }
  }

  .tab-Link.active {
    color: #00f02b;
    border-bottom-color: #00f02b;
    text-shadow: 0 0 10px #00f02b;
  }

  .hoverClass :hover span {
    display: none;
  }
  .hoverClass :hover : before {
    content: "Coming Soon";
  }
  ${Media.md} {
    display: none;
  }
  ${Media.sm} {
    padding-top: 40px;
    .tab-Link {
      width: 100%;
      text-align: left;
      &.active:after {
        content: "";
        border-width: 6px;
        border-style: solid;
        border-bottom: 0px;
        -webkit-transition: 0.5s ease all;
        transition: 0.5s ease all;
        border-color: #00f02b transparent transparent transparent;
        position: absolute;
        right: 0;
        top: 20px;
      }
    }
  }
`;
const MobileTab = styled(FlexDiv)`
  padding-top: 40px;
  display: none;
  h3 {
    color: #00f02b;
    font-size: 18px;
    padding: 15px 0px;
    border-bottom: 3px solid #00f02b;
    width: 100%;
    position: relative;
    margin: 0;
    &:after {
      content: "";
      border-width: 6px;
      border-style: solid;
      border-bottom: 0px;
      -webkit-transition: 0.5s ease all;
      transition: 0.5s ease all;
      border-color: #00f02b transparent transparent transparent;
      position: absolute;
      right: 0;
      top: 23px;
    }
  }
  .dropDown {
    width: 100%;
    display: flex;
    flex-flow: column;
    a {
      padding: 15px 0px;
      font-size: 14px;
      border-bottom: 1px solid #393d46;
      color: #fff;
    }
  }
  ${Media.md} {
    display: block;
  }
`;
const OpfyTableBX = styled(FlexDiv)`
  margin: 0px;
  font-size: 14px;
  color: #fff;
  justify-content: flex-start;
  p {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
  }
  .searchBAR {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    display: flex;
    flex-direction: row;
    padding: 4px;
    align-items: center;
    justify-content: space-between;
    display: none;
    input {
      width: calc(100% - 30px);
      height: 34px;
      padding: 5px;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #545861;
      border-radius: 5px;
    }
  }
  .collapse.active .searchBAR {
    display: flex;
  }

  a {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
    :hover {
      text-decoration: underline;
    }
  }
  .sortLink {
    position: absolute;
    right: 12px;
    margin-top: 0;
    width: auto;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
    ${Media.xl} {
      position: initial;
      margin: 0px 5px;
    }
  }
  .searchLink {
    position: absolute;
    right: 31px;
    margin-top: 0;
    width: auto;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
    ${Media.xl} {
      position: initial;
      margin: 0px 5px 0px 10px;
    }
  }
  .OpfyBTNbar {
    display: flex;
    align-items: center;
    justify-content: center; /* width:210px; */
    &.v2 {
      width: 180px;
      justify-content: center;
    }

    .btn01 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid #00f02b;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #000;
        background-color: #00f02b;
      }
    }
    .btn03 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid transparent;
      background: none;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #00f02b;
        border: 2px solid #00f02b;
        box-shadow: 0 0 10px #00f02bba;
      }
    }
    .btn02 {
      color: #858686;
      padding: 10px;
      min-width: 42px;
      &:hover {
        color: #c32b2d;
      }
      .btn03 + & {
        margin-left: 6px;
      }
      ${Media.md} {
        padding: 10px 0px;
      }
    }
  }
  table {
    min-width: max-content;
    width: 100%;
    border-collapse: collapse;
    background: #000000;
  }
  table tr td {
    border: 1px solid #1e2127;
    padding: 7px 18px;
    &:nth-last-child(01) {
      width: 212px;
    }
    &:nth-child(01) {
      color: #00f02b;
      a {
        color: #00f02b;
        :hover {
          text-decoration: underline;
        }
      }
    }
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
    &.bor02 {
      color: #4b4bff;
    }
    &.noResult {
      font-size: 14px;
      color: #9a9a9a;
      height: 120px;
    }
    .color-red {
      color: #c32b2d;
    }
    .color-green {
      color: #00f02b;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
  }

  table tr:hover td {
    background-color: #0b0c0f;
  }
  table tr th {
    border: 1px solid #1e2127;
    border-top: 2px solid #1e2127;
    border-bottom: 2px solid #1e2127;
    color: #9a9a9a;
    position: relative;
    padding: 12px;
    height: 60px; /* position: sticky; top:0; left:0; */
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
  }
`;

const GreenBadge = styled(FlexDiv)`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #00f02b;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  right: -27px;
  top: -7px;
  ${Media.md} {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
`;

const FormBTNBX = styled(FlexDiv)`
  max-width: 664px;
  margin: 0 auto 50px auto;
  ${Media.md} {
    margin: 0 15px 50px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    ${Media.xs} {
      font-size: 14px;
    }
  }
  button {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    height: 70px;
    background-color: #383b42;
    color: #00f02b;
    border-radius: 10px;
    :hover {
      background-color: #282b32;
    }
    ${Media.md} {
      font-size: 24px;
    }
  }

  .hoverClass :hover span {
    display: none;
  }
  .hoverClass :hover : before {
    content: "Coming Soon";
  }
`;

const Pagination = styled.div`
  margin: 10px 0px 75px;
  display: flex;
  &.m-0 {
    margin: 0;
  }
`;

const PaginationInner = styled(FlexDiv)`
  justify-content: flex-start;
  margin-left: auto;
  width: auto;
  p {
    margin: 0;
    font-size: 12px;
    color: #9a9a9a;
  }
  .caret-down {
    width: 0;
    height: 0;
    border-radius: 3px;
    border: 6px solid transparent;
    border-top: 0px;
    border-bottom: 6px solid #858686;
    transition: 0.5s ease all;
    cursor: pointer;
    transform: rotate(-180deg);
  }
  .p-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    background-color: #010202;
    border-radius: 4px;
    padding: 8px 10px;
    margin: 0px 15px;
  }
`;

const PNumberText = styled.div`
  font-size: 12px;
  color: #9a9a9a;
`;

const THirdBoxBottom = styled(FlexDiv)`
  justify-content: space-between;
  margin: 10px 0px 40px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  ${Media.md} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }
`;

const Tabs = styled.div`
    display: flex;
    flex-flow: wrap;
    flex-wrap: inherit;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0; 
    align-items: center; 
    margin-top: 30px; 
    margin-right: auto;
    gap: 10px;
    a { font-weight: bold;
        &.active {color: #00f02b; text-shadow: 0 0 8px #00f02b}
        &.active.color-red {color: #c32b2d}
    }
    span {margin: 0 6px; color: #9a9a9a;}
    ${Media.sm} {
        flex-flow: wrap;
    }
    .fas.fa-caret-up {font-size: 16px; color: #fff; margin-left: 30px;}
    button { font-weight: bold;
      color: white;
      &.active {color: #00f02b; text-shadow: 0 0 8px #00f02b}
      &.active.color-red {color: #c32b2d}
    }
  }
`;

const GradientButton = styled.div`
  min-width: 48px;
  width: fit-content;
  padding: 2px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #00f02b, #8146e4);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 1px #4fff00;
  @media (max-width: 712px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const PlayButton = styled.button`
  background-color: #000;
  color: #fff;
  height: 100%;
  padding: 15px 30px;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MyOfferButton = styled.button`
  position: absolute;
  z-index: 100;
  top: ${window.innerHeight / 3}px;
  left: 0;
  background-color: yellow;
  padding: 30px 10px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  background-image: linear-gradient(to bottom, #00f02b, #8146e4);
  box-shadow: 0 0 15px #8146e4;
  border-radius: 0.25rem;
  color: #fff;
  writing-mode: vertical-rl;
  text-orientation: mixed;
`;

const TabBox = styled.div`
  background: #000;
  border: 1px solid #1e2127;
  padding: 30px 28px;
  position: relative;
  margin-top: 10px;
  color: #9a9a9a;
  h5 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 25px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 36px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #1e2127;
    font-size: 16px;
    cursor: pointer;
  }
`;

export default OTCNEW;

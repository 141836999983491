import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../modal";
import Collapse from "@kunukn/react-collapse";
import Media from "../../Theme/media-breackpoint";
import { contractAddresses, contractAddressesChainWise } from "../../constants";
import notificationConfig from "../../config/notificationConfig";
import {
  approveTokenTransfer,
  otcDepositTokens,
} from "../../Service/otc_function";
import Web3 from "web3";
import ReactLoaderSpinner from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import {
  changeNetwork,
  getAccountaddress,
} from "../../Service/metamask_function";
import BigNumber from "bignumber.js";
import { GlobalContext } from "../../Context/globalContext";
import { Range } from "react-range";

function DepositRedeem(props) {
  const [dropDown, setDropDown] = useState(false);
  const [depositRedeemLoading, setDepositRedeemLoading] = useState(false);
  const [isInsufficientFund, setIsInsufficientFund] = useState(false);
  const [approveTokenTransferLoading, setApproveTokenTransferLoading] =
    useState(false);
  const [value, setValue] = useState(0);
  const [tokenDetails, setTokenDetails] = useState(props.tokenDetails);

  const [depositWithdrawToken, setDepositWithdrawToken] = useState({});
  const [canDepositWithdraw, setCanDepositWithdraw] = useState(false);
  const [position, setPosition] = useState(0);
  const globalContext = useContext(GlobalContext);
  const [balances, setBalances] = useState({
    mainBalance: 0,
    allowance: 0,
  });

  useEffect(async () => {
    if (globalContext.tokenList === null && props.tokenDetails !== null) {
      await setTokenDetails(props.tokenDetails);
    } else if (
      globalContext.tokenList !== null &&
      props.tokenDetails === null
    ) {
      await setTokenDetails(
        globalContext.tokenList[Object.keys(globalContext.tokenList)[0]]
      );
    } else if (
      globalContext.tokenList !== null &&
      props.tokenDetails !== null
    ) {
      await setTokenDetails(
        globalContext.tokenList[props.tokenDetails.chainId][
          props.tokenDetails.token
        ]
      );
    } else {
      if (props.tokenDetails !== null)
        await setTokenDetails(
          globalContext.tokenList[props.tokenDetails.chainId][
            props.tokenDetails.token
          ]
        );
    }
  }, [props.tokenDetails, globalContext.tokenList]);

  const handleApproveTokenTransfer = async () => {
    if (value === "" || value === "0" || value <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }
    let chainId = localStorage.getItem("chainId");
    setApproveTokenTransferLoading(true);

    let approveTokenTransferData = {};

    approveTokenTransferData["token"] = tokenDetails.token;
    approveTokenTransferData["spender"] =
      contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
    approveTokenTransferData["amount"] = Web3.utils.toWei(
      value.toString(),
      "ether"
    );

    // otcDepositTokensData["orderId"] = orderDetails.orderId;
    // value = value / 100;
    // value = value * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // otcDepositTokensData["paymentAmount"] = Web3.utils.toWei(value.toString());
    // otcDepositTokensData["payableAmount"] = Web3.utils.toWei(value.toString());

    console.log(approveTokenTransferData);

    await approveTokenTransfer(
      approveTokenTransferData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        globalContext.fetchTokenList();
        setApproveTokenTransferLoading(false);
        notificationConfig.success("Token Transfer Approved Successfully!");
      },
      async (error) => {
        setApproveTokenTransferLoading(false);

        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handleDepositToken = async () => {
    if (value === "" || value === "0" || value <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }

    setDepositRedeemLoading(true);

    let otcDepositTokensData = {};

    otcDepositTokensData["token"] = tokenDetails.token;
    otcDepositTokensData["amount"] = Web3.utils.toWei(
      value.toString(),
      "ether"
    );
    otcDepositTokensData["user"] = await getAccountaddress();
    otcDepositTokensData["position"] = position;

    // otcDepositTokensData["orderId"] = orderDetails.orderId;
    // value = value / 100;
    // value = value * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // otcDepositTokensData["paymentAmount"] = Web3.utils.toWei(value.toString());
    // otcDepositTokensData["payableAmount"] = Web3.utils.toWei(value.toString());

    console.log(otcDepositTokensData);

    await otcDepositTokens(
      otcDepositTokensData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        globalContext.fetchTokenList();
        // this.setState({ depositRedeemLoading: false }, () => {
        //   this.props.dismiss();
        // })
        setDepositRedeemLoading(false);
        notificationConfig.success("Tokens Deposit Successfully!");
      },
      async (error) => {
        setDepositRedeemLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handleWithdrawTokens = async () => {
    const { tokenDetails } = this.state;
    let { value } = this.state;

    if (value === "" || value === "0" || value <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }

    this.setState({ depositRedeemLoading: true });

    let otcDepositTokensData = {};

    otcDepositTokensData["token"] = tokenDetails.token;
    otcDepositTokensData["amount"] = value;
    otcDepositTokensData["user"] = await getAccountaddress();
    otcDepositTokensData["position"] = position;

    // otcDepositTokensData["orderId"] = orderDetails.orderId;
    // value = value / 100;
    // value = value * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // otcDepositTokensData["paymentAmount"] = Web3.utils.toWei(value.toString());
    // otcDepositTokensData["payableAmount"] = Web3.utils.toWei(value.toString());

    console.log(otcDepositTokensData);

    await otcDepositTokens(
      otcDepositTokensData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        this.setState({ depositRedeemLoading: false }, () => {
          this.props.dismiss();
        });
        notificationConfig.success("Tokens Deposit Successfully!");
      },
      async (error) => {
        this.setState({ depositRedeemLoading: false });
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handleChange = async (value) => {
    // const {
    //   orderDetails,
    //   userBalance
    // } = this.state;
    // let payableAmount = value / 100;
    // payableAmount = payableAmount * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // let isInsufficientFund = Number(Web3.utils.fromWei(userBalance)) < Number(payableAmount);
    if (Number(value) > Number(Web3.utils.fromWei(tokenDetails.mainBalance))) {
      notificationConfig.warning("Insufficient Fund For Deposit!");
      return;
    }
    setValue(value);
  };

  const intOnly = async (event) => {
    if (event.shiftKey === true) event.preventDefault();

    var code = event.keyCode;

    if (
      (code >= 48 && code <= 57) ||
      (code >= 96 && code <= 105) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      code === 39 ||
      code === 46
    ) {
      // allowed characters
    } else event.preventDefault();
  };

  const onToggle02 = async (dropDownNew) =>
    // this.setState(state => ({ dropDown: state.dropDown === dropDown ? null : dropDown }));
    setDropDown(dropDown === dropDownNew ? null : dropDownNew);

  return (
    <Modal isOpen={props.isOpen} dismiss={props.dismiss}>
      {tokenDetails !== null ? (
        <BuyRPopupContainer>
          <BrPtitle01 className="MRFix01">Deposit Vested Tokens</BrPtitle01>

          <FormMBX01>
            <FormSbxLabel>Choose a token</FormSbxLabel>
            <FormSbxContent>
              <DDMenu onClick={() => onToggle02(1)}>
                {tokenDetails.symbol}
                <div className="DDContainer">
                  <Collapse
                    className={"collapse " + (dropDown === 1 ? "active" : "")}
                    isOpen={dropDown === 1}
                    onChange={({ state }) => {}}
                    onInit={({ state }) => {}}
                  >
                    <div className="DDSubContainer">
                      <button>{tokenDetails.symbol}</button>
                    </div>
                  </Collapse>
                </div>
              </DDMenu>
            </FormSbxContent>
          </FormMBX01>
          <FormMBX01>
            <FormSbxLabel>
              Position
              <span
                data-tip="Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice."
                className="helpIco"
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </FormSbxLabel>
            <FormSbxContent>
              <DDMenu className="noBorder ver2">
                <div
                  className="md-radio md-radio-inline"
                  style={{ width: "30%" }}
                >
                  <input
                    type="radio"
                    defaultChecked
                    id="radioty156"
                    name="position"
                    value={position === 0}
                    onChange={(e) => setPosition(0)}
                  />
                  <label htmlFor="radioty156">
                    1st
                    <span data-tip="1st Position" className="helpIco">
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                </div>
                <div
                  className="md-radio md-radio-inline"
                  style={{ width: "30%" }}
                >
                  <input
                    type="radio"
                    id="radioty157"
                    name="position"
                    value={position === 1}
                    onChange={(e) => setPosition(1)}
                  />
                  <label htmlFor="radioty157">
                    2nd
                    <span data-tip="2nd Position" className="helpIco">
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                </div>
                <div
                  className="md-radio md-radio-inline"
                  style={{ width: "30%" }}
                >
                  <input
                    type="radio"
                    id="radioty158"
                    name="position"
                    value={position === 2}
                    onChange={(e) => setPosition(2)}
                  />
                  <label htmlFor="radioty158">
                    3rd
                    <span data-tip="3rd Position" className="helpIco">
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                </div>
              </DDMenu>
            </FormSbxContent>
          </FormMBX01>
          <FormMBX01 className="">
            <FormSbxLabel>
              How much do you want to <br></br>
              deposit?
            </FormSbxLabel>
            <FormSbxContent>
              <div className="flexBox">
                <DragMBX>
                  <div className="dragorInput">
                    <Range
                      step={1} // Set the step value as needed
                      min={0}
                      max={
                        tokenDetails.mainBalance > 0
                          ? Web3.utils.fromWei(tokenDetails.mainBalance)
                          : 1
                      }
                      values={[value]} // Wrap value in an array
                      onChange={(values) => {
                        handleChange(values[0]); // Update the value from the array
                      }}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "15px",
                            width: "100%",
                            borderRadius: "40px",
                            backgroundColor: "#7f838d",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#00f02b",
                          }}
                        />
                      )}
                    />
                  </div>
                </DragMBX>
                <input
                  type="text"
                  value={value}
                  className="SlideValue"
                  name=""
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyDown={intOnly}
                />
              </div>
            </FormSbxContent>
          </FormMBX01>
          <FormBTNBX>
            {tokenDetails !== null &&
            Web3.utils.toHex(tokenDetails.chainId) !== globalContext.chainId ? (
              <button
                className="PlaOrBTN01"
                onClick={() => {
                  changeNetwork(Web3.utils.toHex(tokenDetails.chainId));
                }}
              >
                CHANGE NETWORK
              </button>
            ) : isInsufficientFund ? (
              <button>Insufficient Fund</button>
            ) : Number(tokenDetails.allowance) >= Number(value) * 10 ** 18 ? (
              <button onClick={() => handleDepositToken()}>
                {depositRedeemLoading ? (
                  <ReactLoaderSpinner
                    type="ThreeDots"
                    color="#fff"
                    height={15}
                    width={80}
                  />
                ) : (
                  "Deposit"
                )}
              </button>
            ) : (
              <button
                className="white"
                onClick={() => handleApproveTokenTransfer()}
              >
                {approveTokenTransferLoading ? (
                  <ReactLoaderSpinner
                    type="ThreeDots"
                    color="#00f02b"
                    height={15}
                    width={80}
                  />
                ) : (
                  "Approve"
                )}
              </button>
            )}
          </FormBTNBX>
          <ReactTooltip effect="solid" className="myTip" />
        </BuyRPopupContainer>
      ) : null}
    </Modal>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const BuyRPopupContainer = styled(FlexDiv)`
  max-width: 872px;
  margin: 0 auto;
  ${Media.md2} {
    width: -webkit-fill-available;
    max-width: 100%;
    margin: 0px 15px;
  }
`;

const BrPtitle01 = styled(FlexDiv)`
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 60px;
  &.MRFix01 {
    margin-top: 120px;
    ${Media.sm} {
      margin-top: 0px;
    }
  }
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  ${Media.sm} {
    display: block;
  }
  &.mtb-50 {
    margin: 50px 0px;
  }
`;

const FormSbxLabel = styled(FlexDiv)`
  width: 350px;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 300;
  ${Media.sm} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: calc(100% - 350px);
  justify-content: flex-start;

  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }

  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }
  ${Media.sm} {
    width: 100%;
  }
  .flexBox {
    display: flex;
    width: 100%;
    margin: 25px 0 0 0;
  }
  .SlideValue {
    width: 140px;
    height: 67px;
    border: 2px solid #5a5e67;
    border-radius: 10px;
    background: none;
    margin-left: 25px;
    padding: 0 15px;
    color: #ffffff;
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 66px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 52px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    z-index: 1000;
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;
const FormBTNBX = styled(FlexDiv)`
  margin-top: 40px;
  margin-bottom: 40px;
  button {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    height: 70px;
    background: none;
    color: #000;
    border: 2px solid #00f02b;
    border-radius: 10px;
    box-shadow: 0 0 10px #00f02b;
    color: #fff;
    text-transform: uppercase;
    :hover {
      background-color: #00f02b;
      color: #fff;
    }
    ${Media.sm} {
      font-size: 18px;
    }
  }
  span {
    font-size: 12px;
    color: #9a9a9a;
    margin: 15px 0 0;
  }

  button.red {
    background-color: #c32b2d;
    color: #fff;
    :hover {
      background-color: #ab1012;
      color: #fff;
    }
  }
  button.white {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px #ffffff;
    color: #00f02b;
    :hover {
      background-color: #ffffff;
      color: #00f02b;
    }
  }
`;
const Spacer = styled(FlexDiv)`
  height: 1px;
  margin: 12px 0 25px 0;
  background-color: #1e2127;
`;

const DragMBX = styled(FlexDiv)`
  position: relative;

  .dragorInput {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0px;
  }

  .dragorInput .input-range__slider {
    -webkit-appearance: none;
    appearance: none;
    background: #00f02b;
    cursor: pointer;
    display: block;
    margin-left: -0.9rem;
    margin-top: -1.4rem;
    color: #fff !important;
    font-weight: 700;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 29px;
    height: 29px;
    font-weight: 400;
    border: 0px;
    border-radius: 50%;
    // transform: translateY(-22px);
  }

  .dragorInput .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .dragorInput .input-range__slider-container {
    transition: left 0.3s ease-out;
    z-index: 100;
  }
  .dragorInput .input-range__label {
    color: #8e9195;
    font-size: 14px;
    transform: translateZ(0);
    white-space: nowrap;
  }
  .dragorInput .input-range__label--min,
  .input-range__label--max {
    bottom: auto;
    position: absolute;
  }
  .dragorInput .input-range__label--min {
    left: 0px;
    top: -35px;
    z-index: 2;
  }
  .dragorInput .input-range__label--max {
    right: 0px;
    top: -35px;
  }
  .dragorInput .input-range__label--value {
    position: absolute;
    top: 15px;
    z-index: 2;
    color: #fff;
    left: 0px;
    pointer-events: none;
    transform: translateX(-50%);
  }
  .dragorInput .input-range__label-container {
    left: 0%;
    position: relative;
    color: #8e9195 !important;
    font: 700 14px/16px "IBM Plex Mono", arial;
    pointer-events: none;
  }
  .dragorInput .input-range__label--max .input-range__label-container {
    left: 0%;
  }
  .dragorInput .input-range__track {
    background: #393e48;
    cursor: pointer;
    display: block;
    height: 15px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .dragorInput .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .dragorInput .input-range__track--active {
    background: #7f838d;
  }
  .dragorInput .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
  .dragorInput .input-range__label--value .input-range__label-container {
    color: #fff !important;
    font-weight: 700;
    font-size: 14px;
    position: relative;
  }

  .input-range__track {
    border-radius: 40px;
  }
  .dragorInput .input-range__label--value {
    &:after {
      content: "";
      border: none;
      width: fit-content;
      left: -12px;
      right: 0;
      bottom: -34px;
      color: #fff;
      font-size: 12px;
      font-family: "IBM Plex Mono", arial;
      text-align: center;
      font-weight: 400;
      position: absolute;
    }
  }
`;

const FormHint = styled(FlexDiv)`
  margin: 20px auto 0 auto;
  justify-content: flex-start;
  font-size: 11px;
  color: #8e9195;
  line-height: 20px;
  span {
    font-size: 12px !important;
    top: 0px;
  }
`;

export default DepositRedeem;

import React, { Component } from "react";
import styled from "styled-components";
import Gs from "../Theme/globalStyles";
import { Link } from "react-router-dom";
import Media from "../Theme/media-breackpoint";
import ReactTooltip from "react-tooltip";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Component/footer";

import Logo from "./../Assets/images/dumperShield-Logo.png";
import Icon1 from "./../Assets/images/icon-1.png";
import Icon2 from "./../Assets/images/icon-2.png";
import Icon3 from "./../Assets/images/icon-3.svg";
import Icon4 from "./../Assets/images/icon-4.svg";
import Icon5 from "./../Assets/images/icon-5.svg";
import Icon6 from "./../Assets/images/icon-6.svg";
import Icon8 from "./../Assets/images/icon-8.png";
import Icon9 from "./../Assets/images/icon-9.png";
import Icon10 from "./../Assets/images/icon-10.png";
import Icon11 from "./../Assets/images/icon-11.png";
import Icon7 from "./../Assets/images/icon-3.png";
import Dummy1 from "./../Assets/images/d1.png";
import Dummy2 from "./../Assets/images/vault.png";
import Dummy3 from "./../Assets/images/d3.png";
import Dummy4 from "./../Assets/images/d2.png";
import Dummy5 from "./../Assets/images/d5.png";
import Dummy6 from "./../Assets/images/d6.png";
import Dummy7 from "./../Assets/images/d7.png";
import Dummy8 from "./../Assets/images/d8.png";
import Dummy9 from "./../Assets/images/d9.png";
import Dummy10 from "./../Assets/images/d10.png";
import Dummy11 from "./../Assets/images/d11.png";
import Dummy31 from "./../Assets/images/D3-1.png";
import Dummy32 from "./../Assets/images/D3-2.png";
import Dummy33 from "./../Assets/images/D3-3.png";
import Dummy34 from "./../Assets/images/arrow-lg-icon.png";
import AtomLogo from "../Assets/images/qtum-logo.png";
import PDOLogo from "../Assets/images/pdo-logo.png";
import BannerChart01 from "../Assets/images/BannerChart.jpg";
import BannerChart02 from "../Assets/images/BannerChart2.jpg";
import arrowBA from "../Assets/images/arrowBA.png";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });
};

const FIRST_IMAGE = {
  imageUrl: BannerChart01,
};
const SECOND_IMAGE = {
  imageUrl: BannerChart02,
};

class FundAndIncentive extends Component {
  constructor(props) {
    super(props);
    this.animationInterval = React.createRef(); // Create a ref
  }
  state = {
    delimiterPercentPosition: 0,
  };
  animateSlider = () => {
    let currentPosition = 50;
    let isIncreasing = true;
    this.animationInterval.current = setInterval(() => {
      if (isIncreasing) {
        currentPosition += 0.1; // Adjust the increment as needed
        if (currentPosition >= 53) {
          isIncreasing = false;
        }
      } else {
        currentPosition -= 0.1; // Adjust the decrement as needed
        if (currentPosition <= 47) {
          isIncreasing = true;
          // clearInterval(animationInterval);
        }
      }
      this.setState({ delimiterPercentPosition: currentPosition });
    }, 30); // Adjust the interval time for smoother animation
  };

  componentDidMount() {
    this.props.changeHead(true);
    AOS.init({
      once: true,
      duration: 3000,
    });
    this.animateSlider();
  }

  componentWillUnmount() {
    this.props.changeHead(false);
    clearInterval(this.animationInterval.current);
  }

  removeAnimate = () => {
    clearInterval(this.animationInterval.current);
  };

  render() {
    const { delimiterPercentPosition } = this.state;

    return (
      <>
        <Warning className="fixed">
          <i className="fas fa-exclamation-triangle"></i>Experimental, unaudited
          BETA system. Use at your own risk
        </Warning>
        {/* <LandingHeader>
          <LogoBX> <Link> <img src={Logo} /> </Link> </LogoBX>
          <LaunchBtn className='ani-1'><Link to={'otc'}>Launch APP</Link></LaunchBtn>
        </LandingHeader> */}
        <BeforeAfter onMouseDown={this.removeAnimate}>
          <ReactBeforeSliderComponent
            firstImage={FIRST_IMAGE}
            secondImage={SECOND_IMAGE}
            currentPercentPosition={delimiterPercentPosition}
          />
          <LogoSec>
            <img src={Logo} />
            <LaunchBtn as={Link} to={"otc"} className="ani-1">
              Launch APP
            </LaunchBtn>
          </LogoSec>
        </BeforeAfter>
        <SectionOne>
          <Gs.Container3>
            <SectionOneInner>
              <W50 className="aos-init" data-aos="fade-right">
                <SOTitle>
                  <span>protect</span> your token price from dumping
                </SOTitle>
              </W50>
              <W50 className="border-left aos-init" data-aos="fade-left">
                <SODesc>
                  Dumper Shield is the next generation of smart vesting
                  solutions, offering a revolutionary approach designed to
                  protect against token price dumping. By leveraging AI it
                  seamlessly merges vesting tokens with the existing circulation
                  supply based on REAL-TIME market liquidity and demand, rather
                  than simply following a predetermined schedule like
                  traditional vesting solutions.{" "}
                </SODesc>
                <SOHighlight>
                  Project, join Dumper Shield early and qualify for up to{" "}
                  <span>$100,000</span> in grant rewards
                </SOHighlight>
                <ButtonList2>
                  <Link to={"addprojecttokens"}>
                    <AppBtn className="ani-1">ADD YOUR TOKEN FOR FREE</AppBtn>
                  </Link>
                  {/* <Border/> */}
                  {/* <Link to={'otc'}><AppBtn className='ani-1'>LAUNCH APP</AppBtn></Link> */}
                </ButtonList2>
                {/* <Warning><i class="fas fa-exclamation-triangle"></i> Experimental beta system</Warning> */}
              </W50>
            </SectionOneInner>
            {/* <LaunchBtn className='ani-1 ver2'>ADD TO YOUR PROJECT</LaunchBtn> */}
          </Gs.Container3>
        </SectionOne>

        {/* <Gs.Container2>
          <SectionTwo>
            <ThereBox className='aos-init' data-aos="fade-right">
              <img src={Icon7} alt='' />
              <IconTitle>Flexible Vesting</IconTitle>
              <IconDesc>Introduce a new type of vesting concept that grants early investors access to liquidity during the vesting period.</IconDesc>
            </ThereBox>
            <ThereBox className='aos-init' data-aos="fade-up">
              <img src={Icon2} alt='' />
              <IconTitle>Initial Airdrop Offering</IconTitle>
              <IconDesc>Growing your community through airdrop distribution while maintaining token price stability.</IconDesc>
            </ThereBox>
            <ThereBox className='aos-init' data-aos="fade-left">
              <img src={Icon1} alt='' />
              <IconTitle>Delay Tax</IconTitle>
              <IconDesc>A Dumper Shield may be able to delay the tax trigger on airdrop and reward tokens.<span  data-html='true'  data-tip="When you receive tokens as a reward or through an airdrop, it can potentially result in a taxable event, even if the tokens do not have immediate liquidity. Many tax codes allow for the deferral of tax obligations if the tokens remain under an option position. This means that recipients are unable to access or utilize the tokens without obtaining prior consent from a third party that is not under their control. By meeting these criteria, taxpayers may be able to delay the tax trigger associated with the tokens until a later point in time. <br /><br />
              it's important to note that specific rules and regulations regarding the taxation of tokens can vary between countries, so it is advisable to consult with a tax professional or advisor who is knowledgeable about the tax laws in your jurisdiction for accurate and personalized guidance." className="helpIco"><i className="fas fa-question-circle"></i></span></IconDesc>
            </ThereBox>
            <ThereBox>
              <img src={Icon3} alt='' />
              <IconTitle>Dynamic Tax</IconTitle>
              <IconDesc>Users wishing to sell tokens behind the Dumper Shield are taxed based on demand and total time locked</IconDesc>
            </ThereBox>
            <ThereBox>
              <img src={Icon4} alt='' />
              <IconTitle>Incentivized Swap Providers</IconTitle>
              <IconDesc>CEX traders can become SwapProviders to benefit from Dumper Shield arbitrage opportunities</IconDesc>
            </ThereBox>
            <ThereBox>
              <img src={Icon5} alt='' />
              <IconTitle>Liquidity Gateway</IconTitle>
              <IconDesc>The Gateway releases tokens only when the market can support them including a circuit breaker based on the previous day’s appreciation</IconDesc>
            </ThereBox>
            <ThereBox>
              <img src={Icon6} alt='' />
              <IconTitle>Decentralized OTC</IconTitle>
              <IconDesc>Allow users one-click OTC orders without knowing the counterparty</IconDesc>
            </ThereBox>
          </SectionTwo>
        </Gs.Container2> */}
        {/* Sec 01 */}
        <SectionThree className="ver2 no-border">
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <STTitle>FLEXIBLE VESTING</STTitle>
                <STDesc>
                  Set up for FREE custom vesting with cliffs, streaming or
                  linears.
                </STDesc>
              </STLeft>
              <STRight className="text-right aos-init" data-aos="fade-left">
                <img src={Dummy7} alt="" />
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 02 */}
        <SectionThree>
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <img src={Dummy4} alt="" />
              </STLeft>
              <STRight className="aos-init" data-aos="fade-left">
                <STTitle>AI Gateway to Secondary Market</STTitle>
                <STDesc>
                  The gateway utilizes AI to drive vesting tokens to the
                  secondary market before their vesting period is due in order
                  to reduce future selling pressure.
                </STDesc>
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 03 */}
        <SectionThree className="ver2">
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <STTitle>Decentralized OTC</STTitle>
                <STDesc>
                  Users can anonymously list tokens at a fixed or market-based
                  price without affecting the market value. Buyers can trade for
                  all or part of the listed tokens, and any remaining tokens are
                  automatically relisted for future buyers. Buyers must inherit
                  the same terms as the seller, which creates a new secure vault
                  for the purchased tokens.
                </STDesc>
                <NoteTxt>
                  <i className="fas fa-lightbulb"></i> OTC is the only place to
                  buy discounted tokens below market price without affecting the
                  market price
                </NoteTxt>
              </STLeft>
              <STRight className="text-right aos-init" data-aos="fade-left">
                <img src={Dummy1} alt="" />
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 04 */}
        <SectionThree>
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <img src={Dummy34} alt="" />
              </STLeft>
              <STRight className="aos-init" data-aos="fade-left">
                <STTitle>dOTC FOR VESTED TOKENS</STTitle>
                <STDesc>
                  The Dumper Shield also supports OTC transactions for vested
                  tokens, enabling the anonymous execution of large transactions
                  without impacting the token's price on the secondary market.
                </STDesc>
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 05 */}
        <SectionThree className="ver2">
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <STTitle>flexible period </STTitle>
                <STDesc>
                  Vesting can be set as permanent periods or stay dynamic by
                  allowing token holders, embodying real DAO power, to vote on
                  adjusting the vesting due date in response to market changes
                  and project progress. Based on experience, more than 85% of
                  all holders, especially the larger ones, choose to extend
                  their shield protection to prevent a race to the bottom.
                </STDesc>
              </STLeft>
              <STRight className="text-right aos-init" data-aos="fade-left">
                <img src={Dummy8} alt="" />
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 05 */}
        <SectionThree>
          <Gs.Container2>
            <STInner>
              <STRight className="text-right aos-init" data-aos="fade-right">
                <img src={Dummy9} alt="" />
              </STRight>
              <STLeft className="aos-init" data-aos="fade-left">
                <STTitle>self-custoday vaults </STTitle>
                <STDesc>
                  Holders holding all their tokens in their own vaults,
                  protected with the user's wallet private keys, ensuring
                  self-custody security.
                </STDesc>
              </STLeft>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 06 */}
        <SectionThree className="ver2">
          <Gs.Container>
            <SectionTwo className="var02">
              <Col03Bx className="aos-init" data-aos="fade-up">
                <img src={Icon8} alt="" />
                <h3>0% Security Exposure</h3>
                <p>
                  Our vaults have zero security exposure as they rely on the
                  security of your own original private key wallet, which is
                  never shared with us.
                </p>
              </Col03Bx>
              <Col03Bx className="aos-init" data-aos="fade-up">
                <img src={Icon9} alt="" />
                <h3>CSV upload</h3>
                <p>
                  Transfer vesting tokens between thousands of new vaults
                  quickly.
                </p>
              </Col03Bx>
              <Col03Bx className="aos-init" data-aos="fade-up">
                <img src={Icon10} alt="" />
                <h3>post launch VAULTS</h3>
                <p>
                  Transfer vesting tokens into new vaults even after the initial
                  distribution is over.
                </p>
              </Col03Bx>
            </SectionTwo>
          </Gs.Container>
        </SectionThree>

        {/* Sec 07 */}
        <SectionThree>
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <STTitle>Unlimited Classes and Positions </STTitle>
                <STDesc>
                  The dumper shield supports unlimited different classes. This
                  structure allows projects to design any token distribution
                  based on different vesting schedules and also to prioritize
                  engagement for liquidity via the Gateway. For example,
                  investment terms for each round can be different class, as
                  well as allocations for the team, advisors, reserves, or users
                  earning airdrops.
                </STDesc>
              </STLeft>
              <STRight className="text-right aos-init" data-aos="fade-left">
                <img src={Dummy5} alt="" />
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 08 */}
        <SectionThree className="ver2">
          <Gs.Container2>
            <STInner className="ver2">
              <STLeft className="aos-init justify-center" data-aos="fade-right">
                <img src={Dummy11} alt="" />
              </STLeft>
              <STRight className="aos-init" data-aos="fade-left">
                <STTitle>self-custody trading</STTitle>
                <STDesc>
                  Buyers can store funds in a secure, self-custody virtual
                  wallet (via a smart exchange) and utilize it for unlimited
                  large orders without locking funds or paying gas costs.
                  Accepted orders utilize vault funds, automatically canceling
                  other bids. <br />
                  <br />
                  New buyers who purchase tokens under the Dumper Shield will
                  receive their tokens in a self-custody vault. These tokens
                  will be subject to the same vesting terms as those applied to
                  the originally purchased tokens.
                </STDesc>
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 09 */}
        <SectionThree>
          <Gs.Container2>
            <STInner>
              <STLeft className="aos-init" data-aos="fade-right">
                <STTitle>AIRDROP PAD</STTitle>
                <STDesc>
                  Growing your community through airdrop distribution while
                  maintaining token price stability.
                </STDesc>
                <NoteTxt>
                  <i className="fas fa-lightbulb"></i> A Dumper Shield may be
                  able to delay the tax trigger on airdrop and reward tokens
                </NoteTxt>
              </STLeft>
              <STRight className="text-right aos-init" data-aos="fade-left">
                <img src={Dummy10} alt="" />
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 10 */}
        <SectionThree className="ver2">
          <Gs.Container2>
            <STInner className="ver2">
              <STRight className="text-right aos-init" data-aos="fade-right">
                <img src={Dummy3} alt="" />
              </STRight>
              <STLeft className="aos-init" data-aos="fade-left">
                <STTitle>self-custody farming</STTitle>
                <STDesc>
                  The self-custody vaults are designed with built-in farming
                  without any lock-in period required. Buyers who hold funds in
                  their vaults can be incentivized by the projects and by the
                  Dumper Shield with APY rewards which are paid out block by
                  block.
                </STDesc>
              </STLeft>
            </STInner>
          </Gs.Container2>
        </SectionThree>

        {/* Sec 11 */}
        <SectionThree>
          <Gs.Container>
            <SectionTwo className="var02">
              <Col03Bx className="aos-init" data-aos="fade-up">
                <img alt="Multichain Support" src={Dummy31} />
                <h3>Multichain Support</h3>
                <p>
                  The Dumper Shield provides support for all chains that are EVM
                  compatible
                </p>
              </Col03Bx>
              <Col03Bx className="aos-init" data-aos="fade-up">
                <img alt="DAO Community Based" src={Dummy33} />
                <h3>DAO SUPPORT</h3>
                <p>
                  Add your DAO smart contract to allow tokens behind the dumper
                  shield to participate in any voting
                </p>
              </Col03Bx>
              <Col03Bx className="aos-init" data-aos="fade-up">
                <img alt="self-custody" src={Icon11} />
                <h3>LP OTC</h3>
                <p>
                  Selling liquidity provider tokens via OTC protect liquidity
                  pools and offering sellers and buyers better prices than pool
                  slippage
                </p>
              </Col03Bx>
            </SectionTwo>
          </Gs.Container>
        </SectionThree>

        {/* <SectionThree>
          <Gs.Container2>
            <STInner>
              <STRight className='aos-init' data-aos="fade-right">
                <STTitle>liquidity gateway</STTitle>
                <STDesc>The Liquidity Gateway reduces OTC orders by importing secondary market liquidity while preserving token appreciation. It monitors the previous day's token appreciation to set a dynamic limit on token sales, preventing excessive dumping by more than 50% of yesterday's appreciation.</STDesc>
              </STRight>
              <STLeft className='aos-init' data-aos="fade-left">
                <img src={Dummy4} alt='' />
              </STLeft>
            </STInner>
          </Gs.Container2>
        </SectionThree> */}

        {/* <SectionThree>
          <Gs.Container2>
            <STInner className='ver2'>
              <STLeft className='aos-init' data-aos="fade-right">
                <STTitle>Farming</STTitle>
                <STDesc>The virtual vaults are designed with built-in farming without any lock-in period required. Buyers who hold funds in their vaults can be incentivized by the projects and by the Dumper Shield with APY rewards which are paid out block by block.</STDesc>
              </STLeft>
              <STRight className='text-right aos-init' data-aos="fade-left">
                <img src={Dummy3} alt='' />
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree> */}

        {/* <SectionThree className='ver2'>
          <Gs.Container2>
            <STInner>
              <STLeft className='aos-init' data-aos="fade-right">
                <img src={Dummy6} alt='' />
              </STLeft>
              <STRight className='aos-init' data-aos="fade-left">
                <STTitle>Free to Use for All Projects</STTitle>
                <STDesc>Any project, group, or person may utilize the Dumper Shield technology instantly and free of charge.</STDesc>
              </STRight>
            </STInner>
          </Gs.Container2>
        </SectionThree> */}

        <SectionThree
          className="text-center padd160 aos-init ver2"
          data-aos="fade-up"
        >
          <Gs.Container2>
            <STTitle className="ver2">
              Project, join Dumper Shield early and <br />
              qualify for up to <span>$100,000</span> in grant rewards{" "}
              <span
                data-html="true"
                data-tip="Early adopter projects will be selected for a grant of up to $100,000 in SHIELD tokens. The grant will be distributed block by block on a pro rata basis as an airdrop reward to the project's holders."
                className="helpIco"
                currentitem="false"
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </STTitle>
            <ButtonList>
              <LaunchBtn className="ani-1 ver2">
                <Link to={"addprojecttokens"}>add your TOKEN FOR FREE</Link>
              </LaunchBtn>
              <LaunchBtn className="ani-1 ver2">
                <Link to={"otc"}>LAUNCH APP</Link>
              </LaunchBtn>
            </ButtonList>
          </Gs.Container2>
        </SectionThree>
        <ReactTooltip effect="solid" className="myTip" />

        <FooterBody>
          <FooterLeft>
            <p>Powered by</p>
            <img src={AtomLogo} alt="" />
          </FooterLeft>
          <Warning>
            <i className="fas fa-exclamation-triangle"></i> Experimental, unaudited
            beta system. Use at your own risk
          </Warning>
          <Link to="#" onClick={scrollToTop}>
            <i className="fas fa-caret-square-up"></i> Go to Top
          </Link>
        </FooterBody>
      </>
    );
  }
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const LogoBX = styled.div`
  a {
    display: inline-block;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ${Media.sm} {
    max-width: 210px;
  }
  ${Media.xs} {
    max-width: 180px;
  }
`;

const LandingHeader = styled(FlexDiv)`
  justify-content: space-between;
  padding: 24px 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  ${Media.xs} {
    padding: 20px 15px;
  }
`;

const LaunchBtn = styled.button`
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #00f02b;
  color: #00f02b;
  padding: 14px 43px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00f02b75;
  text-transform: uppercase;
  &:hover {
    background-color: #00f02b;
    color: #fff;
  }
  a {
    color: #00f02b;
  }
  &.ver2 {
    font-size: 24px;
    min-width: 500px;
    min-height: 80px;
    ${Media.md3} {
      min-width: 400px;
      min-height: 80px;
    }
    ${Media.md2} {
      min-width: auto;
      min-height: auto;
    }
    ${Media.xs} {
      font-size: 18px;
      padding: 15px;
    }
  }
  ${Media.sm} {
    padding: 14px 20px 15px;
  }
  ${Media.xs} {
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;
  }
`;

const Warning = styled.div`
  color: #e45244;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin-top: 25px;
  i {
    font-size: 14px;
  }
  &.fixed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #454952;
    text-align: center;
    margin-top: 0;
    padding: 8px 0;
    font-size: 14px;
    z-index: 1;
    i {
      font-size: 16px;
      display: inline-block;
      vertical-align: top;
      margin-top: 1px;
      margin-right: 12px;
    }
  }
  ${Media.md2} {
    /* display: none; */
  }
`;

const SectionOne = styled.div`
  text-align: center;
  padding: 154px 0px 168px;
  background: #000;
  ${Media.md} {
    padding: 150px 0px;
  }
  ${Media.sm} {
    padding: 100px 0px;
  }
  ${Media.xs} {
    padding: 50px 0px 100px;
  }
`;

const SOTitle = styled.div`
  font: 700 60px/72px "Kanit", arial;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
  padding: 0px 15px;
  span {
    color: #00f02b;
  }
  ${Media.lg} {
    font: 700 45px/50px "Kanit", arial;
  }
  ${Media.md} {
    font: 700 35px/40px "Kanit", arial;
    padding: 0px;
    margin-bottom: 30px;
  }
  ${Media.xs} {
    text-align: center;
  }
`;

const SODesc = styled.div`
  font-size: 18px;
  color: #8e9195;
  line-height: 30px;
  text-align: left;
  margin-bottom: 25px;
  ${Media.xs} {
    text-align: center;
    font-size: 18px;
  }
`;

const SectionOneInner = styled(FlexDiv)`
  align-items: center;
  ${Media.md} {
    padding: 0px 20px;
  }
`;

const W50 = styled.div`
  width: 50%;
  &.border-left {
    border-left: 1px solid #fff;
    padding-left: 55px;
    padding-right: 0px;
    ${Media.md3} {
      padding-left: 20px;
    }
    ${Media.md} {
      padding-left: 0px;
      border: none;
    }
  }
  ${Media.md} {
    width: 100%;
  }
`;

const ButtonList2 = styled(FlexDiv)`
  justify-content: flex-start;
  ${Media.xs} {
    justify-content: center;
  }
`;

const AppBtn = styled.button`
  font-size: 24px;
  font-weight: bold;
  color: #00f02b;
  padding: 5px 25px;
  border: 2px solid transparent;
  position: relative;
  border-radius: 12px;
  :hover {
    border: 2px solid #00f02b;
    box-shadow: 0 0 10px #00f02b75;
    background: #00f02b;
    color: #fff;
  }
  :after {
    content: "";
    position: absolute;
    right: -18px;
    top: 10px;
    width: 1px;
    height: 25px;
    background-color: #2e3138;
    ${Media.xs} {
      display: none;
    }
  }
  :last-child {
    margin-left: 0;
    :after {
      display: none;
    }
    ${Media.xs} {
      margin-left: 0px;
    }
  }
  ${Media.sm} {
    font-size: 20px;
  }
  ${Media.xs} {
    font-size: 18px;
    width: 100%;
    padding: 10px 25px;
    margin: 5px 0px;
  }
`;

const SectionTwo = styled(FlexDiv)`
  align-items: flex-start;
  margin-bottom: 50px;
  ${Media.xs} {
    margin-bottom: 0px;
  }
  &.var02 {
    gap: 65px;
    flex-flow: row;
    margin-bottom: 0;
  }

  ${Media.xl} {
    &.var02 {
      gap: 40px;
    }
  }
  ${Media.md} {
    &.var02 {
      flex-flow: column;
    }
  }
`;

const ThereBox = styled.div`
  img {
    width: 96px;
    margin-bottom: 20px;
  }
  text-align: center;
  width: 33.33%;
  padding: 0px 12px;
  margin-bottom: 50px;
  ${Media.md} {
    width: 50%;
  }
  ${Media.xs} {
    width: 100%;
    padding: 0px 15px;
  }
`;

const IconTitle = styled.div`
  font: 700 30px/34px "Kanit", arial;
  margin: 0px 0px 20px;
`;

const IconDesc = styled.div`
  font-size: 16px;
  color: #8e9195;
  line-height: 26px;
`;

const SectionThree = styled.div`
  background-color: #000000;
  padding: 100px 0px;
  border-top: 1px solid #1e2127;
  &.ver2 {
    background-color: #0b0c0f;
  }
  &.text-center {
    text-align: center;
  }
  &.no-border {
    border-top: 0;
  }
  &.padd160 {
    padding: 160px 0px;
    ${Media.md} {
      padding: 100px 0px;
    }
    ${Media.xs} {
      padding: 50px 0px;
    }
  }
  ${Media.xl} {
    ${Gs.Container} {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
  ${Media.sm} {
    ${Gs.Container} {
      padding: 0 30px;
    }
    padding: 70px 0px;
  }
  ${Media.xs} {
    padding: 50px 0px;
  }
`;

const STInner = styled(FlexDiv)`
  justify-content: space-between;
  ${Media.md} {
    flex-direction: column-reverse;
  }
  &.ver2 {
    ${Media.md} {
      flex-direction: column;
    }
  }
`;

const STLeft = styled.div`
  width: 50%;
  padding: 0px 20px;
  ${Media.md} {
    width: 100%;
    text-align: center;
    padding: 0px 15px;
  }
  &.justify-center {
    text-align: center;
  }
  img {
    ${Media.md} {
      margin: 50px auto;
    }
    ${Media.xs} {
      width: 80%;
    }
  }
`;

const STRight = styled.div`
  width: 50%;
  padding: 0px 20px;
  &.text-right {
    text-align: center;
  }
  ${Media.md} {
    width: 100%;
    text-align: center;
    padding: 0px 15px;
  }
  img {
    ${Media.md} {
      margin: 50px auto;
    }
    ${Media.xs} {
      width: 80%;
    }
  }
`;

const STTitle = styled.div`
  font: 700 48px/58px "Kanit", arial;
  margin: 0px 0px 30px;
  text-transform: uppercase;
  span {
    color: #00f02b;
  }
  span.helpIco {
    color: #fff;
    top: -30px;
  }
  &.ver2 {
    margin: 0px 15px 50px;
  }
  ${Media.md} {
    font: 700 40px/50px "Kanit", arial;
  }
  ${Media.xs} {
    font: 700 30px/40px "Kanit", arial;
  }
`;

const STDesc = styled.div`
  font-size: 18px;
  line-height: 30px;
  color: #8e9195;
  a {
    color: #00f02b;
    text-decoration: underline;
  }
`;

const ButtonList = styled(FlexDiv)`
  button {
    margin: 0px 15px 22px;
  }
`;
const Col3 = styled(FlexDiv)`
  width: 33.33%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  p {
    font-size: 18px;
    color: #8e9195;
    line-height: 30px;
  }
  h3 {
    font-size: 36px;
    text-transform: uppercase;
    font-family: "Kanit", arial;
    margin: 15px 0 20px;
  }
  img {
    height: 281px;
    max-width: 342px;
    object-fit: contain;
  }
  ${Media.md} {
    padding: 0 20px;
    h3 {
      font-size: 26px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  ${Media.sm} {
    width: 100%;
    margin-bottom: 30px;
    h3 {
      margin-top: 0;
    }
  }
`;
const FooterBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  height: 100px;
  margin: 0;
  border-top: 1px solid #1e2127;
  width: 100%;
  padding: 0 30px;
  a {
    color: #00f02b;
    font-size: 14px;
    font-weight: 400;
    :hover {
      text-decoration: underline;
    }
  }
  ${Warning} {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
  }
  ${Media.md} {
    justify-content: space-between;
    height: 80px;
    padding: 0px 20px 20px;
    flex-flow: column;
    height: auto;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0px 15px 0px 0px;
    font-size: 12px;
    font-weight: bold;
    color: #9a9a9a;
    text-align: left;
    ${Media.sm} {
      margin: 0px 10px 0px 0px;
    }
  }
  span {
    width: 1px;
    height: 27px;
    background-color: #1e2127;
    margin: 0px 18px;
    ${Media.sm} {
      margin: 0px 10px;
    }
  }
  ${Media.md} {
    /* display:none; */
  }
  ${Media.sm} {
    padding: 20px 0px;
  }
`;
const Border = styled.div`
  border: 1px solid #1e2127;
  height: 30px;
  margin: 0 18px;
`;
const Col03Bx = styled.div`
  width: 33.33%;
  flex-shrink: 0;
  text-align: center;
  h3 {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Kanit", arial;
    margin: 16px 0 0 0;
  }
  p {
    font-size: 18px;
    color: #8e9195;
    line-height: 30px;
  }
  img {
    height: 274px;
    width: 352px;
    object-fit: contain;
  }
  ${Media.md} {
    width: 100%;
  }
`;
const NoteTxt = styled.div`
  font-size: 11px;
  color: #00f02b;
  margin-top: 43px;
`;
const SOHighlight = styled.div`
  font-weight: 700;
  font-size: 22px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 30px;
  span {
    color: #00f02b;
  }
  ${Media.xs} {
    width: 100%;
    text-align: center;
  }
`;
const LogoSec = styled.div`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  ${LaunchBtn} {
    position: relative;
    text-shadow: 0 0 5px #00f02b75;
    a {
      color: #00f02b;
    }
    /* &:after {content: ""; position: absolute; left: 0; right: 0; bottom: 0; top: 0; z-index: 1; } */
  }
  ${Media.xl} {
    bottom: 20px;
  }
  ${Media.lg} {
    transform: scale(0.8);
    transform-origin: center bottom;
    bottom: 20px;
  }
  ${Media.md} {
    position: relative;
    bottom: 0;
    margin-top: 20px;
    transform: scale(1);
  }
`;
const BeforeAfter = styled.div`
  margin: -45px 0 0 0;
  position: relative;
  background: #000;
  .before-after-slider__delimiter-icon {
    background: url(${arrowBA}) no-repeat;
    background-size: 100% 100%;
    width: 60px;
    height: 60px;
    background-color: inherit !important;
  }
  .before-after-slider__delimiter {
    width: 1px;
  }
  img {
    vertical-align: top;
  }
`;

export default FundAndIncentive;

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { ACTIVE_CHAINS, CHAINS, CHAIN_ID, addChainConfigs } from "../constants";
import notificationConfig from "../config/notificationConfig";

export const metamask_connection = async (account, target) => {
  let web3;

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };
  const provider = await detectEthereumProvider();
  if (provider) {
    if ((account === "" || account == null) && target === "UseEffect") {
      return;
    } else {
      if (typeof window.web3 !== "undefined") {
        web3 = new Web3(window.web3.currentProvider);
      } else if (isMobile()) {
        window.location.href =
          "https://metamask.app.link/dapp/dumpershield.exchange";
      } else {
        web3 = new Web3.providers.HttpProvider("http://localhost:3000");
      }
      window.web3 = new Web3(window.ethereum);
      const chainId = await provider.request({ method: "eth_chainId" });
      // if (ACTIVE_CHAINS.includes(chainId)) {
      try {
        await window.web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          alert("add this chain id");
        }
      }
      // }
      await window.ethereum.enable().then((account) => {
        // if (ACTIVE_CHAINS.includes(chainId)) {
        // console.log("here i am from if--------------------------------------------")
        localStorage.setItem("account", account[0]);
        localStorage.setItem("chainId", chainId);
        // } else {
        //   // console.log("here i am from else--------------------------------------------")
        //   localStorage.removeItem('account');
        //   return;
        // }
      });
      window.ethereum.on("chainChanged", (chainId) => {
        localStorage.setItem("account", localStorage.getItem("account"));
        localStorage.setItem("chainId", chainId);
        // window.location.reload();
      });
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts === "") {
          localStorage.removeItem("account");
        } else {
          localStorage.setItem("account", accounts[0]);
        }
        window.location.reload();
      });

      window.ethereum.on("disconnect", async (error) => {});
    }
  } else {
    if (isMobile()) {
      window.location.href =
        "https://metamask.app.link/dapp/dumpershield.exchange";
    } else {
      alert("Please install MetaMask first.");
    }
  }
};

export const getAccountaddress = async () => {
  return localStorage.getItem("account");
};

export const getChainId = async () => {
  return localStorage.getItem("chainId");
};

export const changeNetwork = async (chainId) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    } catch (error) {
      console.error(error);
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [addChainConfigs[chainId]],
          });
          notificationConfig.success("Chain added successfully!");
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};

export const checkMetasmask = async () => {
  let web3;
  if (window.web3 && window.web3.currentProvider) {
    web3 = new Web3(window.web3.currentProvider);
    web3.eth.getAccounts(function (err, accounts) {
      if (accounts.length === 0) {
        localStorage.removeItem("account");
      }
    });
  }
};

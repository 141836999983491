import axios from "axios";
import Web3 from "web3";
import {
  BASE_API_HOST,
  CHAIN_ID,
  contractAddresses,
  contractAddressesChainWise,
  rpcLink,
} from "../constants";
import { getAccountaddress } from "./metamask_function";
import BigNumber from "bignumber.js";
import { EvmChain } from "@moralisweb3/common-evm-utils";
const Moralis = require("moralis").default;

const DumperShieldFactory = require("../Abi/DumperShieldFactory.json");
const MigrationVault = require("../Abi/MigrationVault.json");
const Degen = require("../Abi/Degen.json");
const DynamicPair = require("../Abi/DynamicPair.json");
const ERC20BEP20TOKEN_CONTRACT_ABI = require("../Abi/erc20bep20Token.json");
const ROUTER_CONTRACT_ABI = require("../Abi/router.json");
const FACTORY_CONTRACT_ABI = require("../Abi/factory.json");
const PAIR_CONTRACT_ABI = require("../Abi/pair.json");
const Pool_ABI = require("../Abi/Pool.json");
const Pair_ABI = require("../Abi/PairContract.json");

export const getEtherValue = (weiValue) =>
  Web3.utils.fromWei(weiValue, "ether");

export const getBalance = async (userAddress, chainId) => {
  let web3;
  web3 = new Web3(rpcLink[chainId]);
  try {
    let balance = await web3.eth.getBalance(userAddress);
    return balance;
  } catch (e) {
    console.log(e);
    return "0";
  }
};

export const getBalanceRPC = async (userAddress, chainId) => {
  try {
    await axios
      .post(rpcLink[chainId], {
        jsonrpc: "2.0",
        method: "eth_getBalance",
        params: [userAddress, "latest"],
        id: userAddress,
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.result);
        // console.log(Web3.utils.hexToString(res.data.result))
        console.log(Web3.utils.fromWei(res.data.result));
        return Web3.utils.fromWei(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
    // return balances;
  } catch (e) {
    console.log(e);
    return [0, 0, 0];
  }
};

export const getTokenBalance = async (balanceOfTokensData) => {
  let web3, contractInstance;
  web3 = new Web3(rpcLink[balanceOfTokensData.chainId]);
  contractInstance = new web3.eth.Contract(
    ERC20BEP20TOKEN_CONTRACT_ABI,
    balanceOfTokensData.token
  );

  try {
    let balance = await contractInstance.methods
      .balanceOf(balanceOfTokensData.user)
      .call();
    return balance;
  } catch (e) {
    console.log(e);
    return "0";
  }
};

export const getFactoryDetails = async (ammRouter, token) => {
  let web3;
  web3 = new Web3(window.web3.currentProvider);

  try {
    let data = {};
    const ROUTER_INSTANCE = await new web3.eth.Contract(
      ROUTER_CONTRACT_ABI,
      ammRouter
    );
    // console.log(await ROUTER_INSTANCE.methods.factory().call())
    const FACTORY_INSTANCE = await new web3.eth.Contract(
      FACTORY_CONTRACT_ABI,
      await ROUTER_INSTANCE.methods.factory().call()
    );
    // console.log(await ROUTER_INSTANCE.methods.WETH().call())
    // console.log(await FACTORY_INSTANCE.methods.getPair(await ROUTER_INSTANCE.methods.WETH().call(), token).call())
    data["ammFactory"] = (
      await ROUTER_INSTANCE.methods.factory().call()
    ).toLowerCase();
    data["ammWETH"] = (
      await ROUTER_INSTANCE.methods.WETH().call()
    ).toLowerCase();
    data["pairAddress"] = (
      await FACTORY_INSTANCE.methods
        .getPair(await ROUTER_INSTANCE.methods.WETH().call(), token)
        .call()
    ).toLowerCase();
    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getTokenDetails = async (tokenDetailsData) => {
  console.log(tokenDetailsData);
  let web3, contractInstance;
  web3 = new Web3(rpcLink[tokenDetailsData.chainId]);
  contractInstance = new web3.eth.Contract(
    ERC20BEP20TOKEN_CONTRACT_ABI,
    tokenDetailsData.token
  );

  try {
    let balance = await contractInstance.methods
      .balanceOf(tokenDetailsData.user)
      .call();
    let name = await contractInstance.methods.name().call();
    let symbol = await contractInstance.methods.symbol().call();
    let decimals = await contractInstance.methods.decimals().call();
    return {
      balance: balance,
      name: name,
      symbol: symbol,
      decimals: decimals,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getTokenAllowance = async (allowanceOfTokenData) => {
  // console.log(allowanceOfTokenData)
  let web3, contractInstance;
  web3 = new Web3(rpcLink[allowanceOfTokenData.chainId]);
  contractInstance = new web3.eth.Contract(
    ERC20BEP20TOKEN_CONTRACT_ABI,
    allowanceOfTokenData.token
  );

  try {
    let balance = await contractInstance.methods
      .allowance(allowanceOfTokenData.owner, allowanceOfTokenData.spender)
      .call();
    return balance;
  } catch (e) {
    console.log(e);
    return "0";
  }
};

export const getBalanceOfTokens = async (balanceOfTokensData) => {
  let address =
    contractAddressesChainWise[Web3.utils.toHex(balanceOfTokensData.chainId)]
      .DUMPERSHIELD_FACTORY_ADDRESS;

  // console.log(address)
  // let web3,contractInstance;
  let web3, contractInstance;
  web3 = new Web3(rpcLink[balanceOfTokensData.chainId]);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));
  // console.log(">>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<");
  // console.log(balanceOfTokensData)
  try {
    let balances = await contractInstance.methods
      .balanceOf(balanceOfTokensData.token, balanceOfTokensData.user)
      .call();
    // console.log("><><><><><><><<><");
    // console.log(balanceOfTokensData.token);
    // console.log("><><><><><><><<><");
    // console.log(balances)
    return balances;
  } catch (e) {
    console.log(e);
    return [0, 0, 0];
  }
};

export const getBalanceOfTokensBatch = async (balanceOfTokensData) => {
  try {
    console.log(rpcLink[56]);
    await axios
      .post(rpcLink[56], {
        jsonrpc: "2.0",
        method: "eth_call",
        params: [
          {
            to: balanceOfTokensData.token,
            data:
              "0x70a08231000000000000000000000000" +
              balanceOfTokensData.user.split("0x")[1],
          },
          "latest",
        ],
        id: balanceOfTokensData.token,
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.result);
        // console.log(Web3.utils.hexToString(res.data.result))
        console.log(Web3.utils.fromWei(res.data.result));
      })
      .catch((err) => {
        console.log("error", err);
      });
    // return balances;
  } catch (e) {
    console.log(e);
    return [0, 0, 0];
  }
};

export const getClaimDetails = async (claimData) => {
  try {
    return await axios
      .post(BASE_API_HOST + "/otcRoute/getClaimDetails", claimData)
      .then((res) => {
        // console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        return err;
      });
    // return balances;
  } catch (e) {
    console.log(e);
  }
};

export const getSignatureDetails = async (signatureData) => {
  try {
    return await axios
      .post(BASE_API_HOST + "/otcRoute/getSignatureDetails", signatureData)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        return err;
      });
    // return balances;
  } catch (e) {
    console.log(e);
  }
};

export const addDEX = async (dexData) => {
  try {
    await axios
      .post(BASE_API_HOST + "/addDEX", dexData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
    // return balances;
  } catch (e) {
    console.log(e);
  }
};

export const addPairAddress = async (pairData) => {
  try {
    await axios
      .post(BASE_API_HOST + "/addPairAddress", pairData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
    // return balances;
  } catch (e) {
    console.log(e);
  }
};

export const getVestedTokens = async (tokenAddress) => {
  let chainId = localStorage.getItem("chainId");
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;

  let web3, contractInstance;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  try {
    let unlockDate = await contractInstance.methods
      .dsUnlockDate(tokenAddress)
      .call();
    return Number(unlockDate + "000");
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const checkTokenListed = async (tokenAddress, chainId) => {
  let address =
    contractAddressesChainWise[Web3.utils.toHex(chainId)]
      .DUMPERSHIELD_FACTORY_ADDRESS;

  let web3, contractInstance;
  web3 = new Web3(rpcLink[chainId]);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  try {
    let unlockDate = await contractInstance.methods
      .dumperShieldTokens(tokenAddress)
      .call();
    return unlockDate;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const fetchCurrencyPrices = async () => {
  console.log(
    "--------------------------------currency-price-fetch-------------------------------------"
  );
  let tableDataLocalcoingecko = [];
  var currencyPrices = {};
  // try {
  await axios
    .get(
      `https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cbinancecoin%2Cmatic-network%2Ctether%2Cusd-coin%2Cdai&vs_currencies=USD&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then(async (res) => {
      tableDataLocalcoingecko = res.data;
      currencyPrices["ETH"] = tableDataLocalcoingecko["ethereum"]["usd"];
      currencyPrices[1] = currencyPrices["ETH"];

      currencyPrices["BNB"] = tableDataLocalcoingecko["binancecoin"]["usd"];
      currencyPrices[56] = currencyPrices["BNB"];

      currencyPrices["MATIC"] = tableDataLocalcoingecko["matic-network"]["usd"];
      currencyPrices[137] = currencyPrices["MATIC"];

      currencyPrices["BASE"] = tableDataLocalcoingecko["ethereum"]["usd"];
      currencyPrices[8453] = currencyPrices["BASE"];

      // currencyPrices["CLO"] = tableDataLocalcoingecko["callisto"]["usd"];
      // currencyPrices[820] = currencyPrices["CLO"];

      currencyPrices["USDT"] = tableDataLocalcoingecko["tether"]["usd"];
      currencyPrices["USDC"] = tableDataLocalcoingecko["usd-coin"]["usd"];
      currencyPrices["DAI"] = tableDataLocalcoingecko["dai"]["usd"];
      currencyPrices["BUSDT"] = tableDataLocalcoingecko["tether"]["usd"];

      let degenPriceBNB = await getDegenPrice();
      currencyPrices["DEGEN"] =
        Number(currencyPrices.BNB) * Number(degenPriceBNB);
      currencyPrices["SMART"] = 0;
    })
    .catch(async (err) => {
      // await Moralis.EvmChain.token.getTokenPrice({address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", chain: "bsc"})
      console.log("error", err);
      if (!Moralis.Core.isStarted) {
        await Moralis.start({
          apiKey:
            "CNkBmyT59hIHHLVPVKc2n4fbcvUwAR3iylv0YlDMKOjZLcN295ib9CpDjvhqNbw3",
        });
      }
      let ethPrice = (
        await Moralis.EvmApi.token.getTokenPrice({
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          chain: EvmChain.ETHEREUM,
        })
      ).toJSON();
      let bnbPrice = (
        await Moralis.EvmApi.token.getTokenPrice({
          address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
          chain: EvmChain.BSC,
        })
      ).toJSON();
      let maticPrice = (
        await Moralis.EvmApi.token.getTokenPrice({
          address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          chain: EvmChain.POLYGON,
        })
      ).toJSON();

      console.log(ethPrice.usdPrice, bnbPrice.usdPrice);
      currencyPrices["ETH"] = ethPrice.usdPrice;
      currencyPrices[1] = currencyPrices["ETH"];

      currencyPrices["BNB"] = bnbPrice.usdPrice;
      currencyPrices[56] = currencyPrices["BNB"];

      currencyPrices["MATIC"] = maticPrice.usdPrice;
      currencyPrices[137] = currencyPrices["MATIC"];

      currencyPrices["BASE"] = ethPrice.usdPrice;
      currencyPrices[8453] = currencyPrices["BASE"];

      currencyPrices["CLO"] = 0;
      currencyPrices[820] = currencyPrices["CLO"];

      let degenPriceBNB = await getDegenPrice();
      currencyPrices["DEGEN"] =
        Number(currencyPrices.BNB) * Number(degenPriceBNB);
      currencyPrices["SMART"] = 0;
    });
  // } catch(ex) {
  //     response = null;
  //     // error
  //     console.log(ex);
  //     reject(ex);
  // }

  return currencyPrices;
};

export const getDegenPrice = async () => {
  let address = contractAddresses.DYNAMIC_PAIR_ADDRESS;

  console.log(address);
  // let web3,contractInstance;
  let web3, contractInstance;
  web3 = new Web3(new Web3.providers.HttpProvider(rpcLink["56"]));
  contractInstance = new web3.eth.Contract(DynamicPair, address);
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));
  console.log(">>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<");

  try {
    let balances = await contractInstance.methods.getReserves().call();
    let degenPriceBNB = Number(balances._reserve1) / Number(balances._reserve0);
    return degenPriceBNB;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

function setDecimals(number, decimals) {
  number = number.toString();
  let numberAbs = number.split(".")[0];
  let numberDecimals = number.split(".")[1] ? number.split(".")[1] : "";
  while (numberDecimals.length < decimals) {
    numberDecimals += "0";
  }
  return numberAbs + numberDecimals;
}

export const getTokenPrice = async (tokenDetails) => {
  // console.log("--------------------------------token-price-fetch-------------------------------------")
  let web3;
  web3 = new Web3(
    new Web3.providers.HttpProvider(rpcLink[tokenDetails.chainId])
  );
  let price = 0;
  try {
    let pairContract = await new web3.eth.Contract(
      Pair_ABI,
      tokenDetails.pairContract
    );
    let reserves = await pairContract.methods.getReserves().call();
    if (tokenDetails.WETH > tokenDetails.token) {
      price = BigNumber(reserves._reserve1)
        .div(BigNumber(reserves._reserve0))
        .toString();
    } else {
      price = BigNumber(reserves._reserve0)
        .div(BigNumber(reserves._reserve1))
        .toString();
    }
  } catch (error) {
    // console.log(error)
    return 0;
  }

  return price;
};

export const getTokenPriceUsingPairAddress = async (tokenDetails) => {
  // console.log(
  //   "--------------------------------token-price-fetch-------------------------------------"
  // );
  let web3;
  web3 = new Web3(
    new Web3.providers.HttpProvider(rpcLink[tokenDetails.chainId])
  );
  let price = 0;
  try {
    let pairContract = await new web3.eth.Contract(
      Pair_ABI,
      tokenDetails.pairContract
    );
    let reserves = await pairContract.methods.getReserves().call();
    let token0 = await pairContract.methods.token0().call();
    let token1 = await pairContract.methods.token1().call();
    if (
      token0.toLowerCase() === tokenDetails.token
        ? token1 > token0
        : token0 > token1
    ) {
      price = BigNumber(reserves._reserve1)
        .div(BigNumber(reserves._reserve0))
        .toNumber();
    } else {
      price = BigNumber(reserves._reserve0)
        .div(BigNumber(reserves._reserve1))
        .toNumber();
    }
  } catch (error) {
    // console.error(error);
    return 0;
  }

  return price;
};

export const createDumperShield = async (
  createDumperShieldData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("createDumperShieldData: ", createDumperShieldData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  // let web3,contractInstance,new_value,response,CurrentgasPrice;
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });
  // const ss = gasPrice != '0' ? gasPrice * Math.pow(10, 9) : CurrentgasPrice;

  let payloadData = contractInstance.methods
    .createDumperShield(
      createDumperShieldData.token,
      // createDumperShieldData.router,
      createDumperShieldData.unlockDate,
      createDumperShieldData.dao
    )
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      // await result.wait().then(async (receipt) => {
      receiptCb(result);
      // });
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const otcTransferToken = async (
  otcTransferTokenData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("justin otcTransferTokenData: ", otcTransferTokenData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  // let web3,contractInstance,new_value,response,CurrentgasPrice;
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));

  let transferType = otcTransferTokenData.transferType;

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });
  console.log(CurrentgasPrice);
  // const ss = gasPrice != '0' ? gasPrice * Math.pow(10, 9) : CurrentgasPrice;
  let estimateGas;
  let payloadData = "";
  if (transferType === "transferAsSale") {
    payloadData = contractInstance.methods
      .sellToken(
        otcTransferTokenData.buyer,
        otcTransferTokenData.tokenSell,
        otcTransferTokenData.sellAmount,
        otcTransferTokenData.wantToken,
        otcTransferTokenData.router,
        otcTransferTokenData.value,
        // otcTransferTokenData.confirmatory,
        otcTransferTokenData.position
      )
      .encodeABI();
  } else if (otcTransferTokenData.receiverSinMul) {
    payloadData = contractInstance.methods
      .transferBatch(
        otcTransferTokenData.token,
        otcTransferTokenData.position,
        otcTransferTokenData.addresses,
        otcTransferTokenData.amounts
      )
      .encodeABI();
    // estimateGas = 0x1026B9;
    estimateGas = await contractInstance.methods
      .transferBatch(
        otcTransferTokenData.token,
        otcTransferTokenData.position,
        otcTransferTokenData.addresses,
        otcTransferTokenData.amounts
      )
      .estimateGas(
        {
          from: userAddress,
          gasPrice: CurrentgasPrice,
        },
        function (error, estimatedGas) {
          console.log(error);
          console.log(estimatedGas);
        }
      );
    console.log(estimateGas);
  } else {
    payloadData = contractInstance.methods
      .transfer(
        otcTransferTokenData.token,
        otcTransferTokenData.position,
        otcTransferTokenData.to,
        otcTransferTokenData.sellAmount
      )
      .encodeABI();
  }

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: otcTransferTokenData.receiverSinMul ? estimateGas : 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .on("transactionHash", async function (hash) {
      await txCb(hash);
    })
    .on("receipt", async function (receipt) {
      await receiptCb(receipt);
    })
    .on("error", async function (error) {
      console.log(error);
      await errorCb(error);
    });
  // return response;
};

export const approveTokenTransfer = async (
  approveData,
  txCb,
  receiptCb,
  errorCb
) => {
  const AccountAddress = await getAccountaddress();
  // let web3,contractInstance;
  let web3, contractInstance, CurrentgasPrice;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(
    ERC20BEP20TOKEN_CONTRACT_ABI,
    approveData.token
  );
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));

  console.log("AccountAddress", AccountAddress);
  // try {
  //     return (await contractInstance.methods.approve(approveData.spender, approveData.amount).send({ from: AccountAddress }))
  // } catch (e) {
  //     console.log(e)
  // }

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .approve(approveData.spender, approveData.amount)
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: AccountAddress,
      to: approveData.token,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      // await result.wait().then(async (receipt) => {
      receiptCb(result);
      // });
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const otcBuyToken = async (
  otcBuyTokenData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("otcBuyTokenData: ", otcBuyTokenData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .buyToken(
      otcBuyTokenData.wantToken,
      otcBuyTokenData.wantTokenAmount,
      otcBuyTokenData.position
    )
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
      value: otcBuyTokenData.payableAmount,
    })
    .on("transactionHash", async function (hash) {
      await txCb(hash);
    })
    .on("receipt", async function (receipt) {
      await receiptCb(receipt);
    })
    .on("error", async function (error) {
      console.log(error);
      await errorCb(error);
    });
  // .then(async (result) => {
  //     console.log(result)

  // })
  // .catch(error => {
  //     console.log(error);
  //     errorCb(error);
  // });
};

export const otcBuyOrder = async (
  otcBuyOrderData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("otcBuyOrderData: ", otcBuyOrderData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .buyOrder(otcBuyOrderData.orderId, otcBuyOrderData.paymentAmount)
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
      value: otcBuyOrderData.payableAmount,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      receiptCb(result);
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const otcCancelOrder = async (
  otcCancelOrderData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("otcCancelOrderData: ", otcCancelOrderData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .cancelOrder(otcCancelOrderData.orderId)
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      receiptCb(result);
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const otcWithdrawTokens = async (
  otcWithdrawTokensData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("otcWithdrawTokensData: ", otcWithdrawTokensData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .withdrawToken(otcWithdrawTokensData.token, otcWithdrawTokensData.amount)
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      receiptCb(result);
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const otcDepositTokens = async (
  otcDepositTokensData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("otcDepositTokensData: ", otcDepositTokensData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .depositPosition(
      otcDepositTokensData.token,
      otcDepositTokensData.amount,
      otcDepositTokensData.user,
      otcDepositTokensData.position
    )
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      receiptCb(result);
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const otcDepositPositionTokens = async (
  otcDepositPositionTokensData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("otcDepositPositionTokensData: ", otcDepositPositionTokensData);
  let address =
    contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
  console.log(address);
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(DumperShieldFactory, address);

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });

  let payloadData = contractInstance.methods
    .depositPosition(
      otcDepositPositionTokensData.token,
      otcDepositPositionTokensData.amount,
      otcDepositPositionTokensData.user,
      otcDepositPositionTokensData.position
    )
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      receiptCb(result);
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

export const vaultTransfer = async (
  vaultTransferData,
  txCb,
  receiptCb,
  errorCb
) => {
  let chainId = localStorage.getItem("chainId");
  console.log("vaultTransferData: ", vaultTransferData);
  let address = contractAddressesChainWise[chainId].MIGRATION_VAULT_ADDRESS;
  console.log(address);
  // let web3,contractInstance,new_value,response,CurrentgasPrice;
  let web3, contractInstance, CurrentgasPrice, gasPrice, response;
  web3 = new Web3(window.web3.currentProvider);
  contractInstance = new web3.eth.Contract(MigrationVault, address);
  // new_value = web3.utils.toBN(parseInt((value)*Math.pow(10,18)));

  let userAddress = localStorage.getItem("account");

  await web3.eth.getGasPrice().then((res) => {
    CurrentgasPrice = res;
  });
  // const ss = gasPrice != '0' ? gasPrice * Math.pow(10, 9) : CurrentgasPrice;

  let payloadData = contractInstance.methods
    .vaultTransfer(
      vaultTransferData.claimToken, // token to transfer
      vaultTransferData.user, // transfer to address
      vaultTransferData.position, // DumperShield position
      vaultTransferData.remainingClaimAmount, // amount of tokens to transfer
      vaultTransferData.signature
    )
    .encodeABI();

  await web3.eth
    .sendTransaction({
      from: userAddress,
      to: address,
      gas: 0x94ed0,
      gasPrice: CurrentgasPrice,
      data: payloadData,
    })
    .then(async (result) => {
      console.log(result);
      await txCb(result.hash);
      // await result.wait().then(async (receipt) => {
      receiptCb(result);
      // });
    })
    .catch((error) => {
      console.log(error);
      errorCb(error);
    });
};

import React, { Component } from 'react';
import ReactLoaderSpinner from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import notificationConfig from '../config/notificationConfig';
import { explorerLink } from '../constants';
import { otcCancelOrder } from '../Service/otc_function';

class OrderRaw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: props.order,
            cancelLoading: false,
            tokenList: props.tokenList
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            order: nextProps.order,
            tokenList: nextProps.tokenList
        })
    }

    async handleCancelOrder(orderId) {

        this.setState({ cancelLoading: true })

        let otcCancelOrderData = {};

        otcCancelOrderData["orderId"] = orderId;

        await otcCancelOrder(otcCancelOrderData,
            (hash) => {
                this.setState({
                    // swapLoading: true,
                    // txIdSent: hash,
                    // txLinkSend: data[networkId].explorer + '/tx/' + hash,
                });
            },
            async (receipt) => {
                console.log("receipt")
                console.log(receipt)
                this.setState({ cancelLoading: false })
                notificationConfig.success('Order cancelled Successfully!');
            },
            async (error) => {
                this.setState({ cancelLoading: false })
                if (error.code === 4001) {
                    notificationConfig.error("Transaction rejected!")
                }
            }
        );

    }

    render() {
        const ele = this.state.order;

        return (
            <tr align="left" valign="middle">
                <td><a target="_blank" href={explorerLink[ele.chainId] + "/tx/" + ele.transactionHash}>{this.props.tokenList[ele.chainId][ele.tokenSell].symbol}</a></td>
                <td>{ele.vestingDate}</td>
                <td>{Number(ele.sellAmount) / 10 ** 18}</td>
                <td className="bor01">${((Number(ele.sellAmount) / 10 ** 18) * (this.state.tokenList[ele.chainId][ele.tokenSell].price)).toFixed(4)}</td>
                <td>{this.props.tokenList[ele.chainId][ele.wantToken].symbol}</td>
                <td>{Number(ele.value) / 10 ** 18}</td>
                <td>${((Number(ele.value) / 10 ** 18) * (this.state.tokenList[ele.chainId][ele.wantToken].price)).toFixed(4)}</td>
                <td>${(((Number(ele.sellAmount) / 10 ** 18) * (this.state.tokenList[ele.chainId][ele.tokenSell].price)) - ((Number(ele.value) / 10 ** 18) * (this.state.tokenList[ele.chainId][ele.wantToken].price))).toFixed(2)} | {((((Number(ele.sellAmount) / 10 ** 18) * (this.state.tokenList[ele.chainId][ele.tokenSell].price)) * 100 / ((Number(ele.value) / 10 ** 18) * (this.state.tokenList[ele.chainId][ele.wantToken].price))) - 100).toFixed(2)}%</td>
                {/* <td>
    <div className="OpfyBTNbar v2">
        <button className="btn01" onClick={() => { this.setState({ popupMyofferClaim: true }) }}>CLAIM</button>

    </div>
</td> */}
                <td align='center' scope="col">
                    <div className="OpfyBTNbar">

                        <button className="btn02" onClick={() => this.handleCancelOrder(ele.orderId)}>
                            {this.state.cancelLoading ?
                                <ReactLoaderSpinner type="ThreeDots" color="#fff" height={15} width={80} />
                                :
                                <i className="fas fa-times"></i>}
                        </button>


                    </div>
                </td>
            </tr>

        );
    }


}



export default OrderRaw;


import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from '../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import { Link, Redirect } from 'react-router-dom';
import Logo from './../Assets/images/dumperShield-Logo.png';
import AtomLogo from '../Assets/images/atom-logo.png';
import PDOLogo from '../Assets/images/pdo-logo.png';
import { metamask_connection } from '../Service/metamask_function';
import notificationConfig from '../config/notificationConfig';
import Media from '../Theme/media-breackpoint';
import { CHAINS, CHAIN_ID } from '../constants';

class ConnectWallet extends Component {
  constructor(props) {
    super(props);
    this.connectWallet = this.connectWallet.bind(this);
    this.state = {
      index: 1,
      dropDown: false,
      popup01: false,
      redirect: false,
    };
  }

  componentDidMount() {
    // this.props.changeHead(true)
  }

  componentWillUnmount() {
    // this.props.changeHead(false)
  }

  async connectWallet() {
    await metamask_connection(localStorage.getItem('account'), 'ahrefClick');
    // let chainId = localStorage.getItem("chainId");
    // if (chainId === CHAIN_ID) {
    //     this.setState({ redirect: true })
    // } else {
    //     if (chainId === CHAINS.BSCTESTNET)
    //         notificationConfig.error("Please Select BSC TestNet")
    //     else if (chainId === CHAINS.BSCMAINNET)
    //         notificationConfig.error("Please Select BSC MainNet")
    // }
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/otc' />;
    }
  };

  render() {
    return (
      <>
        {this.renderRedirect()}
        {/* <MainContainer> 

                    <LogoBX> <Link> <img src={Logo} /> </Link> </LogoBX>

                    <FooterMBX>
                        <Link to="" ><img src={FLogo01}  alt="" /></Link>
                            <span className="Liner"></span>
                        <Link to="" ><img src={FLogo02} alt="" /> </Link>  
                        <FooterLeft>
                            <p>Powered by</p>
                            <img src={AtomLogo} alt='' />
                            <span></span>
                            <img src={PDOLogo} alt='' />
                        </FooterLeft>
                    </FooterMBX>

                </MainContainer>  */}
        <FormBTNBX style={{ marginTop: '12%' }}>
          <button onClick={() => this.connectWallet()}>
            Connect Your Wallet
          </button>
          <p className=''>
            <span>
              <Link to={'addprojecttokens'} style={{ color: 'white' }}>
                Add your project to the Dumper Shield for free{' '}
              </Link>
            </span>
          </p>
        </FormBTNBX>

        <ReactTooltip effect='solid' className='myTip' />
      </>
    );
  }
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const MainContainer = styled(FlexDiv)`
  width: 100%;
  min-height: calc(100vh - 80px);
  position: relative;
`;

const LogoBX = styled.div`
  position: absolute;
  left: 30px;
  top: -48px;
  a {
    display: inline-block;
  }
  img {
    max-width: 100%;
    height: auto;
    ${Media.xs} {
      width: 175px;
    }
  }
  ${Media.xs} {
    left: 20px;
    right: 0;
  }
`;

const FormBTNBX = styled(FlexDiv)`
  max-width: 664px;
  margin: 0 auto 50px auto;
  ${Media.md} {
    margin: 0 15px 50px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    ${Media.xs} {
      font-size: 14px;
    }
  }
  button {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    height: 70px;
    background-color: #383b42;
    color: #00f02b;
    border-radius: 10px;
    :hover {
      background-color: #282b32;
    }
    ${Media.md} {
      font-size: 24px;
    }
  }

  .hoverClass :hover span {
    display: none;
  }
  .hoverClass :hover : before {
    content: 'Coming Soon';
  }
`;

const FooterMBX = styled(FlexDiv)`
  position: absolute;
  left: 30px;
  width: auto;
  bottom: 30px;
  .Liner {
    width: 1px;
    height: 27px;
    display: inline-block;
    background-color: #1e2127;
    margin: 0 15px;
  }
  a:hover {
    filter: brightness(1.4);
  }
  ${Media.xs} {
    left: 0;
    right: 0;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0px 15px 0px 0px;
    font-size: 12px;
    font-weight: bold;
    color: #9a9a9a;
    text-align: left;
    ${Media.sm} {
      margin: 0px 10px 0px 0px;
    }
  }
  span {
    width: 1px;
    height: 27px;
    background-color: #1e2127;
    margin: 0px 18px;
    ${Media.sm} {
      margin: 0px 10px;
    }
  }
  ${Media.sm} {
    padding: 20px 0px;
  }
`;

export default ConnectWallet;

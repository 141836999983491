/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import Collapse from "@kunukn/react-collapse";
import styled from "styled-components";
import Media from "../Theme/media-breackpoint";
import Icon1 from "../Assets/images/swap.png";
import Icon2 from "../Assets/images/push.png";
import new_token from "./../Assets/images/new_token.png";
import CoinGecko from "./../Assets/images/CoinGecko.png";
import ReactLoaderSpinner from "react-loader-spinner";
import { ACTIVE_CHAINS, explorerLink, nativeTokenList } from "../constants";
import { otcCancelOrder } from "../Service/otc_function";
import notificationConfig from "../config/notificationConfig";
import Web3 from "web3";
import { toUpper } from "lodash";
import ReactTooltip from "react-tooltip";
import PriceSupplyLineChart from "./charts/priceSupplyLineChart";
import AskBidBarChart from "./charts/askBidBarChart";
import { changeNetwork } from "../Service/metamask_function";
import { GlobalContext } from "../Context/globalContext";
import bnbIcon from "../Assets/images/chains/bnb.png";
import etherIcon from "../Assets/images/chains/ether.png";
import polygonIcon from "../Assets/images/chains/polygon.png";
import baseIcon from "../Assets/images/chains/base.png";
import arbitrumIcon from "../Assets/images/chains/arbitrum.png";
import cosmosIcon from "../Assets/images/chains/cosmos.png";
import { BsArrowDownCircle } from "react-icons/bs";
import { IoMdArrowDropup, IoMdClose } from "react-icons/io";
import buySellViewImg from "../Assets/images/tableview-1.png";
import onlySellViewImg from "../Assets/images/tableview-2.png";
import onlyBuyViewImg from "../Assets/images/tableview-3.png";
import { formatPrice } from "../utils/utils";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Decimal from "decimal.js-light";
import { FaLightbulb } from "react-icons/fa6";

function NewOtcExchange(props) {
  const [ToggleState, setToggleState] = useState(4);
  const [chainSelected, setChainSelected] = useState("56");
  const [defaultPair, setDefaultPair] = useState("DEGEN/BNB");
  const [showFilter, setShowFilter] = useState(false);
  const [account, setAccount] = useState(null);
  const [pairs, setPairs] = useState(null);
  const [currencyPrices, setCurrencyPrices] = useState({});
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [pairsLoading, setPairsLoading] = useState(false);
  const [otcOrders, setOtcOrders] = useState([]);
  const [historyPurchases, setHistoryPurchases] = useState([]);
  const [historySelling, setHistorySelling] = useState([]);
  const [offersByMe, setOffersByMe] = useState([]);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [otcTradersData, setOtcTradersData] = useState([]);
  const [showBid, setShowBid] = useState(false);
  const [sales, setSales] = useState(false);
  const [globalChainId, setGlogalChainId] = useState(null);
  const [tokenList, setTokenList] = useState(null);
  const [all, setAll] = useState(true);
  const [searchToken, setSearchToken] = useState(null);
  const [listView, setListView] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [position, setPosition] = useState(null);
  const [buySellViewType, setBuySellViewType] = useState(1);
  const [totalOtcAllocation, setTotalOtcAllocation] = useState(null);
  const globalContext = useContext(GlobalContext);
  const [filteredOtcOrders, setFilterdOtcOrders] = useState(null);
  const [filterInput, setFilterInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      tokenSymbol: "",
      askingToken: "",
      minSupplyAvailable: "",
      maxSupplyAvailable: "",
      minAskingAmount: "",
      maxAskingAmount: "",
      minAskingPrice: "",
      maxAskingPrice: "",
      liquidityPositionsArr: [],
      vestingDatte: "",
      minDiscount: "",
      maxDiscount: "",
      verified: false,
    }
  );
  const [sortByBidPriceToken, setSortByBidPriceToken] = useState(false);
  const [sortByBidAllocation, setSortByBidAllocation] = useState(false);
  const [sortByAskingPriceToken, setSortByAskingPriceToken] = useState(false);
  const [sortByAskingAllocation, setSortByAskingAllocation] = useState(false);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setGlogalChainId(props.chainId);
  }, [props.chainId]);

  useEffect(() => {
    setOtcOrders(props.otcOrders);
    if (props.otcOrders[chainSelected]) {
      setFilterdOtcOrders(props.otcOrders[chainSelected]);
    }
  }, [props.otcOrders]);

  useEffect(() => {
    setOffersByMe(props.offersByMe);
  }, [props.offersByMe]);

  useEffect(() => {
    setHistoryPurchases(props.historyPurchases);
  }, [props.historyPurchases]);

  useEffect(() => {
    setHistorySelling(props.historySelling);
  }, [props.historySelling]);

  useEffect(() => {
    setTotalOtcAllocation(props.totalOtcAllocation);
  }, [props.totalOtcAllocation]);

  useEffect(() => {
    setOrdersLoading(props.ordersLoading);
    setPairsLoading(props.ordersLoading);
  }, [props.ordersLoading]);

  useEffect(() => {
    setCurrencyPrices(props.currencyPrices);
  }, [props.currencyPrices]);

  useEffect(() => {
    setPairs(props.pairs);
  }, [props.pairs]);

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  useEffect(() => {
    setOtcTradersData(props.otcTradersData);
  }, [props.otcTradersData]);

  useEffect(() => {
    if (globalContext.tokenList !== null) {
      let list = [];
      Object.entries(globalContext.tokenList).map((pairWiseList, key) => {
        return Object.entries(pairWiseList[1]).map((ele, keyin) => {
          if (ele[1].dumperShieldToken) list.push(ele[1]);
        });
      });
      setTokenList(list);
    }
  }, [globalContext.tokenList]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to update window width state
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Event listener to update window width on resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setOpen] = React.useState(true);

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  function splitString(str) {
    const delimiter = /(\$| to | )/; // Matches '$' or ' to '
    const parts = str
      .split(delimiter)
      .filter(
        (part) => part !== "$" && part !== " to " && part !== "" && part !== " "
      ); // Split the string on the delimiter and filter out delimiters

    return parts;
  }

  function getVal(val) {
    if (isNaN(val)) {
      let multiplier = val.substr(-1).toLowerCase();
      if (multiplier == "k") return parseFloat(val) * 1000;
      else if (multiplier == "m") return parseFloat(val) * 1000000;
    } else {
      return Number(val);
    }
  }

  const handleCancelOrder = async (order) => {
    if (Number(order.chainId) !== Number(Web3.utils.toDecimal(globalChainId))) {
      await changeNetwork(Web3.utils.toHex(order.chainId));
      return 0;
    }
    setCancelLoading(true);

    let otcCancelOrderData = {};

    otcCancelOrderData["orderId"] = order.orderId;

    await otcCancelOrder(
      otcCancelOrderData,
      (hash) => {},
      async (receipt) => {
        setCancelLoading(false);
        notificationConfig.success("Order cancelled Successfully!");
      },
      async (error) => {
        setCancelLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handlePairClick = (e) => {
    const { innerText } = e.target;
    const name = e.target.attributes.name.nodeValue;
    setFilterInput({ [name]: innerText });
  };

  const handleFilters = (event) => {
    const { name, value } = event.target;
    if (name === "1st" || name === "2nd" || name === "3rd") {
      if (event.target.checked) {
        setFilterInput({
          liquidityPositionsArr: [...filterInput.liquidityPositionsArr, name],
        });
      } else {
        const tempArr = filterInput.liquidityPositionsArr.filter(
          (data) => data !== name
        );
        setFilterInput({ liquidityPositionsArr: tempArr });
      }
    } else {
      setFilterInput({ [name]: value });
    }
  };

  const handleSearchToken = (event) => {
    setSearchToken(event.target.value);
  };

  const setValuesInFilter = (name, minValue, maxValue) => {
    if (name === "supplyAvailable") {
      setFilterInput({
        minSupplyAvailable: minValue,
        maxSupplyAvailable: maxValue,
      });
    } else if (name === "askingAmount") {
      setFilterInput({
        minAskingAmount: minValue,
        maxAskingAmount: maxValue,
      });
    } else {
      setFilterInput({
        minAskingPrice: minValue,
        maxAskingPrice: maxValue,
      });
    }
  };

  const handleNumericalValueSelectFilters = (e) => {
    const { innerText } = e.target;
    const name = e.target.attributes.name.nodeValue;
    const values = splitString(innerText);

    if (values[0].includes("Above")) {
      const minValue = getVal(values[1]);
      const maxValue = Infinity;
      setValuesInFilter(name, minValue, maxValue);
    } else {
      const minValue = getVal(values[0]);
      const maxValue = getVal(values[1]);
      setValuesInFilter(name, minValue, maxValue);
    }
  };

  const handleTransferTokens = (e) => {};

  const filterOtcOrders = (orders) => {
    if (orders) {
      let arr = orders.filter((obj) => {
        return (
          obj.pair.split("/")[0].includes(toUpper(filterInput.tokenSymbol)) &&
          obj.pair.split("/")[1].includes(toUpper(filterInput.askingToken)) &&
          (filterInput.minSupplyAvailable
            ? obj.supplyAvailable >= filterInput.minSupplyAvailable
            : true) &&
          (filterInput.maxSupplyAvailable
            ? obj.supplyAvailable <= filterInput.maxSupplyAvailable
            : true) &&
          (filterInput.minAskingPrice
            ? obj.askingAmountUSD >= filterInput.minAskingPrice
            : true) &&
          (filterInput.maxAskingPrice
            ? obj.askingAmountUSD <= filterInput.maxAskingPrice
            : true) &&
          (filterInput.minAskingAmount
            ? Number(obj.askingAmount.split(" ")[0]) >=
              filterInput.minAskingAmount
            : true) &&
          (filterInput.maxAskingAmount
            ? Number(obj.askingAmount.split(" ")[0]) <=
              filterInput.maxAskingAmount
            : true) &&
          (filterInput.liquidityPositionsArr.length
            ? filterInput.liquidityPositionsArr.includes(obj.liquidityPosition)
            : true) &&
          (filterInput.minDiscount
            ? obj.competitiveValuePercentage >= filterInput.minDiscount
            : true) &&
          (filterInput.maxDiscount
            ? obj.competitiveValuePercentage <= filterInput.maxDiscount
            : true)
        );
      });
      return arr;
    }
  };

  const onToggle = (dropDownNew) =>
    setDropDown(dropDown === dropDownNew ? null : dropDownNew);

  const chainColors = [
    "#454a75, #1a1f49",
    "#f3ba2f, #a37400",
    "#8146e4, #390295",
    "#002689, #0d44d5",
    "#070909, #28a0f0",
    "#454a75, #1a1f49",
  ];

  const chainImages = {
    1: etherIcon,
    56: bnbIcon,
    137: polygonIcon,
    8453: baseIcon,
  };

  const [showPairList, setShowPairList] = useState(false);
  const [averageSell, setAverageSell] = useState(0);
  const [averageSellCount, setAverageSellCount] = useState(0);
  const [averageSellUSD, setAverageSellUSD] = useState(0);
  const [averageBuy, setAverageBuy] = useState(0);
  const [averageBuyUSD, setAverageBuyUSD] = useState(0);
  const [averageBuyCount, setAverageBuyCount] = useState(0);
  const [buyPercent, setBuyPercent] = useState(50);
  const [sellPercent, setSellPercent] = useState(50);

  const handleApplyFilter = () => {
    const otcOrders = props.otcOrders[chainSelected];
    const orders = filterOtcOrders(otcOrders);
    setFilterdOtcOrders(orders);
    orders.length > 0 && setDefaultPair(orders[0].pair);
  };

  useEffect(() => {
    if (filteredOtcOrders && filteredOtcOrders.length > 0) {
      const tradingSellPair = filteredOtcOrders.filter(
        (ele) =>
          ele.pair ===
          defaultPair.split("/")[1] + "/" + defaultPair.split("/")[0]
      );
      const tradingSellPairCount = tradingSellPair.length;
      let totalSellOtcPrice = 0;
      let totalSellOtcPriceUSD = 0;
      tradingSellPair.map((ele) => {
        totalSellOtcPrice += Number(ele.otcPrice);
        totalSellOtcPriceUSD += Number(ele.otcPriceUSD);
      });
      setAverageSellCount(tradingSellPairCount);
      setAverageSell(
        new Decimal(totalSellOtcPrice)
          .dividedBy(tradingSellPairCount)
          .toString()
      );
      setAverageSellUSD(
        new Decimal(totalSellOtcPriceUSD)
          .dividedBy(tradingSellPairCount)
          .toString()
      );

      const tradingBuyPair = filteredOtcOrders.filter(
        (ele) => ele.pair === defaultPair
      );

      const tradingBuyPairCount = tradingBuyPair.length;
      let totalBuyOtcPrice = 0;
      let totalBuyOtcPriceUSD = 0;
      tradingBuyPair.map((ele) => {
        totalBuyOtcPrice += Number(ele.otcPrice);
        totalBuyOtcPriceUSD += Number(ele.otcPriceUSD);
      });
      setAverageBuyCount(tradingBuyPairCount);
      setAverageBuy(
        new Decimal(totalBuyOtcPrice).dividedBy(tradingBuyPairCount).toString()
      );
      setAverageBuyUSD(
        new Decimal(totalBuyOtcPriceUSD)
          .dividedBy(tradingBuyPairCount)
          .toString()
      );

      const buyPercent = new Decimal(tradingBuyPairCount)
        .dividedBy(tradingBuyPairCount + tradingSellPairCount)
        .times(100)
        .toNumber();
      const sellPercent = new Decimal(tradingSellPairCount)
        .dividedBy(tradingBuyPairCount + tradingSellPairCount)
        .times(100)
        .toNumber();
      setBuyPercent(buyPercent.toFixed(1));
      setSellPercent(sellPercent.toFixed(1));
    }
  }, [defaultPair, filteredOtcOrders]);

  const handleResetFilter = () => {
    setFilterInput({
      tokenSymbol: "",
      askingToken: "",
      minSupplyAvailable: "",
      maxSupplyAvailable: "",
      minAskingAmount: "",
      maxAskingAmount: "",
      minAskingPrice: "",
      maxAskingPrice: "",
      liquidityPositionsArr: [],
      vestingDatte: "",
      minDiscount: "",
      maxDiscount: "",
      verified: false,
    });
  };

  const dropdownRef = useRef();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowPairList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [lastBuyOrder, setLastBuyOrder] = useState(null);
  const [lastSellOrder, setLastSellOrder] = useState(null);

  useEffect(() => {
    if (filteredOtcOrders) {
      const allBuyOrders = filteredOtcOrders.filter(
        (ele) => ele.pair === defaultPair
      );
      if (allBuyOrders.length > 0) {
        setLastBuyOrder(allBuyOrders.pop());
      } else {
        setLastBuyOrder(0);
      }

      const allSellOrders = filteredOtcOrders.filter(
        (ele) =>
          ele.pair ===
          defaultPair.split("/")[1] + "/" + defaultPair.split("/")[0]
      );
      if (allSellOrders.length > 0) {
        setLastSellOrder(allSellOrders.pop());
      } else {
        setLastSellOrder(null);
      }
    }
  }, [filteredOtcOrders, defaultPair]);

  return (
    <FlexDiv>
      <DSTitle02>
        <DSTitleNumber>1</DSTitleNumber>
        Trading Chain
      </DSTitle02>
      <ChainListContainer>
        <Carousel
          additionalTransfrom={0}
          arrows
          centerMode={false}
          style={{ width: "100%", display: "flex" }}
          containerClass="container"
          focusOnSelect={false}
          keyBoardControl
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop1: {
              breakpoint: {
                max: 4500,
                min: 0,
              },
              items: new Decimal(windowWidth)
                .dividedBy(360)
                .toNumber()
                .toFixed(1),
            },
          }}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {ACTIVE_CHAINS.map((ele, index) => {
            let chainId = Web3.utils.toDecimal(ele);
            return (
              <ChainContainer key={index}>
                <ChainButton color={chainColors[index]}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={chainImages[chainId]}
                      alt="chain"
                      style={{ marginRight: "15px" }}
                    />
                    <span>{nativeTokenList[chainId].networkName}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "30px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#fff",
                    }}
                  >
                    ${currencyPrices[chainId]}
                  </div>
                </ChainButton>
                <ChainSelectButton
                  className="chain-select-button"
                  onClick={() => {
                    setChainSelected(chainId);
                    setShowBid(false);
                    setAll(true);
                    setPosition(null);
                    setDefaultPair(
                      pairs[chainId] ? Object.entries(pairs[chainId])[0][0] : ""
                    );
                  }}
                >
                  {chainId === Number(chainSelected)
                    ? "Selected"
                    : "Select Chain"}
                </ChainSelectButton>
              </ChainContainer>
            );
          })}
        </Carousel>
      </ChainListContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <DSTitle02>
          <DSTitleNumber>2</DSTitleNumber>
          Trading Pair
        </DSTitle02>
        <span style={{ display: "flex", width: "200px", color: "#9a9a9a" }}>
          Pair tokens{" "}
          <span style={{ color: "#00f02b", marginLeft: "15px" }}>
            {pairs !== null && otcTradersData[chainSelected]
              ? otcTradersData[chainSelected].length
              : 0}
          </span>
        </span>
      </div>
      {ordersLoading ? (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <ReactLoaderSpinner
            type="Bars"
            color="#fff"
            height={50}
            width={150}
          />
        </div>
      ) : (
        <PairContainer>
          <PairButtonContainer>
            <GradientButton
              className={"pair-button"}
              onClick={() => setShowPairList(true)}
            >
              <PairButton>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 15px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected].find(
                                (el) => el.pairNames === defaultPair
                              ).tokenLogo !== ""
                              ? otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                ).tokenLogo
                              : new_token
                            : ""
                          : ""
                      }
                      style={{ width: "40px", height: "40px" }}
                    />
                    -
                    <img
                      src={
                        otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected]
                                .find((el) => el.pairNames === defaultPair)
                                .pairNames.split("/")[1] ===
                              nativeTokenList[chainSelected].symbol
                              ? nativeTokenList[chainSelected].logoURI
                              : new_token
                            : new_token
                          : new_token
                      }
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    ></img>
                    {defaultPair.split("/")[0] +
                      " > " +
                      defaultPair.split("/")[1]}
                  </div>
                  <BsArrowDownCircle
                    style={{
                      color: "#00f02b",
                      width: "30px",
                      height: "30px",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </PairButton>
            </GradientButton>
            {showPairList === true && (
              <GradientButton
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: "9999",
                  boxShadow: "0px 0px 10px 1px #4fff00",
                }}
              >
                <PairButton
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <PairListContainer>
                    <div
                      onClick={() => setShowPairList(false)}
                      style={{
                        display: "flex",
                        minWidth: "300px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: showPairList ? "5px 10px" : "5px 15px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            otcTradersData
                              ? otcTradersData[chainSelected]
                                ? otcTradersData[chainSelected].find(
                                    (el) => el.pairNames === defaultPair
                                  ).tokenLogo !== ""
                                  ? otcTradersData[chainSelected].find(
                                      (el) => el.pairNames === defaultPair
                                    ).tokenLogo
                                  : new_token
                                : new_token
                              : new_token
                          }
                          style={{ width: "40px", height: "40px" }}
                        />
                        -
                        <img
                          src={
                            otcTradersData
                              ? otcTradersData[chainSelected]
                                ? otcTradersData[chainSelected]
                                    .find((el) => el.pairNames === defaultPair)
                                    .pairNames.split("/")[1] ===
                                  nativeTokenList[chainSelected].symbol
                                  ? nativeTokenList[chainSelected].logoURI
                                  : new_token
                                : new_token
                              : new_token
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                          }}
                        ></img>
                        {defaultPair.split("/")[0] +
                          " > " +
                          defaultPair.split("/")[1]}
                      </div>
                      <BsArrowDownCircle
                        style={{
                          color: "#00f02b",
                          width: "30px",
                          height: "30px",
                          rotate: "180deg",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        margin: "5px 0",
                        backgroundColor: "#191c22",
                        padding: "10px 15px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        style={{
                          outline: "none",
                          color: "#9a9a9a",
                          background: "transparent",
                          border: "none",
                        }}
                        placeholder="Search token"
                        value={searchToken ?? ""}
                        onChange={(e) => handleSearchToken(e)}
                      ></input>
                      <FilterIconButton
                        style={{
                          color: showFilter === true ? "#00f02b" : "#9099090",
                        }}
                        onClick={() => setShowFilter(!showFilter)}
                      >
                        <i className="fas fa-filter"></i>
                      </FilterIconButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        padding: "5px 15px",
                      }}
                    >
                      {pairs !== null && otcTradersData[chainSelected]
                        ? otcTradersData[chainSelected]
                            .filter(
                              (ele) =>
                                ele.pairNames.split("/")[0] !==
                                  nativeTokenList[chainSelected].symbol &&
                                tokenList.find((e) =>
                                  e.symbol
                                    .toLowerCase()
                                    .includes(ele.pairNames.split("/")[0])
                                ) !== null
                            )
                            .filter(
                              (ele) =>
                                searchToken === null ||
                                (searchToken !== null &&
                                  ele.pairNames
                                    .toLowerCase()
                                    .includes(searchToken))
                            )
                            .map((ele, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "5px 0",
                                  }}
                                  key={index}
                                  onClick={() => setDefaultPair(ele.pairNames)}
                                >
                                  <img
                                    src={
                                      ele.tokenLogo !== ""
                                        ? ele.tokenLogo
                                        : new_token
                                    }
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                  -
                                  <img
                                    src={
                                      ele.pairNames.split("/")[1] ===
                                      nativeTokenList[chainSelected].symbol
                                        ? nativeTokenList[chainSelected].logoURI
                                        : new_token
                                    }
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      marginRight: "10px",
                                    }}
                                  ></img>
                                  <PairTitle>
                                    {ele.pairNames.split("/")[0] +
                                      " > " +
                                      ele.pairNames.split("/")[1]}
                                  </PairTitle>
                                </div>
                              );
                            })
                        : null}
                    </div>
                  </PairListContainer>
                  {showFilter && (
                    <FilterContainer>
                      <FilterTitle>
                        <span>Filter</span>
                        <IoMdClose
                          style={{
                            color: "#00f02b",
                            width: "30px",
                            height: "30px",
                          }}
                          onClick={() => setShowFilter(false)}
                        />
                      </FilterTitle>
                      <FilterMain>
                        <FilterBox>
                          <h4>Token Symbol</h4>
                          <div className="searchBox">
                            <i className="fas fa-search"></i>
                            <input
                              className="search"
                              type="text"
                              placeholder="Search"
                              name="tokenSymbol"
                              value={filterInput.tokenSymbol}
                              onChange={handleFilters}
                            />
                          </div>
                          <ul>
                            <li>
                              <SpanButton
                                onClick={handlePairClick}
                                name="tokenSymbol"
                              >
                                JNTR
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                onClick={handlePairClick}
                                name="tokenSymbol"
                              >
                                SMART
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                onClick={handlePairClick}
                                name="tokenSymbol"
                              >
                                PDO
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                onClick={handlePairClick}
                                name="tokenSymbol"
                              >
                                FREEZ
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                onClick={handlePairClick}
                                name="tokenSymbol"
                              >
                                DEGEN
                              </SpanButton>
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox>
                          <h4>Asking token</h4>
                          <div className="searchBox">
                            <i className="fas fa-search"></i>
                            <input
                              className="search"
                              type="text"
                              placeholder="Search"
                              name="askingToken"
                              value={filterInput.askingToken}
                              onChange={handleFilters}
                            />
                          </div>
                          <ul>
                            <li>
                              <SpanButton
                                name="askingToken"
                                onClick={handlePairClick}
                              >
                                ETH
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingToken"
                                onClick={handlePairClick}
                              >
                                BNB
                              </SpanButton>
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox>
                          <h4>Supply Available</h4>
                          <MaxMin>
                            <input
                              className="textBox"
                              type="number"
                              placeholder="Min"
                              name="minSupplyAvailable"
                              value={filterInput.minSupplyAvailable}
                              onChange={handleFilters}
                            ></input>
                            <span>To</span>
                            <input
                              className="textBox"
                              type="number"
                              placeholder="Max"
                              name="maxSupplyAvailable"
                              value={filterInput.maxSupplyAvailable}
                              onChange={handleFilters}
                            ></input>
                          </MaxMin>
                          <ul>
                            <li>
                              <SpanButton
                                name="supplyAvailable"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                0 to 100
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="supplyAvailable"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                100 to 1k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="supplyAvailable"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                1k to 10k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="supplyAvailable"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                10k to 100k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="supplyAvailable"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                Above 100k
                              </SpanButton>
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox>
                          <h4>Asking Amount</h4>
                          <MaxMin>
                            <input
                              className="textBox"
                              type="number"
                              placeholder="Min"
                              name="minAskingAmount"
                              value={filterInput.minAskingAmount}
                              onChange={handleFilters}
                            ></input>
                            <span>To</span>
                            <input
                              className="textBox"
                              type="number"
                              placeholder="Max"
                              name="maxAskingAmount"
                              value={filterInput.maxAskingAmount}
                              onChange={handleFilters}
                            ></input>
                          </MaxMin>
                          <ul>
                            <li>
                              <SpanButton
                                name="askingAmount"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                0 to 100
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingAmount"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                100 to 1k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingAmount"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                1k to 10k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingAmount"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                10k to 100k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingAmount"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                Above 100k
                              </SpanButton>
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox>
                          <h4>Asking Price</h4>
                          <MaxMin>
                            <input
                              className="textBox"
                              type="number"
                              placeholder="Min"
                              name="minAskingPrice"
                              onChange={handleFilters}
                              value={filterInput.minAskingPrice}
                            ></input>
                            <span>To</span>
                            <input
                              className="textBox"
                              type="number"
                              placeholder="Max"
                              name="maxAskingPrice"
                              value={filterInput.maxAskingPrice}
                              onChange={handleFilters}
                            ></input>
                          </MaxMin>
                          <ul>
                            <li>
                              <SpanButton
                                name="askingPrice"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                $0 to $100
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingPrice"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                $100 to $1k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingPrice"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                $1k to $10k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingPrice"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                $10k to $100k
                              </SpanButton>
                            </li>
                            <li>
                              <SpanButton
                                name="askingPrice"
                                onClick={handleNumericalValueSelectFilters}
                              >
                                Above $100k
                              </SpanButton>
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox>
                          <h4>Liquidity positions </h4>
                          <ul className="checkList">
                            <li>
                              <div className="md-checkbox md-checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="checkbox01"
                                  name="1st"
                                  onClick={handleFilters}
                                />
                                <label htmlFor="checkbox01">1st position</label>
                              </div>
                            </li>
                            <li>
                              <div className="md-checkbox md-checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="checkbox02"
                                  name="2nd"
                                  onClick={handleFilters}
                                />
                                <label htmlFor="checkbox02">2nd position</label>
                              </div>
                            </li>
                            <li>
                              <div className="md-checkbox md-checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="checkbox03"
                                  name="3rd"
                                  onClick={handleFilters}
                                />
                                <label htmlFor="checkbox03">3rd position</label>
                              </div>{" "}
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox className="discount">
                          <h4>Vesting Due Date</h4>
                          <Discount>
                            <div className="hasBox">
                              <input
                                className="textBox"
                                placeholder="Max"
                                type="number"
                                value={filterInput.vestingDate}
                                onChange={handleFilters}
                                name="vestingDate"
                              />
                              <strong className="xl">DAYS</strong>
                            </div>
                          </Discount>
                        </FilterBox>
                        <FilterBox className="discount">
                          <h4>Discount</h4>
                          <Discount>
                            <div className="hasBox">
                              <input
                                className="textBox"
                                placeholder="Min"
                                type="number"
                                value={filterInput.minDiscount}
                                onChange={handleFilters}
                                name="minDiscount"
                              />
                              <strong>%</strong>
                            </div>
                            <span>To</span>
                            <div className="hasBox">
                              <input
                                className="textBox"
                                placeholder="Max"
                                type="number"
                                onChange={handleFilters}
                                name="maxDiscount"
                                value={filterInput.maxDiscount}
                              />
                              <strong>%</strong>
                            </div>
                          </Discount>
                        </FilterBox>
                        <FilterBox>
                          <h4>Verified</h4>
                          <ul className="checkList">
                            <li>
                              <div className="md-checkbox md-checkbox-inline">
                                <input
                                  type="checkbox"
                                  id="checkbox04"
                                  name="verified"
                                />
                                <label htmlFor="checkbox04">CoinGecko</label>
                              </div>
                            </li>
                          </ul>
                        </FilterBox>
                        <FilterBox style={{ width: "100%" }}>
                          <ul>
                            <li>
                              <FilterButton
                                buttonType={"apply"}
                                onClick={() => handleApplyFilter()}
                              >
                                Apply
                              </FilterButton>
                            </li>
                            <li>
                              <FilterButton
                                buttonType={"reset"}
                                onClick={() => handleResetFilter()}
                              >
                                Reset
                              </FilterButton>
                            </li>
                          </ul>
                        </FilterBox>
                      </FilterMain>
                    </FilterContainer>
                  )}
                </PairButton>
              </GradientButton>
            )}
          </PairButtonContainer>
          <PairDetailContainer>
            <AverageDetail>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="main-value">
                  {pairs !== null
                    ? formatPrice(
                        // otcTradersData
                        //   ? otcTradersData[chainSelected]
                        //     ? otcTradersData[chainSelected].find(
                        //         (el) => el.pairNames === defaultPair,
                        //       )
                        //       ? Number(
                        //           otcTradersData[chainSelected].find(
                        //             (el) => el.pairNames === defaultPair,
                        //           ).median,
                        //         )
                        //       : 0
                        //     : 0
                        //   : 0,
                        averageSell
                      )
                    : "0.0000"}{" "}
                  {defaultPair.split("/")[1]}
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    margin: "5px 0px",
                  }}
                >
                  [$ {formatPrice(averageSellUSD)}
                  {/* {formatPrice(
                    otcTradersData
                      ? otcTradersData[chainSelected]
                        ? otcTradersData[chainSelected].find(
                            (el) => el.pairNames === defaultPair,
                          )
                          ? Number(
                              otcTradersData[chainSelected].find(
                                (el) => el.pairNames === defaultPair,
                              ).medianPriceAllUSD,
                            )
                          : 0
                        : 0
                      : 0,
                  )} */}
                  ]
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Average Sell
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="main-value">
                  {pairs !== null
                    ? formatPrice(
                        // otcTradersData
                        //   ? otcTradersData[chainSelected]
                        //     ? otcTradersData[chainSelected].find(
                        //         (el) => el.pairNames === defaultPair,
                        //       )
                        //       ? Number(
                        //           otcTradersData[chainSelected].find(
                        //             (el) => el.pairNames === defaultPair,
                        //           ).median,
                        //         )
                        //       : 0
                        //     : 0
                        //   : 0,
                        averageBuy
                      )
                    : "0.0000"}{" "}
                  {defaultPair.split("/")[1]}
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    margin: "5px 0px",
                  }}
                >
                  [$
                  {formatPrice(
                    // otcTradersData
                    //   ? otcTradersData[chainSelected]
                    //     ? otcTradersData[chainSelected].find(
                    //         (el) => el.pairNames === defaultPair,
                    //       )
                    //       ? Number(
                    //           otcTradersData[chainSelected].find(
                    //             (el) => el.pairNames === defaultPair,
                    //           ).medianPriceAllUSD,
                    //         )
                    //       : 0
                    //     : 0
                    //   : 0,
                    averageBuyUSD
                  )}
                  ]
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Average Buy
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="main-value">
                  15,000 {defaultPair.split("/")[1]}
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    margin: "5px 0px",
                  }}
                >
                  [$100,202]
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  24H Trading Volume
                </span>
              </div>
            </AverageDetail>
            <LastDetail>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#00f02b",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  {lastBuyOrder !== null
                    ? "$" + formatPrice(lastBuyOrder.otcPriceUSD)
                    : "$0"}
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Last Buy Order
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#00f02b",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  {lastSellOrder !== null
                    ? "$" + formatPrice(lastSellOrder.otcPriceUSD)
                    : "$0"}
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Last Sell Order
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#00f02b",
                    fontWeight: "600",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  +5.25%{" "}
                  <IoMdArrowDropup
                    style={{ marginLeft: "5px", width: "25px", height: "25px" }}
                  />
                </span>
                <span
                  style={{
                    color: "#9a9a9a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  24h Change
                </span>
              </div>
            </LastDetail>
          </PairDetailContainer>
        </PairContainer>
      )}
      <Tabs style={{ marginTop: "40px", width: "100%" }}>
        <button
          className={sales ? "active" : ""}
          onClick={() => setSales(true)}
        >
          Sales
        </button>
        <span>|</span>
        <button
          className={!sales ? "active" : ""}
          onClick={() => setSales(false)}
        >
          Depth
        </button>
        <span>|</span>
        <button>Order Book</button>
        <span>|</span>
        <button>Token Release Schedule</button>
        {!ordersLoading && (
          <>
            <ProgressMain>
              {sellPercent > 0 && (
                <Progress process={sellPercent}>
                  <span style={{ color: "#fff" }}>{sellPercent}%</span>
                </Progress>
              )}
              {buyPercent}%
            </ProgressMain>
            <i className="fas fa-caret-up"></i>
          </>
        )}
      </Tabs>
      <Tabs
        style={{
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {pairs !== null && totalOtcAllocation !== null && (
          <p className="color-dull" style={{ fontSize: "12px" }}>
            Total shield allocation{" "}
            <span className="color-white">
              {pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? Number(
                        pairs[chainSelected][defaultPair].totalAllocation * 2
                      ).toLocaleString("en-US")
                    : null
                  : null
                : null}
            </span>
            {defaultPair.split("/")[1] + " (Total OTC Allocation "}
            <span className="color-white">
              {pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? Number(totalOtcAllocation).toLocaleString("en-US") +
                      " / " +
                      // formatPrice(
                      new Decimal(
                        pairs[chainSelected][defaultPair].totalAllocation
                      )
                        .times(200)
                        .dividedBy(totalOtcAllocation)
                        .toString()
                        .slice(0, 6) +
                      "%"
                    : null
                  : null
                : null}
            </span>
            {" )"}
          </p>
        )}
        <CheckBoxes>
          <div className="container" style={{ marginRight: "15px" }}>
            <div className="md-checkbox md-checkbox-inline mb-0">
              <input type="checkbox" id="checkbox01" name="" />
              <label htmlFor="checkbox01">Market price</label>
            </div>
            <div className="md-checkbox md-checkbox-inline mb-0">
              <input type="checkbox" id="checkbox02" name="" />
              <label htmlFor="checkbox02">Lowest Ask</label>
            </div>
          </div>
          <div className="container">
            <div className="md-checkbox md-checkbox-inline mb-0">
              <input type="checkbox" id="checkbox03" name="" />
              <label htmlFor="checkbox03">Average bids</label>
            </div>
            <div className="md-checkbox md-checkbox-inline mb-0">
              <input type="checkbox" id="checkbox04" name="" />
              <label htmlFor="checkbox04">Lowest bids</label>
            </div>
          </div>
        </CheckBoxes>
      </Tabs>
      <ChartContainer>
        {sales ? (
          <PriceSupplyLineChart
            priceData={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].pricesChart
                    : []
                  : []
                : []
            }
            supply={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].supplyChart
                    : []
                  : []
                : []
            }
            marketPrices={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].marketPricesChart
                    : []
                  : []
                : []
            }
          />
        ) : (
          <AskBidBarChart
            priceData={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].pricesChart
                    : []
                  : []
                : []
            }
            supply={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].supplyChart
                    : []
                  : []
                : []
            }
            marketPrices={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].marketPricesChart
                    : []
                  : []
                : []
            }
          />
        )}
      </ChartContainer>
      <OrderBookTitle>
        <DSTitle02>
          <DSTitleNumber>3</DSTitleNumber>
          Order Book
        </DSTitle02>
        <div
          className="view"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img
              src={buySellViewImg}
              style={{
                cursor: "pointer",
                color: buySellViewType === 1 ? "#00f02b" : "#9a9a9a",
                border:
                  buySellViewType === 1
                    ? "1px solid #00f02b"
                    : "1px solid #9a9a9a",
                borderEndStartRadius: "5px",
                borderStartStartRadius: "5px",
                padding: "10px",
              }}
              onClick={() => setBuySellViewType(1)}
            ></img>
            <img
              src={onlySellViewImg}
              style={{
                cursor: "pointer",
                color: buySellViewType === 3 ? "#00f02b" : "#9a9a9a",
                border:
                  buySellViewType === 3
                    ? "1px solid #00f02b"
                    : "1px solid #9a9a9a",
                padding: "10px",
              }}
              onClick={() => setBuySellViewType(3)}
            ></img>
            <img
              src={onlyBuyViewImg}
              style={{
                cursor: "pointer",
                color: buySellViewType === 2 ? "#00f02b" : "#9a9a9a",
                border:
                  buySellViewType === 2
                    ? "1px solid #00f02b"
                    : "1px solid #9a9a9a",
                borderEndEndRadius: "5px",
                borderStartEndRadius: "5px",
                padding: "10px",
              }}
              onClick={() => setBuySellViewType(2)}
            ></img>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <i
              className={`fas fa-th-list`}
              style={{
                cursor: "pointer",
                color: listView ? "#00f02b" : "#9a9a9a",
                border: listView ? "1px solid #00f02b" : "1px solid #9a9a9a",
                borderEndStartRadius: "5px",
                borderStartStartRadius: "5px",
                padding: "10px",
              }}
              onClick={() => setListView(true)}
            ></i>
            <i
              className={`fas fa-stop`}
              style={{
                cursor: "pointer",
                color: !listView ? "#00f02b" : "#9a9a9a",
                border: !listView ? "1px solid #00f02b" : "1px solid #9a9a9a",
                borderEndEndRadius: "5px",
                borderStartEndRadius: "5px",
                padding: "10px",
              }}
              onClick={() => setListView(false)}
            ></i>
          </div>
        </div>
      </OrderBookTitle>
      <ExchangeMain style={{ width: "100%" }}>
        <OrderBookContainer buySellViewType={buySellViewType}>
          <div
            style={{
              width: "100%",
              display:
                buySellViewType === 1 || buySellViewType === 3
                  ? "flex"
                  : "none",
              flexDirection: "column",
            }}
          >
            {/* =========================================================OTC=Ask=Table=========================================================== */}
            <div
              style={{
                widht: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#8146e4",
                  fontWeight: "700",
                  fontSize: "20px",
                  textTransform: "uppercase",
                }}
              >
                Sell Offer
              </span>
              {!ordersLoading && (
                <CreateNewOfferButton
                  offerType={"sell"}
                  onClick={() => {
                    props.setNewOfferType(2);
                    props.setPopupOTCClaim(true);
                  }}
                >
                  Create New Sell Offer
                </CreateNewOfferButton>
              )}
            </div>
            {defaultPair && props.dsUsers !== null && pairs ? (
              <TableTitle
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected]
                                .find((el) => el.pairNames === defaultPair)
                                .pairNames.split("/")[1] ===
                              nativeTokenList[chainSelected].symbol
                              ? nativeTokenList[chainSelected].logoURI
                              : new_token
                            : new_token
                          : new_token
                      }
                      style={{
                        width: "35px",
                      }}
                    ></img>
                    <span
                      className="color-dull"
                      style={{ margin: "0 5px 0 5px" }}
                    >
                      -
                    </span>
                    <img
                      src={
                        otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected].find(
                                (el) => el.pairNames === defaultPair
                              ).tokenLogo !== ""
                              ? otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                ).tokenLogo
                              : new_token
                            : ""
                          : ""
                      }
                      style={{ width: "35px", marginRight: "10px" }}
                    />
                    <span className="color-dull">
                      {defaultPair.split("/")[1] +
                        " > " +
                        defaultPair.split("/")[0]}
                    </span>
                  </div>
                </span>
                <span className="color-dull" style={{ fontSize: "14px" }}>
                  Lowest Ask:{" "}
                  <b className="bold-white">
                    {pairs !== null
                      ? formatPrice(
                          otcTradersData
                            ? otcTradersData[chainSelected]
                              ? otcTradersData[chainSelected].find(
                                  (el) =>
                                    el.pairNames ===
                                    defaultPair.split("/")[1] +
                                      "/" +
                                      defaultPair.split("/")[0]
                                )
                                ? Number(
                                    otcTradersData[chainSelected].find(
                                      (el) =>
                                        el.pairNames ===
                                        defaultPair.split("/")[1] +
                                          "/" +
                                          defaultPair.split("/")[0]
                                    ).lowestAsk
                                  )
                                : 0
                              : 0
                            : 0
                        )
                      : "0.00"}
                    {" " + defaultPair.split("/")[1]}
                  </b>
                  <span> | Unique sellers </span>
                  <b className="bold-white">
                    {otcTradersData
                      ? otcTradersData[chainSelected]
                        ? otcTradersData[chainSelected].find(
                            (el) =>
                              el.pairNames ===
                              defaultPair.split("/")[1] +
                                "/" +
                                defaultPair.split("/")[0]
                          )
                          ? otcTradersData[chainSelected].find(
                              (el) =>
                                el.pairNames ===
                                defaultPair.split("/")[1] +
                                  "/" +
                                  defaultPair.split("/")[0]
                            ).uniqueSellers
                          : null
                        : null
                      : null}
                  </b>
                </span>
              </TableTitle>
            ) : null}
            <TableMain className="height3" offerType="sell">
              {listView ? (
                <table
                  className="border-cell"
                  width="100%"
                  border="0"
                  cellSpacing="0"
                  cellPadding="20"
                >
                  <thead style={{ fontSize: "12px" }}>
                    <tr>
                      <th align="left" valign="middle" scope="col">
                        Liquidity Position{" "}
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>{" "}
                        <div className="sortLink">
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Protection Due Date{" "}
                        <div className="sortLink">
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Asking Allocation
                        <div
                          className="sortLink"
                          onClick={() =>
                            setSortByAskingAllocation(!sortByAskingAllocation)
                          }
                        >
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th
                        align="left"
                        valign="middle"
                        scope="col"
                        className="highlighted"
                      >
                        Asking Price/Token
                        <div
                          className="sortLink"
                          onClick={() =>
                            setSortByAskingPriceToken(!sortByAskingPriceToken)
                          }
                        >
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Spread to Avg{" "}
                        <div className="sortLink">
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th
                        className="ColSticky"
                        align="center"
                        valign="middle"
                        scope="col"
                      >
                        Accept Offer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersLoading ? (
                      <tr>
                        <td colSpan="9" align="middle">
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={50}
                            width={150}
                          />
                        </td>
                      </tr>
                    ) : otcOrders[chainSelected] ? (
                      filteredOtcOrders
                        .filter(
                          (ele) =>
                            ele.pair ===
                            defaultPair.split("/")[1] +
                              "/" +
                              defaultPair.split("/")[0]
                        )
                        .sort((a, b) => {
                          return sortByAskingPriceToken
                            ? a.otcPrice - b.otcPrice
                            : b.otcPrice - a.otcPrice;
                        })
                        .map((ele, key) => {
                          return (
                            <tr key={key} style={{ fontSize: "13px" }}>
                              <td>
                                <i
                                  className={
                                    ele.liquidityPosition === "1st"
                                      ? "fas fa-circle color-green"
                                      : ele.liquidityPosition === "2nd"
                                      ? "fas fa-circle color-yellow"
                                      : "fas fa-circle color-red"
                                  }
                                ></i>
                                {ele.liquidityPosition}
                              </td>
                              <td>{ele.vestingDate}</td>
                              <td
                                data-title="Offering Allocation"
                                data-tip={ele.supplyAvailable}
                                data-for="supplyAvailable"
                              >
                                {Number(
                                  Number(ele.supplyAvailable).toFixed(2)
                                ).toLocaleString("en-US", {
                                  currency: "USD",
                                })}
                                <ReactTooltip id="supplyAvailable" />
                              </td>
                              <td
                                data-title="Offering Price"
                                data-html="true"
                                data-tip={
                                  ele.otcPrice +
                                  " " +
                                  nativeTokenList[chainSelected].symbol +
                                  " <br/> $" +
                                  ele.otcPriceUSD
                                }
                                data-for="otcPrice"
                                style={{ color: "#8146e4" }}
                                className="highlighted"
                              >
                                {formatPrice(ele.otcPrice)}{" "}
                                {nativeTokenList[chainSelected].symbol}
                                <ReactTooltip id="otcPrice" />
                                <Block
                                  style={{ fontSize: "12px" }}
                                  className={"color-dull"}
                                >
                                  [${formatPrice(ele.otcPriceUSD)}]
                                </Block>
                              </td>
                              <td data-title="Spread to Avg">
                                {new Decimal(ele.otcPrice)
                                  .dividedBy(
                                    otcTradersData[chainSelected].find(
                                      (e) =>
                                        e.pairNames ==
                                        defaultPair.split("/")[1] +
                                          "/" +
                                          defaultPair.split("/")[0]
                                    ).avgPriceAll
                                  )
                                  .toNumber() >= 1
                                  ? "+" +
                                    formatPrice(
                                      new Decimal(ele.otcPrice)
                                        .dividedBy(
                                          otcTradersData[chainSelected].find(
                                            (e) =>
                                              e.pairNames ==
                                              defaultPair.split("/")[1] +
                                                "/" +
                                                defaultPair.split("/")[0]
                                          ).avgPriceAll
                                        )
                                        .minus(1)
                                        .times(100)
                                        .toNumber()
                                    ) +
                                    "%"
                                  : new Decimal(ele.otcPrice)
                                      .dividedBy(
                                        otcTradersData[chainSelected].find(
                                          (e) =>
                                            e.pairNames ==
                                            defaultPair.split("/")[1] +
                                              "/" +
                                              defaultPair.split("/")[0]
                                        ).avgPriceAll
                                      )
                                      .toNumber() === 0
                                  ? "0%"
                                  : "-" +
                                    formatPrice(
                                      new Decimal(100)
                                        .minus(
                                          new Decimal(ele.otcPrice)
                                            .dividedBy(
                                              otcTradersData[
                                                chainSelected
                                              ].find(
                                                (e) =>
                                                  e.pairNames == defaultPair
                                              ).avgPriceAll
                                            )
                                            .times(100)
                                        )
                                        .toNumber()
                                    ) +
                                    "%"}
                              </td>
                              <td
                                className="ColSticky"
                                data-title="Accept Offer"
                              >
                                <div className="OpfyBTNbar v2">
                                  {account !== null ? (
                                    account !== ele.seller ? (
                                      <AcceptOfferButton
                                        offerType={"sell"}
                                        onClick={() => {
                                          props.setPopupMyofferClaim(true);
                                          let tokenLogo =
                                            otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo !== ""
                                              ? otcTradersData[
                                                  chainSelected
                                                ].find(
                                                  (e) =>
                                                    e.pairNames == defaultPair
                                                ).tokenLogo
                                              : new_token;
                                          props.setCurrentClaimOrder({
                                            ...ele,
                                            tokenLogo,
                                          });
                                        }}
                                      >
                                        Sell {defaultPair.split("/")[0]}
                                      </AcceptOfferButton>
                                    ) : (
                                      <button className="btn01">
                                        Your request
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      className="btn01"
                                      onClick={() => props.connectWallet()}
                                    >
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan="9" align="middle">
                          No transaction
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <>
                  {ordersLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "30px",
                      }}
                    >
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={50}
                        width={150}
                      />
                    </div>
                  ) : (
                    <Grid buySellViewType={buySellViewType} className="height3">
                      {otcOrders[chainSelected] ? (
                        filteredOtcOrders
                          .filter(
                            (ele) =>
                              ele.pair ===
                              defaultPair.split("/")[1] +
                                "/" +
                                defaultPair.split("/")[0]
                          )
                          .map((ele, key) => {
                            return (
                              <Item offerType={"sell"} key={key}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "15px",
                                  }}
                                >
                                  <a
                                    target="_blank"
                                    href={ele.txLink}
                                    rel="noreferrer"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "white",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <img
                                      src={
                                        otcTradersData
                                          ? otcTradersData[chainSelected]
                                            ? otcTradersData[chainSelected]
                                                .find(
                                                  (el) =>
                                                    el.pairNames === defaultPair
                                                )
                                                .pairNames.split("/")[1] ===
                                              nativeTokenList[chainSelected]
                                                .symbol
                                              ? nativeTokenList[chainSelected]
                                                  .logoURI
                                              : new_token
                                            : new_token
                                          : new_token
                                      }
                                      style={{
                                        width: "40px",
                                        marginRight: "5px",
                                      }}
                                    ></img>
                                    {ele.pair}
                                  </a>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      style={{ marginRight: "10px" }}
                                      className={
                                        ele.liquidityPosition === "1st"
                                          ? "fas fa-circle color-green"
                                          : ele.liquidityPosition === "2nd"
                                          ? "fas fa-circle color-yellow"
                                          : "fas fa-circle color-red"
                                      }
                                    ></i>
                                    {ele.liquidityPosition}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    padding: "15px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50%",
                                      borderRight: "1px solid #1e2127",
                                    }}
                                  >
                                    <span className="color-dull">
                                      Offering allocation
                                    </span>
                                    <span
                                      className="color-white"
                                      style={{
                                        margin: "5px 0px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {Number(
                                        Number(ele.supplyAvailable).toFixed(2)
                                      ).toLocaleString("en-US", {
                                        currency: "USD",
                                      }) +
                                        " " +
                                        ele.pair.split("/")[0]}
                                    </span>
                                    <span
                                      className="color-dull"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {
                                        nativeTokenList[chainSelected]
                                          .networkName
                                      }{" "}
                                      chain
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "end",
                                      width: "50%",
                                    }}
                                  >
                                    <span className="color-dull">
                                      Bid price/token
                                    </span>
                                    <span
                                      className="color-white"
                                      style={{
                                        margin: "5px 0px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {formatPrice(ele.otcPrice)}{" "}
                                      {ele.pair.split("/")[1]}
                                    </span>
                                    <Block
                                      className="color-dull"
                                      style={{ fontSize: "12px" }}
                                    >
                                      [${formatPrice(ele.otcPriceUSD)}]
                                    </Block>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderTop: "1px solid #1e2127",
                                    padding: "15px 20px",
                                  }}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      props.setPopupMyofferClaim(true);
                                      let tokenLogo =
                                        otcTradersData[chainSelected].find(
                                          (e) => e.pairNames == defaultPair
                                        ).tokenLogo !== ""
                                          ? otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo
                                          : new_token;
                                      props.setCurrentClaimOrder({
                                        ...ele,
                                        tokenLogo,
                                      });
                                    }}
                                  >
                                    Sell {defaultPair.split("/")[0]}
                                  </button>
                                </div>
                              </Item>
                            );
                          })
                      ) : (
                        <span>No transaction</span>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </TableMain>
            {/* {!ordersLoading && otcOrders[chainSelected] && (
              <TableBottom>
                <Tnav>
                  Page
                  <SelectCustom>
                    <select
                      onChange={(e) =>
                        setBuyOfferTablePage(Number(e.target.value))
                      }
                    >
                      {[
                        ...Array(
                          Math.floor(
                            filteredOtcOrders.filter(
                              (ele) =>
                                ele.pair ===
                                defaultPair.split("/")[1] +
                                  "/" +
                                  defaultPair.split("/")[0],
                            ).length / 10,
                          ) + 1,
                        ),
                      ].map((_, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </SelectCustom>
                  {"result " +
                    new Decimal(buyOfferTablePage - 1).times(10).add(1) +
                    " - " +
                    buyOfferTablePage * 10 +
                    " of " +
                    filteredOtcOrders.filter(
                      (ele) =>
                        ele.pair ===
                        defaultPair.split("/")[1] +
                          "/" +
                          defaultPair.split("/")[0],
                    ).length}
                </Tnav>
              </TableBottom>
            )} */}
          </div>
          <div
            style={{
              width: "100%",
              display:
                buySellViewType === 1 || buySellViewType === 2
                  ? "flex"
                  : "none",
              flexDirection: "column",
            }}
          >
            {/* =========================================================OTC=BID=Table=========================================================== */}
            <div
              style={{
                widht: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#00f02b",
                  fontWeight: "700",
                  fontSize: "20px",
                  textTransform: "uppercase",
                }}
              >
                Buy Offer
              </span>
              {!ordersLoading && (
                <CreateNewOfferButton
                  offerType={"buy"}
                  onClick={() => {
                    props.setNewOfferType(1);
                    props.setPopupOTCClaim(true);
                  }}
                >
                  Create New Buy Offer
                </CreateNewOfferButton>
              )}
            </div>
            {defaultPair && props.dsUsers !== null && pairs ? (
              <TableTitle
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected].find(
                                (el) => el.pairNames === defaultPair
                              ).tokenLogo !== ""
                              ? otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                ).tokenLogo
                              : new_token
                            : ""
                          : ""
                      }
                      style={{ width: "35px" }}
                    />
                    <span
                      className="color-dull"
                      style={{ margin: "0 5px 0 5px" }}
                    >
                      -
                    </span>
                    <img
                      src={
                        otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected]
                                .find((el) => el.pairNames === defaultPair)
                                .pairNames.split("/")[1] ===
                              nativeTokenList[chainSelected].symbol
                              ? nativeTokenList[chainSelected].logoURI
                              : new_token
                            : new_token
                          : new_token
                      }
                      style={{
                        width: "35px",
                        marginRight: "10px",
                      }}
                    ></img>
                    <span className="color-dull">
                      {defaultPair.split("/")[0] +
                        " > " +
                        defaultPair.split("/")[1]}
                    </span>
                  </div>
                </span>
                <span className="color-dull" style={{ fontSize: "14px" }}>
                  Highest Bid:{" "}
                  <b className="bold-white">
                    {pairs !== null
                      ? formatPrice(
                          otcTradersData
                            ? otcTradersData[chainSelected]
                              ? otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                )
                                ? Number(
                                    otcTradersData[chainSelected].find(
                                      (el) => el.pairNames === defaultPair
                                    ).lowestAsk
                                  )
                                : 0
                              : 0
                            : 0
                        )
                      : "0.00"}
                    {" " + defaultPair.split("/")[1]}
                  </b>
                  {defaultPair && props.dsUsers !== null && pairs ? (
                    <>
                      <span> | Unique buyers </span>
                      <b className="bold-white">
                        {otcTradersData
                          ? otcTradersData[chainSelected]
                            ? otcTradersData[chainSelected].find(
                                (el) => el.pairNames === defaultPair
                              )
                              ? otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                ).uniqueSellers
                              : null
                            : null
                          : null}
                      </b>
                    </>
                  ) : null}
                </span>
              </TableTitle>
            ) : null}
            <TableMain
              className="height3"
              offerType="buy"
              style={{ width: "100%" }}
            >
              {listView ? (
                <table
                  className="border-cell"
                  width="100%"
                  border="0"
                  cellSpacing="0"
                  cellPadding="20"
                >
                  <thead style={{ fontSize: "12px" }}>
                    <tr>
                      <th align="left" valign="middle" scope="col">
                        Liquidity Position{" "}
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>{" "}
                        <div className="sortLink">
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Protection Due Date{" "}
                        <div className="sortLink">
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Bid Allocation
                        <div
                          className="sortLink"
                          onClick={() =>
                            setSortByBidAllocation(!sortByBidAllocation)
                          }
                        >
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th
                        align="left"
                        valign="middle"
                        scope="col"
                        className="highlighted"
                      >
                        Bid Price/Token
                        <div
                          className="sortLink"
                          onClick={() =>
                            setSortByBidPriceToken(!sortByBidPriceToken)
                          }
                        >
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Spread to Avg{" "}
                        <div className="sortLink">
                          <i className="fas fa-sort"></i>
                        </div>
                      </th>
                      <th
                        className="ColSticky"
                        align="center"
                        valign="middle"
                        scope="col"
                      >
                        Accept Offer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersLoading ? (
                      <tr>
                        <td colSpan="9" align="middle">
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={50}
                            width={150}
                          />
                        </td>
                      </tr>
                    ) : otcOrders[chainSelected] ? (
                      filteredOtcOrders
                        .filter(
                          (ele) =>
                            (position === null ||
                              (position !== null &&
                                ele.liquidityPosition === position)) &&
                            ele.pair === defaultPair
                        )
                        .sort((a, b) => {
                          return sortByBidPriceToken
                            ? a.otcPrice - b.otcPrice
                            : b.otcPrice - a.otcPrice;
                        })
                        .map((ele, key) => {
                          return (
                            <tr key={key} style={{ fontSize: "13px" }}>
                              <td>
                                <i
                                  className={
                                    ele.liquidityPosition === "1st"
                                      ? "fas fa-circle color-green"
                                      : ele.liquidityPosition === "2nd"
                                      ? "fas fa-circle color-yellow"
                                      : "fas fa-circle color-red"
                                  }
                                ></i>
                                {ele.liquidityPosition}
                              </td>
                              <td>{ele.vestingDate}</td>
                              <td
                                data-title="Offering Allocation"
                                data-tip={ele.supplyAvailable}
                                data-for="supplyAvailable"
                              >
                                {Number(
                                  Number(ele.supplyAvailable).toFixed(2)
                                ).toLocaleString("en-US", {
                                  currency: "USD",
                                })}
                                <ReactTooltip id="supplyAvailable" />
                              </td>
                              <td
                                data-title="Offering Price"
                                data-html="true"
                                data-tip={
                                  ele.otcPrice +
                                  " " +
                                  nativeTokenList[chainSelected].symbol +
                                  " <br/> $" +
                                  ele.otcPriceUSD
                                }
                                data-for="otcPrice"
                                style={{ color: "#00f02b" }}
                                className="highlighted"
                              >
                                {formatPrice(ele.otcPrice)}{" "}
                                {nativeTokenList[chainSelected].symbol}
                                <ReactTooltip id="otcPrice" />
                                <Block
                                  style={{ fontSize: "12px" }}
                                  className={"color-dull"}
                                >
                                  [${formatPrice(ele.otcPriceUSD)}]
                                </Block>
                              </td>
                              <td data-title="Spread to Avg">
                                {new Decimal(ele.otcPrice)
                                  .dividedBy(
                                    otcTradersData[chainSelected].find(
                                      (e) => e.pairNames == defaultPair
                                    ).avgPriceAll
                                  )
                                  .toNumber() >= 1
                                  ? "+" +
                                    formatPrice(
                                      new Decimal(ele.otcPrice)
                                        .dividedBy(
                                          otcTradersData[chainSelected].find(
                                            (e) => e.pairNames == defaultPair
                                          ).avgPriceAll
                                        )
                                        .minus(1)
                                        .times(100)
                                        .toNumber()
                                    ) +
                                    "%"
                                  : new Decimal(ele.otcPrice)
                                      .dividedBy(
                                        otcTradersData[chainSelected].find(
                                          (e) => e.pairNames == defaultPair
                                        ).avgPriceAll
                                      )
                                      .toNumber() === 0
                                  ? "0%"
                                  : "-" +
                                    formatPrice(
                                      new Decimal(100)
                                        .minus(
                                          new Decimal(ele.otcPrice)
                                            .dividedBy(
                                              otcTradersData[
                                                chainSelected
                                              ].find(
                                                (e) =>
                                                  e.pairNames == defaultPair
                                              ).avgPriceAll
                                            )
                                            .times(100)
                                        )
                                        .toNumber()
                                    ) +
                                    "%"}
                              </td>
                              <td
                                className="ColSticky"
                                data-title="Accept Offer"
                              >
                                <div className="OpfyBTNbar v2">
                                  {account !== null ? (
                                    account !== ele.seller ? (
                                      <AcceptOfferButton
                                        offerType={"buy"}
                                        onClick={() => {
                                          props.setPopupMyofferClaim(true);
                                          let tokenLogo =
                                            otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo !== ""
                                              ? otcTradersData[
                                                  chainSelected
                                                ].find(
                                                  (e) =>
                                                    e.pairNames == defaultPair
                                                ).tokenLogo
                                              : new_token;
                                          props.setCurrentClaimOrder({
                                            ...ele,
                                            tokenLogo,
                                          });
                                        }}
                                      >
                                        Buy {defaultPair.split("/")[0]}
                                      </AcceptOfferButton>
                                    ) : (
                                      <button className="btn01">
                                        Your request
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      className="btn01"
                                      onClick={() => props.connectWallet()}
                                    >
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan="9" align="middle">
                          No transaction
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <>
                  {ordersLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "30px",
                      }}
                    >
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={50}
                        width={150}
                      />
                    </div>
                  ) : (
                    <Grid buySellViewType={buySellViewType} className="height3">
                      {otcOrders[chainSelected] &&
                      otcOrders[chainSelected].length > 0 ? (
                        filteredOtcOrders
                          .filter(
                            (ele) =>
                              (position === null ||
                                (position !== null &&
                                  ele.liquidityPosition === position)) &&
                              ele.pair === defaultPair
                          )
                          .map((ele, key) => {
                            return (
                              <Item offerType={"buy"} key={key}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "15px",
                                  }}
                                >
                                  <a
                                    target="_blank"
                                    href={ele.txLink}
                                    rel="noreferrer"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "white",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "40px",
                                        marginRight: "5px",
                                      }}
                                      src={
                                        otcTradersData[chainSelected].find(
                                          (e) => e.pairNames == defaultPair
                                        )
                                          ? otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo !== ""
                                            ? otcTradersData[
                                                chainSelected
                                              ].find(
                                                (e) =>
                                                  e.pairNames == defaultPair
                                              ).tokenLogo
                                            : new_token
                                          : new_token
                                      }
                                    ></img>{" "}
                                    {ele.pair}
                                  </a>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      style={{ marginRight: "10px" }}
                                      className={
                                        ele.liquidityPosition === "1st"
                                          ? "fas fa-circle color-green"
                                          : ele.liquidityPosition === "2nd"
                                          ? "fas fa-circle color-yellow"
                                          : "fas fa-circle color-red"
                                      }
                                    ></i>
                                    {ele.liquidityPosition}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    padding: "15px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50%",
                                      borderRight: "1px solid #1e2127",
                                    }}
                                  >
                                    <span className="color-dull">
                                      Offering allocation
                                    </span>
                                    <span
                                      className="color-white"
                                      style={{
                                        margin: "5px 0px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {Number(
                                        Number(ele.supplyAvailable).toFixed(2)
                                      ).toLocaleString("en-US", {
                                        currency: "USD",
                                      }) +
                                        " " +
                                        defaultPair.split("/")[0]}
                                    </span>
                                    <span
                                      className="color-dull"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {
                                        nativeTokenList[chainSelected]
                                          .networkName
                                      }{" "}
                                      chain
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "end",
                                      width: "50%",
                                    }}
                                  >
                                    <span className="color-dull">
                                      Asking price/token
                                    </span>
                                    <span
                                      className="color-white"
                                      style={{
                                        margin: "5px 0px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {formatPrice(ele.otcPrice)}{" "}
                                      {defaultPair.split("/")[1]}
                                    </span>
                                    <Block
                                      className="color-dull"
                                      style={{ fontSize: "12px" }}
                                    >
                                      [${formatPrice(ele.otcPriceUSD)}]
                                    </Block>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderTop: "1px solid #1e2127",
                                    padding: "15px 20px",
                                  }}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      props.setPopupMyofferClaim(true);
                                      let tokenLogo =
                                        otcTradersData[chainSelected].find(
                                          (e) => e.pairNames == defaultPair
                                        ).tokenLogo !== ""
                                          ? otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo
                                          : new_token;
                                      props.setCurrentClaimOrder({
                                        ...ele,
                                        tokenLogo,
                                      });
                                    }}
                                  >
                                    Buy {defaultPair.split("/")[0]}
                                  </button>
                                </div>
                              </Item>
                            );
                          })
                      ) : (
                        <span>No transaction</span>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </TableMain>
            {/* {!ordersLoading && otcOrders[chainSelected] && (
              <TableBottom>
                <Tnav>
                  Page
                  <SelectCustom>
                    <select
                      onChange={(e) =>
                        setSellOfferTablePage(Number(e.target.value))
                      }
                    >
                      {[
                        ...Array(
                          Math.floor(
                            filteredOtcOrders.filter(
                              (ele) => ele.pair === defaultPair,
                            ).length / 10,
                          ) + 1,
                        ),
                      ].map((_, index) => (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </SelectCustom>
                  {"result " +
                    new Decimal(sellOfferTablePage - 1).times(10).add(1) +
                    " - " +
                    sellOfferTablePage * 10 +
                    " of " +
                    filteredOtcOrders.filter((ele) => ele.pair === defaultPair)
                      .length}
                </Tnav>
              </TableBottom>
            )} */}
          </div>
        </OrderBookContainer>
      </ExchangeMain>
      <DSTitle02
        style={{
          marginTop: "100px",
          marginBottom: "0px",
        }}
      >
        <DSTitleNumber>4</DSTitleNumber>
        Ledger
      </DSTitle02>
      <TabNav
        className="wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ width: "100%" }}
      >
        <a
          className={`tab-Link ${getActiveClass(4, "active")}`}
          onClick={() => toggleTab(4)}
        >
          <span>OTC Trades</span>
        </a>
        <a
          className={`tab-Link ${getActiveClass(2, "active")}`}
          onClick={() => toggleTab(2)}
        >
          <span>My History purchases</span>
        </a>
        <a
          className={`tab-Link ${getActiveClass(3, "active")}`}
          onClick={() => toggleTab(3)}
        >
          My History selling
        </a>
        <a
          className={`tab-Link ${getActiveClass(1, "active")}`}
          onClick={() => toggleTab(1)}
        >
          <span>My active offers</span>
        </a>
      </TabNav>
      <MobileTab>
        <h3>OTC Trades</h3>
        <div className="dropDown">
          <a
            className={`tab-Link ${getActiveClass(4, "active")}`}
            onClick={() => toggleTab(1)}
          >
            <span>OTC Traders</span>
          </a>
          <a
            className={`tab-Link ${getActiveClass(2, "active")}`}
            onClick={() => toggleTab(2)}
          >
            <span>History purchases</span>
          </a>
          <a
            className={`tab-Link ${getActiveClass(3, "active")}`}
            onClick={() => toggleTab(3)}
          >
            History selling
          </a>
          <a
            className={`tab-Link ${getActiveClass(1, "active")}`}
            onClick={() => toggleTab(1)}
          >
            <span>My active offers</span>
          </a>
        </div>
      </MobileTab>
      {getActiveClass(
        1,
        <>
          <TabContainer>
            <TableMain className="height1">
              <table
                className="border-cell"
                width="100%"
                border="0"
                cellSpacing="0"
                cellPadding="20"
              >
                <thead>
                  <tr>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Token Pairs{" "}
                      <div className="sortLink">
                        <i className="fas fa-sort"></i>
                      </div>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Vesting Due Date{" "}
                      <div className="sortLink">
                        <i className="fas fa-sort"></i>
                      </div>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Offering Allocation
                      <div className="sortLink">
                        <i className="fas fa-sort"></i>
                      </div>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Asking Amount
                      <span
                        data-html="true"
                        data-tip="test"
                        className="helpIco"
                        currentitem="false"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                      <div className="sortLink">
                        <i className="fas fa-sort"></i>
                      </div>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Offering Price
                      <div className="sortLink">
                        <i className="fas fa-sort"></i>
                      </div>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Market Price{" "}
                      <div className="sortLink">
                        <i className="fas fa-sort"></i>
                      </div>
                    </th>
                    <th
                      width="12.5%"
                      align="center"
                      valign="middle"
                      scope="col"
                    >
                      Price Difference
                    </th>
                    <th
                      width="12.5%"
                      align="center"
                      valign="middle"
                      scope="col"
                    >
                      Delist
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersLoading ? (
                    <tr>
                      <td colSpan="9" align="middle">
                        <ReactLoaderSpinner
                          type="Bars"
                          color="#fff"
                          height={50}
                          width={150}
                        />
                      </td>
                    </tr>
                  ) : offersByMe[chainSelected] ? (
                    offersByMe[chainSelected].map((ele, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <a
                              target="_blank"
                              href={ele.txLink}
                              rel="noreferrer"
                            >
                              {ele.pair}
                            </a>
                          </td>
                          <td>{ele.vestingDate}</td>
                          <td data-tip={ele.supplyAvailable}>
                            {Number(ele.supplyAvailable).toFixed(2)}
                          </td>
                          {/* <td data-title="Offering Allocation" className=''></td> */}
                          <td data-tip={ele.askingAmount}>
                            {ele.askingAmount}{" "}
                            <Block className="color-dull">
                              [${ele.askingAmountUSD.toFixed(4)}]
                            </Block>
                          </td>
                          <td
                            data-title="Offering Price"
                            data-html="true"
                            data-tip={
                              ele.otcPrice + " <br/> $" + ele.otcPriceUSD
                            }
                            data-for="otcPrice"
                          >
                            {formatPrice(ele.otcPrice)}{" "}
                            {nativeTokenList[chainSelected].symbol}
                            <ReactTooltip id="otcPrice" />
                            <Block className="color-dull">
                              [${formatPrice(ele.otcPriceUSD)}]
                            </Block>
                          </td>
                          <td
                            data-title="Market Price"
                            data-tip={ele.marketPrice}
                            data-for="marketPrice"
                          >
                            {formatPrice(ele.marketPrice)}{" "}
                            {nativeTokenList[chainSelected].symbol}
                            <ReactTooltip id="marketPrice" />
                            <Block className="color-dull">
                              [${formatPrice(ele.marketPriceUSD)}]
                            </Block>
                          </td>

                          <td
                            className={
                              ele.competitiveValueUSD < 0
                                ? "color-red"
                                : "color-green"
                            }
                          >
                            ${formatPrice(ele.competitiveValueUSD)} |{" "}
                            {formatPrice(ele.competitiveValuePercentage)}%
                          </td>

                          <td className="clickable">
                            <a
                              className="btn02"
                              onClick={() => handleCancelOrder(ele)}
                            >
                              {cancelLoading ? (
                                <ReactLoaderSpinner
                                  type="ThreeDots"
                                  color="#fff"
                                  height={15}
                                  width={80}
                                />
                              ) : (
                                "DELIST"
                              )}
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" align="middle">
                        No transaction
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableMain>
          </TabContainer>
        </>
      )}
      {getActiveClass(
        2,
        <TabContainer>
          <TableMain className="height1">
            <table
              className="border-cell"
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="20"
            >
              <thead>
                <tr>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Token Pairs{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Vesting Due Date{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Allocation Purchased{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Amount Paid{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Purchase Price{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    OTC Price{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Proof
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersLoading ? (
                  <tr>
                    <td colSpan="9" align="middle">
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={50}
                        width={150}
                      />
                    </td>
                  </tr>
                ) : historyPurchases[chainSelected] ? (
                  historyPurchases[chainSelected].map((ele, key) => {
                    return ele.boughtOrders.length > 0
                      ? ele.boughtOrders.map((subEle, key) => {
                          if (
                            subEle.buyer.toLocaleLowerCase() ===
                            account.toLocaleLowerCase()
                          ) {
                            return (
                              <tr key={key}>
                                <td>{ele.pair}</td>
                                <td>{ele.vestingDate}</td>
                                <td>
                                  {Number(
                                    Web3.utils.fromWei(subEle.boughtAmount)
                                  ).toFixed(4)}
                                </td>
                                <td>
                                  {Number(
                                    Web3.utils.fromWei(subEle.paidAmount)
                                  ).toFixed(4)}
                                </td>
                                <td>
                                  {formatPrice(ele.marketPrice)}
                                  <Block className="color-dull">
                                    [${formatPrice(ele.marketPrice)}]
                                  </Block>
                                </td>
                                <td>
                                  {formatPrice(ele.otcPrice)}
                                  <Block className="color-dull">
                                    [${formatPrice(ele.otcPriceUSD)}]
                                  </Block>
                                </td>
                                <td>
                                  <a
                                    target="_blank"
                                    href={
                                      explorerLink[ele.chainId] +
                                      "/tx/" +
                                      subEle.transactionHash
                                    }
                                    rel="noreferrer"
                                  >
                                    {subEle.transactionHash.slice(0, 8) +
                                      ".." +
                                      subEle.transactionHash.slice(60, 66)}
                                  </a>
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })
                      : null;
                  })
                ) : (
                  <tr>
                    <td colSpan="9" align="middle">
                      No transaction
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableMain>
        </TabContainer>
      )}
      {getActiveClass(
        3,
        <TabContainer>
          <TableMain className="height1">
            <table
              className="border-cell"
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="20"
            >
              <thead>
                <tr>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Token Pairs{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Vesting Due Date{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Allocation Sold{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Amount Received
                    <span
                      data-html="true"
                      data-tip="test"
                      className="helpIco"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  {/* <th width='14%' align='left' valign='middle' scope='col'>
                    Supply Sold{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                  <th width="14%" align="left" valign="middle" scope="col">
                    Selling Price{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    OTC Price{" "}
                    <div className="sortLink">
                      <i className="fas fa-sort"></i>
                    </div>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Proof
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersLoading ? (
                  <tr>
                    <td colSpan="9" align="middle">
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={50}
                        width={150}
                      />
                    </td>
                  </tr>
                ) : historySelling[chainSelected] ? (
                  historySelling[chainSelected].map((ele, index1) => {
                    return (
                      <tr key={index1}>
                        <td>{ele.pair}</td>
                        <td>{ele.vestingDate}</td>
                        {ele.boughtOrders.length > 0
                          ? ele.boughtOrders.map((subEle, index2) => {
                              return (
                                <>
                                  <td data-title="Allocation Sold">
                                    {Web3.utils.fromWei(subEle.boughtAmount)}
                                  </td>
                                  <td>
                                    {Web3.utils.fromWei(subEle.paidAmount)}
                                    {/* <Block className='color-dull'>[$0.01986]</Block> */}
                                  </td>
                                  <td>
                                    0.0006{" "}
                                    {nativeTokenList[chainSelected].symbol}
                                    <Block className="color-dull">
                                      [$0.01986]
                                    </Block>
                                  </td>
                                  {/* <td>
                                    {Web3.utils.fromWei(subEle.paidAmount)}
                                  </td> */}
                                  <td>
                                    {formatPrice(ele.otcPrice)}
                                    <Block className="color-dull">
                                      [${formatPrice(ele.otcPriceUSD)}]
                                    </Block>
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      href={
                                        explorerLink[ele.chainId] +
                                        "/tx/" +
                                        subEle.transactionHash
                                      }
                                      rel="noreferrer"
                                    >
                                      {subEle.transactionHash.slice(0, 8) +
                                        ".." +
                                        subEle.transactionHash.slice(60, 66)}
                                    </a>
                                  </td>
                                </>
                              );
                            })
                          : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9" align="middle">
                      No transaction
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableMain>
        </TabContainer>
      )}
      {getActiveClass(
        4,
        <>
          <TabContainer>
            <OTCTradsContainer>
              <div
                style={{
                  width: "100%",
                }}
              >
                {/* =========================================================OTC=Sell=Trades=Table=========================================================== */}
                {defaultPair && props.dsUsers !== null && pairs ? (
                  <TableTitle
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={nativeTokenList[chainSelected].logoURI}
                          style={{
                            width: "25px",
                            marginRight: "10px",
                          }}
                        ></img>
                        <span
                          className="color-dull"
                          style={{ margin: "0 5px 0 0" }}
                        >
                          -
                        </span>
                        <img
                          src={
                            otcTradersData
                              ? otcTradersData[chainSelected]
                                ? otcTradersData[chainSelected].find(
                                    (el) => el.pairNames === defaultPair
                                  ).tokenLogo !== ""
                                  ? otcTradersData[chainSelected].find(
                                      (el) => el.pairNames === defaultPair
                                    ).tokenLogo
                                  : new_token
                                : ""
                              : ""
                          }
                          style={{ width: "35px" }}
                        />
                        <span className="color-white">
                          {defaultPair.split("/")[1]}
                        </span>
                        <span
                          className="color-dull"
                          style={{ margin: "0 5px" }}
                        >
                          {">"}
                        </span>
                        <span className="color-dull">
                          {defaultPair.split("/")[0]}
                        </span>
                      </div>
                    </span>
                    <div style={{ fontWeight: "700", fontSize: "14px" }}>
                      <span style={{ color: "#8146e4" }}>OTC Sell Trades</span>
                      <span className="color-dull" style={{ margin: "0 5px" }}>
                        |
                      </span>
                      <span style={{ color: "#fff" }}>My Trades</span>
                    </div>
                  </TableTitle>
                ) : null}
                <TableMain
                  className="height3"
                  offerType="sell"
                  style={{ width: "100%" }}
                >
                  <table
                    className="border-cell"
                    width="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="20"
                    style={{ border: "1px solid #1e2127" }}
                  >
                    <thead style={{ fontSize: "12px" }}>
                      <tr>
                        <th align="left" valign="middle" scope="col">
                          Time
                          <div className="sortLink">
                            <i className="fas fa-sort"></i>
                          </div>
                        </th>
                        <th align="left" valign="middle" scope="col">
                          Asking Price
                          <div className="sortLink">
                            <i className="fas fa-sort"></i>
                          </div>
                        </th>
                        <th align="left" valign="middle" scope="col">
                          Trades
                          <div className="sortLink">
                            <i className="fas fa-sort"></i>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersLoading ? (
                        <tr>
                          <td colSpan="9" align="middle">
                            <ReactLoaderSpinner
                              type="Bars"
                              color="#fff"
                              height={50}
                              width={150}
                            />
                          </td>
                        </tr>
                      ) : otcOrders[chainSelected] ? (
                        // filteredOtcOrders
                        //   .filter(
                        //     (ele) =>
                        //       position === null ||
                        //       (position !== null &&
                        //         ele.liquidityPosition === position)
                        //   )
                        //   .map((ele, key) => {
                        //     if (ele.pair === defaultPair) {
                        //       return <></>;
                        //     }
                        //   })
                        <></>
                      ) : (
                        <tr>
                          <td colSpan="9" align="middle">
                            No transaction
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </TableMain>
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                {/* =========================================================OTC=Buy=Trades=Table=========================================================== */}
                {defaultPair && props.dsUsers !== null && pairs ? (
                  <TableTitle
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            otcTradersData
                              ? otcTradersData[chainSelected]
                                ? otcTradersData[chainSelected].find(
                                    (el) => el.pairNames === defaultPair
                                  ).tokenLogo !== ""
                                  ? otcTradersData[chainSelected].find(
                                      (el) => el.pairNames === defaultPair
                                    ).tokenLogo
                                  : new_token
                                : ""
                              : ""
                          }
                          style={{ width: "35px" }}
                        />
                        <span
                          className="color-dull"
                          style={{ margin: "0 5px 0 0" }}
                        >
                          -
                        </span>
                        <img
                          src={nativeTokenList[chainSelected].logoURI}
                          style={{
                            width: "25px",
                            marginRight: "10px",
                          }}
                        ></img>
                        <span className="color-white">
                          {defaultPair.split("/")[0]}
                        </span>
                        <span
                          className="color-dull"
                          style={{ margin: "0 5px" }}
                        >
                          {">"}
                        </span>
                        <span className="color-dull">
                          {defaultPair.split("/")[1]}
                        </span>
                      </div>
                    </span>
                    <div style={{ fontWeight: "700", fontSize: "14px" }}>
                      <span style={{ color: "#00f02b" }}>OTC Buy Trades</span>
                      <span className="color-dull" style={{ margin: "0 5px" }}>
                        |
                      </span>
                      <span style={{ color: "#fff" }}>My Trades</span>
                    </div>
                  </TableTitle>
                ) : null}
                <TableMain className="height3" offerType="buy">
                  <table
                    className="border-cell"
                    width="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="20"
                    style={{ border: "1px solid #1e2127" }}
                  >
                    <thead style={{ fontSize: "12px" }}>
                      <tr>
                        <th align="left" valign="middle" scope="col">
                          Time
                          <div className="sortLink">
                            <i className="fas fa-sort"></i>
                          </div>
                        </th>
                        <th align="left" valign="middle" scope="col">
                          Biding Price
                          <div className="sortLink">
                            <i className="fas fa-sort"></i>
                          </div>
                        </th>
                        <th align="left" valign="middle" scope="col">
                          Trades
                          <div className="sortLink">
                            <i className="fas fa-sort"></i>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersLoading ? (
                        <tr>
                          <td colSpan="9" align="middle">
                            <ReactLoaderSpinner
                              type="Bars"
                              color="#fff"
                              height={50}
                              width={150}
                            />
                          </td>
                        </tr>
                      ) : otcOrders[chainSelected] ? (
                        // filteredOtcOrders.map((ele, key) => {
                        //   const pair =
                        //     defaultPair.split("/")[1] +
                        //     "/" +
                        //     defaultPair.split("/")[0];
                        //   if (ele.pair === pair) {
                        //     return <></>;
                        //   }
                        // })
                        <></>
                      ) : (
                        <tr>
                          <td colSpan="9" align="middle">
                            No transaction
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </TableMain>
              </div>
            </OTCTradsContainer>
          </TabContainer>
        </>
      )}
      <OTCDescription>
        <FaLightbulb
          style={{ width: "16px", height: "16px", marginRight: "10px" }}
        />
        <span>
          OTC is the only place to buy discounted tokens below market price
          without affecting the market price
        </span>
      </OTCDescription>
    </FlexDiv>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;
const Height = styled.div`
  height: 500px;
`;

const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 0;
  position: relative;
  .btn01 {
    width: 155px;
    font-weight: 700;
    height: 40px;
    border: 2px solid #00f02b;
    box-shadow: 0 0 8px #00f02b;
    border-radius: 5px;
    color: #00f02b;
    :hover {
      color: #000;
      background-color: #00f02b;
    }
  }
  .filter-icon {
    display: flex;
    color: #fff;
    align-items: center;
    margin-right: 18px;
    padding: 0 17px;
    border-right: 1px solid #1e2127;
    &.active {
      color: #00f02b;
    }
  }
`;
const FilterBox = styled.div`
  border: 1px solid #1e2127;
  padding: 10px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h4 {
    font-size: 14px;
    color: #9a9a9a;
    margin: 0;
    text-align: left;
  }
  .searchBox {
    position: relative;
    margin: 16px 0;
    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 14px;
    }
  }
  .search {
    width: 100%;
    height: 42px;
    background: none;
    border: 1px solid #1e2127;
    border-radius: 0;
    padding: 0 0 0 40px;
    color: #9a9a9a;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      font-size: 14px;
      list-style: none;
      margin-bottom: 16px;
      text-align: left;
    }
    &.checkList {
      margin-top: 24px;
    }
  }
`;
const FilterMain = styled.div`
  background: #000;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-top: 1px solid #1e2127;
  border-bottom: 1px solid #1e2127;
`;

const MaxMin = styled.div`
  display: flex;
  align-items: center;
  span {
    flex-grow: 1;
    text-align: center;
    margin: 0 5px;
  }
  .textBox {
    height: 42px;
    width: 100%;
    flex-grow: 1;
    background: none;
    border: 1px solid #1e2127;
    border-radius: 0;
    margin: 16px 0;
    color: #9a9a9a;
  }
`;
const Discount = styled.div`
  display: flex;
  // flex-flow: column;
  text-align: center;
  margin-top: 15px;
  align-items: center;
  .textBox {
    height: 42px;
    width: 100%;
    flex-grow: 1;
    background: none;
    border: 1px solid #1e2127;
    border-radius: 0;
    padding: 0 10px;
    color: #fff;
  }
  span {
    margin: 10px 4px;
    font-size: 12px;
  }
  .hasBox {
    position: relative;
  }
  strong {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1e2127;
    color: #9a9a9a;
    &.xl {
      width: 50px;
    }
  }
`;
const ExchangeMain = styled.div`
  display: flex;
  ${Media.lg} {
    align-items: flex-end;
  }
  ${Media.md2} {
    flex-flow: column;
  }
  .ChainsList {
    width: 100px;
    flex-shrink: 0;
    background: #000;
    margin-right: 0;
    border: 1px solid #1e2127;
    height: 625px;
    overflow: auto;
    a {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 90px;
      width: 100%;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
      border-bottom: 1px solid #1e2127;
      &:last-child {
        border-bottom: 0;
      }
      span {
        margin-top: 16px;
        text-align: center;
        font-size: 12px;
      }
      &:hover {
        background: #191c22;
      }
      &.active {
        background: #191c22;
      }
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background: #000;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #1e2127; /* outline: 3px solid #1e2127; */
      box-shadow: inset 0 0 0 3px #000;
    }
    ${Media.md2} {
      flex-flow: row;
      width: 100%;
      display: flex;
      height: auto;
      a {
        width: 100px;
        flex-shrink: 0;
        border-right: 1px solid #1e2127;
        border-bottom: 0px;
        flex-grow: 1;
      }
    }
  }
  .leftCol {
    margin-right: 23px;
    ${Media.md2} {
      width: 100%;
      margin: 0;
    }
  }
  .centerCol {
    width: 435px;
    flex-shrink: 0;
    img {
      width: 21px;
      height: 21px;
      object-fit: contain;
    }
    th {
      text-align: left;
    }
    td {
      padding-left: 14px;
      padding-right: 14px;
    }
    ${Media.lg} {
      width: 353px;
    }
    ${Media.md2} {
      width: 100%;
    }
  }
  .rightCol {
    flex-grow: 1;
    padding-left: 23px;
    max-width: calc(100% - 560px);
    .actionIcon.push {
      margin-left: -30px;
      height: 20px;
      margin-right: 10px;
      transform: scaleX(-1);
      opacity: 0;
      visibility: hidden;
    }
    &.expand {
      padding-left: 0;
      max-width: 100%;
      .actionIcon.push {
        opacity: 1;
        visibility: visible;
      }
    }
    ${Media.lg} {
      width: calc(100% - 600px);
      max-width: inherit;
    }
    ${Media.md2} {
      width: 100%;
      padding: 0;
    }
    ${Media.md} {
      .actionIcon.v2 {
      }
    }
  }
  .clickable {
    :hover {
      cursor: pointer;
    }
  }
`;
const TableMain = styled.div`
  position: relative;
  overflow: auto;
  max-width: 100%;
  max-height: 500px;
  width: 100%;
  margin-top: 15px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #1e2127;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #1e2127; */
    box-shadow: inset 0 0 0 2px #1e2127;
  }
  &.height1 {
    max-height: 281px;
    min-height: 281px;
  }
  &.height2 {
    max-height: 281px;
  }
  &.height3 {
    max-height: 622px;
    min-height: 622px;
  }
  table {
    width: 100%;
    background: #000;
    max-width: 100%;
    border-radius: 5px;
    border: ${(props) =>
      props.offerType === "buy"
        ? " 1px solid #00f02b"
        : props.offerType === "sell"
        ? " 1px solid #8146e4"
        : " 1px solid #9a9a9a"};
    border-collapse: separate;
    thead {
      position: sticky;
      top: 0;
      z-index: 5;
      th {
        position: sticky;
        top: 0;
        left: 0;
        border-top: 1px solid #1e2127;
        border-bottom: 1px solid #1e2127;
        border-right: 1px solid #1e2127;
        &:last-child {
          border-right: 0;
        }
        &.highlighted {
          background: #191c22;
        }
        &.ColSticky {
          right: 0px;
          position: sticky;
          border-right: 1px solid #1e2127 !important;
          padding-right: 10px;
          ${Media.lg} {
            box-shadow: -6px 5px 10px 0 rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    td {
      border-top: 1px solid #1e2127;
      &.highlighted {
        background: #191c22;
      }
    }
    &.border-cell {
      td {
        border-bottom: 0 solid #1e2127;
        border-right: 1px solid #1e2127;
        &:first-child {
          a {
            color: #fff;
            white-space: nowrap;
          }
        }
        &:last-child {
          border-right: 0;
          a:not(.btn02) {
            color: #3131ff;
          }
        }
        &.ColSticky {
          right: 0;
          border-right: 1px solid #1e2127 !important;
          position: sticky;
          background: #000;
          overflow: hidden;
          /* &:after {content: ""; background: #000; z-index: 1; position: absolute; top: 0; left: 0; right: 0; bottom: 0;} */
          ${Media.lg} {
            box-shadow: -6px 5px 10px 0 rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  table tr td {
    padding: 10px 18px;
    color: #fff;
    height: 60px;
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
    &.bor02 {
      color: #4b4bff;
    }
    &.highlighted {
      background: #191c22;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
    .fa-circle {
      font-size: 8px;
      line-height: 16px;
      vertical-align: top;
      margin-right: 8px;
    }
  }

  table tr:hover td {
    background-color: #010202;
  }
  table tr th {
    background-color: #000;
    color: #9a9a9a;
    position: relative;
    padding: 12px;
    height: 60px;
    padding-right: 27px;
    div {
      color: #9a9a9a;
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    &:nth-last-child(01) {
    }
    &:nth-child(01) {
    }
    &.bor01 {
      border-right: 5px solid #545861;
    }
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  .btn01 {
    min-width: 102px;
    white-space: nowrap;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #00f02b;
      box-shadow: 0 0 10px #00f02b;
    }
  }
  .btn02 {
    width: -webkit-fill-available;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #c32b2d;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #c32b2d;
      box-shadow: 0 0 10px #c32b2d;
      cursor: pointer;
    }
  }
`;
const TableTitle = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  font-size: 14px;
  padding-top: 20px;
  ${Media.sm} {
    margin-bottom: 15px;
  }
  h3 {
    font-size: 18px;
    color: #fff;
    margin: 0;
  }
  .color-dull {
    color: #9a9a9a;
  }
  .bold-white {
    color: #fff;
  }
  .rightTitle {
    margin-left: auto;
    .actionIcon {
      height: 20px;
      margin: -2px 0 0;
      vertical-align: top;
      & + span {
        margin-left: 10px;
      }
    }
    ${Media.md} {
      line-height: 1.6;
      &.v2 .actionIcon {
        transform: rotate(-90deg);
      }
    }
    ${Media.sm} {
      &.v2 {
        margin-left: auto;
      }
    }
  }
  ${Media.lg} {
    flex-flow: wrap;
    .leftTitle {
      order: 1;
    }
    .ttTop {
      order: 3;
      margin-top: 13px;
      p {
        &:first-child {
          border-left: 0;
          padding: 0 0 0 0;
          margin: 0 0 0 0;
        }
      }
    }
    .rightTitle {
      order: 2;
    }
  }
  ${Media.sm} {
    .ttTop {
      flex-flow: wrap;
      margin-top: 20px;
      p {
        display: block;
        width: 100%;
        border: 0;
        padding: 0;
        margin: 0 0 10px;
        &:first-child {
          margin: 0 0 10px;
        }
      }
    }
    .rightTitle {
      order: 3;
      margin-left: 0;
      margin-top: 15px;
      /* .actionIcon {display: none;} */
    }
    .color-dull {
      line-height: 1.5;
    }
  }
`;
const Block = styled.div`
  display: block;
`;
const TableBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #9a9a9a;
  padding: 16px 0 0;
  h5 {
    font-size: 12px;
    font-weight: bold;
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
`;
const Tnav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #9a9a9a;

  p {
    color: #9a9a9a;
  }
  a {
    color: #aaa;
    margin: 0 10px;
  }
  a:hover {
    color: #91dc27;
  }
`;
const SelectCustom = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 10px;
  select {
    background: #010202;
    min-width: 80px;
    color: #fff;
    font-size: 12px;
    border: 0;
    height: 30px;
    border-radius: 4px;
    padding: 0 10px;
    appearance: none;
  }
  &:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    color: #aaa;
    font-weight: 900;
    position: absolute;
    right: 5px;
    top: 8px;
  }
`;

const TabNav = styled.div`
  padding-top: 50px;
  flex-direction: row;
  display: flex;
  .tab-Link {
    font-size: 21px;
    font-weight: 700;
    color: #9a9a9a;
    flex-grow: 1;
    text-align: center;
    border-bottom: 3px solid #373b44;
    padding-bottom: 30px;
    position: relative;
    i {
      position: absolute;
      top: -3px;
      margin-left: 3px;
      i {
        font-size: 14px;
        color: #000;
        width: 23px;
        height: 23px;
        background-color: #00f02b;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
    span {
      position: relative;
    }
    ${Media.md} {
      font-size: 18px;
    }
    ${Media.sm} {
      font-size: 14px;
      padding: 15px 0px;
    }
  }
  ${Media.md} {
    display: none;
  }
  ${Media.sm} {
    align-items: stretch;
  }
  .tab-Link.active {
    color: #fff;
    border-bottom-color: #fff;
  }

  .hoverClass:hover span {
    display: none;
  }
  .hoverClass:hover:before {
    content: "Coming Soon";
  }
`;

const Nowrap = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
  i {
    margin-right: 6px;
  }
  ${Media.xs} {
    white-space: normal;
    p {
      span {
        display: block;
      }
    }
  }
`;

const TabContainer = styled.div`
  padding: 50px 0 20px;
  width: 100%;
  .Note {
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #9a9a9a;
    margin-top: 56px;
  }
  .clickable {
    :hover {
      cursor: pointer;
    }
  }
`;

const SpanButton = styled.span.attrs((props) => ({
  name: props.name,
}))`
  cursor: pointer;
`;
const Tabs = styled.div`
    display: flex; margin-bottom: 0; align-items: center; margin-top: 0; margin-right: auto; 
    a { font-weight: bold;
        &.active {color: #00f02b; text-shadow: 0 0 8px #00f02b}
        &.active.color-red {color: #c32b2d}
    }
    span {margin: 0 6px; color: #9a9a9a;}

  .color-dull {
    color: #9a9a9a;
  }
  .color-white {
    color: #fff;
  }
    
    ${Media.sm} {
        margin-top: 0; flex-flow: wrap;
    }
    &.v2 {
        a { color: #5a5e67; font-weight: bold;
            &.active {color: #fff}
        }
    }
    .fas.fa-caret-up {font-size: 16px; color: #fff; margin-left: 30px;}
    button { font-weight: bold;
      color: white;
      &.active {color: #00f02b; text-shadow: 0 0 8px #00f02b}
      &.active.color-red {color: #c32b2d}
    }
  }
`;
const ChartContainer = styled.div`
  background: #000;
  width: 100%;
  border: 1px solid #1e2127;
  padding: 0 30px 15px;
  position: relative;
  margin: 25px 0 40px 0;
  position: relative;
  .apexcharts-tooltip span {
    color: #000;
  }
  .apexcharts-tooltip-title {
    color: #000;
  }
`;
const ProgressMain = styled.div`
  background: #00f02b;
  box-shadow: 0 0 10px #00f02b;
  width: 330px;
  height: 24px;
  margin-left: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 7px;

  ${Media.sm} {
    margin: 15px 0;
  }
`;
const Progress = styled.div`
  background: #8146e4;
  box-shadow: 0 0 10px #8146e4;
  width: ${(props) => props.process + "%"};
  min-width: 45px;
  height: 24px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  &:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 24px;
    left: calc(100% - 7px);
    background: #8146e4;
    transform: skew(-30deg, 0deg);
  }
`;
const CheckBoxes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 18px 0 12px 0;
  .md-checkbox {
    &:not(:last-child) {
      padding-right: 18px;
    }
    label {
      padding-left: 25px;
      font-size: 12px;
      &:before {
        width: 14px;
        height: 14px;
        border-radius: 0;
        top: 2px;
      }
      ${Media.md} {
        margin-bottom: 0px !important;
      }
    }
    input[type="checkbox"]:checked + label:after {
      top: 1px;
      left: 4px;
      width: 15px;
      height: 8px;
      border: 3px solid #fff;
      border-top-style: none;
      border-right-style: none;
      font-weight: bold;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${Media.md2} {
    flex-direction: column;
  }

  ${Media.md} {
    padding: 0;
  }
`;
const Time = styled.div`
  position: absolute;
  bottom: -38px;
  left: 0;
  display: flex;
  b {
    font-size: 14px;
    padding: 0 15px;
    border-right: 1px solid #000;
    border-left: 1px solid #2a2a2a;
    &:last-child {
      border-right: 0 solid #000;
    }
    &:first-child {
      border-left: 0 solid #2a2a2a;
    }
    &.active {
      color: #00f02b;
    }
  }
`;
const TabBox = styled.div`
  background: #000;
  border: 1px solid #1e2127;
  padding: 30px 28px;
  position: relative;
  margin-bottom: 30px;
  h5 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 25px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 36px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #1e2127;
    font-size: 16px;
    cursor: pointer;
  }
`;
const MobileTab = styled(FlexDiv)`
  padding-top: 40px;
  display: none;
  h3 {
    color: #fff;
    font-size: 18px;
    padding: 15px 0px;
    border-bottom: 3px solid #fff;
    width: 100%;
    position: relative;
    margin: 0;
    &:after {
      content: "";
      border-width: 6px;
      border-style: solid;
      border-bottom: 0px;
      -webkit-transition: 0.5s ease all;
      transition: 0.5s ease all;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      right: 0;
      top: 23px;
    }
  }
  .dropDown {
    width: 100%;
    display: flex;
    flex-flow: column;
    a {
      padding: 15px 0px;
      font-size: 14px;
      border-bottom: 1px solid #393d46;
      color: #fff;
    }
  }
  ${Media.md} {
    display: block;
  }
`;
const ButtonGroup = styled.div`
  border-radius: 5px;
  border: 1px solid #1e2127;
  display: flex;
  flex: row;
  .btn {
    padding: 0px 20px;
    width: -webkit-fill-available;
    font-weight: 700;
    height: 35px;
    border-radius: 5px;
    color: #9a9a9a;
    display: inline-block;
    text-align: center;
    border: 2px solid transparent;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #00f02b;
    }
    &.buy {
      color: #00f02b;
    }
    &.sell {
      color: #d22b2b;
    }
  }
`;

const SeachInput = styled.div`
  border-radius: 5px;
  border: 1px solid #1e2127;
  display: flex;
  width: -webkit-fill-available;
  color: #9a9a9a;
  align-items: center;
  padding: 0px 15px;
  .textBox {
    width: -webkit-fill-available;
    background: transparent;
    border: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.buySellViewType === 1 ? "repeat(2,1fr)" : "repeat(4,1fr)"};
  grid-gap: 15px;

  position: relative;
  overflow: auto;
  max-width: 100%;
  max-height: 500px;

  &.height3 {
    max-height: 622px;
    min-height: 622px;
  }

  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #1e2127;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #1e2127; */
    box-shadow: inset 0 0 0 2px #1e2127;
  }
  ${Media.md2} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${Media.xs} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Item = styled.div`
  border-radius: 5px;
  border: ${(props) =>
    props.offerType === "buy" ? "1px solid #00f02b" : "1px solid #8146e4"};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-height: 255px;
  max-height: 255px;
  .btn {
    width: 100%;
    font-weight: 700;
    height: 40px;
    border: ${(props) =>
      props.offerType === "buy" ? "2px solid #00f02b" : "2px solid #8146e4"};
    box-shadow: ${(props) =>
      props.offerType === "buy" ? "0 0 8px #00f02b" : "0 0 8px #8146e4"};
    border-radius: 5px;
    color: #fff;
    :hover {
      background-color: ${(props) =>
        props.offerType === "buy" ? "#00f02b" : "#8146e4"};
    }
  }
`;
const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #1e2127;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  align-items: center;
  color: #9a9a9a;
  i {
    max-width: 80px;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 15px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 64px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }

    &.green {
      input {
        color: #00f02b;
        font-size: 18px;
      }
    }
    &.red {
      input {
        color: #c32b2d;
        font-size: 18px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  &.ApBX01 {
    position: relative;

    .plsBtn {
      position: absolute;
      left: 4px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;
      :hover {
        background-color: #00f02b;
      }
      i {
        margin: 0;
      }
    }
    .minBtn {
      position: absolute;
      left: 54px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      :hover {
        background-color: #c32b2d;
      }
      i {
        margin: 0;
      }
    }

    input {
      padding-left: 120px;
      font-weight: 400;
    }
    span {
      background-color: #5a5e67;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 60px;
      border-radius: 0 6px 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const DSTitle02 = styled(FlexDiv)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  font: 700 24px/40px "Kanit", arial;
  color: #00f02b;
  margin: 22px 0;
`;

const DSTitleNumber = styled(FlexDiv)`
  color: #28282e;
  font-size: 72px;
  margin-right: 20px;
  width: auto;
`;

const ChainListContainer = styled.div`
  width: 100%;
  cursor: pointer;
  margin-bottom: 50px;
  .react-multiple-carousel__arrow--right {
    right: 0% !important;
    top: 30% !important;
  }

  .react-multiple-carousel__arrow--left {
    left: 0% !important;
    top: 30% !important;
  }

  .react-multiple-carousel__arrow {
    min-width: 36px !important;
    min-height: 36px !important;
    background: rgba(0, 0, 0, 1) !important;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 16px !important;
    font-weight: 900 !important;
  }
`;

const ChainButton = styled.button`
  min-width: 275px;
  padding: 20px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  border-radius: 0.5rem;
  color: #ffffff;
  background-image: ${(props) => `linear-gradient(to right, ${props.color});`};
`;

const GradientButton = styled.div`
  padding: 2px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #00f02b, #8146e4);
  border-radius: 0.5rem;

  .pair-button {
    width: 400px;

    ${Media.md3} {
      width: 100%;
    }
  }

  ${Media.md3} {
    width: 100%;
  }
`;

const PairButton = styled.div`
  min-width: 310px;
  background-color: #000;
  color: #fff;
  height: 100%;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;

  ${Media.md3} {
    width: 100%;
  }
`;

const PairContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  grid-gap: 20px;
  position: relative;
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  background-color: #191c22;
  border-radius: 0.5rem;
  align-items: center;

  ${Media.md3} {
    flex-direction: column;
    padding: 20px;
  }
`;

const PairDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  padding-left: 20px;

  .main-value {
    color: #00f02b;
    font-weight: 600;
    font-size: 20px;

    ${Media.md2} {
      font-size: 16px;
    }
  }

  ${Media.md} {
    flex-direction: column;
    padding: 0;
  }
`;

const AverageDetail = styled.div`
  display: grid;
  align-items: start;
  justify-content: flex-start;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-right: 10px;

  ${Media.md2} {
    grid-gap: 10px;
  }

  ${Media.md} {
    width: 100%;
    grid-gap: 10px;
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const LastDetail = styled.div`
  display: grid;
  align-items: start;
  justify-content: flex-start;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  background-color: #000;
  border-radius: 100px;
  border: 1px solid #00f02b;
  padding: 15px 30px;

  ${Media.md2} {
    grid-gap: 10px;
    padding: 15px;
  }

  ${Media.md2} {
    width: 100%;
  }
`;

const CreateNewOfferButton = styled.button`
  color: ${(props) => (props.offerType === "buy" ? "#00f02b;" : "#8146e4;")};
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  border: ${(props) =>
    props.offerType === "buy" ? " 1px solid #00f02b;" : " 1px solid #8146e4;"};
  background-color: transparent;
  :hover {
    background-color: ${(props) =>
      props.offerType === "buy" ? "#00f02b;" : "#8146e4;"};
    color: #fff;
  }
`;

const AcceptOfferButton = styled.button`
color: ${(props) => (props.offerType === "buy" ? "#00f02b;" : "#8146e4;")};
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 5px;
  border: ${(props) =>
    props.offerType === "buy" ? " 1px solid #00f02b;" : " 1px solid #8146e4;"};
  background-color: #000;
  box-shadow: ${(props) =>
    props.offerType === "buy" ? "0 0 8px #00f02b" : "0 0 8px #8146e4"};
  :hover {
    background-color: ${(props) =>
      props.offerType === "buy" ? "#00f02b;" : "#8146e4;"};
    color: #fff;
`;

const FilterButton = styled.button`
	color: ${(props) => (props.buttonType === "apply" ? "#00f02b;" : "#999999;")};
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 5px;
	width: 100%;
	font-weight: 700;
	font-size: 14px;
  border: ${(props) =>
    props.buttonType === "apply"
      ? " 1px solid #00f02b;"
      : " 1px solid #999999;"};
  background-color: #000;
  box-shadow: ${(props) =>
    props.buttonType === "apply" ? "0 0 8px #00f02b" : "0 0 8px #999999"};
  :hover {
    background-color:${(props) =>
      props.buttonType === "apply" ? "#00f02b" : "#999999"};
    color: ${(props) => (props.buttonType === "apply" ? "#fff" : "#000")};
`;

const PairTitle = styled.span`
  color: #909090;
  :hover {
    color: #fff;
  }
`;

const FilterIconButton = styled.button`
  color: #909090;
  :hover {
    color: #00f02b;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;

  ${Media.md3} {
    display: none;
  }
`;

const FilterTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  border: 1px solid #1e2127;
`;

const ChainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 5px;
  min-height: 220px;
  margin: 0px 1 0px;
  width: 300px;
  :hover {
    background-color: #191c22;
  }
`;

const ChainSelectButton = styled.button`
  margin-top: 15px;
  color: #00f02b;
  box-shadow: 0 0 8px #00f02b;
  border: 2px solid #00f02b;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  &.chain-select-button {
    display: none;
  }
  ${ChainContainer}:hover & {
    display: block;
  }

  :hover {
    background-color: #00f02b;
    color: #fff;
  }
`;

const OrderBookTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .view {
    width: 350px;
    ${Media.sm} {
      width: 197px;
    }
  }

  ${Media.sm} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: 30px;
  }
`;

const OrderBookContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.buySellViewType === 1 ? "repeat(2,1fr)" : "repeat(1,1fr)"};
  grid-gap: ${(props) => (props.buySellViewType === 1 ? "15px" : "0")};

  ${Media.md2} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const OTCTradsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  ${Media.md2} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const PairButtonContainer = styled.div`
  position: relative;
  ${Media.md3} {
    width: 100%;
  }
`;

const PairListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${Media.md3} {
    width: 100%;
  }
`;

const OTCDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  margin: 50px 0;
  width: 100%;
  justify-content: center;
`;

export default NewOtcExchange;

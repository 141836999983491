import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Component/header";
import SnackbarProvider from "react-simple-snackbar";
import OTC from "./Pages/OTC";
import Gateway from "./Pages/Gateway";
import BuyTokenFromGateway from "./Pages/BuyTokenFromGateway";
import RouterPage from "./Pages/RouterPage";
import Governance from "./Pages/Governance";
import Unfreeze from "./Pages/Unfreeze";
import FundsIncentive from "./Pages/FundAndIncentive";
import AddProject from "./Pages/AddProject";
import AddProjectTokens from "./Pages/AddProjectTokens";
import Gs from "./Theme/globalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "./Theme/theme";
import Footer from "./Component/footer";
import { metamask_connection } from "./Service/metamask_function";
import { getDegenPrice } from "./Service/otc_function";
import OTCNEW from "./Pages/OTCNew";

const { useState } = React;

const selectedTheme = theme(true);
function App() {
  const [isHead, setIsHead] = useState(false);

  function changeHead(flag) {
    setIsHead(flag);
  }
  return (
    <Router basename={""}>
      {/*  basename={'Demo/governance'}   */}
      <ThemeProvider theme={selectedTheme}>
        <SnackbarProvider>
          <section className="MainBox clearfix">
            <Gs.GlobalStyle />
            {isHead ? null : <Header />}
            <Gs.MainBox>
              <Switch>
                <Route path="/" exact>
                  {" "}
                  <FundsIncentive changeHead={changeHead} />{" "}
                </Route>
                {/* <Route path="/otc-old" exact>
                  {" "}
                  <OTC />
                </Route> */}
                <Route path="/otc" exact>
                  <OTCNEW />
                </Route>
                {/* <Route path="/Router" exact> <RouterPage />  </Route> */}
                {/* <Route path="/Unfreeze" exact> <Unfreeze />  </Route> */}
                {/* <Route path="/Governance" exact> <Governance />  </Route> */}
                {/* <Route path="/Gateway" exact> <Gateway />  </Route> */}
                {/* <Route path="/buy-tokens" exact> <BuyTokenFromGateway />  </Route> */}
                {/* <Route path="/Router" exact> <RouterPage />  </Route>
                <Route path="/Unfreeze" exact> <Unfreeze />  </Route>
                <Route path="/Governance" exact> <Governance />  </Route>
                <Route path="/fundsincentive" exact> <FundsIncentive changeHead={changeHead} />  </Route>
                <Route path="/addproject" exact> <AddProject />  </Route> */}
                <Route path="/jude_testing_governance" exact> <Governance />  </Route>
                <Route path="/addprojecttokens" exact>
                  {" "}
                  <AddProjectTokens />{" "}
                </Route>
                {/* <Route path="/">
                  {" "}
                  <OTC />{" "}
                </Route> */}
              </Switch>
            </Gs.MainBox>
            {isHead ? null : <Footer />}
          </section>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Collapse from "@kunukn/react-collapse";
import Logo from "./../Assets/images/dumperShield-Logo.png";
import CloseImg from "./../Assets/images/close-icon02.png";
import { Link } from "react-router-dom";
import AstICO01 from "./../Assets/images/assIcon-01.png";
import AstICO02 from "./../Assets/images/assIcon-02.png";
import AstICO03 from "./../Assets/images/assIcon-03.png";
import AstICO04 from "./../Assets/images/assIcon-04.png";
import new_token from "./../Assets/images/new_token.png";
import Icon1 from "./../Assets/images/icon.png";
import {
  getAccountaddress,
  metamask_connection,
} from "../Service/metamask_function";
import notificationConfig from "../config/notificationConfig";
import Media from "../Theme/media-breackpoint";
import { GlobalContext, GlobalContextProvider } from "../Context/globalContext";
import TokenDetails from "./TokenDetails";
import DepositRedeem from "./popup/depositRedeem";
import ReactLoaderSpinner from "react-loader-spinner";
import BuyReqPopup from "./popup/buyreqpopup";
import { BASE_API_HOST } from "../constants";
import { PiAlignRightSimple } from "react-icons/pi";

function Header() {
  const [index, setIndex] = useState(1);
  const [active, setActive] = useState(true);
  const globalContext = useContext(GlobalContext);
  const [balances, setBalances] = useState({ available: 0, total: 0 });
  const [vestingDate, setVestingDate] = useState(null);
  const [vestedTokens, setVestedTokens] = useState(false);
  const [tokenList, setTokenList] = useState(null);
  const [tokenListLoading, setTokenListLoading] = useState(true);
  const [otcPairs, setOtcPairs] = useState({});
  const [canDepositWithdraw, setCanDepositWithdraw] = useState(false);
  const [depositWithdrawToken, setDepositWithdrawToken] = useState(null);
  const [popupOTCClaim, setPopupOTCClaim] = useState(false);
  const [currencyPrices, setCurrencyPrices] = useState({});

  const toggleClass = () => {
    const currentState = active;
    setActive(!currentState);
  };

  useEffect(async () => {
    // console.log("called-------------------------------------------------")
    // setTimeout(async () => {
    // await setModifiedAddress()
    // console.log(globalContext.tokenList)

    if (globalContext.tokenList !== null) {
      await setTokenList(globalContext.tokenList);
    }
  });

  useEffect(async () => {
    await setModifiedAddress();
    // if (tokenList !== undefined && tokenList !== null)
    //   await setTokenListLoading(false);
  }, [tokenList]);

  useEffect(async () => {
    if (globalContext.currencyPrices !== null) {
      await setCurrencyPrices(globalContext.currencyPrices);
    }
  }, [globalContext.currencyPrices]);

  const connectWallet = async () => {
    await metamask_connection(localStorage.getItem("account"), "ahrefClick");
    setTimeout(async () => {
      await setModifiedAddress();
    }, 1000);
  };

  const setModifiedAddress = async () => {
    // console.log("===============================here-it-is-called=======================================")
    const { isWalletConnected, walletAddress } = globalContext;
    if (isWalletConnected) {
      let tokenListUpdate = tokenList;
      await setTokenList(tokenListUpdate);
    }
  };

  const onToggle = (indexnew) => {
    setIndex(index === indexnew ? null : indexnew);
  };

  const updateGlobalContext = () => {
    globalContext.fetchTokenList();
  };

  useEffect(async () => {
    await fetchOrders();
    // await startOrderInterval();
    // window.addEventListener("focus", async () => {
    //   await startOrderInterval();
    // });
  }, [tokenList]);

  const startOrderInterval = async () => {
    let createdInterval = setInterval(async () => {
      await fetchOrders();
    }, 5000);
    window.addEventListener("blur", async () => {
      await clearInterval(createdInterval);
    });
  };

  const fetchOrders = async () => {
    if (tokenList !== undefined && tokenList !== null) {
      await fetch(BASE_API_HOST + "/otcRoute/getAllOTCMarketPlaceOrders")
        .then((res) => res.json())
        .then(async (result) => {
          let otcOrders = result.result.filter(
            (token) => token.chainId !== 820
          );
          let pairs = [];
          for (var i = 0; i < otcOrders.length; i++) {
            let currentOrder = otcOrders[i];
            let currentPair =
              (tokenList[currentOrder.chainId][currentOrder.tokenSell]
                ? tokenList[currentOrder.chainId][currentOrder.tokenSell].symbol
                : "") +
              "/" +
              (tokenList[currentOrder.chainId][currentOrder.wantToken]
                ? tokenList[currentOrder.chainId][currentOrder.wantToken].symbol
                : "");
            pairs.push(currentPair);
          }
          setOtcPairs(pairs);
          await setTokenListLoading(false);
        });
    }
  };

  return (
    <>
      <Mainheadbox className="wow fadeInDown" data-wow-delay="0.1s">
        <LogoBX>
          {" "}
          <Link to={"/"}>
            {" "}
            <img src={Logo} />{" "}
          </Link>{" "}
        </LogoBX>
        {/* <HeaderNew>
          <div className='vltBal'>
            <span><i className="fas fa-minus-circle"></i>Vault balance<i className="fas fa-plus-circle"></i></span>
            <p><img src={Icon1} width={8} />3.563 [$16.65K]</p>
          </div>
          <div className='headerarrow'><i className="fas fa-angle-double-left"></i></div>
          <div className='deposit'>
            <span>Deposit to the vault<span data-tip='Help Text here' className="helpIco"><i className="fas fa-question-circle"></i></span></span>
            <p>Gain 25.53% APY</p>
          </div>
          <div className='wallet'>
            <span>0xe39...075D</span>
            <p><img width={8} src={Icon1} />3.563 [$16.65K]</p>
          </div>
          <HeaderDots><span/><span/><span/></HeaderDots>
        </HeaderNew> */}
        {globalContext.shortWalletAddress !== null ? (
          <HeadRightBX>
            {globalContext.shortWalletAddress}
            <MainLogBTN
              onClick={() => toggleClass()}
              onMouseEnter={() => toggleClass()}
            >
              {/* <Dot01></Dot01>
              <Dot01></Dot01>
              <Dot01></Dot01> */}

              <i
                className="fas fa-wallet"
                style={{ fontSize: "23px", color: "white" }}
              ></i>
            </MainLogBTN>
          </HeadRightBX>
        ) : (
          <HeadRightBX>
            <button onClick={() => connectWallet()} style={{ color: "white" }}>
              Connect wallet
            </button>
          </HeadRightBX>
        )}
      </Mainheadbox>

      <SideBar className={active ? "menuOpen" : null}>
        <CloseBTN onClick={() => toggleClass()}>
          {" "}
          <img src={CloseImg} />{" "}
        </CloseBTN>
        <WalletTxt>
          {/* {walletAddress} */}
          {globalContext.walletAddress}
        </WalletTxt>
        <TabLinkbx>
          <button
            className={index === 1 ? "active" : ""}
            onClick={() => onToggle(1)}
          >
            ASSETS
          </button>
          <button
            className={index === 2 ? "active" : ""}
            onClick={() => onToggle(2)}
          >
            ACTIVITY
          </button>
        </TabLinkbx>
        <Collapse
          className={"collapse noAni " + (index === 1 ? "active" : "")}
          isOpen={index === 1}
          onChange={({ state }) => {}}
          onInit={({ state }) => {}}
        >
          <TabContainer>
            <TabCBX01 style={{ justifyContent: "center" }}>
              {!tokenListLoading ? (
                Object.entries(tokenList).map((pairWiseList, key) => {
                  return Object.entries(pairWiseList[1]).map((ele, keyin) => {
                    if (ele[1].dumperShieldToken)
                      return (
                        <TokenDetails
                          ele={ele}
                          otcPairs={otcPairs}
                          key={keyin}
                          setDeactive={() => setActive(false)}
                          setPopupOTCClaim={() => {
                            setPopupOTCClaim(true);
                          }}
                          handleDepositTokens={(depositWithdrawData) => {
                            setCanDepositWithdraw(true);
                            setDepositWithdrawToken(depositWithdrawData);
                          }}
                        />
                      );
                  });
                })
              ) : (
                <ReactLoaderSpinner
                  type="Bars"
                  color="#fff"
                  height={100}
                  width={80}
                />
              )}
              {/* {!tokenListLoading ?
                Object.entries(tokenList).map((ele) => {
                  return <button key={ele[1].token}>
                    <i className="ImgBX"> <img src={ele[1].logoURI !== "" ? ele[1].logoURI : new_token} /> </i>
                    <VuttinRight>
                      <div className="BTNText">
                        <DFlex>{ele[1].symbol}<p>[${ele[1].price}]</p></DFlex>
                        <DFlex2>Vesting due date: <p>{ele[1].dsUnlockDateUTC}</p></DFlex2>
                        <DFlex2>Vesting tokens:<span data-html='true' data-tip='test' className="helpIco"><i className="fas fa-question-circle"></i></span> <p>{Number(ele[1].balances.total) / 10 ** 18}</p></DFlex2>
                        <DFlex2>Total value:<span data-html='true' data-tip='test' className="helpIco"><i className="fas fa-question-circle"></i></span><p>$0</p></DFlex2>
              
                        <DFlex2>Vested tokens: <p>{ele[1].canRedeem ? Number(ele[1].balances[0]) / 10 ** 18 : 0}</p></DFlex2>
                        <LinksOuter>
                          <a href="#" className='green'>Deposit</a>
                          {ele[1].canRedeem ? <a href="#" className='red' onClick={() => { handleWithdrawTokens(ele[1].token, ele[1].balances); }}>Redeem vested tokens</a> : <a href="#" className='red'>No vested tokens available</a>}
                        </LinksOuter>
                      </div>
              
                    </VuttinRight>
                  </button>
                })
                : null} */}

              {/* <button>
                  <i className="ImgBX"> <img src={AstICO02} /> </i>
                  <div className="BTNText">
                    <DFlex>DEGEN<span>[$0.1435]</span></DFlex>
                    <span>1,000,000</span>
                  </div>
                  <i className="fas fa-chevron-right"></i>
                </button>
                <button>
                  <i className="ImgBX"> <img src={AstICO03} /> </i>
                  <div className="BTNText">
                    <DFlex>FREEZ<span>[$0.1435]</span></DFlex>
                    <span>42,859,6593</span>
                  </div>
                  <i className="fas fa-chevron-right"></i>
                </button>
                <button>
                  <i className="ImgBX"> <img src={AstICO04} /> </i>
                  <div className="BTNText">
                    <DFlex>JNTR<span>[$0.1435]</span></DFlex>
                    <span>10,859,000,8596</span>
                  </div>
                  <i className="fas fa-chevron-right"></i>
                </button>  */}
            </TabCBX01>
          </TabContainer>
        </Collapse>
        <DepositRedeem
          isOpen={canDepositWithdraw}
          tokenDetails={depositWithdrawToken}
          dismiss={() => {
            setCanDepositWithdraw(false);
          }}
        ></DepositRedeem>
        <Collapse
          className={"collapse noAni " + (index === 2 ? "active" : "")}
          isOpen={index === 2}
          onChange={({ state }) => {}}
          onInit={({ state }) => {}}
        >
          <TabContainer>
            <TabCBX01>
              <button className="v2">
                <div className="BTNText">
                  {" "}
                  Send <p>1000 SMART</p>{" "}
                </div>
                <DFlex2>
                  Date:<p className="v2">August 10, 2021 12:44:31:25 UTC</p>
                </DFlex2>
                <DFlex2>
                  Tx proof:<p className="v2 text-blue">0xf9c...c4c3</p>
                </DFlex2>
              </button>
              <button className="v2">
                <div className="BTNText">
                  {" "}
                  Receive <p>253 DEGEN</p>{" "}
                </div>
                <DFlex2>
                  Date:<p className="v2">August 10, 2021 12:44:31:25 UTC</p>
                </DFlex2>
                <DFlex2>
                  Tx proof:<p className="v2 text-blue">0xf9c...c4c3</p>
                </DFlex2>
              </button>
              <button className="v2">
                <div className="BTNText">
                  {" "}
                  Sell <p>1000 FREEZ</p>{" "}
                </div>
                <DFlex2>
                  Date:<p className="v2">August 10, 2021 12:44:31:25 UTC</p>
                </DFlex2>
                <DFlex2>
                  Tx proof:<p className="v2 text-blue">0xf9c...c4c3</p>
                </DFlex2>
              </button>
              <button className="v2">
                <div className="BTNText">
                  {" "}
                  Buy <p>500 DEGEN</p>{" "}
                </div>
                <DFlex2>
                  Date:<p className="v2">August 10, 2021 12:44:31:25 UTC</p>
                </DFlex2>
                <DFlex2>
                  Tx proof:<p className="v2 text-blue">0xf9c...c4c3</p>
                </DFlex2>
              </button>
              <button className="v2">
                <div className="BTNText">
                  {" "}
                  Deposit <p>500 DEGEN</p>{" "}
                </div>
                <DFlex2>
                  Date:<p className="v2">August 10, 2021 12:44:31:25 UTC</p>
                </DFlex2>
                <DFlex2>
                  Tx proof:<p className="v2 text-blue">0xf9c...c4c3</p>
                </DFlex2>
              </button>
              <button className="v2">
                <div className="BTNText">
                  {" "}
                  Redeem <p>500 DEGEN</p>{" "}
                </div>
                <DFlex2>
                  Date:<p className="v2">August 10, 2021 12:44:31:25 UTC</p>
                </DFlex2>
                <DFlex2>
                  Tx proof:<p className="v2 text-blue">0xf9c...c4c3</p>
                </DFlex2>
              </button>
            </TabCBX01>
          </TabContainer>
        </Collapse>

        <div className="closerBX" onClick={() => toggleClass()}></div>
      </SideBar>
      <BuyReqPopup
        isOpen={popupOTCClaim}
        dismiss={() => {
          setPopupOTCClaim(false);
        }}
        newOfferType={1}
        currencyPrices={currencyPrices}
      />
    </>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const Mainheadbox = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 79px;
  background-color: ${(props) => props.theme.headbg01};
  z-index: 1000;
  border-bottom: 1px solid #1e2127;
  padding: 0 30px;
  ${Media.sm} {
    padding: 0px 10px 0px 20px;
  }
`;

const LogoBX = styled.div`
  a {
    display: inline-block;
  }
  img {
    max-width: 100%;
    height: auto;
    ${Media.sm} {
      width: 200px;
    }
  }
  ${Media.sm} {
    margin-right: 15px;
  }
`;

const HeadRightBX = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const SideBar = styled(FlexDiv)`
  width: 742px;
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  background-color: #16191e;
  border-left: 1px solid #000;
  z-index: 10000;
  align-content: baseline;
  overflow-y: auto;
  transition: 0.6s ease-in-out;
  visibility: hidden;
  .closerBX {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - 742px);
  }
  &.menuOpen {
    right: 0;
    visibility: visible;
    .closerBX {
      display: block;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    background: #545861;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #545861; */
    box-shadow: inset 0 0 0 2px #545861;
  }
  ${Media.sm} {
    width: 100%;
  }
`;

const WalletTxt = styled(FlexDiv)`
  justify-content: flex-start;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  padding: 30px 25px;
  color: white;
  margin-bottom: 30px;
  ${Media.sm} {
    padding: 30px 40px 30px 25px;
  }
`;

const CloseBTN = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 14px;
  top: 27px;
  padding: 0;
  margin: 0;
  transition: 0.4s linear;
  :hover {
    transform: rotate(180deg);
  }
`;
const TabLinkbx = styled(FlexDiv)`
  button {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    line-height: 52px;
    border-bottom: 2px solid #000000;
    width: 50%;
    &:hover,
    &.active {
      color: #00f02b;
      border-bottom: 2px solid #00f02b;
    }
  }
`;
const TabContainer = styled(FlexDiv)``;
const TabCBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  button {
    width: 50%;
    min-height: 86px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 22px 0 22px 26px;
    position: relative;
    /* :hover{ background-color:#1d2229;}   */
    i.ImgBX {
      max-width: 46px;
      width: 100%;
      height: 46px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-right: 14px;
      margin-top: 0;
      flex-shrink: 0;
    }
    .BTNText {
      margin: -4px 0 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      color: #fff;
      flex-direction: column;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #9a9a9a;
        top: 0;
        margin-right: 5px;
      }
      &.v2 {
        margin-left: auto;
        span {
          color: #fff;
          padding-top: 21px;
        }
      }
    }
    .fa-chevron-right {
      font-size: 20px;
      color: #fff;
      margin-left: auto;
    }
    &.v2 {
      flex-direction: column;
      width: 100%;
      padding: 22px 26px;
      border-bottom: 1px solid #000;
      :hover {
        background-color: #1d2229;
      }
      .BTNText {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 9px;
        p {
          margin: 0px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
`;

const MainLogBTN = styled.button`
  width: 32px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 6px;
  span:nth-child(01) {
    width: 8px;
    height: 8px;
    margin: 2px 0;
  }
  span:nth-child(02) {
    width: 6px;
    height: 6px;
    margin: 2px 0;
  }
  span:nth-child(03) {
    width: 4px;
    height: 4px;
    margin: 2px 0;
  }
`;
const HeaderNew = styled.div`
  display: flex;
  align-items: center;
  .wallet {
    padding: 0 30px;
    margin-left: 10px;
    border-left: 1px solid #1c1f25;
    text-align: center;
    span {
      font-size: 14px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 3px 0 0;
      justify-content: center;
    }
    b {
      color: #9a9a9a;
    }
    img {
      margin-right: 7px;
    }
  }
  .deposit {
    padding: 0 20px;
    text-align: center;
    span {
      font-size: 14px;
      font-weight: bold;
      color: #00f02b;
    }
    p {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 3px 0 0;
      justify-content: center;
    }
    b {
      color: #9a9a9a;
    }
    img {
      margin-right: 7px;
    }
  }
  .headerarrow {
    font-size: 18px;
    color: #00f02b;
  }
  .vltBal {
    padding: 0 20px;
    text-align: center;
    span {
      font-size: 14px;
      font-weight: bold;
      i.fa-plus-circle {
        margin-left: 6px;
        color: #00f02b;
      }
      i.fa-minus-circle {
        margin-right: 6px;
        color: #c32b2d;
      }
    }
    p {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 3px 0 0;
      justify-content: center;
    }
    b {
      color: #9a9a9a;
    }
    img {
      margin-right: 7px;
    }
  }
  ${Media.md} {
    display: none;
  }
`;
const HeaderDots = styled.button`
  width: 32px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 6px;
  span {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 6px;
    background-color: #00f02b;
    position: relative;
    padding: 0;
    margin: 0 0 0 40px;
  }
  span:nth-child(01) {
    width: 8px;
    height: 8px;
    margin: 2px 0;
  }
  span:nth-child(02) {
    width: 6px;
    height: 6px;
    margin: 2px 0;
  }
  span:nth-child(03) {
    width: 4px;
    height: 4px;
    margin: 2px 0;
  }
`;

const Dot01 = styled.span`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 6px;
  background-color: #00f02b;
  position: relative;
  padding: 0;
  margin: 0 0 0 40px;
  animation: pulse 2s infinite;
  border: none;
  outline: none;
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
      box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
      box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
      box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
    }
  }
`;

const VuttinRight = styled(FlexDiv)``;

const DFlex = styled(FlexDiv)`
  justify-content: space-between;
  margin: 0px 0px 5px;
  // span{margin-left:8px;}
  p {
    font-size: 12px;
    font-weight: 400;
    color: #9a9a9a;
    text-align: left;
    margin: 0;
    width: 40%;
    margin-left: auto;
  }
`;

const DFlex2 = styled(FlexDiv)`
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
  margin: 0px 0px 5px;
  p {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin: 0;
    width: 40%;
    margin-left: auto;
    &.v2 {
      width: auto;
      margin: 0;
    }
    &.text-blue {
      color: #2e2eff;
    }
  }
  span {
    font-size: 11px !important;
    font-weight: 400;
  }
`;

const LinksOuter = styled(FlexDiv)`
  justify-content: flex-start;
  a {
    font-size: 12px;
    font-weight: 700;
    position: relative;
    margin-right: 20px;
    &.green {
      color: #00f02b;
    }
    &.red {
      color: #c32b2d;
    }
    :after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: #9a9a9a;
      position: absolute;
      right: -11px;
      top: 0;
    }
    :last-child {
      :after {
        display: none;
      }
    }
  }
`;

export default Header;

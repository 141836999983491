import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetTime }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  useEffect(() => {
    const updateTimer = () => {
      const currentTime = new Date().getTime();
      const difference = targetTime - currentTime;

      if (difference <= 0) {
        clearInterval(timerInterval);
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        return;
      }

      const hours = Math.floor((difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((difference % (60 * 1000)) / 1000);
      const milliseconds = difference % 1000;

      setTimeLeft({ hours, minutes, seconds, milliseconds });
    };

    const timerInterval = setInterval(updateTimer, 1); // Update every millisecond

    return () => clearInterval(timerInterval); // Cleanup interval on component unmount
  }, [targetTime]);

  return (
    
    //   <h1>Time Remaining:</h1>
    //   <p>Hours: {timeLeft.hours}</p>
    //   <p>Minutes: {timeLeft.minutes}</p>
    //   <p>Seconds: {timeLeft.seconds}</p>
    //   <p>Milliseconds: {timeLeft.milliseconds}</p>
    <div className='tableTimer'>
        {
        timeLeft.hours.toString().length > 1 ?
        <>
            <span>{timeLeft.hours.toString().charAt(0)}</span>
            <span>{timeLeft.hours.toString().charAt(1)}</span>
        </> :
        <>
            <span>0</span>
            <span>{timeLeft.hours.toString().charAt(0)}</span>
        </>
        }
        :
        {
        timeLeft.minutes.toString().length > 1 ?
        <>
            <span>{timeLeft.minutes.toString().charAt(0)}</span>
            <span>{timeLeft.minutes.toString().charAt(1)}</span>
        </> :
        <>
            <span>0</span>
            <span>{timeLeft.minutes.toString().charAt(0)}</span>
        </>
        }
        :
        {
        timeLeft.seconds.toString().length > 1 ?
        <>
            <span>{timeLeft.seconds.toString().charAt(0)}</span>
            <span>{timeLeft.seconds.toString().charAt(1)}</span>
        </> :
        <>
            <span>0</span>
            <span>{timeLeft.seconds.toString().charAt(0)}</span>
        </>
        }
        :
        {
        timeLeft.milliseconds.toString().length > 1 ?
        <>
            <span>{timeLeft.milliseconds.toString().charAt(0)}</span>
            <span>{timeLeft.milliseconds.toString().charAt(1)}</span>
        </> :
        <>
            <span>0</span>
            <span>{timeLeft.milliseconds.toString().charAt(0)}</span>
        </>
        }
    </div>
  );
};

export default CountdownTimer;

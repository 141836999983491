import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import Media from '../Theme/media-breackpoint';
import AtomLogo from '../Assets/images/atom-logo.png';
import PDOLogo from '../Assets/images/pdo-logo.png'; 

const scrollToTop = () =>{
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
            in place of 'smooth' */
    });
};

const Footer = () => {
    return (
        <FooterBody> 
            <FooterLeft>
                <p>Powered by</p>
                <img src={AtomLogo} alt='' />
                {/* <span></span> */}
                {/* <img src={PDOLogo} alt='' /> */}
            </FooterLeft>
            <Link to="#" onClick={scrollToTop} ><i className="fas fa-caret-square-up"></i> Go to Top</Link> 
        </FooterBody>
    )
}

const FooterBody = styled.div`
    display:flex; align-items: center; justify-content:space-between; background-color:#000000; height:100px; margin:0; border-top:1px solid #1e2127;  width:100%; padding:0 30px; 
    a{ color:#00f02b; font-size:14px; font-weight:400; :hover{ text-decoration:underline;}}
    ${Media.md} {
        height:80px; padding:0px 20px;
    }
    ${Media.sm} {
        flex-flow: column; height: auto; padding: 30px 20px 34px;
    }
`;

const FooterLeft = styled.div`
    display:flex; align-items: center; justify-content:center;
    p{margin:0px 15px 0px 0px; font-size:12px; font-weight:bold; color:#9a9a9a; text-align:left;
        ${Media.sm} {
            margin:0px 10px 0px 0px;
        }
    }
    span{width:1px; height:27px; background-color:#1e2127; margin:0px 18px;
        ${Media.sm} {
            margin:0px 10px;
        }
    }
    ${Media.md} {
        /* display:none; */
    }
    ${Media.sm} {
        padding:0 0px 20px;
    }
`;

export default Footer
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PriceSupplyLineChart({ priceData, supply, marketPrices }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        // text: "Chart.js Bar Chart",
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 350000,
        },
      },
    },
  };
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Buy",
        data: [],
        borderColor: "#00f02b",
        backgroundColor: "rgba(0, 240, 43, 0.5)",
        borderWidth: 2,
        stepped: true,
        fill: true,
      },
      {
        label: "Sales",
        data: [],
        borderColor: "#8146e4",
        backgroundColor: "rgba(129, 70, 228, 0.5)",
        stepped: true,
        borderWidth: 2,
        fill: "-1",
      },
    ],
  });

  useEffect(() => {
    if (priceData.length > 0 && supply.length > 0 && marketPrices.length > 0)
      setData({
        labels: priceData.sort().map((ele) => {
          return Number(ele).toFixed(4);
        }),
        datasets: [
          {
            label: "Buy",
            data: supply.sort(),
            borderColor: "#00f02b",
            backgroundColor: "rgba(0, 240, 43, 0.5)",
            borderWidth: 2,
            stepped: true,
            fill: true,
          },
          {
            label: "Sales",
            data: marketPrices.sort(),
            borderColor: "#8146e4",
            backgroundColor: "rgba(129, 70, 228, 0.5)",
            stepped: true,
            borderWidth: 2,
            fill: "-1",
          },
        ],
      });
  }, [priceData, supply, marketPrices]);

  return <Line options={options} data={data} height={"100px"} />;
}

export default PriceSupplyLineChart;

export const theme = (isDarkTheme) => {
    return {


        // text colors
        color01: isDarkTheme ? '#fff': '#000',


        // background colors
        bodybg01: isDarkTheme ? '#0b0c0f': '#fff',
        headbg01: isDarkTheme ? '#010602': '#fff',










    }
}
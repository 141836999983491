import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class AskBidBarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            // text: "Chart.js Bar Chart",
          },
        },
        scales: {
          y: {
            ticks: {
              stepSize: 350000,
            },
          },
        },
      },
      data: {
        labels: [],
        datasets: [
          {
            label: "Buy",
            data: [],
            backgroundColor: "#00f02b",
          },
          {
            label: "Sales",
            data: [],
            backgroundColor: "#8146e4",
          },
        ],
      },
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.priceData !== this.state.seriesOwn) {
      this.setState({
        seriesOwn: newProps.priceData,
      });
      // console.log(newProps);
      this.setState({
        data: {
          labels: newProps.priceData.sort().map((ele) => {
            return Number(ele).toFixed(4);
          }),
          datasets: [
            {
              label: "Buy",
              data: newProps.supply.sort(),
              backgroundColor: "#00f02b",
              barThickness: 25,
              maxBarThickness: 25,
            },
            {
              label: "Sales",
              data: newProps.marketPrices.sort(),
              backgroundColor: "#8146e4",
              barThickness: 25,
              maxBarThickness: 25,
            },
          ],
        },
      });
    }
  }

  render() {
    return (
      <Bar
        options={this.state.options}
        data={this.state.data}
        height={"100px"}
      />
    );
  }
}

export default AskBidBarChart;

import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import LeftMBX from "../Component/leftBar";
import DumperShieldStatus from "../Component/popup/dumperShieldStatus";
import Media from "../Theme/media-breackpoint";
import Collapse from "@kunukn/react-collapse";
import {
  CHAINS,
  contractAddresses,
  contractAddressesChainWise,
  ZERO_ADDRESS,
} from "../constants";
import notificationConfig from "../config/notificationConfig";
import Web3 from "web3";
import {
  approveTokenTransfer,
  checkTokenListed,
  createDumperShield,
  getTokenDetails,
  otcDepositTokens,
} from "../Service/otc_function";
import ReactLoaderSpinner from "react-loader-spinner";
import {
  changeNetwork,
  getAccountaddress,
  metamask_connection,
} from "../Service/metamask_function";
import { GlobalContext } from "../Context/globalContext";
const Moralis = require("moralis").default;

function AddProjectTokens(props) {
  const [account, setAccount] = useState(null);
  const [index, setIndex] = useState(1);
  const [dropDown, setDropDown] = useState(false);
  const [popup01, setPopup01] = useState(false);
  const [token, setToken] = useState("");
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [chainSelectedCreate, setChainSelectedCreate] = useState(null);
  const [chainSelectedDeposit, setChainSelectedDeposit] = useState(null);
  const [depositToken, setDepositToken] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [manual_router, setManualRouter] = useState(ZERO_ADDRESS);
  const [unlockDate, setUnlockDate] = useState(null);
  const [dao, setDao] = useState(ZERO_ADDRESS);
  const [createDumperShieldLoading, setCreateDumperShieldLoading] =
    useState(false);
  const [depositTokensLoading, setDepositTokensLoading] = useState(false);
  const [fetchedTokenDetails, setFetchedTokenDetails] = useState(null);
  const [userTokenList, setUserTokenList] = useState(null);
  const [position, setPosition] = useState(null);
  const [tokenList, setTokenList] = useState(null);
  const [approveTokenTransferLoading, setApproveTokenTransferLoading] =
    useState(false);
  const [enableCustomToken, setEnableCustomToken] = useState(false);
  const [fetchTokenDetailsLoading, setFetchTokenDetailsLoading] =
    useState(false);
  const globalContext = useContext(GlobalContext);

  const [inviteCodeType, setInviteCodeType] = useState(0);
  const [inviteCode, setInviteCode] = useState(null);

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      await setTokenList(globalContext.tokenList);
    }
  }, [tokenList, globalContext.tokenList]);

  useEffect(async () => {
    console.log(Moralis.Core.isStarted);
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey:
          "CNkBmyT59hIHHLVPVKc2n4fbcvUwAR3iylv0YlDMKOjZLcN295ib9CpDjvhqNbw3",
      });
    }
  }, []);

  useEffect(async () => {
    if (globalContext.isWalletConnected) {
      await setAccount(globalContext.walletAddress);
    }
  }, [globalContext.walletAddress]);

  useEffect(async () => {
    if (chainSelectedCreate !== null) {
      await setToken("");
      await setEnableCustomToken(false);
      await setUserTokenList(null);
      try {
        const response = await Moralis.EvmApi.token.getWalletTokenBalances({
          address: globalContext.walletAddress,
          chain: Web3.utils.toHex(chainSelectedCreate),
        });
        await response.toJSON().map(async (ele) => {
          ele["canDeposit"] =
            (await checkTokenListed(ele.token_address, chainSelectedCreate)) !==
            ZERO_ADDRESS;
        });
        await setUserTokenList(response.toJSON());
      } catch (error) {
        console.log(error);
        await setUserTokenList([]);
      }
    }
  }, [chainSelectedCreate]);

  useEffect(async () => {
    if (chainSelectedDeposit !== null && tokenList !== null) {
      if (
        userTokenList !== null &&
        chainSelectedDeposit !== userTokenList[depositToken].chainId
      ) {
        await setDepositToken("");
      }
      // await setUserTokenList(null)
      try {
        let depositList = [];
        await Object.entries(tokenList[chainSelectedDeposit]).map(
          (ele, key) => {
            if (ele[0] !== ZERO_ADDRESS) depositList.push(ele[1]);
          }
        );
        await setUserTokenList(depositList);
      } catch (error) {
        console.log(error);
        await setUserTokenList([]);
      }
    }
  }, [chainSelectedDeposit, tokenList]);

  useEffect(async () => {
    if (Web3.utils.isAddress(token)) {
      setEnableCustomToken(true);
    } else {
      setFetchedTokenDetails(null);
      setEnableCustomToken(false);
    }
  }, [token]);

  const handleCreateDumperShield = async (network) => {
    if (
      token === ZERO_ADDRESS ||
      token === "" ||
      token === undefined ||
      token === null
    ) {
      notificationConfig.info("Invalid token address!");
      return 0;
    }

    if (unlockDate === "" || unlockDate === undefined || unlockDate === null) {
      notificationConfig.info("Invalid Vesting Days!");
      return 0;
    }
    // if (dao === ZERO_ADDRESS) {
    //     notificationConfig.info("Invalid DAO address!");
    //     return 0;
    // }

    // if (Number(transferTokensAmount) > (Number(balances[0]) / 10 ** 18)) {
    //     console.log('============');
    //     console.log(Number(transferTokensAmount));
    //     console.log(balances[0]);
    //     console.log((Number(balances[0]) / 10 ** 18));
    //     notificationConfig.info("Insufficient Balance For Transfer");
    //     return 0;
    // }

    setCreateDumperShieldLoading(true);

    let tokenAddress = "";
    if (enableCustomToken) {
      tokenAddress = token;
    } else {
      tokenAddress = userTokenList[token].token_address;
    }

    let createDumperShieldData = {};

    createDumperShieldData["token"] = tokenAddress;
    // createDumperShieldData['router'] =
    //   router === 'OTHER' ? manual_router : router;
    createDumperShieldData["unlockDate"] = new Date()
      .setDate(new Date().getDate() + Number(unlockDate))
      .toString()
      .slice(0, 10);
    createDumperShieldData["dao"] = dao;

    await createDumperShield(
      createDumperShieldData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        await globalContext.fetchTokenList();
        notificationConfig.success("Dumper Shield Created Successfully!");
        setCreateDumperShieldLoading(false);
      },
      async (error) => {
        setCreateDumperShieldLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const intOnly = async (event) => {
    if (event.shiftKey === true) event.preventDefault();

    var code = event.keyCode;

    if (
      (code >= 48 && code <= 57) ||
      (code >= 96 && code <= 105) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      code === 39 ||
      code === 46
    ) {
      // allowed characters
    } else event.preventDefault();
  };

  const handleApproveTokenTransfer = async () => {
    if (depositAmount === "" || depositAmount === "0" || depositAmount <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }

    if (depositToken === "") {
      notificationConfig.info("Please Select Deposit Token");
      return 0;
    }
    let chainId = localStorage.getItem("chainId");
    setApproveTokenTransferLoading(true);

    console.log(userTokenList);
    console.log(userTokenList[depositToken]);
    console.log(userTokenList[depositToken].token);

    let approveTokenTransferData = {};

    approveTokenTransferData["token"] = userTokenList[depositToken].token;
    approveTokenTransferData["spender"] =
      contractAddressesChainWise[chainId].DUMPERSHIELD_FACTORY_ADDRESS;
    approveTokenTransferData["amount"] = Web3.utils.toWei(
      depositAmount.toString(),
      "ether"
    );

    // otcDepositTokensData["orderId"] = orderDetails.orderId;
    // value = value / 100;
    // value = value * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // otcDepositTokensData["paymentAmount"] = Web3.utils.toWei(value.toString());
    // otcDepositTokensData["payableAmount"] = Web3.utils.toWei(value.toString());

    console.log(approveTokenTransferData);

    await approveTokenTransfer(
      approveTokenTransferData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        await globalContext.fetchTokenList();
        await setApproveTokenTransferLoading(false);
        await notificationConfig.success(
          "Token Transfer Approved Successfully!"
        );
      },
      async (error) => {
        setApproveTokenTransferLoading(false);

        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handleDepositTokens = async () => {
    if (depositAmount === "" || depositAmount === "0" || depositAmount <= 0) {
      notificationConfig.info("Please Select Valid Token Amount");
      return 0;
    }

    if (depositToken === "") {
      notificationConfig.info("Please Select Deposit Token");
      return 0;
    }

    if (position === null) {
      notificationConfig.info("Please Select Deposit Position");
      return 0;
    }

    setDepositTokensLoading(true);

    let otcDepositTokensData = {};

    otcDepositTokensData["token"] = userTokenList[depositToken].token;
    otcDepositTokensData["amount"] = Web3.utils.toWei(depositAmount);
    otcDepositTokensData["position"] = position;
    otcDepositTokensData["user"] = await getAccountaddress();

    // otcDepositTokensData["orderId"] = orderDetails.orderId;
    // depositAmount = depositAmount / 100;
    // depositAmount = depositAmount * Number(new BigNumber(orderDetails.value).div(new BigNumber(orderDetails.sellAmount)).toString())
    // otcDepositTokensData["paymentAmount"] = Web3.utils.toWei(value.toString());
    // otcDepositTokensData["payableAmount"] = Web3.utils.toWei(value.toString());

    console.log(otcDepositTokensData);

    await otcDepositTokens(
      otcDepositTokensData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        await globalContext.fetchTokenList();
        await setDepositTokensLoading(false);
        await notificationConfig.success("Tokens Deposited Successfully!");
      },
      async (error) => {
        setDepositTokensLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const fetchTokenDetails = async (event) => {
    await setFetchTokenDetailsLoading(true);
    let token = event.target.value;
    if (token !== "" && Web3.utils.isAddress(token)) {
      let tokenDetails = await getTokenDetails({
        token: token,
        user: await getAccountaddress(),
        chainId: chainSelectedCreate,
      });
      if (tokenDetails !== null) {
        await setFetchedTokenDetails(tokenDetails);
        await setFetchTokenDetailsLoading(false);
        notificationConfig.success("Successfully fetched token!");
      } else {
        notificationConfig.warning("Invalid token address!");
        await setFetchedTokenDetails(null);
        await setFetchTokenDetailsLoading(false);
        setToken("");
      }
    } else if (!Web3.utils.isAddress(token)) {
      notificationConfig.warning("Invalid token address!");
      await setFetchedTokenDetails(null);
      await setFetchTokenDetailsLoading(false);
      setToken("");
    }
  };

  const onToggle = (newIndex) =>
    setIndex(index === newIndex ? index : newIndex);
  const onToggle02 = (newDropDown) =>
    setDropDown(dropDown === newDropDown ? null : newDropDown);

  return (
    <>
      <MainContainer>
        <LeftMBX />
        <RightContainer>
          <DSTitle01 className="wow fadeInUp" data-wow-delay="0.1s">
            Add your project tokens to the Dumper Shield{" "}
            <span data-tip="Help Text" className="helpIco">
              <i className="fas fa-question-circle"></i>
            </span>
          </DSTitle01>
          <Tabs className="v2">
            <a
              className={showHowItWorks ? "active" : ""}
              onClick={() => {
                setShowHowItWorks(!showHowItWorks);
              }}
            >
              <i className="fas fa-exclamation-circle"></i> How it works
            </a>
          </Tabs>
          {showHowItWorks ? (
            <TabBox>
              <a
                className="close"
                onClick={() => {
                  setShowHowItWorks(false);
                }}
              >
                <i className="fas fa-times"></i>
              </a>
              <h5>How it works</h5>
              <p>
                Traditionally, discounted token recipients have harmed
                communities by selling tokens at lower prices, known as
                "dumping" on the community. To tackle this issue, projects
                introduced vesting models that lock discounted tokens for a
                specific period. However, this approach created a race to the
                bottom, leaving investors unsure whether to wait or dump tokens,
                risking slippage despite beating others.
              </p>
              <p>
                To solve this dilemma, the Dumper Shield was developed as a
                dynamic vesting model. It safeguards the community by
                eliminating exposure to dumping. Its goal is to ensure safe
                liquidity for token holders during the vesting period, aiming to
                reduce sell pressure once the period ends.
              </p>
            </TabBox>
          ) : null}

          <TabNav className="wow fadeInUp" data-wow-delay="0.9s">
            <TabLink
              onClick={() => onToggle(1)}
              className={index === 1 ? "active" : ""}
            >
              STEP 1: Create a Dumper Shield
            </TabLink>
            <TabLink
              onClick={() => onToggle(2)}
              className={index === 2 ? "active" : ""}
            >
              STEP 2: Deposit Tokens to The Dumper Shield
            </TabLink>
          </TabNav>
          <TabContent className={index === 1 ? "active" : ""}>
            <FormMBX01 className="mt-50">
              <FormSbxLabel className="L1">
                Chain on you want to create token shield
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu onClick={() => onToggle02(4)}>
                  {chainSelectedCreate !== null
                    ? CHAINS[Web3.utils.toHex(chainSelectedCreate)]
                    : "Select Network..."}
                  <div className="DDContainer">
                    <Collapse
                      className={"collapse " + (dropDown === 4 ? "active" : "")}
                      isOpen={dropDown === 4}
                      onChange={({ state }) => {}}
                      onInit={({ state }) => {}}
                    >
                      <div className="DDSubContainer">
                        {CHAINS ? (
                          Object.entries(CHAINS).map((ele, index) => {
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  setChainSelectedCreate(
                                    Web3.utils.toDecimal(ele[0])
                                  );
                                }}
                              >
                                {ele[1]}
                              </button>
                            );
                          })
                        ) : (
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={20}
                            width={40}
                          />
                        )}

                        {/* <button>BNB</button>
                                          <button>ETH</button>
                                          <button>FTM</button> */}
                      </div>
                    </Collapse>
                  </div>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01>
              <FormSbxLabel className="L1">
                Choose token to add to the Dumper Shield{" "}
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu onClick={() => onToggle02(1)}>
                  {token !== "" &&
                  userTokenList !== null &&
                  userTokenList[token]
                    ? userTokenList[token].symbol
                    : "Please select token....."}
                  <div className="DDContainer">
                    <Collapse
                      className={"collapse " + (dropDown === 1 ? "active" : "")}
                      isOpen={dropDown === 1}
                      onChange={({ state }) => {}}
                      onInit={({ state }) => {}}
                    >
                      <div className="DDSubContainer">
                        {userTokenList ? (
                          userTokenList.length > 0 ? (
                            userTokenList.map((ele, key) => {
                              return (
                                <button
                                  key={key}
                                  onClick={() => {
                                    setToken(key);
                                  }}
                                >
                                  {" "}
                                  {ele.symbol}{" "}
                                </button>
                              );
                            })
                          ) : (
                            <button> No tokens </button>
                          )
                        ) : (
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={20}
                            width={40}
                          />
                        )}
                        <button
                          onClick={() => {
                            setEnableCustomToken(true);
                            setToken("");
                          }}
                        >
                          {" "}
                          Other{" "}
                        </button>
                      </div>
                    </Collapse>
                  </div>
                </DDMenu>
              </FormSbxContent>
              {enableCustomToken ? (
                <FormMBX01 style={{ marginTop: "35px" }}>
                  <FormSbxLabel className="L1">
                    Add Custom Token Address{" "}
                  </FormSbxLabel>
                  <FormSbxContent>
                    <DDMenu className="formBox">
                      <span
                        className={
                          fetchedTokenDetails !== null
                            ? "badge01Green"
                            : "badge01"
                        }
                      >
                        {fetchTokenDetailsLoading ? (
                          <ReactLoaderSpinner
                            type="TailSpin"
                            color="#fff"
                            height={20}
                            width={40}
                          />
                        ) : fetchedTokenDetails !== null ? (
                          fetchedTokenDetails.symbol
                        ) : (
                          "TOKEN"
                        )}
                      </span>
                      <input
                        className="hasPadding-left"
                        type="text"
                        name="token"
                        onChange={(e) => setToken(e.target.value)}
                        onBlur={fetchTokenDetails}
                        placeholder={ZERO_ADDRESS}
                        value={token}
                        autoComplete="off"
                      />
                    </DDMenu>
                  </FormSbxContent>
                </FormMBX01>
              ) : null}
              {/* {router !== 'OTHER' ? null : (
                      <FormMBX01
                        className='wow fadeInRight mt-50'
                        data-wow-delay='0.1s'
                      >
                        <FormSbxLabel className='L1'>
                          AMM Service Router Address
                        </FormSbxLabel>
                        <FormSbxContent>
                          <DDMenu className='formBox'>
                            <span className='badge01'>ADDRESS</span>
                            <input
                              className='hasPadding-left'
                              type='text'
                              name='manual_router'
                              onChange={(e)=>setManualRouter(e.target.value)}
                              placeholder={ZERO_ADDRESS}
                              autoComplete='off'
                            />
                          </DDMenu>
                        </FormSbxContent>
                      </FormMBX01>
                    )} */}
            </FormMBX01>

            <FormMBX01 className="" data-wow-delay="">
              <FormSbxLabel className="L1">
                Choose due date to end vesting{" "}
                <span data-tip="Help Text" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  {/* <i className='fas fa-calendar-alt'></i> */}
                  <span className="badge01">DAYS</span>
                  <input
                    className="hasPadding-left"
                    type="text"
                    name="unlockDate"
                    onChange={(e) => setUnlockDate(e.target.value)}
                    onKeyDown={intOnly}
                    value={unlockDate}
                    autoComplete="off"
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>

            <FormMBX01
              style={{
                marginBottom: "35px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormSbxLabel style={{ display: "inline" }}>
                Please enter your invite code to apply for up to $100K grant
                <span data-tip="Help Text" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="noBorder ver2">
                  <div
                    className="md-radio md-radio-inline"
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      defaultChecked
                      id="radioic150"
                      name="inviteCodeType"
                      value={inviteCodeType === 0}
                      onChange={(e) => setInviteCodeType(0)}
                    />
                    <label htmlFor="radioic150" style={{ top: "-8px" }}></label>
                    <input
                      disabled={inviteCodeType !== 0}
                      style={{
                        borderRadius: "10px",
                        border: "2px solid #5a5e67",
                        background: "transparent",
                        fontSize: "16px",
                        fontWeight: "400",
                        padding: "15px",
                        color: "white",
                      }}
                      className="hasPadding-left "
                      type="text"
                      name="inviteCode"
                      onChange={(e) => setInviteCode(e.target.value)}
                      placeholder={"Type the Invite Code"}
                      value={inviteCode}
                    />
                  </div>
                  <div
                    className="md-radio md-radio-inline"
                    style={{ width: "50%" }}
                  >
                    <input
                      type="radio"
                      id="radioic151"
                      name="inviteCodeType"
                      value={inviteCodeType === 1}
                      onChange={(e) => setInviteCodeType(1)}
                    />
                    <label htmlFor="radioic151">
                      I don&apos;t have an invite code
                    </label>
                  </div>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>

            {/* <FormMBX01 className='wow fadeInRight' data-wow-delay='0.3s'>
                    <FormSbxLabel className='L1'>
                      Please enter your invite code to apply for up to $100K grant {' '}
                      <span data-tip='Help Text' className='helpIco'>
                        <i className='fas fa-question-circle'></i>
                      </span>
                    </FormSbxLabel>
                    <FormSbxContent>
                      <DDMenu className='noBorder ver2'>
                        <div className='md-radio md-radio-inline'>
                          <input
                            type='radio'
                            defaultChecked
                            id='radio145'
                            name='tokenTaxProtected'
                            value={inviteCodeType === 0 ? 'true' : 'false'}
                            onChange={(e) => {setInviteCodeType((e.target.value) === "true" ? 0 : 1)}}
                          />
                          <label htmlFor='radio145'>No</label>
                        </div>
                        <div className='md-radio md-radio-inline'>
                          <input
                            type='radio'
                            id='radio146'
                            name='tokenTaxProtected'
                            value={inviteCodeType === 1 ? 'true' : 'false'}
                            onChange={(e) => setInviteCodeType((e.target.value) === "true" ? 1 : 0)}
                          />
                          <label htmlFor='radio146'>I don't have an invite code </label>
                        </div>
                      </DDMenu>
                    </FormSbxContent>
                  </FormMBX01> */}
            {/*<FormMBX01 className='wow fadeInRight' data-wow-delay='0.1s'>
                    <FormSbxLabel className='L1'>
                      Choose a tax for gateway{' '}
                      <span data-tip='Help Text' className='helpIco'>
                        <i className='fas fa-question-circle'></i>
                      </span>
                    </FormSbxLabel>

                    <FormSbxContent>
                      <DDMenu className='formBox'>
                        <span className='badge01'>%</span>
                        <input
                          className='hasPadding-left'
                          type='text'
                          name='token'
                          onChange={(e)=>setToken(e.target.value)}
                          onBlur={fetchTokenDetails}
                          placeholder={ZERO_ADDRESS}
                          value={token}
                          autoComplete='off'
                        />
                      </DDMenu>
                    </FormSbxContent>
                  </FormMBX01> */}

            {/* <FormMBX01 className="wow fadeInRight" data-wow-delay="0.4s">
                            <FormSbxLabel className='L2'>Can the users vote to change the shield period?</FormSbxLabel>
                            <FormSbxContent className='ver2'>
                                <ROuter>
                                    <div className="md-radio md-radio-inline">
                                        <input type="radio" id="radio01" name="apradio01" />
                                        <label htmlFor="radio01">
                                            No
                                        </label>
                                    </div>
                                    <RInner>
                                        <div className="md-radio md-radio-inline">
                                            <input type="radio" id="radio02" name="apradio01" />
                                            <label htmlFor="radio02">
                                                Yes
                                            </label>
                                        </div>
                                        <MajorityBlock>
                                            <p>Majority require 75%</p>
                                        </MajorityBlock>
                                    </RInner>
                                </ROuter>
                            </FormSbxContent>
                        </FormMBX01>
                        <FormMBX01 className="wow fadeInRight" data-wow-delay="0.5s">
                            <FormSbxLabel>Choose a tax for gateway <span data-tip='Help Text' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                            <FormSbxContent >
                                <DDMenu className="formBox">
                                    <span className="badge01 badge-min">%</span>
                                    <input type="text" />
                                </DDMenu>
                            </FormSbxContent>
                        </FormMBX01> */}
            <FormMBX01 className="" data-wow-delay="">
              <FormSbxLabel></FormSbxLabel>
              <FormSbxContent>
                {account !== null ? (
                  createDumperShieldLoading ? (
                    <button className="PlaOrBTN01">
                      <ReactLoaderSpinner
                        type="ThreeDots"
                        color="#fff"
                        height={15}
                        width={80}
                      />
                    </button>
                  ) : chainSelectedCreate !== null &&
                    Web3.utils.toHex(chainSelectedCreate) !==
                      globalContext.chainId ? (
                    <button
                      className="PlaOrBTN01"
                      onClick={() => {
                        changeNetwork(Web3.utils.toHex(chainSelectedCreate));
                      }}
                    >
                      CHANGE NETWORK
                    </button>
                  ) : (
                    <button
                      className="PlaOrBTN01"
                      onClick={() => handleCreateDumperShield("BSC")}
                    >
                      CREATE A DUMPER SHIELD
                    </button>
                  )
                ) : (
                  <button
                    className="PlaOrBTN01"
                    onClick={() => {
                      metamask_connection(
                        localStorage.getItem("account"),
                        "ahrefClick"
                      );
                    }}
                  >
                    CONNECT WALLET
                  </button>
                )}
              </FormSbxContent>
            </FormMBX01>
            <div>
              <Divider />
              <TabTitle>
                For developers <i className="fas fa-caret-up"></i>
              </TabTitle>
              <TabPera>
                Call this function from your smart contracts, when users claims
                tokens from staking, presale or anyway
                <span data-html="true" data-tip="Something" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </TabPera>
              <CodeBlock>
                <p>
                  <span>//Create Dumper Shield for new token</span>
                </p>
                <p>function createDumperShield(</p>
                <p className="tabspace-1">
                  address token, <span>// token contract address</span>
                </p>
                <p className="tabspace-1">
                  // address router,{" "}
                  <span>
                    // Uniswap compatible AMM router address where exist Token
                    &#60;&#62; WETH pair
                  </span>
                </p>
                <p className="tabspace-1">
                  uint256 unlockDate,{" "}
                  <span>
                    // Epoch time (in second) when tokens will be unlocked
                  </span>
                </p>
                <p className="tabspace-1">
                  address dao{" "}
                  <span>
                    // Address of token's voting contract if exist. Otherwise =
                    address(0).
                  </span>
                </p>
                <p>)</p>
              </CodeBlock>
              <RightIcon>
                <button style={{ color: "white" }}>
                  <i
                    className="fas fa-copy"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "//Create Dumper Shield for new token  function createDumperShield(  address token, // token contract address  //address router, // Uniswap compatible AMM router address where exist Token <> WETH pair  uint256 unlockDate, // Epoch time (in second) when tokens will be unlocked  address dao // Address of token's voting contract if exist. Otherwise = address(0).  )"
                      );
                    }}
                  ></i>
                </button>
              </RightIcon>
            </div>
          </TabContent>
          <TabContent className={index === 2 ? "active" : ""}>
            <FormMBX01 className="mt-50">
              <FormSbxLabel className="L1">
                Chain on you want to deposit token
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu onClick={() => onToggle02(4)}>
                  {chainSelectedDeposit !== null
                    ? CHAINS[Web3.utils.toHex(chainSelectedDeposit)]
                    : "Select Network..."}
                  <div className="DDContainer">
                    <Collapse
                      className={"collapse " + (dropDown === 4 ? "active" : "")}
                      isOpen={dropDown === 4}
                      onChange={({ state }) => {}}
                      onInit={({ state }) => {}}
                    >
                      <div className="DDSubContainer">
                        {CHAINS ? (
                          Object.entries(CHAINS).map((ele, index) => {
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  setChainSelectedDeposit(
                                    Web3.utils.toDecimal(ele[0])
                                  );
                                }}
                              >
                                {ele[1]}
                              </button>
                            );
                          })
                        ) : (
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={20}
                            width={40}
                          />
                        )}

                        {/* <button>BNB</button>
                                        <button>ETH</button>
                                        <button>FTM</button> */}
                      </div>
                    </Collapse>
                  </div>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01>
              <FormSbxLabel className="L1">
                Choose tokens to deposit{" "}
              </FormSbxLabel>

              <FormSbxContent>
                <DDMenu onClick={() => onToggle02(3)}>
                  {depositToken !== "" &&
                  userTokenList !== null &&
                  userTokenList[depositToken]
                    ? userTokenList[depositToken].symbol
                    : "Please select deposit token...."}
                  <div className="DDContainer">
                    <Collapse
                      className={"collapse " + (dropDown === 3 ? "active" : "")}
                      isOpen={dropDown === 3}
                      onChange={({ state }) => {}}
                      onInit={({ state }) => {}}
                    >
                      <div className="DDSubContainer">
                        {userTokenList ? (
                          userTokenList.length > 0 ? (
                            userTokenList.map((ele, key) => {
                              return (
                                <button
                                  key={key}
                                  onClick={() => {
                                    setDepositToken(key);
                                  }}
                                >
                                  {ele.symbol}
                                </button>
                              );
                            })
                          ) : (
                            <button>No tokens</button>
                          )
                        ) : (
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={20}
                            width={40}
                          />
                        )}
                      </div>
                    </Collapse>
                  </div>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
            <FormMBX01 className="" data-wow-delay="">
              <FormSbxLabel className="L1">Amount to deposit </FormSbxLabel>

              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    className="hasPadding-left"
                    type="text"
                    name="depositAmount"
                    onChange={(e) => setDepositAmount(e.target.value)}
                    onKeyDown={intOnly}
                    value={depositAmount}
                    autoComplete="off"
                  />
                </DDMenu>
              </FormSbxContent>
              <div style={{ alignItems: "right", marginTop: "4px" }}>
                [Balance:{" "}
                {userTokenList !== null &&
                depositToken !== "" &&
                userTokenList[depositToken]
                  ? Number(
                      Web3.utils.fromWei(
                        userTokenList[depositToken].mainBalance
                      )
                    ).toFixed(4)
                  : 0}
                ]
              </div>
            </FormMBX01>
            <FormMBX01>
              <FormSbxLabel>
                Position
                <span
                  data-tip="Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice."
                  className="helpIco"
                >
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="noBorder ver2">
                  <div
                    className="md-radio md-radio-inline"
                    style={{ width: "30%" }}
                  >
                    <input
                      type="radio"
                      defaultChecked
                      id="radioty159"
                      name="position"
                      value={position === 0}
                      onClick={(e) => setPosition(0)}
                    />
                    <label htmlFor="radioty159">
                      1st
                      <span data-tip="1st Position" className="helpIco">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                  </div>
                  <div
                    className="md-radio md-radio-inline"
                    style={{ width: "30%" }}
                  >
                    <input
                      type="radio"
                      id="radioty160"
                      name="position"
                      value={position === 1}
                      onClick={(e) => setPosition(1)}
                    />
                    <label htmlFor="radioty160">
                      2nd
                      <span data-tip="2nd Position" className="helpIco">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                  </div>
                  <div
                    className="md-radio md-radio-inline"
                    style={{ width: "30%" }}
                  >
                    <input
                      type="radio"
                      id="radioty161"
                      name="position"
                      value={position === 2}
                      onClick={(e) => setPosition(2)}
                    />
                    <label htmlFor="radioty161">
                      3rd
                      <span data-tip="3rd Position" className="helpIco">
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                  </div>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
            {/* <FormMBX01 className='wow fadeInRight' data-wow-delay='0.6s'>
                    <FormSbxLabel>Choose vesting position <span
                      data-html='true'
                      data-tip='First position vs second position vs third position<br/>
                      The Dumper Shield comprises three classes of positions: 1st, 2nd, and 3rd. The position determines the order in which liquidity from the Gateway is disbursed among the positions. Once there are no holders on the position above, the liquidity flows to the next position, all the way to the last.<br/><br/>
                      While the first position may have a variable vesting period based on voting, the second and third positions have an infinite vesting period. Tokens always remain behind the Dumper Shield but are still available to engage with liquidity via the OTC and through the Gateway.<br/><br/>
                      For example, Qwantum Finance Labs uses the first position to place investors who actually paid to purchase tokens, while the second position is used to place investors who teleport their positions from other Qwantum Finance Labs projects. The last position is used for placing users who receive tokens for free as rewards or airdrops. This order makes sense because users who receive free tokens may have different selling behavior than investors who bought the tokens.<br/>
                      That being said, each project can decide how to utilize these three positions according to their specific needs.<br/><br/>
                      Dumper Shield Position Examples<br/><br/>
                      1st position supply 10,000 tokens <br/>
2nd position supply 5,000 tokens<br/>
3rd position supply 2,000 tokens <br/>
Day 1 the gateway determines 6,000 worth of tokens are available<br/>
Day 2 the gateway determines additional 8,000 worth of tokens are available<br/>
Day 3 the gateway determines additional 1,000 worth of tokens are available<br/>
In day 1,  the 6,000 token liquidity available will be used to buyout orders in the 1st position. Ordres in 2nd and 3rd positions remain pending<br/>
In day 2,  the 9,000 token liquidity available will be used to buyout the remaining 2,000 orders in the 1st position, and the 5,000 tokens liquidity available leftover will be used to buyout the 5,000 orders in the 2st position. Ordres in 3rd positions remain pending.<br/>
In day 3,  the 1,000 token liquidity available will be used to buyout the partia of the  2,000 orders in the 3st position, and 1,000 orders on 3rd positions remain pending.'
                      className='helpIco'
                    >
                      <i className='fas fa-question-circle'></i>
                    </span></FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="noBorder ver2">
                            <div className="md-radio md-radio-inline">
                                <input type="radio" defaultChecked id="radioty145" name="tokenTaxProtected" value="false"  /><label htmlFor="radioty145">1st</label>
                            </div>
                            <div className="md-radio md-radio-inline">
                                <input type="radio" id="radioty146" name="tokenTaxProtected" value="true" /><label htmlFor="radioty146">2nd</label>
                            </div>
                            <div className="md-radio md-radio-inline">
                                <input type="radio" id="radioty147" name="tokenTaxProtected" value="true" /><label htmlFor="radioty147">3rd</label>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                  </FormMBX01> */}
            <FormMBX01 className="" data-wow-delay="">
              <FormSbxLabel></FormSbxLabel>
              <FormSbxContent>
                {account !== null ? (
                  tokenList !== null &&
                  userTokenList !== null &&
                  depositToken !== "" &&
                  tokenList[chainSelectedDeposit] &&
                  userTokenList[depositToken] ? (
                    chainSelectedDeposit !== null &&
                    Web3.utils.toHex(chainSelectedDeposit) !==
                      globalContext.chainId ? (
                      <button
                        className="PlaOrBTN01"
                        onClick={() => {
                          changeNetwork(Web3.utils.toHex(chainSelectedDeposit));
                        }}
                      >
                        CHANGE NETWORK
                      </button>
                    ) : Number(userTokenList[depositToken].allowance) >=
                      Number(depositAmount) * 10 ** 18 ? (
                      depositTokensLoading ? (
                        <button className="PlaOrBTN01">
                          <ReactLoaderSpinner
                            type="ThreeDots"
                            color="#fff"
                            height={15}
                            width={80}
                          />
                        </button>
                      ) : (
                        <button
                          className="PlaOrBTN01"
                          onClick={() => handleDepositTokens("BSC")}
                        >
                          DEPOSIT TOKENS TO THE DUMPER SHIELD
                        </button>
                      )
                    ) : Number(userTokenList[depositToken].mainBalance) <
                      Number(depositAmount) * 10 ** 18 ? (
                      <button className="PlaOrBTN01" onClick={() => {}}>
                        Insufficient Balance
                      </button>
                    ) : approveTokenTransferLoading ? (
                      <button className="PlaOrBTN01">
                        <ReactLoaderSpinner
                          type="ThreeDots"
                          color="#fff"
                          height={15}
                          width={80}
                        />
                      </button>
                    ) : (
                      <button
                        className="PlaOrBTN01"
                        onClick={() => handleApproveTokenTransfer()}
                      >
                        APPROVE TOKENS TO THE DUMPER SHIELD
                      </button>
                    )
                  ) : account === null ? (
                    <button
                      className="PlaOrBTN01"
                      onClick={() => {
                        metamask_connection(
                          localStorage.getItem("account"),
                          "ahrefClick"
                        );
                      }}
                    >
                      CONNECT WALLET
                    </button>
                  ) : chainSelectedDeposit !== null ? (
                    <button className="PlaOrBTN01">Select Token</button>
                  ) : (
                    <button className="PlaOrBTN01">Select Network</button>
                  )
                ) : (
                  <button className="PlaOrBTN01">
                    <ReactLoaderSpinner
                      type="ThreeDots"
                      color="#fff"
                      height={15}
                      width={80}
                    />
                  </button>
                )}
              </FormSbxContent>
            </FormMBX01>
            <Divider />
            <TabTitle>
              For developer <i className="fas fa-caret-up"></i>
            </TabTitle>
            <TabPera>
              <strong>Deposit tokens to the dumper shield automatically</strong>{" "}
              <br />
              Call this function from your smart contracts, when users claims
              tokens from staking, presale or anyway{" "}
              <span data-html="true" data-tip="Something" className="helpIco">
                <i className="fas fa-question-circle"></i>
              </span>
            </TabPera>
            <CodeBlock>
              <p>
                <span>
                  //Deposit tokens to user's address into Dumper Shield in
                  specific position. Should be called approve() before deposit.
                </span>
              </p>
              <p>function depositPosition(</p>
              <p className="tabspace-1">
                address token, <span>// token contract address</span>
              </p>
              <p className="tabspace-1">
                uint256 amount, <span>// amount of tokens to deposit</span>
              </p>
              <p className="tabspace-1">
                address user, <span>// user address who receives token</span>
              </p>
              <p className="tabspace-1">
                uint256 position <span>// position in queue (0, 1, 2)</span>
              </p>
              <p>) public returns (bool) </p>
            </CodeBlock>
            <RightIcon>
              <button style={{ color: "white" }}>
                <i
                  className="fas fa-copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "// Deposit tokens to user's address into Dumper Shield. Should be called approve() before deposit. function deposit( address token,  // token contract address uint256 amount, // amount of tokens to deposit address user,    // user address who receives token uint256 position // position in queue (0, 1, 2)) public returns (bool)"
                    );
                  }}
                ></i>
              </button>
            </RightIcon>
          </TabContent>
        </RightContainer>
      </MainContainer>

      <DumperShieldStatus
        isOpen={popup01}
        dismiss={() => {
          setPopup01(false);
        }}
      />
      <ReactTooltip effect="solid" className="myTip" />
    </>
  );
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const AddNewChanel = styled.div`
  margin-bottom: 50px;
  a {
    font-size: 12px;
    color: #00f02b;
  }
`;

const MainContainer = styled(FlexDiv)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

const RightContainer = styled(FlexDiv)`
  width: calc(100% - 82px);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 18px 56px;
  align-content: baseline;
  ${Media.md} {
    padding: 25px 20px;
  }
  ${Media.sm} {
    width: 100%;
  }
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 19px;
  ${Media.md} {
    display: block;
    margin-bottom: 25px;
  }
  &.mt-50 {
    margin-top: 50px;
    ${Media.md} {
      margin-top: 35px;
    }
  }
`;

const FormSbxLabel = styled(FlexDiv)`
  width: 30%;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  line-height: 24px;
  ${Media.md} {
    width: 100%;
  }
  &.L1 {
    ${Media.md} {
      margin-bottom: 15px;
    }
  }
  &.L2 {
    ${Media.md} {
      margin-bottom: 15px;
    }
  }
  .md-checkbox {
    width: 100%;
    label {
      color: #c32b2d;
      font-size: 12px;
      padding-left: 22px;
      margin-bottom: 0;
      &:before {
        border: 2px solid #c32b2d !important;
        border-radius: 0;
        width: 14px;
        height: 14px;
        top: 7px;
      }
    }
    input[type="checkbox"]:checked + label:after {
      top: 6px;
      left: 3px;
      width: 15px;
      height: 8px;
      border: 3px solid #c32b2d;
      border-top-style: none;
      border-right-style: none;
      font-weight: bold;
    }
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: 70%;
  justify-content: flex-start;
  ${Media.md} {
    width: 100%;
  }
  &.ver2 {
    justify-content: space-between;
  }
  .md-checkbox {
    width: 33.33%;
    ${Media.md} {
      width: 100%;
    }
  }
  .md-radio {
    width: 28.33%;
    ${Media.md} {
      width: 100%;
    }
  }
  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    padding: 0 5px 0 40px;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }
  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }
  .radioTxt {
    font-weight: normal;
    font-size: 16px;
    margin-left: -50px;
  }
  .PlaOrBTN01 {
    min-width: 407px;
    font-size: 16px;
    font-weight: 700;
    height: 60px;
    color: #fff;
    border-radius: 10px;
    background-color: inherit;
    border: 2px solid #00f02b;
    box-shadow: 0 0 10px #00f02b;
    padding: 10px 40px;
    :hover {
      background-color: #00f02b;
    }
    ${Media.sm} {
      min-width: auto;
      width: 100%;
    }
  }
  ${Media.md} {
    .radioTxt {
      margin-left: 0;
    }
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 60px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    top: 17px;
    left: 17px;
    position: absolute;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
  }
  &.formBox {
    position: relative;
    input {
      width: 100%;
      height: 52px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      &.hasPadding-left {
        padding-left: 30px;
      }
    }
    :after {
      display: none;
    }
    .badge01 {
      background-color: #5a5e67;
      padding: 0 25px;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      &.badge-min {
        min-width: 60px;
      }
    }
    .badge01Green {
      background-color: #28d017;
      padding: 0 25px;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      &.badge-min {
        min-width: 60px;
      }
    }
    ${Media.md} {
      width: 100% !important;
      margin-bottom: 0px;
    }
    &.v2 {
      width: calc(50% - 33px);
      img {
        position: absolute;
        top: 18px;
        right: 20px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
    ${Media.md} {
      height: auto;
    }
  }
  .DDContainer {
    width: 100%;
    z-index: 1000;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .collapse.active {
    .DDSubContainer {
      z-index: 100;
    }
  }
  .helpIco i {
    top: 0;
    left: 0;
  }
`;

const MajorityBlock = styled(FlexDiv)`
  width: 70%;
  justify-content: flex-start;
  .formBox {
    width: calc(100% - 165px);
    margin-left: 30px;
    &.ml-0 {
      margin-left: 0px;
    }
    &.ver2 {
      width: 100%;
    }
    ${Media.md} {
      margin-left: 0px;
    }
  }
  ${Media.md} {
    width: 100%;
    justify-content: flex-start;
    margin: 20px 0px 0px;
  }
  p {
    margin: 0px;
    ${Media.md} {
      margin: 0px 0px 20px;
    }
  }
`;

const ROuter = styled(FlexDiv)`
  justify-content: space-between;
`;

const RInner = styled(FlexDiv)`
  width: calc(100% - 33.33%);
  justify-content: space-between;
  ${Media.md} {
    width: 100%;
  }
`;

const CodeBlock = styled.div`
  background-color: #242831;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin: 30px 0px 0;
  p {
    font-size: 11px;
    :first-child {
      margin-top: 0px;
    }
    :last-child {
      margin-bottom: 0px;
    }
    span {
      color: #5a5e67;
    }
    &.tabspace-1 {
      padding-left: 25px;
    }
  }
  ${Media.md} {
    margin: 30px 0px 0;
  }
`;
const DSTitle01 = styled(FlexDiv)`
  justify-content: flex-start;
  font: 700 36px/40px "Kanit", arial;
  color: #00f02b;
  .DSrPart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
  }
  ${Media.xs} {
    font: 700 30px/34px "Kanit", arial;
    display: block;
  }
  .linkBX01 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #5a5e67;
    font-weight: 300;
    text-transform: initial;
    a {
      color: #00f02b;
      margin: 0 8px;
      font-family: "IBM Plex Mono", Arial, sans-serif;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
      :first-child {
        ${Media.xs} {
          margin: 0px 8px 0px 0px;
        }
      }
    }
    ${Media.xs} {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
`;

const TabNav = styled(FlexDiv)`
  margin: 19px 0 0 0;
  align-items: stretch;
  ${Media.sm} {
    flex-flow: column;
  }
`;
const TabLink = styled.a`
  font-size: 21px;
  color: #fff;
  flex-grow: 1;
  width: 50%;
  border-bottom: 3px solid #393d46;
  text-align: center;
  font-weight: bold;
  line-height: 1.3;
  padding: 37px 10px;
  margin: 0;
  cursor: pointer;
  &.active {
    color: #00f02b;
    border-block-color: #00f02b;
  }
  ${Media.md} {
    font-size: 18px;
    padding: 25px 10px;
  }
  ${Media.sm} {
    width: 100%;
  }
`;
const TabContent = styled.div`
  width: 100%;
  display: none;
  &.active {
    display: block;
  }
`;
const TabTitle = styled.h4`
  font-size: 21px;
  color: #fff;
  margin: 46px 0 36px 0;
  i {
    color: #00f02b;
  }
`;
const TabPera = styled.p`
  font-size: 18px;
  margin: -10px 0 20px 0;
  strong {
    display: block;
  }
`;
const RightIcon = styled.div`
  text-align: right;
  margin: 16px 0 80px;
  font-size: 18px;
`;
const Divider = styled.div`
  height: 1px;
  margin: 67px -56px;
  background: #1c1f25;
`;
const TabBox = styled.div`
  background: #000;
  border: 1px solid #1e2127;
  padding: 30px 28px;
  position: relative;
  h5 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 25px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 36px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #1e2127;
    font-size: 16px;
    cursor: pointer;
  }
`;
const Tabs = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  margin-top: 48px;
  a {
    font-weight: bold;
    &.active {
      color: #00f02b;
    }
    &.active.color-red {
      color: #c32b2d;
    }
  }
  span {
    margin: 0 6px;
    color: #9a9a9a;
  }
  div {
    margin-left: auto;
    color: #9a9a9a;
  }
  ${Media.sm} {
    margin-top: 0;
    flex-flow: wrap;
    div {
      width: 100%;
      margin-top: 16px;
    }
  }
  &.v2 {
    margin-bottom: 48px;
    a {
      color: #5a5e67;
      font-weight: bold;
      &.active {
        color: #fff;
      }
    }
  }
`;
export default AddProjectTokens;
